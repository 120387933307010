import React from 'react';
import { Icon } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import styles from './StatusView.module.scss';
import { Text } from '../typography';

export default function StatusView({
  loading = false,
  status = true,
  show = false,
}) {
  return (
    <div className={show ? styles.show : styles.hide}>
      {loading ? (
        <Spinner
          size="sm"
          className={styles.spinner}
          animation="border"
          variant="primary"
        />
      ) : status ? (
        <span className={styles.container}>
          <Icon
            baseClassName="material-icons-outlined"
            className={styles.saveIcon}
          >
            done
          </Icon>
          <Text noMargin weight="semiBold" color="green">
            Saved
          </Text>
        </span>
      ) : (
        <span className={styles.container}>
          <Icon
            baseClassName="material-icons-outlined"
            className={styles.failIcon}
          >
            close
          </Icon>
          <Text noMargin weight="semiBold" color="red">
            There was an error saving, please try again
          </Text>
        </span>
      )}
    </div>
  );
}
