import React from 'react';
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineContent,
  TimelineSeparator,
  Timeline,
} from '@mui/lab';

import styles from './ActivityFeed.module.scss';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components/typography';
import { Icon } from '@mui/material';
import { colors } from '../../constants/misc';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function ActivityFeed({ history = [] }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.timelineContainer}>
      {history.length ? (
        <Timeline position="alternate">
          {history.map((e, index) => (
            <TimelineItem
              key={index}
              className={styles.clickable}
              onClick={() => {
                navigate(e.miniUrl);
              }}
            >
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <Text
                  textAlign={index % 2 === 1 ? 'left' : 'right'}
                  size="md"
                  weight="semiBold"
                  color="secondary"
                  noMargin
                >
                  {e.momentOfEvent.format('MMM Do, h:mma')}
                </Text>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{ backgroundColor: colors[e.color] }}>
                  <Icon>{e.icon}</Icon>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Text
                  textAlign={index % 2 === 0 ? 'left' : 'right'}
                  size="md"
                  weight="semiBold"
                  noMargin
                >
                  {e.title}
                </Text>
                <Text
                  textAlign={index % 2 === 0 ? 'left' : 'right'}
                  size="sm"
                  noMargin
                >
                  {e.description}
                </Text>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      ) : (
        <div>
          <img
            className={styles.emptyStateGraphic}
            src="https://opas-public.s3.us-east-2.amazonaws.com/webapp-assets/tasklist.png"
            alt="Task List"
          />
          <br />
          <Text size="md" noMargin weight="bold" textAlign="center">
            {t(keys.settings.NO_RECENT_HISTORY)}
          </Text>
          <Text
            size="sm"
            weight="semiBold"
            color="secondary"
            textAlign="center"
          >
            {t(keys.settings.GO_MAKE_TODAY_PRODUCTIVE)}
          </Text>
        </div>
      )}
    </div>
  );
}
