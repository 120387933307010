import { Icon } from '@mui/material';
import React from 'react';
import styles from './WorkflowStage.module.scss';
import classNames from 'classnames';

export default function WorkflowStage({
  title,
  subtitle,
  icon,
  selected,
  onClick,
  completed,
  workflow,
  selectedTitle,
  workflowKey,
  showTitle = true,
  disableBackwardFlow,
}) {
  const myIndex = workflow.findIndex((o) => o.key === workflowKey);
  const completedIndex = workflow.findIndex((o) => o.key === selectedTitle);

  const disabled = myIndex > completedIndex;

  return (
    <>
      <div
        className={classNames(
          styles.container,
          (disabled || disableBackwardFlow) && styles.disabled,
        )}
        onClick={disabled ? () => {} : onClick}
      >
        {showTitle && (
          <div className={styles.textContainer}>
            <p
              className={classNames(
                styles.title,
                !selected && styles.nonSelected,
              )}
            >
              {title}
            </p>
            <p
              className={classNames(
                styles.subtitle,
                !selected && styles.nonSelected,
              )}
            >
              {subtitle}
            </p>
          </div>
        )}
        <div
          className={classNames(
            styles.iconContainer,
            !selected && (styles.nonSelected, styles.nonSelectedIconContainer),
            completed && styles.iconContainerCompleted,
            selected && styles.selected,
          )}
        >
          <Icon
            baseClassName="material-icons-outlined"
            className={classNames(styles.icon, !selected && styles.nonSelected)}
          >
            {icon}
          </Icon>
        </div>
      </div>
    </>
  );
}
