import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import styles from './TemplateCsvModal.module.scss';
import { Text } from '../typography';
import Button from '../Button';
import { loader } from 'graphql.macro';
import { ProgressBar } from 'react-bootstrap';
import graphic from '../../assets/cloud_upload.svg';
import {
  csvImportModalVar,
  defaultCsvImportModalState,
  showToast,
} from '../../graphql/cache/modal';
import { toastLength } from '../../constants/misc';
import { useMutation } from '@apollo/client';
import { sleep } from '../../utilities/time';
import { validateCsv } from '../../utilities/csv_upload';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { templateFilePaths } from '../../constants/strings';
import * as xlsx from 'xlsx';
import { inputTypes } from '../../utilities/inspection';

const categoryMutation = loader('./TemplateCsvModal.category.graphql');
const questionMutation = loader('./TemplateCsvModal.question.graphql');

const REQUEST_THROTTLE = 100;

export default function TemplateCsvModal({
  show = false,
  onHide,
  template,
  refetch,
}) {
  const [addCategory] = useMutation(categoryMutation);
  const [addQuestion] = useMutation(questionMutation);
  const [completed, setCompleted] = useState(0);
  const [loading, setLoading] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
  const instructionsLink = (
    <a
      className={styles.resetAnchor}
      href={templateFilePaths.ImportInstructions}
      target="blank"
      download
    >
      <Text noMargin color="accentPrimary" weight="bold" textAlign="center">
        {t(keys.templates.CSV_INSTRUCTIONS)}
      </Text>
    </a>
  );

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setLoading(false);
        refetch();
        onHide();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    if (loading && numberOfQuestions && completed) {
      setProgress(Math.round((completed / numberOfQuestions) * 100));
    }

    if (numberOfQuestions === completed && loading) {
    }
  }, [loading, numberOfQuestions, completed]);

  const formatAdditional = ({ additional, max, min, inputType }) => {
    switch (inputType) {
      case inputTypes.table:
      case inputTypes.select:
      case inputTypes.multiSelect:
        return (
          additional
            ?.split(',')
            .map((option) => option.trim())
            .join('|') ?? []
        );
      case inputTypes.test:
        return (
          additional
            ?.split(',')
            .map((option) => option.trim())
            .join('|') ?? null
        );
      case inputTypes.number:
        return !!min || !!max ? `${min ?? ''}|${max ?? ''}` : null;
      default:
        return null;
    }
  };

  const handleExcelUpload = async (e) => {
    setCompleted(0);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = xlsx.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rawRows = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
      const nonEmptyRows = rawRows.filter((row) =>
        row.some((cell) => cell !== undefined && cell !== null && cell !== ''),
      );

      if (nonEmptyRows.length === 0) {
        showToast({
          title: 'Error',
          variant: 'danger',
          time: toastLength.lg,
          message: 'No data found in the Excel file.',
        });
        return;
      }
      const headers = nonEmptyRows[0];
      const dataRows = nonEmptyRows;

      const restructuredRows = dataRows.slice(1).map((row) => {
        const obj = {};
        headers?.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });

      const { error, rows } = validateCsv(restructuredRows);

      if (error) {
        csvImportModalVar(defaultCsvImportModalState);
        showToast({
          title: t(keys.templates.CSV_UPLOAD_ERROR),
          variant: 'danger',
          time: toastLength.lg,
          message: `${error}. ${t(keys.templates.CSV_UPLOAD_FAIL_MESSAGE)}`,
        });
        return;
      }
      let categories = {};

      if (template?.templateType === 'OBSERVATION') {
        categories = template.categories;
        setNumberOfQuestions(rows.length);
        setLoading(true);
      }

      if (template?.templateType !== 'OBSERVATION') {
        rows.forEach(
          (row) =>
            (categories[row.category] = {
              title: row.category,
              isBundle: row.bundle === 'YES' ? true : false,
            }),
        );

        setNumberOfQuestions(rows.length + Object.keys(categories).length);
        setLoading(true);

        for (let i = 0; i < Object.keys(categories).length; i++) {
          await sleep(REQUEST_THROTTLE);
          const key = Object.keys(categories)[i];
          const variables = {
            templateId: template.id,
            title: categories[key].title,
            isBundle: categories[key].isBundle,
          };
          const {
            data: {
              addInspectionTemplateCategory: { id: categoryId },
            },
          } = await addCategory({ variables });

          categories[key].id = categoryId;
          const targetCompleted = i + 1;
          setCompleted(targetCompleted);
        }
      }

      for (let i = 0; i < rows.length; i++) {
        await sleep(REQUEST_THROTTLE);
        const targetCompleted = Object.keys(categories).length + 1 + i;

        const {
          title,
          description,
          category,
          'input type': inputType,
          required,
          additional,
          min,
          max,
        } = rows[i];

        const additionalData = formatAdditional({
          max,
          min,
          additional,
          inputType,
        });

        const categoryId =
          template.templateType === 'OBSERVATION'
            ? categories[0].id
            : categories[category].id;

        const variables = {
          categoryId,
          templateId: template.id,
          title,
          description: description || '',
          inputType,
          required: required.toUpperCase() === 'YES',
          additionalData,
        };
        await addQuestion({ variables });
        setCompleted(targetCompleted);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.header}>
            <Text
              className={styles.header}
              size="lg"
              weight="semiBold"
              noMargin
            >
              {t(keys.action.IMPORT, { variable: t(keys.common.FILE) })}
            </Text>
            <Icon className={styles.closeIcon} onClick={() => onHide()}>
              close
            </Icon>
          </div>
          {loading ? (
            <div className={styles.spinnerContainer}>
              <ProgressBar animated now={progress} />
            </div>
          ) : (
            <>
              <img src={graphic} className={styles.graphic} alt="csv" />
              <br />
              <Text textAlign="center" size="lg" weight="semiBold" noMargin>
                {t(keys.action.ADD, { variable: t(keys.templates.CSV_FILE) })}
              </Text>
              <div className={styles.uploadButton}>
                <label>
                  <Text
                    noMargin
                    weight="semiBold"
                    className={styles.fileUploadButton}
                    textAlign="center"
                  >
                    <input
                      className={styles.hideDefault}
                      type={'file'}
                      id={'csvFileInput'}
                      accept=".csv,.xlsx"
                      onChange={handleExcelUpload}
                      onClick={() => {}}
                    />
                    {t(keys.action.SELECT_VARIABLE, {
                      variable: t(keys.common.FILE),
                    })}
                  </Text>
                </label>
              </div>
              <Text
                textAlign="center"
                size="md"
                weight="semiBold"
                noMargin
                color="secondary"
              >
                {t(keys.templates.CSV_UPLOAD_MODAL_TEXT)}
              </Text>
              {instructionsLink}
              <Text
                textAlign="center"
                size="md"
                weight="semiBold"
                noMargin
                color="secondary"
              >
                or
              </Text>
              <a
                className={styles.resetAnchor}
                href={templateFilePaths.ExcelTemplate}
                target="blank"
                download={true}
              >
                <Button
                  className={styles.downloadButton}
                  size="md"
                  value={t(keys.templates.DOWNLOAD_EXCEL_TEMPLATE)}
                />
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;
}
