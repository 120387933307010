import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../providers/UserProvider';
import CustomSpinner from '../components/CustomSpinner';

export default function Auth0Landing() {
  const navigate = useNavigate();
  const { user } = useCurrentUser();

  useEffect(() => {
    navigate(`/workspace/${user.lastWorkspaceId}/dashboard`);
  }, [navigate, user?.lastWorkspaceId]);

  return <CustomSpinner text={'Directing to Dashboard...'} />;
}
