import { useMemo } from 'react';
import { reviewStatus } from '../../../constants/misc';

export const useReviewFooterHelpers = ({
  reviewers,
  currentUser,
  participants,
  inspectionType,
}) => {
  const isReviewer = useMemo(() => {
    return reviewers.some((r) => r.user.id === currentUser.id);
  }, [currentUser.id, reviewers]);

  const isParticipant = useMemo(() => {
    return inspectionType === 'INCIDENT'
      ? true
      : participants?.some((u) => u.id === currentUser.id);
  }, [currentUser.id, inspectionType, participants]);

  const isApproved = useMemo(() => {
    return reviewers.some((r) => r.status === reviewStatus.approved);
  }, [reviewers]);

  const hasChangeRequest = useMemo(() => {
    return reviewers.some((r) => r.status === reviewStatus.changeRequest);
  }, [reviewers]);

  return { isReviewer, isParticipant, isApproved, hasChangeRequest };
};
