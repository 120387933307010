import { Icon } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './Accordian.module.scss';
import { Text } from './typography';
export default function Accordian({
  title,
  children,
  message = '',
  messageVariant = 'primary',
  size = 'lg',
  button = false,
  onClick,
  icon = null,
  iconProps = {},
  accordianSize = false,
  isBundle = false,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className={!accordianSize ? styles.container : styles.smallContainer}>
      <div
        className={classNames(
          !accordianSize ? styles.header : styles.smallHeader,
          styles.icon,
        )}
        onClick={() => !button && setOpen(!open)}
      >
        <div
          className={styles.withIcon}
          style={{
            flexDirection:
              iconProps.position !== 'left' ? 'row-reverse' : 'row',
          }}
        >
          {icon && (
            <Icon
              baseClassName={
                iconProps.outlined
                  ? 'material-icons-outlined'
                  : 'material-icons'
              }
              sx={{ color: iconProps.color ? iconProps.color : '' }}
              className={styles.accordianIcon}
            >
              {icon}
            </Icon>
          )}
          <Text
            weight="bold"
            size={size}
            noMargin
            noSelect
            onClick={() => setOpen(!open)}
          >
            {title}
          </Text>
        </div>

        <div className={styles.rightContainer} onClick={() => setOpen(!open)}>
          {!isBundle && (
            <Text
              color={messageVariant}
              weight="semiBold"
              size="md"
              noMargin
              noSelect
            >
              {message}
            </Text>
          )}
          <Icon>{open ? 'expand_less' : 'expand_more'}</Icon>
        </div>
      </div>

      <div className={styles[open ? 'showContainer' : 'hideContainer']}>
        {children}
      </div>
    </div>
  );
}
