import React, { useState } from 'react';
import logo from '../assets/logo_white_horiz.svg';
import logoColor from '../assets/logo_blue_black.svg';
import background from '../assets/logo_white_icon.svg';
import styles from './Auth0Login.module.scss';
import { Button } from '../components';
import { Text } from '../components/typography';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { privacyPolicy } from '../constants/endpoints';
import CustomSpinner from '../components/CustomSpinner';

export default function Login() {
  const { loginWithRedirect, isLoading } = useAuth0();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    loginWithRedirect();
    setLoading(false);
  };

  return isLoading || loading ? (
    <CustomSpinner text={'Authenticating...'} />
  ) : (
    <>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.leftChild}>
            <img alt="Opas logo" className={styles.logo} src={logo} />
            <p className={styles.leftTitle}>{t(keys.utilities.LEFT_HEADER)}</p>
            <p className={styles.leftSubtitle}>
              {t(keys.utilities.LEFT_SUBTEXT)}
            </p>
            <img
              alt="background graphic"
              className={styles.backgroundGraphic}
              src={background}
            />
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.loginContainerParent}>
            <div className={styles.loginContainer}>
              <img
                className={styles.mobileLogo}
                alt="opas logo"
                src={logoColor}
              />
              <Text size="xl" noMargin noSelect>
                {t(keys.utilities.SIGN_IN)}
              </Text>
              <Text>{t(keys.utilities.WELCOME_BACK)}</Text>
              <br />
              <Button
                className={styles.button}
                value={t(keys.utilities.SIGN_IN)}
                onClick={() => handleLogin()}
              />
              <div className={styles.optionsContainer}>
                <div className={styles.registerLink}>
                  {t(keys.companyRegistration.COMPANY_REG_PT1)}
                  <a
                    rel="noreferrer"
                    className={styles.privacyLink}
                    href={`${window.origin}/company-sign-up`}
                  >
                    {t(keys.companyRegistration.COMPANY_REG_PT2)}
                  </a>
                </div>
                <br />
              </div>
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              className={styles.privacyLink}
              href={privacyPolicy}
            >
              {t(keys.companyRegistration.PRIVACY_POLICY)}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
