import { UNAUTHENTICATED_USER } from '../../constants/auth';

export function checkGraphError(error) {
  if (
    error.extensions.code === UNAUTHENTICATED_USER &&
    localStorage.getItem('token')
  ) {
    localStorage.clear();
  }
}
