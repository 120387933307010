import admin from './admin.svg';
import analytics from './analytics.svg';
import award from './award.svg';
import bell from './bell.svg';
import bellBlack from './bell_black.svg';
import building from './building.svg';
import buildingBlack from './building_black.svg';
import calendarFull from './calendar_full.svg';
import calendarToday from './calendar_today.svg';
import chevronRight from './chevron_right.svg';
import close from './close.svg';
import contact from './contact.svg';
import dashboard from './dashboard.svg';
import edit from './edit.svg';
import editWhite from './edit_white.svg';
import gavel from './gavel.svg';
import gear from './gear.svg';
import help from './help.svg';
import leave from './leave.svg';
import lightbulb from './lightbulb.svg';
import link from './link.svg';
import localShippingBlack from './local_shipping_black.svg';
import lock from './lock.svg';
import manageAccounts from './manage_accounts.svg';
import medical from './medical.svg';
import money from './money.svg';
import people from './people.svg';
import permMedia from './perm_media.svg';
import plane from './plane.svg';
import profile from './profile.svg';
import search from './search.svg';
import timer from './timer.svg';
import truck from './truck.svg';
import menuBlack from './menu_black.svg';

const icons = {
  white: {
    admin,
    analytics,
    award,
    bell,
    building,
    calendarFull,
    calendarToday,
    contact,
    dashboard,
    edit: editWhite,
    gavel,
    gear,
    help,
    leave,
    lightbulb,
    medical,
    money,
    permMedia,
    people,
    plane,
    profile,
    timer,
    truck,
  },
  black: {
    bell: bellBlack,
    building: buildingBlack,
    chevronRight,
    close,
    edit,
    link,
    localShipping: localShippingBlack,
    lock,
    manageAccounts,
    menu: menuBlack,
    search,
  },
};
export default icons;
