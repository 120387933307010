import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import styles from './DateRangeSelector.module.scss';

export default function DateRangeSelector({
  onChange,
  minLabel = 'Min Date',
  maxLabel = 'Max Date',
  values,
}) {
  const style = {
    actionBar: { actions: ['clear'] },
    textField: {
      InputLabelProps: {
        shrink: true,
        error: false,
      },
      InputProps: {
        style: {
          fontSize: '0.8rem',
          height: '2rem',
        },
        error: false,
      },
    },
  };
  const handleMinChange = (newDate) => {
    onChange({
      min: newDate && newDate.isValid() ? newDate.format('YYYY-MM-DD') : null,
      max: values.max,
    });
  };

  const handleMaxChange = (newDate) => {
    onChange({
      min: values.min,
      max: newDate && newDate.isValid() ? newDate.format('YYYY-MM-DD') : null,
    });
  };

  return (
    <div className={styles.container}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          className={styles.input}
          slotProps={style}
          label={minLabel}
          inputFormat="YYYY-MM-DD"
          onChange={(newMoment) => {
            handleMinChange(newMoment);
          }}
          value={moment(values.min) || null}
          clearable
          TextFieldProps={{
            style: {
              width: '3rem', // Adjust width as needed
            },
          }}
        />
        <DatePicker
          className={styles.input}
          slotProps={style}
          label={maxLabel}
          inputFormat="YYYY-MM-DD"
          value={moment(values.max) || null}
          onChange={(newMoment) => {
            handleMaxChange(newMoment);
          }}
          clearable
        />
      </LocalizationProvider>
    </div>
  );
}
