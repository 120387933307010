import { Text } from '../typography';
import styles from './EditVehicleModal.module.scss';
import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import Button from '../Button';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { assetFields, vehicleKeys } from '../../utilities/vehicles';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const updateVehicleMutation = loader('./EditVehicleModal.graphql');

export default function EditVehicleModal({ vehicle, show = false, onHide }) {
  const [updateVehicle] = useMutation(updateVehicleMutation);
  const [editedVehicle, setEditedVehicle] = useState(vehicle);
  const { t } = useTranslation();

  const dataType = useMemo(
    () => (vehicleKeys.includes(vehicle?.type) ? 'vehicle' : 'asset'),
    [vehicle?.type],
  );

  useEffect(() => {
    setEditedVehicle(vehicle);
  }, [vehicle]);

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalHeader}>
            <Text color="accentPrimary" size="lg" noMargin weight="bold">
              {t(keys.assets.EDIT_INFORMATION)}
            </Text>
            <div className={styles.closeIcon}>
              <Icon
                baseClassName="material-icons-outlined"
                className={classNames(styles.iconButton, styles.red)}
                onClick={() => {
                  onHide();
                }}
              >
                close
              </Icon>
            </div>
          </div>
          <div className={styles.editVehicleContainer}>
            <div className={styles.row}>
              <div className={styles.formLabel}>
                <Text noMargin size="md" weight="bold">
                  {t(assetFields.make[dataType].title)}
                </Text>
                <Form.Control
                  name="make"
                  type="text"
                  className={styles.infoInput}
                  value={editedVehicle?.make || ''}
                  onChange={(e) =>
                    setEditedVehicle({
                      ...editedVehicle,
                      make: e.target.value,
                    })
                  }
                ></Form.Control>
              </div>
              <div className={styles.formLabel}>
                <Text noMargin size="md" weight="bold">
                  {t(keys.assets.MODEL)}
                </Text>
                <Form.Control
                  type="text"
                  className={styles.infoInput}
                  value={editedVehicle.model || ''}
                  onChange={(e) =>
                    setEditedVehicle({
                      ...editedVehicle,
                      model: e.target.value,
                    })
                  }
                ></Form.Control>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formLabel}>
                <Text noMargin size="md" weight="bold">
                  {t(keys.assets.YEAR)}
                </Text>
                <Form.Control
                  min={1950}
                  type="number"
                  value={editedVehicle.year || ''}
                  onChange={(e) =>
                    setEditedVehicle({
                      ...editedVehicle,
                      year: e.target.valueAsNumber,
                    })
                  }
                ></Form.Control>
              </div>
              <div className={styles.formLabel}>
                <Text noMargin size="md" weight="bold">
                  {t(keys.common.COLOUR)}
                </Text>
                <Form.Control
                  type="text"
                  value={editedVehicle.color || ''}
                  onChange={(e) =>
                    setEditedVehicle({
                      ...editedVehicle,
                      color: e.target.value,
                    })
                  }
                ></Form.Control>
              </div>
            </div>
            {dataType === 'vehicle' ? (
              <div className={styles.row}>
                <div className={styles.formLabel}>
                  <Text noMargin size="md" weight="bold">
                    {t(keys.assets.ODOMETER)}
                  </Text>
                  <div className={styles.odometer}>
                    <Form.Control
                      type="number"
                      value={editedVehicle.odometer || ''}
                      onChange={(e) =>
                        setEditedVehicle({
                          ...editedVehicle,
                          odometer: e.target.valueAsNumber,
                        })
                      }
                    ></Form.Control>
                    <Form.Select
                      data-cy="input-odometer"
                      value={editedVehicle.isMetric ? 'kilometers' : 'miles'}
                      onChange={(e) => {
                        setEditedVehicle({
                          ...editedVehicle,
                          isMetric:
                            e.target.value === 'kilometers' ? true : false,
                        });
                      }}
                    >
                      <option value="miles">{t(keys.assets.MILES)}</option>
                      <option value="kilometers">
                        {t(keys.assets.KILOMETERS)}
                      </option>
                    </Form.Select>
                  </div>
                </div>
                <div className={styles.formLabel}>
                  <Text noMargin size="md" weight="bold">
                    {t(keys.assets.LICENSE_PLATE)}
                  </Text>
                  <Form.Control
                    type="text"
                    value={editedVehicle.licensePlate || ''}
                    onChange={(e) =>
                      setEditedVehicle({
                        ...editedVehicle,
                        licensePlate: e.target.value,
                      })
                    }
                  ></Form.Control>
                </div>
              </div>
            ) : null}
            <div className={styles.row}>
              <div className={styles.formLabel}>
                <Text noMargin size="md" weight="bold">
                  {t(keys.assets.UNIT_NUMBER)}
                </Text>
                <Form.Control
                  type="text"
                  value={editedVehicle.unitNumber || ''}
                  onChange={(e) =>
                    setEditedVehicle({
                      ...editedVehicle,
                      unitNumber: e.target.value,
                    })
                  }
                ></Form.Control>
              </div>
              <div className={styles.formLabel}>
                <Text noMargin size="md" weight="bold">
                  {t(assetFields.vin[dataType].title)}
                </Text>
                <Form.Control
                  minLength={5}
                  maxLength={15}
                  type="text"
                  value={editedVehicle.vehicleIdentificationNumber || ''}
                  onChange={(e) =>
                    setEditedVehicle({
                      ...editedVehicle,
                      vehicleIdentificationNumber: e.target.value,
                    })
                  }
                ></Form.Control>
              </div>
            </div>
            {vehicle.type !== 'Light Vehicle' ? (
              <div className={styles.row}>
                <div className={styles.formLabel}>
                  <Text size="md" weight="bold" noSelect noMargin>
                    {t(keys.assets.OPERATING_HOURS)}
                  </Text>
                  <Form.Control
                    type="number"
                    value={editedVehicle.hours}
                    onChange={(e) => {
                      setEditedVehicle({
                        ...editedVehicle,
                        hours: parseFloat(e.target.value) || undefined,
                      });
                    }}
                  ></Form.Control>
                </div>
                <div className={styles.formLabel}>
                  <Text
                    size="md"
                    weight="bold"
                    noSelect
                    noMargin
                    className={styles.subtitle}
                  >
                    {t(keys.assets.END_OF_LIFE_HOURS)}
                  </Text>
                  <Form.Control
                    type="number"
                    value={editedVehicle.endOfLifeHours}
                    onChange={(e) => {
                      setEditedVehicle({
                        ...editedVehicle,
                        endOfLifeHours: parseInt(e.target.value) || undefined,
                      });
                    }}
                  ></Form.Control>
                </div>
              </div>
            ) : null}

            <div className={styles.row}>
              <div className={styles.formLabel}>
                <Text
                  size="md"
                  weight="bold"
                  noSelect
                  noMargin
                  className={styles.subtitle}
                >
                  {t(keys.assets.END_OF_LIFE_DATE)}
                </Text>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: false,
                        InputProps: {
                          style: {
                            height: '2.5rem',
                            backgroundColor: 'white',
                            width: '100%',
                          },
                        },
                      },
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                    sx={{ width: '100%' }}
                    value={moment(vehicle.endOfLife)}
                    onChange={(newMoment) => {
                      setEditedVehicle({
                        ...editedVehicle,
                        endOfLife: newMoment,
                      });
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className={styles.formLabel} />
            </div>
          </div>
          <div className={styles.button}>
            <Button
              disabled={editedVehicle === vehicle}
              className={styles.saveButton}
              value={t(keys.action.SAVE)}
              size="md"
              type="submit"
              variant="primary"
              onClick={() => {
                updateVehicle({
                  variables: {
                    id: vehicle.id,
                    ...editedVehicle,
                  },
                }).then(() => onHide());
              }}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
