import styles from './Expense.module.scss';
import { Text } from '../../components/typography';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import { useQueryParams } from '../../hooks/misc';
import { DropdownText } from '../../components/dropdowns/DropdownText';
import {
  expenseStatusTypes,
  expenseTopInputs,
  expenseBottomInputs,
  strings,
  reviewerButtons,
  requiredKeys,
  keysToSum,
  paidButton,
  creatorButtons,
} from './Expenses';
import { Form } from 'react-bootstrap';
import Button from '../../components/Button';
import SimpleUserCard from '../../components/SimpleUserCard';
import { useMemo, useState, useEffect } from 'react';
import Label from '../../components/action_items/Label';
import { Spinner } from 'react-bootstrap';
import { getLocalTime } from '../../utilities/time';
import { Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import FileDropzone from '../../components/image_uploads/FileDropzone';
import FileCard from '../../components/files/FileCard';
import { openFileViewer } from '../../graphql/cache/modal';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import noImage from '../../assets/icons/image_blank.png';
import { useCurrentUser } from '../../providers/UserProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import ActivitySelector from '../../components/activities/ActivitySelector';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ExpenseDocument } from '../../utilities/pdf_export/expense';
import { convertPDFToImages } from '../../utilities';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FlagDisabled from '../flagDisabled/FlagDisabled';

const expenseQuery = loader('./Expenses.fetch.graphql');
const updateExpenseMutation = loader('./Expenses.update.graphql');
const deleteExpenseMutation = loader('./Expense.delete.graphql');
const addFileMutation = loader('./Expense.addFile.graphql');

export default function Expense() {
  const { t } = useTranslation();
  const { openModal, openConfirmationModal } = useModal();
  const { user: currentUser, isAdmin, team } = useCurrentUser();
  const { getParam } = useQueryParams();
  const id = getParam('id');
  const [updateExpense] = useMutation(updateExpenseMutation);
  const [deleteExpense] = useMutation(deleteExpenseMutation);
  const [addFile] = useMutation(addFileMutation);
  const navigate = useNavigate();
  const expenseId = useMemo(() => parseInt(id), [id]);

  const { refetch, data: { expenses: [expense] = [{}] } = {} } = useQuery(
    expenseQuery,
    {
      skip: !expenseId,
      variables: {
        options: {
          filters: [{ field: 'id', operator: 'eq', value: [`${id}`] }],
        },
      },
    },
  );
  const { creator, workspace, activity, reviewer, files } = expense || {};
  const hasBeenReviewed = !!reviewer;

  const [editedExpense, setEditedExpense] = useState(expense || {});
  const [isEditingComments, setIsEditingComments] = useState(false);
  const [comments, setComments] = useState(expense.comment || '');

  const [selectedFile, setSelectedFile] = useState(0);

  const isCreator = creator?.id === currentUser?.id;
  const allowChanges = isCreator && expense?.status === strings.Denied;

  const isReviewer = reviewer?.id === currentUser?.id;
  const isUserSupervisor = !!team?.some((u) => u.id === creator?.id);

  const allowedApprovers = isUserSupervisor || isAdmin;

  const canApprove =
    !isCreator && allowedApprovers && expense?.status === strings.Pending;

  const readOnly = expense?.status !== strings.Draft;

  const isComplete =
    expense?.status === strings.Approved || expense?.status === strings.Denied;

  const disableSubmit = useMemo(() => {
    return requiredKeys.some(
      (key) => editedExpense[key] === null || editedExpense[key] === '',
    );
  }, [editedExpense]);

  const isMileage = expense?.type === 'Mileage';

  const canMarkAsPaid =
    isAdmin && !isCreator && expense?.status === strings.Approved;

  const total = useMemo(() => {
    const totalSum = keysToSum.reduce(
      (sum, key) => sum + (expense[key] || 0),
      0,
    );

    const mileageSum = editedExpense.distance
      ? parseFloat(editedExpense.distance * expense?.rate)
      : 0;

    return isMileage ? mileageSum : parseFloat(totalSum);
  }, [editedExpense.distance, expense, isMileage]);
  const [loadingFile, setLoadingFile] = useState(false);

  const onFileSubmit = ({ url, size, fileType, extension, name }) => {
    setLoadingFile(true);
    addFile({
      variables: {
        url,
        size,
        fileType,
        extension,
        name,
        expenseId: expense.id,
        downloadAllowed: true,
      },
    }).then(() => {
      refetch().then(() => setLoadingFile(false));
    });
  };

  useEffect(() => {
    if (expense) {
      setEditedExpense(expense);
    }
  }, [expense]);

  const onChange = ({ newValue, objectKey }) => {
    updateExpense({ variables: { id: expenseId, [objectKey]: newValue } });
  };

  const allowRevert =
    (isCreator && expense?.status === strings.Pending) ||
    (isReviewer && isComplete) ||
    (isAdmin && expense?.status !== strings.Draft);

  const isPaid = expense?.status === strings.Complete;
  const [PDFImages, setPDFImages] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const pdfAttachments = files?.some((file) => file.extension === 'pdf');

  useEffect(() => {
    let images = [];
    const fetchPDFImages = async () => {
      setLoadingPDF(true);
      setPDFImages([]);
      for (let file of files) {
        if (file.extension === 'pdf') {
          const imageUrl = await convertPDFToImages(file.url);
          images.push(imageUrl);
        }
      }
      setPDFImages([...PDFImages, ...images]);
      setLoadingPDF(false);
    };
    if (readOnly && files?.length && !!pdfAttachments) {
      fetchPDFImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files?.length, readOnly]);

  const hasFiles = !!PDFImages?.length || !!files?.length;

  const { enableExpenses } = useFlags();

  return enableExpenses ? (
    !expense?.workspace || !expense ? (
      <Spinner
        className={styles.spinner}
        animation="border"
        variant="primary"
      />
    ) : (
      <div className={styles.container}>
        <div className={styles.page}>
          <div className={styles.header}>
            <Text size="lg" color="accentPrimary" weight="semiBold">
              {isCreator
                ? t(keys.expenses.MY_EXPENSE)
                : t(keys.expenses.TEAM_EXPENSE, {
                    firstName: creator?.firstName,
                  })}
            </Text>
          </div>
          <div className={styles.content}>
            <div className={styles.top}>
              {expenseTopInputs.map((field, index) => (
                <div
                  key={`expense-${field.query}-${index}`}
                  className={styles.form}
                >
                  <Text noMargin weight="semiBold">
                    {t(field.title)}
                    <span
                      className={!field.required ? styles.hide : styles.span}
                    >
                      *
                    </span>
                  </Text>
                  {field.type === 'dropdown' ? (
                    <DropdownText
                      placeholder={t(field.title)}
                      className={styles.dropdown}
                      opacityOnDisabled={true}
                      items={field.options.map(({ text }) => t(text))}
                      disabled={readOnly}
                      selected={t(
                        field.options.find(
                          ({ key }) => key === editedExpense[field.query],
                        )?.text,
                      )}
                      onChange={(newValue) =>
                        onChange({
                          newValue: field.options.find(
                            ({ text }) => t(text) === newValue,
                          ).key,
                          objectKey: field.query,
                        })
                      }
                    />
                  ) : field.type === 'Date' ? (
                    <Form.Control
                      className={styles.input}
                      type={field.type}
                      disabled={readOnly}
                      value={editedExpense[field.query] || ''}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setEditedExpense((prev) => ({
                          ...prev,
                          [field.query]: newValue,
                        }));
                      }}
                      onBlur={() => {
                        const objectKey = field.query;
                        const newValue = editedExpense[field.query];
                        onChange({ newValue, objectKey });
                      }}
                    ></Form.Control>
                  ) : (
                    <Form.Control
                      className={styles.input}
                      type={field.type}
                      disabled={readOnly}
                      value={editedExpense[field.query] || ''}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setEditedExpense((prev) => ({
                          ...prev,
                          [field.query]: newValue,
                        }));
                      }}
                      onBlur={() => {
                        const objectKey = field.query;
                        const newValue = editedExpense[field.query];
                        onChange({ newValue, objectKey });
                      }}
                    ></Form.Control>
                  )}
                </div>
              ))}
            </div>

            <div className={styles.bottom}>
              <div className={styles.left}>
                {isMileage ? (
                  <div>
                    <br />
                    <Text noMargin weight="semiBold">
                      {t(keys.expenses.DISTANCE, {
                        variable: expense?.isMetric
                          ? t(keys.common.KILOMETERS)
                          : t(keys.common.MILES),
                      })}
                    </Text>
                    <Form.Control
                      type="number"
                      disabled={readOnly}
                      value={editedExpense.distance || ''}
                      onChange={(e) => {
                        setEditedExpense({
                          ...editedExpense,
                          distance: parseInt(e.target.value),
                        });
                      }}
                      onBlur={() => {
                        const objectKey = 'distance';
                        const newValue = editedExpense.distance;
                        onChange({ newValue, objectKey });
                      }}
                    ></Form.Control>
                    <br />
                    <Text noMargin weight="semiBold">
                      {t(keys.expenses.TOTAL)}
                    </Text>
                    <Form.Control
                      type="number"
                      disabled={true}
                      value={total.toFixed(2)}
                      onChange={() =>
                        setEditedExpense({
                          ...editedExpense,
                          total: parseFloat(total),
                        })
                      }
                    ></Form.Control>
                  </div>
                ) : (
                  expenseBottomInputs.map((field, index) => (
                    <div
                      key={`expense-${field.query}-${index}`}
                      className={styles.form}
                    >
                      <Text noMargin weight="semiBold">
                        {t(field.title)}
                        <span
                          className={
                            !field.required ? styles.hide : styles.span
                          }
                        >
                          *
                        </span>
                      </Text>
                      <Form.Control
                        className={styles.input}
                        type={field.type}
                        disabled={readOnly || field.disabled}
                        value={
                          field.query === 'total'
                            ? (total || 0).toFixed(2)
                            : editedExpense[field.query] || '0.00'
                        }
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setEditedExpense((prev) => ({
                            ...prev,
                            [field.query]: newValue,
                          }));
                        }}
                        placeholder={'0.00'}
                        onBlur={() => {
                          const objectKey = field.query;
                          const newValue = parseFloat(
                            editedExpense[field.query],
                          );
                          onChange({ newValue, objectKey });
                        }}
                      ></Form.Control>
                    </div>
                  ))
                )}
              </div>

              {loadingFile ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  className={styles.spinner}
                />
              ) : (
                <div className={styles.right}>
                  {files?.length ? (
                    <div className={styles.filesContainer}>
                      {!readOnly && (
                        <div className={styles.filesHeader}>
                          <Text noMargin weight="semiBold">
                            {t(keys.common.FILES)}
                          </Text>
                          <Icon
                            onClick={() => {
                              openModal({
                                modalName: modals.fileUpload,
                                variables: {
                                  onSubmit: onFileSubmit,
                                  allWorkspaces: false,
                                  simple: true,
                                  onLoading: () => setLoadingFile(true),
                                },
                              });
                            }}
                            className={styles.addButton}
                          >
                            add
                          </Icon>
                        </div>
                      )}
                      <div className={styles.fileCards}>
                        {files?.map((file, index) => (
                          <div
                            key={`${file.id}+${index}`}
                            className={styles.fileCard}
                          >
                            <FileCard
                              readOnly={readOnly}
                              file={file}
                              simple={true}
                              refetchFiles={refetch}
                              openDetails={() => {
                                setSelectedFile(file);
                              }}
                              selected={selectedFile?.id === file.id}
                              key={`file-${file.id}`}
                              onClick={() =>
                                openFileViewer(file, () => {
                                  setSelectedFile(file);
                                })
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : !readOnly ? (
                    <div className={styles.fileDropzone}>
                      {loadingFile ? (
                        <Spinner
                          animation="border"
                          variant="primary"
                          className={styles.spinner}
                        />
                      ) : (
                        <FileDropzone
                          title={t(keys.action.UPLOAD_VARIABLE, {
                            variable: t(keys.common.FILE),
                          })}
                          onSubmit={onFileSubmit}
                          onLoading={() => {
                            setLoadingFile(true);
                          }}
                          allWorkspaces={false}
                          simple={true}
                        />
                      )}
                    </div>
                  ) : (
                    <div className={styles.emptyState}>
                      <EmptyStateView
                        title={t(keys.common.NOT_FOUND, {
                          variable: t(keys.common.FILE),
                        })}
                        image={noImage}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.sidebar}>
          <Text noMargin size="lg" weight="bold">
            {t(keys.common.DETAILS)}
          </Text>
          <div className={styles.sectionLine} />
          <ActivitySelector
            workspace={workspace}
            update={updateExpense}
            activity={activity}
            readOnly={readOnly}
            id={expenseId}
            isMandatory={false}
            lineSeparated={true}
            creator={creator}
          />

          <div className={styles.sectionLine} />
          <Text size="md" weight="bold" color="secondary">
            {t(keys.common.CREATOR)}
          </Text>
          <div className={styles.userCard}>
            <SimpleUserCard user={creator} />
          </div>
          <div className={styles.sectionLine} />
          <Text size="md" weight="bold" color="secondary">
            {t(keys.common.STATUS)}
          </Text>
          <div className={styles.spaceBetween}>
            <Label
              className={styles.label}
              color={expenseStatusTypes[expense?.status]?.variant}
              name={t(expenseStatusTypes[expense?.status]?.title)}
              rowSized={false}
            />
            {allowRevert ? (
              <Icon
                className={styles.icon}
                baseClassName="material-icons-outlined"
                onClick={() => {
                  const newValue = isComplete ? strings.Pending : strings.Draft;
                  onChange({ newValue, objectKey: 'status' });
                }}
              >
                undo
              </Icon>
            ) : null}
          </div>
          <div className={styles.sectionLine} />
          {!readOnly ? (
            <>
              <Text size="md" weight="bold" color="secondary">
                {t(keys.common.DATE_CREATED)}
              </Text>
              <Text noMargin>
                {getLocalTime(parseInt(expense?.dateCreated, 10)).format(
                  'dddd, MMMM Do YYYY, h:mm a',
                )}
              </Text>
              <div className={styles.sectionLine} />
            </>
          ) : (
            <>
              <Text size="md" weight="bold" color="secondary">
                {t(keys.common.DATE_SUBMITTED)}
              </Text>
              <Text noMargin>
                {getLocalTime(parseInt(expense?.dateSubmitted, 10)).format(
                  'dddd, MMMM Do YYYY, h:mm a',
                )}
              </Text>
              <div className={styles.sectionLine} />
            </>
          )}

          {isComplete && (
            <>
              <Text size="md" weight="bold" color="secondary">
                {t(keys.common.DATE_REVIEWED)}
              </Text>
              <Text noMargin>
                {getLocalTime(parseInt(expense?.dateReviewed, 10)).format(
                  'dddd, MMMM Do YYYY, h:mm a',
                )}
              </Text>
              <div className={styles.sectionLine} />
            </>
          )}
          {isPaid && (
            <>
              <Text size="md" weight="bold" color="secondary">
                {t(keys.common.DATE_PAID)}
              </Text>
              <Text noMargin>
                {getLocalTime(parseInt(expense?.datePaid, 10)).format(
                  'dddd, MMMM Do YYYY, h:mm a',
                )}
              </Text>
              <div className={styles.sectionLine} />
            </>
          )}
          {isMileage && (
            <div>
              <Text size="md" weight="bold" color="secondary">
                {t(keys.expenses.MILEAGE_RATE)}
              </Text>
              <Text noMargin weight="semiBold">{`${expense?.rate || 0} ${
                expense?.isMetric ? '$/km' : '$/mi'
              }`}</Text>

              <div className={styles.sectionLine} />
            </div>
          )}

          {hasBeenReviewed && (
            <>
              <Text size="md" weight="bold" color="secondary">
                {t(keys.common.REVIEWER)}
              </Text>
              <div className={styles.userCard}>
                <SimpleUserCard user={reviewer} />
              </div>
              <div className={styles.sectionLine} />
              <div className={styles.comments}>
                <Text size="md" weight="bold" color="secondary">
                  {t(keys.common.COMMENTS)}
                </Text>
                {isReviewer && !isPaid && (
                  <Icon
                    className={styles.icon}
                    baseClassName="material-icons-outlined"
                    onClick={() => {
                      setIsEditingComments(!isEditingComments);
                      if (isEditingComments) {
                        updateExpense({
                          variables: {
                            id: expenseId,
                            comment: comments,
                          },
                        });
                      }
                    }}
                  >
                    {isEditingComments ? 'save' : 'edit'}
                  </Icon>
                )}
              </div>

              <div className={styles.commentInput}>
                {isEditingComments && isReviewer ? (
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                ) : (
                  <Text>{expense?.comment || t(keys.common.NONE)}</Text>
                )}
              </div>
              <div className={styles.sectionLine} />

              {canMarkAsPaid && (
                <Button
                  className={styles.largeButton}
                  variant={paidButton.variant}
                  outlined
                  icon={paidButton.icon}
                  value={t(paidButton.value)}
                  onClick={() => {
                    openConfirmationModal({
                      title: t(paidButton.title),
                      description: t(paidButton.description),
                      variant: paidButton.variant,
                      onSubmit: () => {
                        updateExpense({
                          variables: {
                            id: expenseId,
                            status: strings.Complete,
                          },
                        }).then(() =>
                          navigate(`/${paths.expenses}/${paidButton.navigate}`),
                        );
                      },
                    });
                  }}
                />
              )}
            </>
          )}
          <div className={styles.buttons}>
            {canApprove &&
              reviewerButtons.map((button, index) => (
                <Button
                  className={styles.largeButton}
                  key={`button-${button.value}-${index}`}
                  value={t(button.value)}
                  variant={button.variant}
                  onClick={() =>
                    openConfirmationModal({
                      title: t(button.title, {
                        variable: t(keys.common.EXPENSE),
                      }),
                      description: t(button.description),
                      variant: button.variant,
                      textInput: button.textInput,
                      textInputTitle: t(button.textInputTitle),
                      onSubmit: (comment) => {
                        updateExpense({
                          variables: {
                            id: expenseId,
                            status: button.status,
                            comment: `${expense.comment || ''} ${comment}`,
                          },
                        }).then(() => {
                          navigate(`/${paths.expenses}/${button.navigate}`);
                        });
                      },
                    })
                  }
                />
              ))}
            {!readOnly &&
              creatorButtons.map((button, index) => (
                <Button
                  className={styles.largeButton}
                  key={`button-${button.value}-${index}`}
                  disabled={button.key === 'SUBMIT' ? disableSubmit : false}
                  value={t(button.value)}
                  variant={button.variant}
                  outlined={button.outlined}
                  icon={button.icon}
                  onClick={() =>
                    openConfirmationModal({
                      title: t(button.title, {
                        variable: t(keys.common.EXPENSE),
                      }),
                      description: t(button.description, {
                        variable: `${t(keys.action.THIS)} ${t(
                          keys.common.EXPENSE,
                        )}`,
                      }),
                      variant: button.modalVariant,
                      onSubmit: () => {
                        button.key === 'DELETE'
                          ? deleteExpense({
                              variables: { id: expenseId },
                            })
                          : updateExpense({
                              variables: {
                                id: expenseId,
                                status: button.status,
                                total,
                                reviewer: !isCreator ? currentUser.id : null,
                                comment: !isCreator
                                  ? `${
                                      expense.comment || ''
                                    } Expense edited by: ${
                                      currentUser.firstName
                                    } ${
                                      currentUser.lastName
                                    } ${new moment().format(
                                      'YYYY-MM-DD HH:mm:ss',
                                    )}`
                                  : expense.comment,
                              },
                            });
                        if (isCreator) {
                          navigate(`/${paths.expenses}`);
                        }
                      },
                    })
                  }
                />
              ))}
            {readOnly && (
              <PDFDownloadLink
                document={
                  <ExpenseDocument
                    user={currentUser}
                    expense={expense}
                    files={files || []}
                    PDFImages={PDFImages || []}
                    hasFiles={hasFiles}
                  />
                }
                fileName={`Expense_Report-${creator.firstName}_${
                  creator.lastName
                }-${new moment().format('YYYY-MM-DD')}`}
              >
                {({ loading, url }) => (
                  <Button
                    className={styles.largeButton}
                    variant="primary"
                    outlined
                    icon="download"
                    value={
                      loading || loadingPDF
                        ? t(keys.action.LOADING)
                        : t(keys.action.DOWNLOAD)
                    }
                    disabled={loading || !url || loadingPDF}
                  />
                )}
              </PDFDownloadLink>
            )}

            {allowChanges && (
              <Button
                variant="primary"
                outlined
                value={t(keys.action.EDIT)}
                className={styles.largeButton}
                onClick={() => {
                  updateExpense({
                    variables: {
                      id: expenseId,
                      status: strings.Draft,
                      reviewer: null,
                    },
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  ) : (
    <FlagDisabled />
  );
}
