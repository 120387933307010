import { Grid } from '@mui/material';
import toolbarIconsList from './toolbarIconsList';
// import { Box } from 'mdi-material-ui';
// import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import useOnClickListener from './useOnClickListener';
import { createPortal } from 'react-dom';
import FloatingLinkEditor from './FloatingLinkEditor';
// import { InsertImageDialog } from '../CustomPlugins/ImagePlugin';

const LexicalEditorTopBar = () => {
  const { onClick, selectedEventTypes, blockType, isLink, editor, modal } =
    useOnClickListener();

  const isIconSelected = (plugin) =>
    selectedEventTypes.includes(plugin.event) ||
    blockType.includes(plugin.event);

  return (
    <Grid
      container
      // justifyContent="space-between"
      spacing={3}
      alignItems="center"
      sx={{
        background: 'white',
        maxWidth: '100%',
        marginLeft: '0rem',
        py: 1.5,
        px: 0.5,
      }}
    >
      {toolbarIconsList.map((plugin) => (
        <Grid
          key={plugin.id}
          sx={{
            cursor: 'pointer',
          }}
          item
        >
          {
            <plugin.Icon
              sx={{
                ...plugin.iconSx,
                color: isIconSelected(plugin) ? '#0d6efd' : '#0b1833',
              }}
              onClick={() => onClick(plugin.event)}
            />
          }
        </Grid>
      ))}
      {modal}
      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
    </Grid>
  );
};

export default LexicalEditorTopBar;
