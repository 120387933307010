import React, { useMemo } from 'react';
import styles from './ActionItemSlimCard.module.scss';
import { Icon } from '@mui/material';
import { Text } from '../../components/typography';
import { priorityStringLookupReverse } from '../../components/action_items/action_item_utilities';
import classNames from 'classnames';
import { truncate } from '../../utilities';

export default function ActionItemSlimCard({
  title,
  priority,
  creator,
  onClick,
  disabled = false,
}) {
  const priorityData = useMemo(() => {
    switch (priority) {
      case priorityStringLookupReverse.High:
        return { icon: 'expand_less', color: 'red' };
      case priorityStringLookupReverse.Medium:
        return { icon: 'unfold_more', color: 'yellow' };
      case priorityStringLookupReverse.Low:
        return { icon: 'expand_more', color: 'green' };
      default:
        throw new Error(
          `Unsupported priority value in ActionItemSlimCard: ${priority}`,
        );
    }
  }, [priority]);
  return (
    <div
      className={classNames(
        styles.container,
        disabled ? styles.disabled : null,
      )}
      onClick={onClick}
    >
      <div className={classNames(styles.info, styles[priorityData.color])}>
        <Text size="md" weight="semiBold" noMargin>
          {truncate(title, 23)}
        </Text>
        <Icon
          baseClassName="material-icons-outlined"
          className={styles.icon}
          fontSize="inherit"
        >
          {priorityData.icon}
        </Icon>
      </div>
      <img
        className={styles.avatar}
        src={creator.avatarUrl}
        alt={creator.firstName}
      />
    </div>
  );
}
