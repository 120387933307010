import React from 'react';
import classNames from 'classnames';
import styles from './FailureModal.module.scss';
import Button from '../Button';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import ImageDropzone from '../image_uploads/ImageDropzone';
import UserSelector from '../UserSelector';
import { closeFailureModal } from '../../graphql/cache/modal';
import { keys } from '../../utilities/translator/translation_keys.js';
import ImageWrapper from '../offline-wrapper-functions/image-wrapper';
import { useTranslation } from 'react-i18next';

export default function FailureModal({
  show = false,
  title,
  description,
  onSubmit,
  onCancel,
}) {
  const [notes, setNotes] = useState('');
  const [assignee, setAssignee] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const onHide = () => {
    setAssignee(undefined);
    closeFailureModal();
    setLoading(false);
    setImage(undefined);
    setNotes('');
  };
  const { t } = useTranslation();

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.contentContainer}>
            <div className={styles.header}>
              <Text weight="bold" size="lg" noMargin>
                {t(keys.assessments.WHAT_WENT_WRONG)}
              </Text>
              <Icon
                sx={{ fontSize: '1.5rem' }}
                fontSize="inherit"
                className={styles.closeIcon}
                baseClassName="material-icons-outlined"
                onClick={() => onHide()}
              >
                close
              </Icon>
            </div>
            <br />
            <div>
              <div className={styles.titleContainer}>
                <Text noMargin weight="bold" color="red" size="lg">
                  {title}
                </Text>
                <Icon
                  sx={{ fontSize: '2rem' }}
                  className={styles.icon}
                  fontSize="inherit"
                  baseClassName="material-icons-outlined"
                >
                  cancel
                </Icon>
              </div>
              <Text size="md">{description}</Text>
            </div>
            <Text className={styles.formLabel} weight="bold" noMargin>
              {`${t(keys.common.NOTES)}*`}
            </Text>
            <Form onSubmit={(e) => e.stopPropagation()}>
              <Form.Group>
                <Form.Control
                  type="text"
                  as="textarea"
                  required
                  placeholder={t(keys.assessments.FAILURE_NOTES)}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {t(keys.assessments.NOTES_VALIDATION)}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
            <div className={styles.formSpacer} />
            <Text className={styles.formLabel} weight="bold" noMargin>
              {t(keys.assessments.ASSIGN_FAILURE_ACTION_ITEM)}
            </Text>
            <UserSelector
              type="Assignee"
              selected={assignee}
              onSelectionChanged={(u) => setAssignee(u)}
              onClose={() => {
                setAssignee(() => setAssignee(undefined));
              }}
            />
            <div className={styles.formSpacer} />
            <Text className={styles.formLabel} weight="bold" noMargin>
              {`${t(keys.common.REFERENCE_IMAGE)} (${t(keys.common.OPTIONAL)})`}
            </Text>
            {image ? (
              <ImageWrapper image={image} className={styles.image} alt="sdsa" />
            ) : loading ? (
              <Spinner
                className={styles.spinner}
                animation="border"
                variant="primary"
              />
            ) : (
              <ImageDropzone
                title=""
                imageUrl={image}
                onLoading={() => setLoading(true)}
                onSubmit={async (url) => {
                  setLoading(true);
                  setImage(url);
                  setLoading(false);
                }}
              />
            )}
            <div className={styles.buttonContainer}>
              <Button
                size="md"
                variant="secondary"
                value={t(keys.action.CANCEL)}
                onClick={() => onHide()}
              />
              <Button
                size="md"
                variant="danger"
                disabled={!notes?.length || loading}
                value={t(keys.action.SUBMIT)}
                onClick={() => {
                  onSubmit(notes, image, assignee?.id);
                  onHide();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
