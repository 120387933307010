import styles from './AnalysisModal.module.scss';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import { Text } from '../typography';
import Button from '../Button';
import { LinearProgress } from '@mui/material';
import { useMutation, useSubscription } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useState, useEffect } from 'react';
import {
  modalLoadingMessage,
  modalAnalysisMessage,
  modalNoAnalysisMessage,
} from '../../components/machine_learning/riskAnalysis';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
const analyzeInspectionMutation = loader('./AnalysisModal.analyze.graphql');
const updateFeedbackMutation = loader(
  '../../pages/inspection_page/Inspection.updateFeedbackLike.graphql',
);
const secondsightSubscription = loader(
  '../../pages/inspection_page/Inspection.secondsightSubscription.graphql',
);

export default function AnalysisModal({
  show = false,
  onCancel,
  onSubmit,
  inspection = {},
}) {
  const [loading, setLoading] = useState(false);
  const [analyzeInspection] = useMutation(analyzeInspectionMutation);
  const [updateFeedback] = useMutation(updateFeedbackMutation);
  const [analysisResponse, setAnalysisResponse] = useState([]);
  const { t } = useTranslation();
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [feedbackNotes, setfeedbackNotes] = useState('');

  const onSubmitFeedback = () => {
    setfeedbackNotes('');
    setSelectedFeedback(null);
    return updateFeedback({
      variables: {
        id: inspection?.id,
        feedbackLike: selectedFeedback,
        feedbackNotes: selectedFeedback === null ? null : feedbackNotes,
      },
    });
  };

  useSubscription(secondsightSubscription, {
    variables: {
      inspectionId: inspection.id,
    },
    skip: !inspection?.id,
    onSubscriptionData: ({
      subscriptionData: {
        data: { secondsightProcessed: { riskAnalysis = '' } } = {},
      } = {},
    }) => {
      if (riskAnalysis) {
        setAnalysisResponse(riskAnalysis.split('|'));
        setLoading(false);
      } else {
        setAnalysisResponse([]);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!show) {
      setfeedbackNotes('');
      setSelectedFeedback(null);
    }
  }, [show]);

  useEffect(() => {
    if (!show) {
      setAnalysisResponse([]);
      return;
    } else {
      setLoading(true);
    }
    if (!inspection?.riskAnalysis?.length) {
      analyzeInspection({ variables: { id: inspection?.id } });
    } else {
      setAnalysisResponse(inspection?.riskAnalysis?.split('|'));
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.contentContainer}>
            <div className={styles.header}>
              <Icon sx={{ fontSize: '3.5rem', color: '#0d6efd' }}>
                analytics
              </Icon>
              <Text noMargin size="lg" weight="semibold">
                SecondSight
              </Text>
            </div>
            <div className={styles.textContainer}>
              {loading ? (
                <div>
                  <div className={styles.progressContainer}>
                    <LinearProgress className={styles.progress} />
                  </div>
                  <br />
                  <div>
                    <Text size="md">{t(modalLoadingMessage.title)}</Text>
                    <Text size="sm" weight="semiBold">
                      {t(modalLoadingMessage.subtitle)}
                    </Text>
                  </div>
                </div>
              ) : (
                <div>
                  {analysisResponse?.length && !loading ? (
                    <>
                      <Text size="md">{t(modalAnalysisMessage.title)}</Text>
                      <div className={styles.responseContainer}>
                        {analysisResponse?.map((data, index) => (
                          <div key={data + index} className={styles.response}>
                            <Icon
                              baseClassName="material-icons-outlined"
                              className={styles.icon}
                            >
                              emoji_objects
                            </Icon>
                            <Text weight="semiBold" size="md" noMargin>
                              {data}
                            </Text>
                          </div>
                        ))}
                      </div>
                      <div className={styles.feedbackContainer}>
                        <Text
                          size="sm"
                          weight="semiBold"
                          textAlign="center"
                          className={styles.feedbackText}
                        >
                          {t(modalAnalysisMessage.feedback)}
                        </Text>
                        <Button
                          icon={'thumb_down'}
                          className={
                            selectedFeedback === false
                              ? styles.feedbackThumbButtonSelected
                              : styles.feedbackThumbButton
                          }
                          iconStylesClassName={
                            selectedFeedback === false
                              ? styles.feedbackThumbIconSelected
                              : styles.feedbackThumbIcon
                          }
                          onClick={() => {
                            if (
                              selectedFeedback === false &&
                              selectedFeedback !== null
                            ) {
                              setSelectedFeedback(null);
                            } else if (selectedFeedback !== false) {
                              setSelectedFeedback(false);
                            }
                          }}
                        />
                        <Button
                          icon={'thumb_up'}
                          className={
                            selectedFeedback
                              ? styles.feedbackThumbButtonSelected
                              : styles.feedbackThumbButton
                          }
                          iconStylesClassName={
                            selectedFeedback
                              ? styles.feedbackThumbIconSelected
                              : styles.feedbackThumbIcon
                          }
                          onClick={() => {
                            if (selectedFeedback) {
                              setSelectedFeedback(null);
                            } else if (!selectedFeedback) {
                              setSelectedFeedback(true);
                            }
                          }}
                        />
                      </div>
                      {!(selectedFeedback === null) ? (
                        <div className={styles.textAreaContainer}>
                          <textarea
                            className={styles.textArea}
                            value={feedbackNotes}
                            type="text"
                            placeholder={t(
                              modalAnalysisMessage.feedback_placeholder,
                            )}
                            rows={2}
                            onChange={(e) => setfeedbackNotes(e.target.value)}
                          />
                        </div>
                      ) : null}
                      <Text size="sm" weight="semiBold" color="secondary">
                        {inspection.internalType === 'RECORDED_MEETING'
                          ? t(modalAnalysisMessage.subtitle)
                          : t(keys.assessments.MODAL_ANALYSIS_NO_SUBMIT)}
                      </Text>
                    </>
                  ) : (
                    <div className={styles.modalMessage}>
                      <div className={styles.response}>
                        <Text noMargin textAlign="center">
                          {t(modalNoAnalysisMessage.title)}
                        </Text>
                      </div>
                      <br />
                      <Icon
                        sx={{
                          color: 'red',
                          margin: '0 auto',
                          fontSize: '3rem',
                        }}
                      >
                        error
                      </Icon>
                      <br />
                      {inspection.isDraft && (
                        <Text size="sm" weight="semiBold" textAlign="center">
                          {t(modalNoAnalysisMessage.subtitle)}
                        </Text>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            {!inspection.isDraft ? (
              <Button
                align="right"
                variant={loading ? 'warning' : 'primary'}
                value={loading ? 'Ignore' : 'Acknowledge'}
                onClick={() => {
                  if (!loading) {
                    onSubmitFeedback().then(() => {
                      onCancel();
                    });
                  } else {
                    onCancel();
                  }
                }}
              />
            ) : (
              <div className={styles.buttonContainer}>
                <Button
                  size="md"
                  variant="secondary"
                  value={loading ? t(keys.action.CANCEL) : t(keys.action.EDIT)}
                  onClick={() => {
                    onCancel();
                  }}
                />
                <Button
                  size="md"
                  variant={loading ? 'warning' : 'primary'}
                  value={
                    loading ? t(keys.assessments.IGNORE) : t(keys.action.SUBMIT)
                  }
                  onClick={() => {
                    if (!loading) {
                      onSubmitFeedback().then(() => {
                        onSubmit();
                        onCancel();
                      });
                    } else {
                      onSubmit();
                      onCancel();
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
