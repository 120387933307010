import { Card, CardContent } from '@mui/material';
import { Text } from '../../../components/typography';
import Icon from '../../../components/Icon';
import styles from './DashboardCard.module.scss';

export default function DashboardNumberStats({
  backgroundImage,
  number,
  text,
}) {
  return (
    <Card
      sx={{
        zIndex: 0,
        position: 'relative',
        backgroundSize: 'cover',
        margin: '0.2rem',
        height: '90%',
        backgroundPosition: 'center',
        borderRadius: '12px',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(0.5px)',
          transform: 'scale(1.1)',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <CardContent
        sx={{
          position: 'relative',
          height: '100%',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {!isNaN(number) && number !== null ? (
          <Text size="xl" weight="bold" color="white" noMargin>
            {number}
          </Text>
        ) : (
          <div className={styles.icon}>
            <Icon color="white" size="xl">
              all_inclusive
            </Icon>
          </div>
        )}
        <Text
          size="sm"
          weight="semiBold"
          noMargin
          textAlign="left"
          color="white"
        >
          {text}
        </Text>
      </CardContent>
    </Card>
  );
}
