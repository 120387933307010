import { Page, Text, View, Document } from '@react-pdf/renderer';
import {
  PageHeaderLogo,
  styles,
  BatchPageHeader,
  PageFooter,
} from './constants';

export const ActivityDocument = ({ data, user, headers, totals }) => {
  const { company } = user;
  const activityWidth = 170;
  const cellWidth = 50;
  const fontSize = 11;
  const cellStyle = {
    width: cellWidth,
    fontSize,
    fontFamily: 'eina03bold',
    textAlign: 'right',
  };
  const firstRowStyle = {
    width: activityWidth,
    fontSize,
    fontFamily: 'eina03bold',
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PageHeaderLogo company={company} />
        <BatchPageHeader company={company} user={user} />
        <View style={{ marginBottom: 3, marginTop: 5 }}>
          <Text style={styles.title}>
            Timecard Summary for {headers[0]} - {headers[6]}
          </Text>
        </View>
        <View style={styles.border}>
          <View style={styles.row}>
            <Text style={firstRowStyle}>Activity</Text>
            {headers.map((header, index) => (
              <Text key={index} style={cellStyle}>
                {header === 'Total' ? header : header.slice(5)}
              </Text>
            ))}
          </View>
          {data.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.row}>
              {row.map((col, colIndex) => (
                <Text
                  key={colIndex}
                  style={{
                    width: colIndex === 0 ? activityWidth : cellWidth,
                    fontSize: 10,
                    textAlign: colIndex === 0 ? 'left' : 'right',
                    marginBottom: 2,
                    fontFamily: colIndex === 8 ? 'eina03bold' : 'eina03regular',
                  }}
                >
                  {colIndex === 0 ? col : parseFloat(col).toFixed(2)}
                </Text>
              ))}
            </View>
          ))}
          <View style={styles.rowNoBorder}>
            <Text style={firstRowStyle}>Totals</Text>
            {totals.map((total, idx) => (
              <Text style={cellStyle} key={idx}>
                {parseFloat(total).toFixed(2)}
              </Text>
            ))}
          </View>
        </View>
        <PageFooter />
      </Page>
    </Document>
  );
};
