import React, { useState, useMemo, useEffect } from 'react';
import styles from './Expenses.module.scss';
import { Text } from '../../components/typography';
import { Button } from '../../components';
import SimpleTabSystem from '../../components/SimpleTabSystem';
import { paths, sortExpenseStrings } from '../../constants/strings';
import { useNavigate, useParams } from 'react-router-dom';
import {
  expenseStatusTypes,
  expenseTable,
  reverseStringLookupType,
  strings,
} from './Expenses';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import templatesImage from '../../assets/empty_state_images/templates.svg';
import { Spinner, Table } from 'react-bootstrap';
import TableFiltering from '../../components/sorting/TableFiltering';
import TableSorting from '../../components/sorting/TableSorting';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import { getLocalTime } from '../../utilities/time';
import TablePagination from '../../components/pagination/TablePagination';
import {
  convertExpenseFiltersToQueryParams,
  expenseFilterOptions,
  expenseStatusOptions,
  teamExpenseStatusOptions,
} from '../../utilities/filtering';
import SimpleUserCard from '../../components/SimpleUserCard';
import Label from '../../components/action_items/Label';
import { truncate } from '../../utilities';
import classNames from 'classnames';
import ContextMenu from '../../components/ContextMenu';
import { showToast } from '../../graphql/cache/modal';
import { toastLength, toastVariant } from '../../constants/misc';
import { useMobileListener } from '../../hooks/misc';
import { Form } from 'react-bootstrap';
import BatchUpdateButtons, {
  handleRowSelect,
  handleSelectAll,
  process,
} from './BatchUpdateButtons';
import { useCurrentUser } from '../../providers/UserProvider';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import ExpenseReportExportModal from '../../components/modals/ExpenseReportExportModal';
import { Icon } from '@mui/material';
import DateRangeSelector from '../../components/DateRangeSelector';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FlagDisabled from '../flagDisabled/FlagDisabled';

const expenseQuery = loader('./Expenses.fetch.graphql');
const addExpenseMutation = loader('./Expenses.create.graphql');
const updateExpensesMutation = loader('./Expenses.batchUpdate.graphql');

export default function Expenses({ disabled = false }) {
  const { t } = useTranslation();
  const tabOptions = [
    { title: t(keys.common.PERSONAL), key: 'personal' },
    { title: t(keys.common.TEAM), key: 'team' },
  ];

  const { tab } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(tab || tabOptions[0]);
  const { user: currentUser, isAdmin, team, isSupervisor } = useCurrentUser();
  const [addExpense] = useMutation(addExpenseMutation);
  const [updateExpenses] = useMutation(updateExpensesMutation);
  const [rawFilters, setRawFilters] = useState({});
  const [page, setPage] = useState(1);
  const [isAscending, setIsAscending] = useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const isMobile = useMobileListener();
  const { workspace } = useWorkspace();
  const sortingOptions = [
    'dateCreated',
    'dateOfExpense',
    'status',
    'dateModified',
  ];

  const [sortBy, setSortBy] = useState('dateOfExpense');
  const pageSize = 12;

  const isPersonal = selectedTab === 'personal';
  const isTeam = selectedTab === 'team';
  const [myTeamOnly, setMyTeamOnly] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    min: null,
    max: null,
  });

  const filters = useMemo(() => {
    return convertExpenseFiltersToQueryParams(
      rawFilters,
      tab,
      currentUser,
      team,
      isSupervisor,
      myTeamOnly,
      searchText,
      dateFilter,
    );
  }, [
    currentUser,
    dateFilter,
    isSupervisor,
    myTeamOnly,
    rawFilters,
    searchText,
    tab,
    team,
  ]);

  const {
    loading,
    refetch,
    data: { expenses = [], expensesCount = 0 } = {},
  } = useQuery(expenseQuery, {
    variables: {
      options: {
        page,
        pageSize,
        sort: [{ field: sortBy, order: isAscending ? 'asc' : 'desc' }],
        filters,
      },
    },
  });

  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const toApprove = process(expenses, selected, strings.Pending);
  const toPay = process(expenses, selected, strings.Approved);

  useEffect(() => {
    if (isTeam) {
      setRawFilters((prev) => ({ ...prev, filterData: { Status: 'Pending' } }));
    } else {
      setRawFilters({});
    }
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const [selectedExpense, setSelectedExpense] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);

  const contextMenuOptions = [
    {
      title: t(keys.action.OPEN_IN_NEW_WINDOW),
      onClick: () => {
        window.open(`/${paths.expense}/?id=${selectedExpense}`);
        setContextMenuOpen(false);
      },
    },
  ];

  const ExpenseTable = ({ data, headers }) => {
    return (
      <Table striped>
        <thead>
          <tr>
            {isTeam && isAdmin && (
              <th className={styles.xxsmall}>
                <Form.Check
                  checked={selectAll}
                  onChange={() =>
                    handleSelectAll({
                      items: expenses,
                      setSelected,
                      setSelectAll,
                      selectAll,
                    })
                  }
                />
              </th>
            )}
            {headers.map((header, index) => {
              if (
                (isPersonal && header.query === 'creator') ||
                (isTeam && header.query === 'dateCreated')
              ) {
                return null;
              }
              return (
                <th
                  key={index}
                  className={classNames(
                    styles.tableHeader,
                    styles[header.className],
                    styles[header.hide],
                  )}
                >
                  <Text weight="semiBold" color="secondary" noMargin>
                    {t(header.title)}
                  </Text>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((expense, rowIndex) => (
            <tr key={rowIndex}>
              {isTeam && isAdmin && (
                <td className={styles.xxsmall}>
                  {expense.status !== strings.Complete &&
                    expense.status !== strings.Denied && (
                      <Form.Check
                        checked={selected.includes(expense.id) || selectAll}
                        onChange={() =>
                          handleRowSelect({
                            item: expense,
                            setSelectAll,
                            selected,
                            setSelected,
                          })
                        }
                      />
                    )}
                </td>
              )}

              {headers.map((header, colIndex) => {
                if (
                  (isPersonal && header.query === 'creator') ||
                  (isTeam && header.query === 'dateCreated')
                ) {
                  return null;
                }
                return (
                  <td
                    key={colIndex}
                    className={classNames(styles[header.hide], styles.cell)}
                    onClick={() =>
                      navigate(`/${paths.expense}/?id=${expense.id}`)
                    }
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setSelectedExpense(expense.id);
                      setContextMenuOpen(true);
                    }}
                  >
                    <div>
                      {getTableDisplay(
                        header,
                        expense[header.query],
                        expense[header.subquery],
                        expense.id,
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };
  const getTableDisplay = (header, value, subquery, expenseId) => {
    switch (header.type) {
      case 'user':
        return <SimpleUserCard user={value} size="sm" hideAvatar={isMobile} />;
      case 'date':
        return (
          <Text weight="semiBold" className={styles.title} size="sm" noMargin>
            {getLocalTime(value).format(
              isMobile ? 'YYYY-MM-DD' : 'MMMM Do YYYY',
            )}
          </Text>
        );
      case 'label':
        return (
          <>
            {contextMenuOpen && (
              <ContextMenu
                open={contextMenuOpen && selectedExpense === expenseId}
                options={contextMenuOptions}
                setOpen={setContextMenuOpen}
                className={styles.contextMenu}
              />
            )}
            <Label
              className={styles.label}
              color={expenseStatusTypes[value]?.variant}
              name={t(expenseStatusTypes[value]?.title)}
            />
          </>
        );
      case 'number':
        return (
          <Text noMargin weight="semiBold" size="sm">
            {`${value?.toFixed(2)} ${subquery?.slice(0, 3)} `}
          </Text>
        );
      case 'type':
        return (
          <Text noMargin noSelect weight="semiBold" size="sm">
            {truncate(t(reverseStringLookupType[value]), 30) || '-'}
          </Text>
        );
      default:
        return (
          <Text noMargin noSelect weight="semiBold" size="sm">
            {truncate(value, 30) || '-'}
          </Text>
        );
    }
  };
  let typingTimer;
  const handleInputChange = (event) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      setSearchText(event.target.value);
    }, 500);
  };

  const headerTitles = useMemo(
    () => ({
      id: t(keys.expenses.HEADERS_ID),
      user: t(keys.expenses.HEADERS_USER),
      date: t(keys.expenses.HEADERS_DATE),
      total: t(keys.expenses.HEADERS_TOTAL),
      type: t(keys.expenses.HEADERS_TYPE),
      description: t(keys.expenses.HEADERS_DESCRIPTION),
      status: t(keys.expenses.HEADERS_STATUS),
    }),
    [t],
  );

  const { enableExpenses } = useFlags();

  return enableExpenses ? (
    !expenses ? (
      <div className={styles.spinnerContainer}>
        <Spinner animation="border" variant="primary" />
      </div>
    ) : (
      <div
        className={classNames(
          styles.container,
          disabled ? styles.disabled : null,
        )}
      >
        <div className={styles.header}>
          <Text size="lg" color="accentPrimary" weight="semiBold">
            {t(keys.common.EXPENSES)}
          </Text>
          <div className={styles.buttons}>
            <Button
              value={t(keys.action.NEW)}
              icon="add"
              onClick={() => {
                addExpense({
                  variables: {
                    status: strings.Draft,
                    workspaceId: workspace?.id,
                    currency: 'CAD - Canadian Dollar',
                    isMetric: workspace.isMetric,
                    rate: workspace.distanceRate,
                  },
                }).then(
                  ({
                    data: {
                      addExpense: { id },
                    },
                  }) => {
                    navigate(`/${paths.expense}/?id=${id}`);
                  },
                );
              }}
            />
            <Button
              variant="primary"
              value={t(keys.action.EXPORT)}
              outlined
              onClick={() => {
                setShowExportModal(true);
              }}
            />
          </div>
        </div>

        <div className={styles.page}>
          <div className={styles.subHeader}>
            <SimpleTabSystem
              options={isAdmin || isSupervisor ? tabOptions : [tabOptions[0]]}
              selected={selectedTab}
              setSelected={setSelectedTab}
              route={paths.expenses}
            />
          </div>
          <br />
          <div className={styles.sort}>
            <div className={styles.leftSortContainer}>
              <TableFiltering
                filters={filters}
                setFilters={setRawFilters}
                rawFilters={rawFilters}
                filterOptions={
                  tab === tabOptions[0].key
                    ? expenseFilterOptions.slice(0, 2)
                    : expenseFilterOptions
                }
                statusOptions={
                  tab === tabOptions[0].key
                    ? expenseStatusOptions
                    : teamExpenseStatusOptions
                }
                setPage={setPage}
                userQuery={isAdmin ? 'all' : 'workspace'}
              />
            </div>
            <div className={styles.rightButtons}>
              <BatchUpdateButtons
                toApprove={toApprove}
                toPay={toPay}
                type={t(keys.common.EXPENSE)}
                onSubmit={async ({ ids, status }) => {
                  const { errors, data } = await updateExpenses({
                    variables: { ids, status },
                  });
                  setSelectAll(false);
                  setSelected([]);
                  if (errors) {
                    console.error(errors);
                    showToast({
                      title: t(keys.action.ERROR_BATCH_UPDATE_TITLE, {
                        variable: t(keys.common.EXPENSES),
                      }),
                      message: t(keys.action.ERROR_BATCH_UPDATE_MESSAGE, {
                        variable: t(keys.common.EXPENSES),
                        message: errors[0].message,
                      }),
                      time: toastLength.lg,
                      variant: toastVariant.danger,
                    });
                  } else {
                    console.error(errors);
                    showToast({
                      title: t(keys.expenses.BATCH_UPDATE_SUCCESS_TITLE),
                      message: t(keys.expenses.BATCH_UPDATE_SUCCESS_MESSAGE, {
                        count: data.updateExpenses.length,
                        status,
                      }),
                      time: toastLength.lg,
                      variant: toastVariant.info,
                    });
                  }
                }}
              />
              {isTeam && isAdmin && (
                <div className={styles.teamFilter}>
                  <Form.Check
                    onChange={() => {
                      setPage(1);
                      setMyTeamOnly(!myTeamOnly);
                    }}
                    checked={myTeamOnly}
                  />
                  <Text noMargin noSelect size="sm">
                    {t(keys.common.MY_TEAM_ONLY)}
                  </Text>
                </div>
              )}
              <TableSorting
                setSortBy={setSortBy}
                sortBy={sortBy}
                setIsAscending={setIsAscending}
                refetch={refetch}
                isAscending={isAscending}
                sortingOptions={sortingOptions}
                sortStrings={sortExpenseStrings}
              />
            </div>
          </div>
          <div className={styles.bottomFilterContainer}>
            <div className={styles.searchContainer}>
              <Icon
                onClick={() => {
                  setIsSearching(!isSearching);
                  setSearchText('');
                }}
                className={styles.icon}
              >
                {!isSearching ? 'search' : 'search_off'}
              </Icon>
              {isSearching && (
                <Form.Control
                  type="number"
                  size="sm"
                  placeholder={t(keys.expenses.SEARCH_ID)}
                  onChange={handleInputChange}
                />
              )}
            </div>
            <div className={styles.bottomFilters}>
              <DateRangeSelector
                onChange={({ min, max }) => {
                  setPage(1);
                  setDateFilter({ min, max });
                }}
                values={dateFilter}
              />
            </div>
          </div>

          {expenses?.length ? (
            <div className={styles.tableContainer}>
              <ExpenseTable
                data={expenses}
                headers={expenseTable(headerTitles)}
              />
              <TablePagination
                pageSize={pageSize}
                count={expensesCount}
                setPage={setPage}
                page={page}
              />
            </div>
          ) : loading ? (
            <div className={styles.spinnerContainer}>
              <Spinner
                className={styles.spinner}
                animation="border"
                variant="primary"
              />
            </div>
          ) : (
            <div className={styles.emptyState}>
              <EmptyStateView
                title={t(keys.action.NOT_FOUND, {
                  variable: t(keys.common.EXPENSES),
                })}
                text={t(keys.action.EMPTY_STATE_MESSAGE, {
                  variable: t(keys.common.EXPENSES),
                })}
                image={templatesImage}
              />
            </div>
          )}
        </div>
        <ExpenseReportExportModal
          show={showExportModal}
          onClose={() => setShowExportModal(false)}
          title={t(keys.expenses.EXPORT_MODAL_TITLE)}
        />
      </div>
    )
  ) : (
    <FlagDisabled />
  );
}
