import React from 'react';
import { Page, Document, View, Text } from '@react-pdf/renderer';
import {
  styles,
  BatchPageHeader,
  PageHeaderLogo,
  PageFooter,
} from './constants';
import moment from 'moment';

export const TimecardDocument = ({ timecards, filters, user }) => {
  const { company } = user;

  const Timecard = ({ timecard }) => (
    <View style={styles.list} wrap={false}>
      <View style={styles.listItem}>
        <View style={styles.listItemHeader}>
          <Text style={styles.listCategoryHeader}>
            Date: {moment(parseInt(timecard?.startTime)).format('yyyy-MM-DD')}
          </Text>
        </View>
        <View style={styles.listContentContainer}>
          <View style={styles.listContent}>
            <View style={styles.cardRow}>
              <Text style={styles.cardRowText}>Workspace: </Text>
              <Text style={styles.text}>{timecard?.workspace.title}</Text>
            </View>
            <View style={styles.cardRow}>
              <Text style={styles.cardRowText}>Working Hours: </Text>
              <Text style={styles.text}>
                {!!timecard?.billableHours ? timecard?.billableHours : 0}
              </Text>
            </View>
            <View style={styles.cardRow}>
              <Text style={styles.cardRowText}>Non-working Hours: </Text>
              <Text style={styles.text}>
                {!!timecard?.nonBillableHours ? timecard?.nonBillableHours : 0}
              </Text>
            </View>
          </View>
          <View style={styles.listContent}>
            <View style={styles.cardRow}>
              <Text style={styles.cardRowText}>Activity: </Text>
              <Text style={styles.text}>
                {!!timecard?.activity
                  ? timecard?.activity?.description
                  : 'None'}
              </Text>
            </View>
            <View style={styles.cardRow}>
              <Text style={styles.cardRowText}>PO#: </Text>
              <Text style={styles.text}>
                {!!timecard?.poNumber ? timecard?.poNumber : 'None'}
              </Text>
            </View>
            <View style={styles.cardRow}>
              <Text style={styles.cardRowText}>Status#: </Text>
              <Text
                style={
                  timecard.status === 'APPROVED'
                    ? styles.textApproved
                    : timecard.status === 'DENIED'
                    ? styles.textDenied
                    : timecard.status === 'SUBMITTED'
                    ? styles.textPending
                    : styles.textComplete
                }
              >
                {timecard.status}
              </Text>
            </View>
          </View>
          <View style={styles.lastListContent}>
            <View style={styles.lastCardRow}>
              <Text style={styles.cardRowText}>Asset: </Text>
              <Text style={styles.text}>
                {!!timecard?.vehicle
                  ? `${timecard?.vehicle?.year} ${timecard?.vehicle?.make} ${timecard?.vehicle?.model}`
                  : 'None'}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  return (
    timecards?.length > 0 && (
      <Document>
        <Page size="A4" style={styles.page} wrap>
          <PageHeaderLogo company={company} />
          <BatchPageHeader company={company} user={user} filters={filters} />
          {timecards.map((timecard) => (
            <Timecard
              key={`${timecard.id}-${timecard.dateCreated}`}
              timecard={timecard}
            />
          ))}
          <PageFooter />
        </Page>
      </Document>
    )
  );
};
