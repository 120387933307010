import React from 'react';
import styles from './AddQuestionButton.module.scss';
import Icon from '@mui/material/Icon';
import { Text } from '../typography';
import { getTranslationKey } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
export default function AddQuestionButton({
  icon = '',
  name,
  selected,
  onClick,
}) {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      className={selected ? styles.selectedContainer : styles.container}
    >
      <div className={styles.subContainer}>
        <div className={styles.iconContainer}>
          <Icon
            baseClassName="material-icons-outlined"
            className={selected ? styles.selectedIcon : styles.icon}
          >
            {icon.toLowerCase()}
          </Icon>
        </div>
        <Text
          textAlign="center"
          size="sm"
          noMargin
          color={selected ? 'white' : 'primary'}
        >
          {t(getTranslationKey(name, 'templates')) || name}
        </Text>
      </div>
    </div>
  );
}
