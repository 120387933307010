import sevenZip from '../assets/file_icons/7z.svg';
import ai from '../assets/file_icons/ai.svg';
import apk from '../assets/file_icons/apk.svg';
import bat from '../assets/file_icons/bat.svg';
import bin from '../assets/file_icons/bin.svg';
import css from '../assets/file_icons/css.svg';
import db from '../assets/file_icons/db.svg';
import audio from '../assets/file_icons/audio.svg';
import dll from '../assets/file_icons/dll.svg';
import dmg from '../assets/file_icons/dmg.svg';
import dng from '../assets/file_icons/dng.svg';
import doc from '../assets/file_icons/doc.svg';
import eml from '../assets/file_icons/eml.svg';
import exe from '../assets/file_icons/exe.svg';
import folder from '../assets/file_icons/folder.svg';
import gif from '../assets/file_icons/gif.svg';
import html from '../assets/file_icons/html.svg';
import ico from '../assets/file_icons/ico.svg';
import iso from '../assets/file_icons/iso.svg';
import jar from '../assets/file_icons/jar.svg';
import jpg from '../assets/file_icons/jpg.svg';
import js from '../assets/file_icons/js.svg';
import mkv from '../assets/file_icons/mkv.svg';
import mp3 from '../assets/file_icons/mp3.svg';
import mp4 from '../assets/file_icons/mp4.svg';
import mpeg from '../assets/file_icons/mpeg.svg';
import msi from '../assets/file_icons/msi.svg';
import odt from '../assets/file_icons/odt.svg';
import otf from '../assets/file_icons/otf.svg';
import pdf from '../assets/file_icons/pdf.svg';
import png from '../assets/file_icons/png.svg';
import ppt from '../assets/file_icons/ppt.svg';
import psd from '../assets/file_icons/psd.svg';
import py from '../assets/file_icons/py.svg';
import rar from '../assets/file_icons/rar.svg';
import rss from '../assets/file_icons/rss.svg';
import rtf from '../assets/file_icons/rtf.svg';
import sav from '../assets/file_icons/sav.svg';
import sql from '../assets/file_icons/sql.svg';
import svg from '../assets/file_icons/svg.svg';
import ttf from '../assets/file_icons/ttf.svg';
import txt from '../assets/file_icons/txt.svg';
import wav from '../assets/file_icons/wav.svg';
import woff from '../assets/file_icons/woff.svg';
import xls from '../assets/file_icons/xls.svg';
import xml from '../assets/file_icons/xml.svg';
import zip from '../assets/file_icons/zip.svg';
import webm from '../assets/file_icons/webm.svg';
import unknown from '../assets/file_icons/unknown.svg';
import globalFolder from '../assets/icons/folder_global.svg';

export default function findFileIcon(extension) {
  switch (extension?.toLowerCase() || extension) {
    case '7z':
      return sevenZip;
    case 'ai':
      return ai;
    case 'apk':
      return apk;
    case 'bat':
      return bat;
    case 'bin':
      return bin;
    case 'css':
      return css;
    case 'csv':
      return xls;
    case 'db':
      return db;
    case 'dll':
      return dll;
    case 'dmg':
      return dmg;
    case 'dng':
      return dng;
    case 'docm':
      return doc;
    case 'doc':
      return doc;
    case 'docx':
      return doc;
    case 'eml':
      return eml;
    case 'exe':
      return exe;
    case 'folder':
      return folder;
    case 'global-folder':
      return globalFolder;
    case 'gif':
      return gif;
    case 'html':
      return html;
    case 'ico':
      return ico;
    case 'iso':
      return iso;
    case 'jar':
      return jar;
    case 'jpg':
      return jpg;
    case 'jpeg':
      return jpg;
    case 'js':
      return js;
    case 'mkv':
    case 'mov':
      return mkv;
    case 'mp3':
      return mp3;
    case 'mp4':
    case 'audio/mp4':
      return mp4;
    case 'mpeg':
      return mpeg;
    case 'msi':
      return msi;
    case 'odt':
      return odt;
    case 'otf':
      return otf;
    case 'pdf':
      return pdf;
    case 'png':
      return png;
    case 'ppt':
      return ppt;
    case 'psd':
      return psd;
    case 'py':
      return py;
    case 'rar':
      return rar;
    case 'rss':
      return rss;
    case 'rtf':
      return rtf;
    case 'sav':
      return sav;
    case 'sql':
      return sql;
    case 'svg':
      return svg;
    case 'ttf':
      return ttf;
    case 'txt':
      return txt;
    case 'audio':
      return audio;
    case 'webm':
    case 'audio/webm':
    case 'audio/webm;codecs=opus':
      return webm;
    case 'wav':
      return wav;
    case 'woff':
      return woff;
    case 'xls':
      return xls;
    case 'xlsx':
      return xls;
    case 'xml':
      return xml;
    case 'zip':
      return zip;
    default:
      return unknown;
  }
}

export const fileStatusTypes = [
  'Issued For Information',
  'Issued For Construction',
  'Reviewed',
  'Issued For Tender',
  'Issued for Use',
  'Rejected',
  'Issued For Review',
  'Issued for Design',
  'Revise and Resubmit',
  'Issued For Approval',
  'Issued for Fabrication',
  'Reviewed with Comments',
  'Issued for Design',
  'As Built',
  'VOID',
  'Issued For Design Review',
  'Closed',
  'Redline',
];

export const fileDescriptionTypes = [
  'Inspection',
  'Drawing Review',
  'Warranty',
  'Insurance',
  'Minutes of Meeting',
  'Model',
  'Org Chart',
  'Policy',
  'Procedure',
  'Report',
  'Request for Tender',
  'Resume/CV',
  'Safety Data Sheet',
  'Schedule',
  'Standard',
  'Request for Information',
  'Site Instruction',
  'Field Instruction',
  'Work Package',
  'Technical Sketch',
  'Technical Change Request',
  'Technical Data Sheet',
  'Technical Drawing',
  'Technical Specification',
  'Tender',
  'Other - Technical',
  'Other - Project Controls',
  'Other Human Resources',
  'Other - Maintenance',
  'Other - Quality',
  'Other',
];

export const fileTypesConversion = {
  Drawing: ['dwg'],
  Image: ['png', 'jpeg', 'svg', 'HEIC', 'jpg', 'gif', 'webp'],
  Spreadsheet: ['xls', 'xlsx', 'csv'],
  PDF: ['pdf', 'ps', 'html'],
  Word: ['doc', 'docx'],
  Video: ['mp4', '.avi', 'wmv', '.mov', 'mpg'],
};
export const imageExtensions = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'webp',
  'tiff',
  'svg',
];

export const fileStatusStringLookup = {
  'Issued For Information': 'ISSUED_FOR_INFORMATION',
  'Issued For Construction': 'ISSUED_FOR_CONSTRUCTION',
  Reviewed: 'REVIEWED',
  'Issued For Tender': 'ISSUED_FOR_TENDER',
  'Issued for Use': 'ISSUED_FOR_USE',
  Rejected: 'REJECTED',
  'Issued For Review': 'ISSED_FOR_REVIEW',
  'Issued for Design': 'ISSUED_FOR_DESIGN',
  'Revise and Resubmit': 'REVISE_AND_RESUBMIT',
  'Issued For Approval': 'ISSUED_FOR_APPROVAL',
  'Issued for Fabrication': 'ISSUED_FOR_FABRICATION',
  'Reviewed with Comments': 'REVIEWED_FOR_COMMENTS',
  'As Built': 'AS_BUILT',
  VOID: 'VOID',
  'Issued For Design Review': 'ISSUED_FOR_DESIGN_REVIEW',
  Closed: 'CLOSED',
  Redline: 'REDLINE',
};

export const fileDescriptionStringLookup = {
  Inspection: 'INSPECTION',
  'Drawing Review': 'DRAWING_REVIEW',
  Warranty: 'WARRANTY',
  Insurance: 'INSURANCE',
  'Minutes of Meeting': 'MINUTES_OF_MEETING',
  Model: 'MODEL',
  'Org Chart': 'ORG_CHART',
  Policy: 'POLICY',
  Procedure: 'PROCEDURE',
  Report: 'REPORT',
  'Request for Tender': 'REQUEST_FOR_TENDER',
  'Resume/CV': 'RESUME_CV',
  'Safety Data Sheet': 'SAFETY_DATA_SHEET',
  Schedule: 'SCHEDULE',
  Standard: 'STANDARD',
  'Request for Information': 'REQUEST_FOR_INFORMATION',
  'Site Instruction': 'SITE_INSTRUCTION',
  'Field Instruction': 'FIELD_INSTRUCTION',
  'Work Package': 'WORK_PACKAGE',
  'Technical Sketch': 'TECHNICAL_SKETCH',
  'Technical Change Request': 'TECHNICAL_CHANGE_REQUEST',
  'Technical Data Sheet': 'TECHNICAL_DATA_SHEET',
  'Technical Drawing': 'TECHNICAL_DRAWING',
  'Technical Specification': 'TECHNICAL_SPECIFICATION',
  Tender: 'TENDER',
  'Other - Technical': 'OTHER_TECHNICAL',
  'Other - Project Controls': 'OTHER_PROJECT_CONTROLS',
  'Other Human Resources': 'OTHER_HUMAN_RESOURCES',
  'Other - Maintenance': 'OTHER_MAINTENANCE',
  'Other - Quality': 'OTHER_QUALITY',
  Other: 'OTHER',
};
