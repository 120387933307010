import styles from './FullPageImageModal.module.scss';
import { Text } from '../typography';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import { hideFullPageImageModal } from '../../graphql/cache/modal';
import EmptyStateView from '../empty_state_view/EmptyStateView';
import noImage from '../../assets/icons/image_blank.png';

export default function FullPageImageModal({ show = false, image = {} }) {
  const title = image?.description ? image.description : 'Image Viewer';
  const imageUrl = image?.imageUrl ? image.imageUrl : null;

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.header}>
            <Text
              className={styles.titleText}
              weight="semiBold"
              size="lg"
              color="primary"
            >
              {title}
            </Text>
            <Icon
              className={styles.closeIcon}
              onClick={() => {
                hideFullPageImageModal();
              }}
            >
              close
            </Icon>
          </div>

          <div className={styles.imageContainer}>
            {imageUrl ? (
              <img
                className={styles.image}
                src={imageUrl || noImage}
                alt="imageViewer"
              />
            ) : (
              <div className={styles.emptyState}>
                <EmptyStateView
                  title="Image Not Found"
                  text="We have encountered an error displaying this image. Please close this window, refresh your browser, and try again."
                  image={noImage}
                ></EmptyStateView>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
