import styles from './DashboardCard.module.scss';
import Icon from '../../../components/Icon';
import { Text } from '../../../components/typography';
import classNames from 'classnames';

export default function DashboardCard({
  icon,
  iconColor,
  header,
  subtext,
  rightContent,
  onClick = () => {},
  className,
}) {
  return (
    <div
      className={classNames(styles.container, styles[className])}
      onClick={onClick}
    >
      <div className={styles.left}>
        <Icon color={iconColor} className={styles[iconColor]}>
          {icon || 'circle'}
        </Icon>
      </div>
      <div className={styles.middle}>
        <div className={styles.text}>
          <Text weight="semiBold" noMargin truncate>
            {header}
          </Text>
          <Text size="sm" noMargin>
            {subtext}
          </Text>
        </div>
      </div>
      <div className={styles.right}>{rightContent}</div>
    </div>
  );
}
