import React from 'react';
import { reviewStatus } from '../../../constants/misc';
import styles from './Review.module.scss';
import { Text } from '../../../components/typography';
import { getLocalTime } from '../../../utilities/time';

export default function Review({
  reviewer,
  editable = true,
  deletable = true,
  onEditClick,
  onCancelEdit,
  onDelete,
  isEditing,
}) {
  if (reviewer.status === reviewStatus.open) {
    return null;
  }

  const getReviewMessage = (user, status, feedback) => {
    const actions = {
      [reviewStatus.approved]: 'approved this review',
      [reviewStatus.changeRequest]: 'requested revisions',
    };

    return (
      <div className={styles.reviewContainer}>
        <img
          className={styles.avatar}
          src={reviewer.user.avatarUrl}
          alt={`${reviewer.user.firstName} ${reviewer.user.lastName}`}
        />
        <div className={editable ? styles.editContainer : null}>
          <a href={`/user/${user.id}`} className={styles.userLink}>
            {`${user.firstName} ${user.lastName}`}
          </a>{' '}
          {actions[status]} with the feedback:{' '}
          <span className={styles.feedback}>"{feedback}"</span>.
          <Text size="sm" color="secondary" weight="semiBold">
            {getLocalTime(reviewer.dateModified).format(
              'MMMM Do YYYY, h:mm:ss a',
            )}
          </Text>
        </div>
        {deletable && !editable && (
          <Text
            className={styles.link}
            size="sm"
            weight="semiBold"
            color="red"
            onClick={onDelete}
          >
            Delete
          </Text>
        )}
        {editable && (
          <Text
            className={styles.link}
            size="sm"
            weight="semiBold"
            color="blue"
            onClick={isEditing ? onCancelEdit : onEditClick}
          >
            {isEditing ? 'Cancel Edit' : 'Edit Your Review'}
          </Text>
        )}
      </div>
    );
  };

  const message = getReviewMessage(
    reviewer.user,
    reviewer.status,
    reviewer.notes,
  );

  return <div className={styles.notificationMessage}>{message}</div>;
}
