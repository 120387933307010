import { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

const labelQuery = loader('./LabelProvider.graphql');

const LabelProviderContext = createContext();
export const LabelProvider = ({ children }) => {
  const { data: { labels = [] } = {} } = useQuery(labelQuery);

  return (
    <LabelProviderContext.Provider value={{ labels }}>
      {children}
    </LabelProviderContext.Provider>
  );
};
export const useLabels = () => useContext(LabelProviderContext);
