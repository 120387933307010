import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Text } from '../typography';
import styles from './VehicleCard.module.scss';
import { truncate } from '../../utilities';
import noImage from '../../assets/icons/image_blank.png';
import { useTranslation } from 'react-i18next';
import { getTranslationKey } from '../../utilities/translator/translation_keys';
import { Icon } from '@mui/material';
import { showAssetAlarmClassname } from '../../utilities';

export default function VehicleCard({ vehicle, className, onClick }) {
  const { make, model, imageUrl, unitNumber, year } = vehicle;
  const namespace = 'assets';
  const { t } = useTranslation();

  return vehicle?.id ? (
    <div className={classNames(styles.container, className)} onClick={onClick}>
      <Icon
        className={classNames([
          styles.notificationIcon,
          styles[showAssetAlarmClassname({ vehicle })],
        ])}
      >
        error
      </Icon>
      <div className={styles.parent}>
        <div className={styles.leftContainer}>
          <div className={styles.header}>
            <Text size="md" weight="bold" className={styles.title} noMargin>
              {truncate(`${year || ''} ${make} ${model}`, 15)}
            </Text>
            <Text noMargin size="md" weight="semiBold" color="secondary">
              {truncate(unitNumber, 7)}
            </Text>
          </div>
          <div className={styles.vehicleType}>
            <Text size="xs" color="secondary">
              {`${t(getTranslationKey(vehicle.type, namespace))} - ${t(
                getTranslationKey(vehicle.subtype, namespace),
              )}` || '-'}
            </Text>
          </div>

          <img
            className={imageUrl ? styles.image : styles.noImage}
            src={imageUrl ? imageUrl : noImage}
            alt={`${make} ${model}`}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.spinnerContainer}>
      <Spinner
        className={styles.spinner}
        animation="border"
        variant="primary"
      />
    </div>
  );
}
