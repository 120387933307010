import React, { useState, useMemo, useEffect } from 'react';
import styles from './Timecards.module.scss';
import { Text } from '../../components/typography';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { Button } from '../../components';
import SimpleTabSystem from '../../components/SimpleTabSystem';
import { paths, sortTimecardStrings } from '../../constants/strings';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleUserCard from '../../components/SimpleUserCard';
import TablePagination from '../../components/pagination/TablePagination';
import classNames from 'classnames';
import TableFiltering from '../../components/sorting/TableFiltering';
import TableSorting from '../../components/sorting/TableSorting';
import {
  timecardFilterOptions,
  timecardStatusOptions,
  convertTimecardFiltersToQueryParams,
} from '../../utilities/filtering';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import emptyState from '../../assets/empty_state_images/party_actions.svg';
import { Spinner, Table } from 'react-bootstrap';
import { truncate } from '../../utilities/';
import { getLocalTime } from '../../utilities/time';
import Label from '../../components/action_items/Label';
import { timecardStatusTypes, timecardTable } from './Timecards.js';
import { showToast } from '../../graphql/cache/modal.js';
import { toastLength, toastVariant } from '../../constants/misc.js';
import { Form } from 'react-bootstrap';
import { timecardStatus } from '../../constants/misc.js';
import {
  handleRowSelect,
  handleSelectAll,
  process,
} from './BatchUpdateButtons.jsx';
import moment from 'moment';
import { activityStatusOptions } from '../../components/activities/activites.js';
import { useMobileListener } from '../../hooks/misc';
import BatchUpdateButtons from './BatchUpdateButtons.jsx';
import { useCurrentUser } from '../../providers/UserProvider.jsx';
import TimecardReportExportModal from '../../components/modals/TimecardReportExportModal.jsx';
import ContextMenu from '../../components/ContextMenu.jsx';
import DateRangeSelector from '../../components/DateRangeSelector.jsx';
import { keys } from '../../utilities/translator/translation_keys.js';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FlagDisabled from '../flagDisabled/FlagDisabled';

const timecardsQuery = loader('./Timecards.graphql');
const sortingOptions = [
  'startTime',
  'endTime',
  'status',
  'billableHours',
  'poNumber',
  'dateModified',
];
const updateTimecardMutation = loader('./Timecards.update.graphql');
const updateActivityMutation = loader('./AddTimecard.updateActivity.graphql');
const pageSize = 12;

export default function Timecards({ disabled = false }) {
  const { t } = useTranslation();
  const tabOptions = [
    { title: t(keys.common.PERSONAL), key: 'personal' },
    { title: t(keys.common.TEAM), key: 'team' },
  ];

  const { tab } = useParams();
  const [selectedTab, setSelectedTab] = useState(tab || tabOptions[0]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('startTime');
  const [isAscending, setIsAscending] = useState(false);
  const { user: currentUser, isAdmin, team, isSupervisor } = useCurrentUser();
  const [rawFilters, setRawFilters] = useState({});
  const [updateTimecards] = useMutation(updateTimecardMutation);
  const [updateActivity] = useMutation(updateActivityMutation);
  const isMobile = useMobileListener();
  const [myTeamOnly, setMyTeamOnly] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    min: null,
    max: null,
  });

  const filters = useMemo(() => {
    return convertTimecardFiltersToQueryParams(
      rawFilters,
      selectedTab,
      currentUser || {},
      team,
      isSupervisor,
      myTeamOnly,
      dateFilter,
    );
  }, [
    rawFilters,
    selectedTab,
    currentUser,
    team,
    isSupervisor,
    myTeamOnly,
    dateFilter,
  ]);

  const {
    networkStatus,
    data: { timecards = [], timecardsCount = 0 } = {},
    refetch,
  } = useQuery(timecardsQuery, {
    variables: {
      options: {
        page,
        pageSize,
        sort: [{ field: sortBy, order: isAscending ? 'asc' : 'desc' }],
        filters,
      },
    },
    skip: !currentUser?.id,
  });

  const isPersonal = selectedTab === 'personal';
  const isTeam = selectedTab === 'team';

  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const toApprove = process(timecards, selected, timecardStatus.submitted);
  const toPay = process(timecards, selected, timecardStatus.approved);
  const [showExportModal, setShowExportModal] = useState(false);
  const [selectedTimecard, setSelectedTimecard] = useState(null);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  useEffect(() => {
    if (isTeam) {
      setRawFilters((prev) => ({ ...prev, filterData: { Status: 'Pending' } }));
    } else {
      setRawFilters({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const contextMenuOptions = [
    {
      title: t(keys.action.OPEN_IN_NEW_WINDOW),
      onClick: () => {
        window.open(`/${paths.timecard}/?id=${selectedTimecard}`);
        setContextMenuOpen(false);
      },
    },
  ];

  const TimecardTable = ({ data, headers }) => {
    return (
      <Table striped style={{ verticalAlign: 'middle' }}>
        <thead>
          <tr>
            {isTeam && isAdmin && (
              <th className={styles.xsmall}>
                <Form.Check
                  checked={selectAll}
                  onChange={() =>
                    handleSelectAll({
                      items: timecards,
                      setSelected,
                      setSelectAll,
                      selectAll,
                    })
                  }
                />
              </th>
            )}
            {headers.map((header, index) => {
              if (
                (isPersonal && header.query === 'creator') ||
                (isTeam && header.query === 'dateCreated')
              ) {
                return null;
              }
              return (
                <th
                  key={index}
                  className={classNames(
                    styles.tableHeader,
                    styles[header.className],
                    styles[header.hide],
                  )}
                >
                  <Text weight="semiBold" color="secondary" noMargin>
                    {t(header.title)}
                  </Text>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((timecard, rowIndex) => (
            <tr key={rowIndex}>
              {isTeam && isAdmin && (
                <td className={styles.xsmall} key={timecard.id + rowIndex}>
                  {timecard.status !== timecardStatus.complete &&
                    timecard.status !== timecardStatus.denied && (
                      <Form.Check
                        checked={selected.includes(timecard.id) || selectAll}
                        onChange={() =>
                          handleRowSelect({
                            item: timecard,
                            setSelectAll,
                            selected,
                            setSelected,
                          })
                        }
                      />
                    )}
                </td>
              )}

              {headers.map((header, colIndex) => {
                if (
                  (isPersonal && header.query === 'creator') ||
                  (isTeam && header.query === 'dateCreated')
                ) {
                  return null;
                }
                return (
                  <td
                    key={colIndex}
                    className={styles[header.hide]}
                    onClick={() =>
                      navigate(`/${paths.timecard}/?id=${timecard.id}`)
                    }
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setSelectedTimecard(timecard.id);
                      setContextMenuOpen(true);
                    }}
                  >
                    <div className={styles.cell}>
                      {header.title === 'Activity' ? (
                        <Text noMargin weight="semiBold" size="sm">
                          {timecard?.activity?.description || '-'}
                        </Text>
                      ) : (
                        getTableDisplay(
                          header,
                          timecard[header.query],
                          timecard.id,
                        )
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const getTableDisplay = (header, value, timecardId) => {
    switch (header.type) {
      case 'user':
        return <SimpleUserCard user={value} size="sm" hideAvatar={isMobile} />;
      case 'date':
        return (
          <Text weight="semiBold" className={styles.title} size="sm" noMargin>
            {value ? getLocalTime(value).format('yyyy-MM-DD HH:mm') : '-'}
          </Text>
        );
      case 'label':
        return (
          <>
            {contextMenuOpen && (
              <ContextMenu
                open={contextMenuOpen && selectedTimecard === timecardId}
                options={contextMenuOptions}
                setOpen={setContextMenuOpen}
                className={styles.contextMenu}
              />
            )}
            <Label
              className={styles.label}
              color={timecardStatusTypes[value]?.variant}
              name={t(timecardStatusTypes[value]?.title)}
            />
          </>
        );
      default:
        return (
          <Text noMargin weight="semiBold" size="sm">
            {truncate(value, 20) || '-'}
          </Text>
        );
    }
  };

  const { enableTimecards } = useFlags();

  return enableTimecards ? (
    <div
      className={classNames(
        styles.container,
        disabled ? styles.disabled : null,
      )}
    >
      <div className={styles.header}>
        <Text size="lg" color="accentPrimary" weight="bold">
          {t(keys.common.TIMECARDS)}
        </Text>
        <div className={styles.buttons}>
          <Button
            value={t(keys.action.NEW)}
            icon="add"
            onClick={() => {
              navigate(`/${paths.timecard}`);
            }}
          />
          <Button
            variant="primary"
            value={t(keys.action.EXPORT)}
            outlined
            onClick={() => {
              setShowExportModal(true);
            }}
          />
        </div>
      </div>
      <div className={styles.page}>
        <SimpleTabSystem
          options={isAdmin || isSupervisor ? tabOptions : [tabOptions[0]]}
          selected={selectedTab}
          setSelected={setSelectedTab}
          route={paths.timecards}
        />
        <div className={styles.sort}>
          <TableFiltering
            filters={filters}
            setFilters={setRawFilters}
            rawFilters={rawFilters}
            filterOptions={
              selectedTab === tabOptions[0].key
                ? timecardFilterOptions.slice(0, 3)
                : timecardFilterOptions
            }
            statusOptions={timecardStatusOptions}
            setPage={setPage}
            userQuery={isAdmin ? 'all' : 'workspace'}
          />
          <div className={styles.rightButtons}>
            <BatchUpdateButtons
              toApprove={toApprove}
              toPay={toPay}
              type={t(keys.common.TIMECARD)}
              onSubmit={async ({ ids, status }) => {
                let errorLog = [];
                const { data, errors } = await updateTimecards({
                  variables: {
                    ids,
                    status,
                  },
                });
                const timecards = data.updateTimecards;
                if (
                  !!timecards &&
                  status === timecardStatus.approved &&
                  !errors
                ) {
                  for (let i = 0; i < timecards.length; i++) {
                    const timecard = timecards[i];
                    if (timecard.status === timecardStatus.approved) {
                      const activity = timecard.activity;
                      const { errors } = await updateActivity({
                        variables: {
                          id: activity.id,
                          progress: 50,
                          actualStartDate: activity?.actualStartDate
                            ? activity.actualStartDate
                            : moment(parseInt(timecard?.startTime)).format(
                                'MM/DD/YYYY HH:mm:ss',
                              ),
                          actualEndDate: null,
                          status: activityStatusOptions.inProgress,
                          actualHours:
                            parseFloat(activity?.actualHours) +
                            parseFloat(timecard.billableHours),
                        },
                      });
                      if (errors) {
                        console.error(errors);
                        errorLog.push({
                          activity: activity.description,
                          message: errors[0].message,
                        });
                      }
                    }
                  }
                }
                setSelectAll(false);
                setSelected([]);
                if (errors) {
                  console.error(errors);
                  showToast({
                    title: t(keys.action.ERROR_BATCH_UPDATE_TITLE, {
                      variable: t(keys.common.TIMECARDS),
                    }),
                    message: t(keys.action.ERROR_BATCH_UPDATE_MESSAGE, {
                      variable: t(keys.common.TIMECARDS),
                      message: errors[0].message,
                    }),
                    time: toastLength.lg,
                    variant: toastVariant.danger,
                  });
                }
                if (errorLog.length) {
                  const errorMessageString = errorLog
                    .map(({ activity, message }) => `${activity}: ${message}`)
                    .join(', ');
                  showToast({
                    title: t(keys.timecards.ACTIVITY_ERROR_TITLE),
                    message: t(keys.timecards.ACTIVITY_ERROR_MESSAGE, {
                      variable: errorMessageString,
                    }),
                    time: toastLength.lg * 1000,
                    variant: toastVariant.danger,
                  });
                } else {
                  showToast({
                    title: t(keys.timecards.BATCH_UPDATE_SUCCESS_TITLE),
                    message: t(keys.timecards.BATCH_UPDATE_SUCCESS_MESSAGE, {
                      count: timecards.length,
                      status,
                    }),
                    time: toastLength.lg,
                    variant: toastVariant.info,
                  });
                }
              }}
            />
            {isTeam && isAdmin && (
              <div className={styles.teamFilter}>
                <Form.Check
                  onChange={() => {
                    setPage(1);
                    setMyTeamOnly(!myTeamOnly);
                  }}
                  checked={myTeamOnly}
                />
                <Text noMargin noSelect size="sm">
                  {t(keys.common.MY_TEAM_ONLY)}
                </Text>
              </div>
            )}

            <TableSorting
              setSortBy={setSortBy}
              setIsAscending={setIsAscending}
              refetch={refetch}
              isAscending={isAscending}
              sortingOptions={sortingOptions}
              sortBy={sortBy}
              sortStrings={sortTimecardStrings}
            />
          </div>
        </div>
        <div className={styles.bottomFilters}>
          <DateRangeSelector
            onChange={({ min, max }) => {
              setPage(1);
              setDateFilter({ min, max });
            }}
            values={dateFilter}
          />
        </div>
        {networkStatus === NetworkStatus.loading ? (
          <Spinner className={styles.spinner} animation="border" />
        ) : timecards.length ? (
          <div className={styles.tableContainer}>
            <TimecardTable data={timecards} headers={timecardTable} />
            <TablePagination
              pageSize={pageSize}
              count={timecardsCount}
              setPage={setPage}
              page={page}
            />
          </div>
        ) : (
          <div className={styles.emptyStateContainer}>
            <EmptyStateView
              title={t(keys.action.NOT_FOUND, {
                variable: t(keys.common.TIMECARDS),
              })}
              text={
                rawFilters?.filterData?.length
                  ? t(keys.action.EMPTY_STATE_CHECK_FILTERS, {
                      variable: t(keys.common.TIMECARDS),
                    })
                  : t(keys.action.EMPTY_STATE_MESSAGE, {
                      variable: t(keys.common.TIMECARDS),
                    })
              }
              image={emptyState}
            />
          </div>
        )}
      </div>
      <TimecardReportExportModal
        show={showExportModal}
        onClose={() => setShowExportModal(false)}
        title={t(keys.timecards.EXPORT_MODAL_TITLE)}
      />
    </div>
  ) : (
    <FlagDisabled />
  );
}
