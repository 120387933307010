import styles from './CreateObservationQuestions.module.scss';
import { Form, Col } from 'react-bootstrap';
import { Text } from '../../components/typography';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import { keys } from '../../utilities/translator/translation_keys';
import { useState, useMemo } from 'react';
import emptyStateGraphic from '../../assets/folder_graphic.svg';
import Modal from '../../components/modals/Modal';
import { mockQuestionTypes } from '../../utilities/inspection';
import AddQuestionButton from '../../components/question_menu/AddQuestionButton';
import { dateOptions } from '../../utilities/inspection';
import { getTranslationKey } from '../../utilities/translator/translation_keys';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { inputTypes } from '../../constants/strings';
import OptionsSelector from '../../components/OptionsSelector';
import Icon from '../../components/Icon';
import { getObservationIcon, observationSupportedTypes } from '../../utilities';
import { useCurrentUser } from '../../providers/UserProvider';
import { toastVariant } from '../../constants/misc';
import { showToast } from '../../graphql/cache/modal';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { modals } from '../../providers/modals';
import { useModal } from '../../providers/ModalProvider';

const addQuestionMutation = loader(
  '../../graphql/mutations/inspection_template_item_create.graphql',
);
const deleteQuestionMutation = loader(
  './CreateQuestions.deleteQuestion.graphql',
);

const updateAllWorkspacesMutation = loader(
  './CreateObservationQuestions.allWorkspaces.graphql',
);
export default function CreateObservationQuestions({
  observationTemplate,
  refetch,
}) {
  const { t } = useTranslation();
  const { openModal, openConfirmationModal } = useModal();
  const [modalOpen, setModalOpen] = useState(false);
  const emptyState = {
    type: 'Text',
    single: true,
    required: false,
    title: '',
    description: '',
  };
  const [question, setQuestion] = useState(emptyState);
  const [addQuestion] = useMutation(addQuestionMutation);
  const [selectionOptions, setSelectionOptions] = useState('');
  const [deleteQuestion] = useMutation(deleteQuestionMutation);
  const { isAdmin } = useCurrentUser();
  const [updateAllWorkspaces] = useMutation(updateAllWorkspacesMutation);
  const navigate = useNavigate();
  const { refetchWorkspace, workspaceId } = useWorkspace();
  const templateId = observationTemplate?.id;
  const categoryId = observationTemplate?.categories[0]?.id;
  const { categories = [], isDraft } = observationTemplate || {};
  const [{ questions } = {}] = categories;

  const inputType = useMemo(() => {
    switch (question.type) {
      case 'Text':
        return question.single ? inputTypes.text : inputTypes.textMulti;
      case 'Select':
        return question.single ? inputTypes.select : inputTypes.multiSelect;
      case 'Date':
        return !question.option ? inputTypes.date : question.option;
      default:
        return null;
    }
  }, [question]);

  return !templateId ? (
    <></>
  ) : (
    <div className={styles.container}>
      {isAdmin && (
        <div className={styles.top}>
          {isDraft ? (
            <Text color="secondary" size="sm" noMargin>
              {t(keys.observations.OBSERVATION_TEMPLATE_PREVIEW_TEXT)}
              <span
                className={styles.link}
                onClick={() => {
                  openModal({
                    modalName: modals.observation,
                    variables: {
                      preview: true,
                      templateId: observationTemplate?.id,
                      questions:
                        observationTemplate?.categories[0]?.questions || [],
                    },
                  });
                }}
              >
                {t(keys.observations.PREVIEW)}
              </span>
            </Text>
          ) : (
            <Button
              value={t(keys.observations.APPLY_TO_ALL_WORKSPACES)}
              onClick={() => {
                openConfirmationModal({
                  title: t(keys.observations.OBSERVATION_TEMPLATE),
                  description: t(keys.observations.APPLY_ALL_MESSAGE),
                  variant: toastVariant.danger,
                  onSubmit: () => {
                    updateAllWorkspaces({ variables: { templateId } }).then(
                      () => {
                        refetchWorkspace().then(() => {
                          showToast({
                            title: t(keys.observations.ALL_WORKSPACES_UPDATED),
                            message: t(
                              keys.observations.ALL_WORKSPACES_UPDATED_MESSAGE,
                              { variable: observationTemplate.title },
                            ),
                            variant: 'info',
                          });
                          navigate(
                            getRoute(
                              workspaceId,
                              paths.observations,
                              '?tab=templates',
                            ),
                          );
                        });
                      },
                    );
                  },
                });
              }}
            />
          )}
        </div>
      )}
      {questions?.length ? (
        <div className={styles.questionContainer}>
          <div className={styles.header}>
            <Text size="lg" weight="semiBold" noMargin>
              {t(keys.observations.QUESTIONS)}
            </Text>
            {observationTemplate.isDraft && (
              <Icon color="primary" hover onClick={() => setModalOpen(true)}>
                add
              </Icon>
            )}
          </div>
          {questions?.map(
            ({
              title,
              description,
              id: questionId,
              inputType,
              additionalData,
              required,
            }) => (
              <div className={styles.questionDisplay} key={questionId}>
                <div className={styles.left}>
                  <Icon className={styles.icon}>
                    {getObservationIcon(inputType)}
                  </Icon>
                  <div>
                    <Text noMargin weight="semiBold">{`${title}${
                      required ? ' *' : ''
                    }`}</Text>
                    <Text noMargin size="sm" color="secondary">
                      {description}
                    </Text>
                    <Text
                      noMargin
                      size="sm"
                      color={
                        observationSupportedTypes.includes(inputType)
                          ? 'secondaryLight'
                          : 'red'
                      }
                    >
                      {observationSupportedTypes.includes(inputType)
                        ? t(getTranslationKey(inputType, 'templates')) ||
                          inputType
                        : t(keys.observations.DEFAULT_TO_TEXT)}
                    </Text>
                    <Text noMargin size="sm" color="secondaryLight">
                      {observationSupportedTypes.includes(inputType) &&
                        additionalData?.split('|').join(', ')}
                    </Text>
                  </div>
                </div>
                <Icon
                  hover
                  color="red"
                  onClick={() => {
                    openConfirmationModal({
                      title: t(keys.templates.DELETE_QUESTION),
                      description: t(keys.action.CONFIRMATION_MESSAGE, {
                        variable: t(keys.action.DELETE_VARIABLE, {
                          variable: `${t(keys.common.QUESTION)} ${
                            question.title
                          }`,
                        }),
                      }),
                      variant: 'danger',
                      buttonText: t(keys.action.DELETE),
                      onSubmit: () => {
                        deleteQuestion({
                          variables: {
                            id: questionId,
                          },
                        }).then(() => {
                          refetch();
                        });
                      },
                    });
                  }}
                >
                  delete
                </Icon>
              </div>
            ),
          )}
        </div>
      ) : (
        <div className={styles.emptyStateContainer}>
          <img alt="empty" className={styles.graphic} src={emptyStateGraphic} />
          <Text noMargin size="lg" weight="semiBold" textAlign="center">
            {t(keys.observations.START_ADDING_QUESTIONS)}
          </Text>
          <Text
            noMargin
            size="md"
            weight="semiBold"
            color="secondary"
            textAlign="center"
          >
            {t(keys.observations.ADD_QUESTION_MESSAGE)}
          </Text>
          <Button
            className={styles.button}
            size="md"
            type="submit"
            value={t(keys.action.ADD, { variable: 'Question' })}
            icon="add"
            onClick={() => setModalOpen(true)}
          />
        </div>
      )}
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setQuestion({
            single: true,
            type: 'Text',
            title: '',
          });
        }}
        footer={
          <div className={styles.footer}>
            <Text weight="semiBold" noMargin>
              {t(keys.common.REQUIRED)}
            </Text>
            <Form.Check
              required
              checked={question.required}
              value={question.required}
              id="switch"
              type="switch"
              onChange={(e) => {
                setQuestion({ ...question, required: e.target.checked });
              }}
            />
          </div>
        }
        submitDisabled={
          !question.title ||
          !question.type ||
          (question.type === 'Select' && !selectionOptions)
        }
        title={t(keys.observations.CREATE_OBSERVATION_QUESTIONS)}
        onSubmit={() => {
          addQuestion({
            variables: {
              title: question.title,
              required: question.required || false,
              inputType,
              templateId,
              categoryId,
              description: question.description,
              additionalData:
                selectionOptions?.options?.map((o) => o.text).join('|') || null,
            },
          }).then(() =>
            refetch().then(() => {
              setQuestion(emptyState);
              setSelectionOptions(null);
            }),
          );
        }}
      >
        <div className={styles.form}>
          <Text weight="semiBold" noMargin>
            {t(keys.common.TITLE)}
          </Text>
          <Form.Control
            placeholder={t(keys.action.ENTER, {
              variable: t(keys.common.TITLE),
            })}
            onChange={(e) =>
              setQuestion({ ...question, title: e.target.value })
            }
            value={question?.title}
          />
          <Text weight="semiBold" noMargin>
            {`${t(keys.common.DESCRIPTION)} (${t(keys.common.OPTIONAL)})`}
          </Text>
          <Form.Control
            placeholder={t(keys.action.ENTER, {
              variable: t(keys.common.DESCRIPTION),
            })}
            as="textarea"
            onChange={(e) =>
              setQuestion({ ...question, description: e.target.value })
            }
            value={question?.description}
          />
          <Text noMargin weight="semiBold" size="md">
            {t(keys.action.SELECT)}
          </Text>
          {question?.type === 'Date' ? (
            <Form.Group
              onChange={(e) =>
                setQuestion({ ...question, option: e.target.value })
              }
            >
              {Object.entries(dateOptions).map(([key, value]) => (
                <Form.Check
                  key={`${key}-${value.title}`}
                  inline
                  type="radio"
                  label={t(getTranslationKey(key, 'templates'))}
                  name="dateOptions"
                  value={key}
                  defaultChecked={key === 'DATE'}
                />
              ))}
            </Form.Group>
          ) : (
            <Form.Group as={Col}>
              <Form.Check
                inline
                name="selectOptions"
                type="radio"
                checked={question.single}
                label={
                  question.type === 'Text'
                    ? t(keys.templates.SINGLE_LINE)
                    : t(keys.templates.SINGLE)
                }
                onChange={(e) =>
                  setQuestion({ ...question, single: e.target.checked })
                }
              />
              <Form.Check
                inline
                name="selectOptions"
                checked={!question.single}
                value={question.single}
                type="radio"
                label={
                  question.type === 'Text'
                    ? t(keys.templates.MULTI_LINE)
                    : t(keys.templates.MULTI)
                }
                onChange={(e) =>
                  setQuestion({ ...question, single: !e.target.checked })
                }
              />
            </Form.Group>
          )}
        </div>
        {question?.type === 'Select' && (
          <div className={styles.optionsSelector}>
            <Text
              noMargin
              weight="semiBold"
              size="md"
              className={styles.formTitle}
            >
              {t(keys.common.OPTIONS)}
            </Text>
            <OptionsSelector
              selected={selectionOptions}
              onSelectionChanged={(opt) => {
                setSelectionOptions(opt);
              }}
            />
          </div>
        )}

        <div className={styles.buttons}>
          {mockQuestionTypes
            .filter((q) => q.observationType)
            .map(({ name, icon }) => (
              <AddQuestionButton
                key={name}
                icon={icon}
                name={name}
                selected={question.type === name}
                onClick={() => setQuestion({ ...question, type: name })}
              />
            ))}
        </div>
      </Modal>
    </div>
  );
}
