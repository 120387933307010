import { ResponsivePieCanvas } from '@nivo/pie';
import React, { useMemo } from 'react';
import { bootstrapColors, primaryGroupings } from './utilities';

export default function PieChart({ events, group }) {
  const data = useMemo(() => {
    const categoryMap = new Map();
    const isSubGroup = !primaryGroupings.some(
      ({ key }) => key.toLocaleLowerCase() === group.toLocaleLowerCase(),
    );
    for (const event of events) {
      const groupName =
        group === 'None'
          ? 'All'
          : isSubGroup
          ? event.information[group]
          : event[group];

      categoryMap.set(
        groupName,
        categoryMap.has(groupName) ? categoryMap.get(groupName) + 1 : 1,
      );
    }

    const result = Array.from(categoryMap.entries()).map(([id, value]) => {
      const color = 'hsl(220, 70%, 50%)';

      return {
        id,
        label: id,
        value,
        color,
      };
    });

    return result;
  }, [events, group]);

  return (
    <div style={{ margin: 'auto', width: 'calc(100% + 4rem)', height: 400 }}>
      <ResponsivePieCanvas
        data={data}
        margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
        innerRadius={0.65}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        colors={bootstrapColors}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
      />
    </div>
  );
}
