import React, { useMemo } from 'react';
import { ResponsiveCalendarCanvas } from '@nivo/calendar';
import moment from 'moment';
import { getLocalTime } from '../../utilities/time';

export default function CalendarChart({
  events = [],
  width,
  startDate,
  endDate,
  hover = false,
  margin = null,
  height = null,
  legends = true,
}) {
  const data = useMemo(() => {
    const counts = new Map();
    for (const event of events || []) {
      const date = getLocalTime(event.dateCreated).format('YYYY-MM-DD');
      if (counts.has(date)) {
        counts.set(date, counts.get(date) + 1);
      } else {
        counts.set(date, 1);
      }
    }
    const result = Array.from(counts).map(([day, value]) => ({ day, value }));
    return result;
  }, [events]);

  return (
    <div style={{ width, height: height || 200, margin: 'auto' }}>
      <ResponsiveCalendarCanvas
        isInteractive={hover}
        data={data}
        from={startDate || moment().subtract(30, 'days').format('YYYY-MM-DD')}
        to={endDate || moment().format('YYYY-MM-DD')}
        emptyColor="#eeeeee"
        colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
        margin={margin || { top: 40, right: 40, bottom: 40, left: 40 }}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        legends={
          legends
            ? [
                {
                  anchor: 'bottom-right',
                  direction: 'row',
                  translateY: 40,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: 'right-to-left',
                },
              ]
            : [{}]
        }
      />
    </div>
  );
}
