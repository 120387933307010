import CustomSpinner from '../components/CustomSpinner';
import { useApolloContext } from '../graphql/ApiProvider';
import { useEffect } from 'react';

export default function SignOut() {
  const { signOut } = useApolloContext();
  useEffect(() => {
    signOut();
  }, [signOut]);

  return <CustomSpinner text={'Logging out...'} />;
}
