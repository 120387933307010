import styles from './ErrorBoundaryPage.module.scss';
import { Text } from '../components/typography';
import { Button } from '../components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import Bugsnag from '@bugsnag/js';
export default function ErrorBoundaryPage({ error }) {
  const goToPage = () => {
    window.location.href = '/';
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      Bugsnag.notify(`ERROR BOUNDARY PAGE rendered: ${error}`);
    } else {
      Bugsnag.notify(new Error('ERROR BOUNDARY PAGE rendered: Error unknown'));
    }
  }, [error]);

  return (
    <div className={styles.container}>
      <img
        src="https://opas-public.s3.us-east-2.amazonaws.com/webapp-assets/stop_robot.png"
        alt="error"
        className={styles.errorImage}
      ></img>
      <br />
      <Text size="xl" weight="bold" noMargin textAlign="center">
        {t(keys.utilities.ERROR_PAGE_TITLE)}
      </Text>
      <Text noMargin textAlign="center">
        {t(keys.utilities.ERROR_PAGE_DESCRIPTION)}
      </Text>
      <Text noMargin textAlign="center">
        {t(keys.utilities.HELP)}
        <span
          className={styles.link}
          onClick={() =>
            (window.location.href = 'https://www.opasmobile.com/contact')
          }
        >
          {t(keys.utilities.LET_US_KNOW)}
        </span>
      </Text>
      <br />

      <div className={styles.button}>
        <Button
          className={styles.hideMobile}
          variant="primary"
          value={t(keys.utilities.REFRESH)}
          icon="refresh"
          onClick={() => window.location.reload()}
        />
        <Button
          variant="primary"
          value={t(keys.common.HOME)}
          icon="home"
          onClick={goToPage}
        />
      </div>
      <br />
    </div>
  );
}
