import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useCallback, useEffect, useState } from 'react';

const initialState =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

const findFirstTextNodeValue = (nodeMap) => {
  let currentKey = nodeMap.get('root').__first; // Start with the first child of the root
  while (currentKey !== null) {
    const node = nodeMap.get(currentKey);
    if (node.__type === 'text') {
      return node.__text; // If it's a text node, return its text
    }
    // If the current node has a child, go down
    if (node.__first !== null) {
      currentKey = node.__first;
    } else if (node.__next !== null) {
      // No child, has a sibling, move to the sibling
      currentKey = node.__next;
    } else {
      // No child, no sibling, backtrack to parent and then to next until you find a sibling or reach the root
      while (currentKey !== null && nodeMap.get(currentKey).__next === null) {
        currentKey = nodeMap.get(currentKey).__parent;
        // Prevent infinite loop if it goes back to root without finding a text node
        if (currentKey === 'root') {
          return null; // No text node found
        }
      }
      if (currentKey !== null) {
        currentKey = nodeMap.get(currentKey).__next;
      }
    }
  }
  return null; // No text node found
};

export default function CustomOnChangePlugin({
  value,
  id,
  onChange: onChangeCallback,
}) {
  const [editor] = useLexicalComposerContext();

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender(true);
  }, [id]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      const initialEditorState = editor.parseEditorState(value || initialState);
      editor.setEditorState(initialEditorState);
    }
  }, [isFirstRender, value, editor]);

  const onChange = useCallback(
    (editorState) => {
      onChangeCallback(
        JSON.stringify(editorState.toJSON()),
        findFirstTextNodeValue(editorState._nodeMap),
      );
    },
    [onChangeCallback],
  );

  // TODO: add ignoreSelectionChange
  return <OnChangePlugin onChange={onChange} />;
}
