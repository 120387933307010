import { keys } from './translator/translation_keys';

export const vehicles = {
  LV: {
    string: 'Light Vehicle',
    CAR: {
      string: 'Car',
    },
    SUV: {
      string: 'SUV',
    },
    VAN: {
      string: 'Van',
    },
    TRAILER: {
      string: 'Trailer',
    },
    MOTORCYCLE: {
      string: 'Motorcycle',
    },
    TRUCK: {
      string: 'Truck',
    },
    OTHER: {
      string: 'Other',
    },
  },
  RV: {
    string: 'Recreation Vehicle',
    ATV: {
      string: 'ATV',
    },
    BOAT: {
      string: 'Boat',
    },
    SNOWMOBILE: {
      string: 'Snowmobile',
    },
    OTHER: {
      string: 'Other',
    },
  },
  AIRCRAFT: {
    string: 'Aircraft',
    HELICOPTER: {
      string: 'Helicopter',
    },
    PASSENGER: {
      string: 'Passenger',
    },
    CARGO: {
      string: 'Cargo',
    },
    SAR: {
      string: 'Search and Rescue',
    },
    FIXED_WING: {
      string: 'Fixed Wing',
    },
    OTHER: {
      string: 'Other',
    },
  },
  HE: {
    string: 'Heavy Equipment',
    CRN: {
      string: 'Crane',
    },
    BULLDOZER: {
      string: 'Bulldozer',
    },
    GENERATOR: {
      string: 'Generator (Towable)',
    },
    COMPRESSOR: {
      string: 'Compressor (Towable)',
    },
    FORKLIFT: {
      string: 'Forklift',
    },
    FEL: {
      string: 'Front End Loader',
    },
    SS: {
      string: 'Skid-steer',
    },
    EXC: {
      string: 'Excavator',
    },
    SEMI: {
      string: 'Semi / Lorry',
    },
    TEL: {
      string: 'Telehandler',
    },
    DUMP: {
      string: 'Dump Truck',
    },
    VAC: {
      string: 'Vac Truck',
    },
    HT: {
      string: 'Heavy Truck',
    },
    BAR: {
      string: 'Barge',
    },
    OTHER: {
      string: 'Other',
    },
  },
  IT: {
    string: 'IT & Office',
    COMP: {
      string: 'Computer',
    },
    PHONE: {
      string: 'Phone',
    },
    TABLET: {
      string: 'Tablet',
    },
    CAM: {
      string: 'Camera',
    },
    FURN: {
      string: 'Furniture',
    },
    OTHER: {
      string: 'Other',
    },
  },
  TOOL: {
    string: 'Tools & Equipment',
    TB: {
      string: 'Test Bench',
    },
    HANDTOOL: {
      string: 'Hand Tools',
    },
    PWRTOOL: {
      string: 'Power Tools',
    },
    WELD: {
      string: 'Welder',
    },
    COMP: {
      string: 'Compressor',
    },
    LIFT: {
      string: 'Lifting Equipment',
    },
    INSP: {
      string: 'Inspection Equipment',
    },
    HYDRAULIC: {
      string: 'Hydraulic Equipment',
    },
    DRONE: {
      string: 'Drone',
    },
    BATTERY: {
      string: 'Battery',
    },
    PUMP: {
      string: 'Pump',
    },
    PPE: {
      string: 'Personal Protective Equipment',
    },
    FSUIT: {
      string: 'Flight Suit',
    },
    FTOOLS: {
      string: 'Flight Tools',
    },
    HARNESS: {
      string: 'Harness',
    },
    HELMET: {
      string: 'Helmet',
    },
    IMSUIT: {
      string: 'Immersion Suit',
    },
    LANYARD: {
      string: 'LANYARD',
    },
    LIGHT: {
      string: 'Light',
    },
    OTHER: {
      string: 'Other',
    },
  },
  PROPERTY: {
    string: 'Property',
    LAND: {
      string: 'Land',
    },
    BUILDING: {
      string: 'Building',
    },
    APPLIANCES: {
      string: 'Appliances',
    },
    FIXED_TANK: {
      string: 'Fixed Tank',
    },
    SEACAN_CONTAINER: {
      string: 'Seacan/Container',
    },
    OTHER: {
      string: 'Other',
    },
  },
  INDUST: {
    string: 'Industrial Equipment',
    HYDROT: {
      string: 'Hydro Test',
    },
    OVCRANE: {
      string: 'Overhead Crane',
    },
    PUMP: {
      string: 'Pump',
    },
    MOBILE_TANK: {
      string: 'Mobile Tank',
    },
    OTHER: {
      string: 'Other',
    },
  },
  EE: {
    string: 'Emergency Equipment',
    FIRSTAID: {
      string: 'First Aid',
    },
    EYEWASHSTATIONS: {
      string: 'Eye Wash Stations',
    },
    AEDs: {
      string: 'AEDs',
    },
    SCBA: {
      string: 'SCBA',
    },
    RESCUEKIT: {
      string: 'Rescue Kit',
    },
    FIRESAFETYEQUIPMENT: {
      string: 'Fire Safety Equipment',
    },
    LIFEPRESERVER: {
      string: 'Life Preserver',
    },
    LIFERAFT: {
      string: 'Life Raft',
    },
    PERSONALLOCATORBEACON: {
      string: 'Personal Locator Beacon',
    },
    RESCUEEQUIPMENT: {
      string: 'Rescue Equipment',
    },
    OTHER: {
      string: 'Other',
    },
  },
};

export const vehicleKeys = [
  'LV',
  'RV',
  'HE',
  'Light Vehicle',
  'Recreation Vehicle',
  'Heavy Equipment',
];

export const assetFields = {
  make: {
    vehicle: {
      title: keys.assets.MAKE,
      tooltip: 'Ford, Audi, etc.',
    },
    asset: {
      title: keys.assets.BRAND,
      tooltip: keys.assets.BRAND,
    },
  },
  model: {
    vehicle: {
      title: keys.assets.MODEL,
      tooltip: 'F-150, Silverado, etc.',
    },
    asset: {
      title: keys.assets.MODEL,
      tooltip: keys.assets.MODEL,
    },
  },
  vin: {
    vehicle: {
      title: keys.assets.VIN,
      tooltip: '5YJSA1DG9DFP13705, etc.',
    },
    asset: {
      title: keys.assets.SERIAL_NUMBER,
      tooltip: '5YJSA1DG9DFP13705, etc.',
    },
  },
};

export const assetTypeStringLookup = {
  'Light Vehicle': 'LIGHT_VEHICLE',
  'Recreation Vehicle': 'RECREATION_VEHICLE',
  'Heavy Equipment': 'HEAVY_EQUIPMENT',
  'IT & Office': 'IT_&_OFFICE',
  'Tools & Equipment': 'TOOLS_&_EQUIPMENT',
  Property: 'PROPERTY',
  'Industrial Equipment': 'INDUSTRIAL_EQUIPMENT',
  'Emergency Equipment': 'EMERGENCY_EQUIPMENT',
  Aircraft: 'AIRCRAFT',
};

export const assetStatus = [
  'Quarantine',
  'Stock',
  'Decommissioned',
  'Active',
  'In Service',
  'Out For Repair',
  'Out For Service',
];

export const asseetColorStringLookup = {
  Stock: 'blue',
  Active: 'green',
  'In Service': 'green',
  Quarantine: 'red',
  Decommissioned: 'red',
  'Out For Repair': 'yellow',
  'Out For Service': 'yellow',
};

export const assetTypeIcons = {
  'Light Vehicle': 'directions_car',
  'Recreation Vehicle': 'snowmobile',
  'Heavy Equipment': 'precision_manufacturing',
  'IT & Office': 'devices',
  'Tools & Equipment': 'plumbing',
  Property: 'home_work',
  'Industrial Equipment': 'factory',
  'Emergency Equipment': 'medical_services',
  Aircraft: 'flight',
};

export const showAssetAlarmClassname = ({ vehicle }) => {
  const { hours, endOfLifeHours, endOfLife } = vehicle || {};
  if (!!hours && !!endOfLifeHours && vehicle.type !== 'Light Vehicle') {
    if (hours >= endOfLifeHours) {
      return 'red';
    } else if (hours >= 0.75 * endOfLifeHours) {
      return 'yellow';
    }
  }

  if (!!endOfLife) {
    const now = new Date();
    const eolDate = new Date(endOfLife);
    const diffInDays = (eolDate - now) / (1000 * 60 * 60 * 24);
    if (0 < diffInDays && diffInDays <= 10) {
      return 'yellow';
    } else if (diffInDays <= 0) {
      return 'red';
    }
  }

  return 'hide';
};

export const endOfLifeDateExpiryCheck = ({ vehicle }) => {
  const { endOfLife } = vehicle || {};
  if (!!endOfLife) {
    const now = new Date();
    const eolDate = new Date(endOfLife);
    const diffInDays = (eolDate - now) / (1000 * 60 * 60 * 24);

    if (diffInDays <= 0) {
      return 'red';
    } else if (diffInDays <= 10) {
      return 'yellow';
    }
  }
  return null;
};
export const endOfLifeHoursCheck = ({ vehicle }) => {
  const { hours, endOfLifeHours } = vehicle || {};
  if (!!hours && !!endOfLifeHours) {
    if (hours >= endOfLifeHours) {
      return 'red';
    } else if (hours >= 0.75 * endOfLifeHours) {
      return 'yellow';
    }
  }
  return null;
};
