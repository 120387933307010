import { openTableFilterModal } from '../../graphql/cache/modal';
import Button from '../Button';
import styles from './TableFiltering.module.scss';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import { useState, useEffect } from 'react';
import { formatFilterDisplay } from '../../utilities/filtering';
import { useTranslation } from 'react-i18next';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';

export default function TableFiltering({
  filters,
  setFilters,
  rawFilters,
  filterOptions,
  statusOptions = null,
  priorityOptions = null,
  setPage,
  userQuery,
}) {
  const [hasFilters, setHasFilters] = useState([]);
  const removeFilters = (key) => {
    delete rawFilters.filterData[key];
    const filterData = rawFilters.filterData;
    setFilters({ filterData });
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (rawFilters?.filterData) {
      setHasFilters(Object.values(rawFilters?.filterData));
    } else {
      setHasFilters([]);
    }
  }, [rawFilters]);

  return (
    <div className={styles.container}>
      <div className={styles.filterList}>
        {rawFilters?.filterData &&
          Object.entries(rawFilters?.filterData).map((filter) => (
            <div className={styles.filterDisplay} key={filter[0]}>
              <Text className={styles.filter} noMargin size="sm">
                <span style={{ fontWeight: 'bold' }}>
                  {t(getTranslationKey(filter[0], 'utilities')) || filter[0]}
                </span>
                {formatFilterDisplay(
                  filter[0],
                  filter[1],
                  filterOptions?.includes('INCIDENT')
                    ? 'incidents'
                    : 'utilities',
                )}
              </Text>
              <Icon
                className={styles.deselectIcon}
                sx={{ fontSize: '1rem' }}
                baseClassName="material-icons-outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  removeFilters(filter[0]);
                }}
              >
                close
              </Icon>
            </div>
          ))}

        {!hasFilters.length ? (
          <Button
            value={t(keys.utilities.FILTERS)}
            icon="filter_alt_icon"
            variant="secondary"
            outlined
            onClick={() => {
              setPage(1);
              openTableFilterModal(
                filters,
                filterOptions,
                statusOptions,
                priorityOptions,
                (f) => {
                  setFilters(f);
                },
                userQuery,
              );
            }}
          />
        ) : (
          <div className={styles.filterDisplay}>
            <Icon
              className={styles.closeIcon}
              sx={{ fontSize: '1rem' }}
              onClick={() => {
                setPage(1);
                openTableFilterModal(
                  filters,
                  filterOptions,
                  statusOptions,
                  priorityOptions,
                  (f) => {
                    setFilters(f);
                  },
                );
              }}
            >
              add
            </Icon>
          </div>
        )}
      </div>
    </div>
  );
}
