import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { loader } from 'graphql.macro';
import { NetworkStatus, useQuery, useMutation } from '@apollo/client';
import { useWorkspace } from './WorkspaceProvider';
import { useCurrentUser } from './UserProvider';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CustomSpinner from '../components/CustomSpinner';

const workspacePermissionsQuery = loader(
  './WorkspacePermissionsProvider.graphql',
);
const addWorkspacePermission = loader('./WorkspaceProvider.addUser.graphql');
const WorkspacePermissionsContext = createContext();

export const WorkspacePermissionsProvider = ({ children }) => {
  const { workspaceId, notInWorkspaces, refetchWorkspace } = useWorkspace();
  const { user, isAdmin } = useCurrentUser();
  const [addAdminPermission] = useMutation(addWorkspacePermission);
  const [changingWorkspace, setChangingWorkspace] = useState(false);
  const { pathname } = useLocation();

  const {
    refetch: refetchPermissions,
    networkStatus,
    data: { workspacePermissions = [], users = [] } = {},
  } = useQuery(workspacePermissionsQuery, {
    skip: !workspaceId,
    nextFetchPolicy: 'standby',
    variables: {
      options: {
        filters: [
          {
            field: 'workspaceId',
            operator: 'eq',
            value: [`${workspaceId}`],
          },
        ],
      },
    },
  });
  useEffect(() => {
    if (isAdmin && notInWorkspaces?.some((id) => id === workspaceId)) {
      addAdminPermission({
        variables: {
          id: workspaceId,
          userId: user.id,
          accessLevel: 'ADMIN',
        },
      }).then(() => {
        refetchWorkspace().then(() =>
          refetchPermissions().then(() => setChangingWorkspace(false)),
        );
      });
    } else {
      setChangingWorkspace(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, pathname]);

  const workspaceAdmins = useMemo(() => {
    return (
      workspacePermissions
        ?.filter((p) => p.accessLevel === 'ADMIN')
        .map((u) => u.user) || []
    );
  }, [workspacePermissions]);

  const workspaceUsers = useMemo(() => {
    return workspacePermissions
      ?.filter((p) => p.accessLevel === 'USER')
      .map((u) => u.user);
  }, [workspacePermissions]);

  const loading = networkStatus === NetworkStatus.loading;

  const isWorkspaceAdmin = workspaceAdmins
    .map(({ id }) => id)
    .includes(user.id);

  return loading || changingWorkspace ? (
    <CustomSpinner text={'Loading Permissions...'} />
  ) : (
    <WorkspacePermissionsContext.Provider
      value={{
        workspaceAdmins,
        workspaceUsers,
        allWorkspaceUsers: workspacePermissions?.map((u) => u.user),
        refetchPermissions,
        setChangingWorkspace,
        allUsers: users || [],
        isWorkspaceAdmin,
      }}
    >
      {children}
    </WorkspacePermissionsContext.Provider>
  );
};

export const useWorkspacePermissions = () =>
  useContext(WorkspacePermissionsContext);
