import styles from './InfoMenu.module.scss';
import classNames from 'classnames';
import { Text } from '../typography';
import { LanguageSelector } from '../../utilities/translator/LanguageSelector';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useEffect, useRef } from 'react';
import { closeModalComponents } from '../../utilities';

const helpCenterURL = 'https://opas.productfruits.help/';

export default function InfoMenu({ show, setShow, className }) {
  const { t } = useTranslation();
  const ref = useRef();

  useEffect(() => closeModalComponents(ref, setShow), [ref, setShow]);

  return show ? (
    <div ref={ref} className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <Text weight="bold" color="accentPrimary">
          {t(keys.dashboard.INFO)}
        </Text>
        <Icon style={{ fontSize: '1.2rem' }} onClick={() => setShow(false)}>
          close
        </Icon>
      </div>
      <div className={styles.row}>
        <Icon className={styles.icon}>help_outline</Icon>
        <div className={styles.text}>
          <Text size="sm" noMargin weight="semiBold">
            {t(keys.dashboard.NEED_HELP)}
          </Text>
          <a href={helpCenterURL} target="_blank" rel="noopener noreferrer">
            <Text size="sm" weight="semiBold" color="accentPrimary" noMargin>
              {t(keys.dashboard.VISIT_HELP_CENTER)}
            </Text>
          </a>
        </div>
      </div>
      <div className={styles.row}>
        <Icon className={styles.icon}>mail_outline</Icon>
        <div className={styles.text}>
          <Text size="sm" weight="semiBold" noMargin>
            {t(keys.dashboard.CONTACT_US)}
          </Text>
          <a href="mailto:support@opasmobile.com?subject=Support%20Request&body=Please%20provide%20details%20here">
            <Text size="sm" weight="semiBold" color="accentPrimary" noMargin>
              support@opasmobile.com
            </Text>
          </a>
        </div>
      </div>
      <div className={styles.row}>
        <Icon className={styles.icon}>language</Icon>
        <div className={styles.text}>
          <Text noMargin weight="semiBold" size="sm">
            {t(keys.dashboard.LANGUAGE)}
          </Text>
          <LanguageSelector />
        </div>
      </div>
    </div>
  ) : null;
}
