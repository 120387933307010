import Button from './Button';
import styles from './ColumnInput.module.scss';
import { useState } from 'react';
import { Text } from './typography';
import { Icon } from '@mui/material';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';

export default function ColumnInput({ columns, onChange }) {
  const [input, setInput] = useState('');
  const onClose = (col) => {
    onChange(columns.filter((c) => c !== col));
  };
  const { t } = useTranslation();
  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Group>
          <Form.Label>{t(keys.templates.COLUMN)}</Form.Label>
          <div className={styles.inputContainer}>
            <Form.Control
              autoFocus={false}
              type="text"
              placeholder={t(keys.templates.TABLE_COLUMN_PLACEHOLDER)}
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <Button
              value={t(keys.action.CREATE)}
              disabled={!input.length}
              size="md"
              onClick={() => {
                onChange([...columns, input]);
                setInput('');
              }}
            />
          </div>
        </Form.Group>
      </Form>
      <div className={styles.columnContainer}>
        {columns?.map((col) => (
          <div className={styles.column} key={col}>
            <Text noMargin>{col}</Text>
            <Icon
              className={styles.closeIcon}
              fontSize="inherit"
              baseClassName="material-icons-outlined"
              onClick={() => onClose(col)}
            >
              close
            </Icon>
          </div>
        ))}
      </div>
    </>
  );
}
