import styles from './IncidentReport.module.scss';
import { Text } from '../../components/typography';
import { Icon } from '@mui/material';
import { openFileViewer } from '../../graphql/cache/modal';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import findFileIcon, { imageExtensions } from '../../utilities/files';

export default function IncidentImageCard({ readOnly, file, onDelete }) {
  const { t } = useTranslation();
  const extension = file.imageUrl.split('.').pop().toLowerCase();
  const isImage = imageExtensions.includes(extension);

  return (
    <div key={file.imageUrl} className={styles.imagesContainer}>
      <Card className={styles.card}>
        <div className={styles.imageContainer}>
          <Card.Img
            className={styles.cardImage}
            variant="bottom"
            src={isImage ? file.imageUrl : findFileIcon(extension)}
            onClick={() => openFileViewer(file)}
          />
        </div>
        <Text
          className={styles.descriptionHeader}
          size="sm"
          color="primary"
          weight="semiBold"
          noMargin
        >
          {t(keys.common.DESCRIPTION)}
        </Text>
        <div className={styles.cardDescription}>
          <Text size="sm" color="accentPrimary" noMargin>
            {readOnly
              ? 'Observation Image'
              : file.description ?? t(keys.incidents.NO_IMAGE_DESCRIPTION)}
          </Text>
        </div>
        <div className={styles.bottomIcons}>
          {!readOnly && (
            <Icon
              onClick={onDelete}
              baseClassName="material-icons-outlined"
              className={styles.deleteIcon}
              sx={{
                fontSize: '1rem',
                color: '#e9222c',
              }}
            >
              delete
            </Icon>
          )}
        </div>
      </Card>
    </div>
  );
}
