/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import styles from './Board.module.scss';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '../../components/typography';
import { Button } from '../../components';
import Graph from './Graph';
import { useMobileListener } from '../../hooks/misc';
import { useScreenshot } from 'use-react-screenshot';
import axios from 'axios';
import { CF_DOMAIN } from '../../constants/aws';
import { Spinner } from 'react-bootstrap';
import { getLocalTime } from '../../utilities/time';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { BoardDocument } from '../../utilities/pdf_export/board';
import { b64toBlob } from '../../utilities';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCurrentUser } from '../../providers/UserProvider';
import { paths } from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const boardQuery = loader('./Board.graphql');
const uploadUrlQuery = loader('../../graphql/queries/s3.graphql');
const updateBoardMutation = loader('./Board.update.graphql');

const UPDATE_THRESHOLD = 30; // number of seconds to wait before refreshing thumbnail

export default function Board() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const isMobile = useMobileListener();
  const ref = useRef(null);
  const [loadedGraphs, setLoadedGraphs] = useState({});
  const { enableInsightPdf } = useFlags();
  const client = useApolloClient();
  const [image, takeScreenshot] = useScreenshot({
    type: 'image/png',
    quality: 0.5,
  });
  const [updateBoard] = useMutation(updateBoardMutation, {
    fetchPolicy: 'no-cache',
  });
  const { boardId: id } = useParams();
  const { loading, data: { boards: [board] = [] } = {} } = useQuery(
    boardQuery,
    {
      variables: { id },
      skip: !id,
      fetchPolicy: 'no-cache',
    },
  );

  const handleScreenshot = () => {
    if (board?.analytics?.length) {
      takeScreenshot(ref.current);
    }
  };

  const sanitizedTitle = useMemo(() => {
    return board?.title?.replace(/[^a-zA-Z0-9_\-.]/g, '_');
  }, [board?.title]);

  const uploadImage = (base64Image) => {
    const [metadata, imageData] = base64Image.split(',');
    const [contentType] = metadata.split(':')[1].split(';');
    const blob = b64toBlob(imageData, contentType);

    const fileUUID = uuidv4();
    const fileType = 'png';
    const fileName = `${fileUUID}.${fileType}`;

    client
      .query({
        query: uploadUrlQuery,
        variables: {
          fileName: `assets/${user.company.id}/${fileName}`,
          fileType,
          fetchPolicy: 'no-cache',
        },
      })
      .then((data) => {
        const signedUrl = data.data.simpleStorageUploadUrl;
        const options = {
          headers: {
            'Content-Type': contentType,
          },
        };
        axios.put(signedUrl, blob, options).then((result) => {
          if (result.status === 200) {
            const fileUrl = `${CF_DOMAIN(user)}${fileName}`;
            updateBoard({
              variables: { id: parseInt(id, 10), thumbnail: fileUrl },
            });
          }
        });
      })
      .catch((error) => {
        console.error('Upload error:', error);
      });
  };

  useEffect(() => {
    if (!image || !board?.id) {
      return;
    }
    const thumbnailLastUpdated = getLocalTime(board.thumbnailUpdated);

    const diff = new moment().diff(thumbnailLastUpdated, 'seconds');

    if (diff > UPDATE_THRESHOLD || !board.thumbnail) {
      uploadImage(image);
    }
  }, [image, board?.analytics]);

  const handleAddGraph = () => {
    navigate(`/${paths.analytics}/addgraph?board=${id}`);
  };

  useEffect(() => {
    if (
      Object.keys(loadedGraphs).length === board?.analytics?.length &&
      !image &&
      !isMobile
    ) {
      handleScreenshot();
    }
  }, [loadedGraphs, board?.analytics, isMobile]);

  const sortedAnalytics = useMemo(() => {
    const newAnalytics = board?.analytics ? [...board.analytics] : [];

    newAnalytics.sort((a, b) => {
      const sizeOrder = { sm: 2, lg: 1 };
      return sizeOrder[a.size] - sizeOrder[b.size];
    });

    return newAnalytics;
  }, [board?.analytics]);

  return (
    <div className={styles.container}>
      {board ? (
        <>
          <div className={styles.header}>
            <div className={!isMobile ? styles.headerText : ''}>
              <Text size="lg" weight="bold" noMargin>
                {board.title}
              </Text>
              <Text size="md" color="secondary" weight="semiBold">
                {board.description || ''}
              </Text>
            </div>
            {!!enableInsightPdf && image && !isMobile ? (
              <PDFDownloadLink
                document={
                  <BoardDocument
                    user={user}
                    title={board?.title}
                    image={image}
                    date={new moment()}
                  />
                }
                fileName={`${sanitizedTitle}_${new moment().format(
                  'MM-DD-YYYY',
                )}.pdf`}
              >
                <Button
                  icon="download"
                  value={t(keys.action.DOWNLOAD_VARIABLE, { variable: 'PDF' })}
                />
              </PDFDownloadLink>
            ) : (
              !!enableInsightPdf &&
              !isMobile && (
                <Button
                  icon="download"
                  disabled={true}
                  value={t(keys.action.DOWNLOAD_VARIABLE, { variable: 'PDF' })}
                />
              )
            )}
          </div>
          <br />
          {loading ? (
            <div className={styles.loading}>
              <Spinner variant="primary" />
            </div>
          ) : sortedAnalytics.length > 0 ? (
            <>
              <div ref={ref} className={styles.graphContainer}>
                {sortedAnalytics.map((analytic) => (
                  <Graph
                    range={analytic.period}
                    className={styles[analytic.size]}
                    title={analytic.title}
                    description={analytic.description}
                    graphType={analytic.type}
                    action={analytic.event}
                    group={analytic.group}
                    filterOperator={analytic.filterOperator}
                    filterValue={analytic.filterValue}
                    filterType={analytic.filterField}
                    id={analytic.id}
                    key={`analytic-${analytic.id}`}
                    onLoaded={() => {
                      setLoadedGraphs((prevGraphs) => ({
                        ...prevGraphs,
                        [analytic.id]: true,
                      }));
                    }}
                  />
                ))}
              </div>
              <div className={styles.addContainer}>
                <Button
                  icon="add"
                  value={t(keys.boards.GRAPH)}
                  onClick={handleAddGraph}
                />
              </div>
              <br />
              <br />
              <br />
            </>
          ) : (
            <div className={styles.emptyStateParent}>
              <img
                className={styles.emptyStateGraphic}
                alt="Graphs"
                src="https://app.opasmobile.com/assets/graphics/empty_graphs_es.png"
              />
              <div className={styles.emptyStateContainer}>
                <Text textAlign="center" size="lg" weight="semiBold">
                  {t(keys.boards.EMPTY_STATE_BOARDS_HEADER)}
                </Text>
                <Text
                  textAlign="center"
                  size="md"
                  weight="semiBold"
                  color="secondary"
                >
                  {t(keys.boards.EMPTY_STATE_BOARDS_MESSAGE)}
                </Text>
              </div>
              <br />
              <Button value={t(keys.action.START)} onClick={handleAddGraph} />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}
