export const colors = {
  yellow: '#FFC107',
  blue: '#0D6DFC',
  red: '#DC3545',
  cyan: '#DC3545',
  green: '#28A745',
};

export const toastLength = {
  sm: 2000,
  md: 4000,
  lg: 10000,
};

export const toastVariant = {
  info: 'info',
  warning: 'warning',
  danger: 'danger',
};

export const conversationTypes = {
  room: 'ROOM',
  conversation: 'CONVERSATION',
};

export const pollInterval = {
  sm: 3000,
  md: 10_000,
  lg: 30_000,
  xl: 60_000,
  xxl: 120_000,
  xxxl: 300_000,
};

export const reviewStatus = {
  open: 'OPEN',
  changeRequest: 'CHANGE_REQUEST',
  approved: 'APPROVED',
};

export const fileExpiryStatus = {
  none: 'NONE',
  expiring: 'EXPIRING',
  expired: 'EXPIRED',
};

export const EMPTY_OBJECT = {};

export const EMPTY_ARRAY = [];

export const MAX_FILE_UPLOAD_SIZE = 104857600; // 100MB

export const fileType = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  webp: 'image/webp',
  gif: 'image/gif',
  pdf: 'application/pdf',
};

export const timecardStatus = {
  draft: 'DRAFT',
  submitted: 'SUBMITTED',
  approved: 'APPROVED',
  denied: 'DENIED',
  complete: 'COMPLETE',
};

export const timecardStrings = {
  DRAFT: 'Draft',
  SUBMITTED: 'Pending',
  APPROVED: 'Approved',
  DENIED: 'Denied',
  COMPLETE: 'Complete',
};

export const ANNOUNCEMENT_INTERACTION_STATUS = {
  read: 'READ',
  unread: 'UNREAD',
  snoozed: 'SNOOZED',
};

export const mobileThreshold = 1000;
