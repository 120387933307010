import React from 'react';
import LexicalEditorWrapper from './components/LexicalEditorWrapper';

export default function RichTextEditor({
  id,
  className,
  onChange = () => {},
  value,
  readOnly = false,
  topBarComponent = null
}) {
  return (
    <div className={className}>
      <LexicalEditorWrapper
        id={id}
        topBarComponent={topBarComponent}
        onChange={onChange}
        readOnly={readOnly}
        value={value}
      />
    </div>
  );
}
