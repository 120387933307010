import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import styles from './ExcelToCSVConverter.module.scss';
import Button from '../Button';
import { Text } from '../typography';
import { showToast } from '../../graphql/cache/modal';
import { toastLength, toastVariant } from '../../constants/misc';

export default function ExcelToCSVConverter({
  setCSVData,
  title = null,
  text = null,
  variant = 'primary',
  outlined = false,
  icon = 'file_upload',
  value = 'Import XLSX',
}) {
  const [uploading, setUploading] = useState(false);
  const sheetToUpload = 'TASK';

  const onDrop = (acceptedFiles) => {
    setUploading(true);
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetIndex = workbook.SheetNames.findIndex(
          (name) => name === sheetToUpload,
        );

        if (sheetIndex !== -1) {
          const sheetName = workbook.SheetNames[sheetIndex];
          const csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
          const result = Papa.parse(csv, {
            header: true,
            skipEmptyLines: 'greedy',
          });

          const processedData = {
            sheetName,
            data: result.data.slice(1),
          };
          setCSVData(processedData.data);
        } else {
          showToast({
            title: 'Error',
            message: `Sheet "${sheetToUpload}" not found in the Excel file.`,
            variant: toastVariant.danger,
            time: toastLength.lg,
          });
        }
      } catch (error) {
        showToast({
          title: 'Error',
          message: `There was a problem reading this excel file: ${error}`,
          variant: toastVariant.danger,
          time: toastLength.lg,
        });
      } finally {
        setUploading(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xls, .xlsx',
    disabled: uploading,
  });

  return (
    <div {...getRootProps()} className={styles.container}>
      <input {...getInputProps()} />
      {title && (
        <Text size="md" weight="bold" textAlign="center" noMargin>
          {title}
        </Text>
      )}
      {text && (
        <Text textAlign="center" color="secondary" weight="semiBold" size="sm">
          {text}
        </Text>
      )}
      <Button
        onClick={() => document.querySelector('input').click()}
        disabled={uploading}
        className={styles.button}
        variant={variant}
        size="md"
        value={value}
        icon={icon}
        outlined={outlined}
      />
    </div>
  );
}
