import React, { useState, useEffect } from 'react';
import styles from './PdfViewer.module.scss';
import { Document, Page } from 'react-pdf';

export default function PdfViewer({ file, width }) {
  const [numPages, setNumPages] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // We want 20 padding for mobile, and 200 for desktop

  const pageWidth = windowWidth - window.innerWidth / 8;

  return (
    <div>
      {file?.url && (
        <div className={styles.document}>
          <Document
            className={StyleSheet.document}
            file={file.url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={width || pageWidth}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                customTextRenderer={false}
              />
            ))}
          </Document>
        </div>
      )}
    </div>
  );
}
