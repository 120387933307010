import { openAssetFilterModal } from '../../graphql/cache/modal';
import Button from '../Button';
import styles from './TableFiltering.module.scss';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import { useState, useEffect } from 'react';
import { formatFilterDisplay } from '../../utilities/filtering';
import { useTranslation } from 'react-i18next';
import {
  keys,
  getTranslationKey,
} from '../../utilities/translator/translation_keys';
import { useQueryParams } from '../../hooks/misc';
import { searchParamKeys } from '../../constants/strings';

export default function AssetFiltering({
  filters,
  setFilters,
  rawFilters,
  filterOptions,
  setPage,
  assetType,
}) {
  const [hasFilters, setHasFilters] = useState([]);
  const { t } = useTranslation();
  const { getParam, deleteParam } = useQueryParams();
  const fileId = getParam(searchParamKeys.file);

  const removeFilters = (key) => {
    delete rawFilters.filterData[key];
    const filterData = rawFilters.filterData;
    setFilters({ filterData });
    if (fileId) {
      deleteParam(searchParamKeys.file);
      deleteParam(searchParamKeys.parent);
      deleteParam(searchParamKeys.hidden);
    }
  };

  useEffect(() => {
    if (rawFilters?.filterData) {
      setHasFilters(
        Object.values(rawFilters?.filterData).filter((f) => f !== null),
      );
    } else {
      setHasFilters([]);
    }
  }, [rawFilters]);
  const namespace = assetType === 'Asset' ? 'assets' : 'files';

  return (
    <div className={styles.container}>
      <div className={styles.filterList}>
        {rawFilters?.filterData &&
          Object.entries(rawFilters?.filterData).map(
            (filter, index) =>
              filter[1] !== null && (
                <div className={styles.filterDisplay} key={filter + index}>
                  <Text className={styles.filter} noMargin size="sm">
                    <span style={{ fontWeight: 'bold' }}>
                      {t(getTranslationKey(filter[0], 'utilities'))}
                    </span>
                    {formatFilterDisplay(filter[0], filter[1], namespace)}
                  </Text>
                  <Icon
                    className={styles.deselectIcon}
                    sx={{ fontSize: '1rem' }}
                    baseClassName="material-icons-outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFilters(filter[0]);
                    }}
                  >
                    close
                  </Icon>
                </div>
              ),
          )}

        {!hasFilters.length ? (
          <Button
            value={t(keys.utilities.FILTERS)}
            icon="filter_alt_icon"
            variant="secondary"
            outlined
            onClick={() => {
              setPage(1);
              openAssetFilterModal(
                filters,
                filterOptions,
                (f) => setFilters(f),
                assetType,
              );
            }}
          />
        ) : (
          <div className={styles.filterDisplay}>
            <Icon
              className={styles.closeIcon}
              sx={{ fontSize: '1rem' }}
              onClick={() => {
                setPage(1);
                openAssetFilterModal(
                  filters,
                  filterOptions,
                  (f) => setFilters(f),
                  assetType,
                );
              }}
            >
              add
            </Icon>
          </div>
        )}
      </div>
    </div>
  );
}
