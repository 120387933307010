import React, { useState, useEffect, useMemo } from 'react';
import ExcelToCSVConverter from '../excel/ExcelToCSVConverter';
import styles from './ActivityConverter.module.scss';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { headerMap, configurationStyle, importHeaderMap } from './activites';
import Button from '../Button';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { showToast } from '../../graphql/cache/modal';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { toastLength, toastVariant } from '../../constants/misc';
import moment from 'moment';
import ProgressModal from '../modals/ProgressModal';
import ActivityExport from './ActivityExport';
import { useModal } from '../../providers/ModalProvider';

const addActivityMutation = loader('./ActivityConverter.add.graphql');

const userQuery = loader('./ActivityConverter.users.graphql');

const clearActivitiesMutation = loader('./ActivityConverter.clear.graphql');
const activityCountQuery = loader('./ActivityConverter.count.graphql');

export default function ActivityConverter() {
  const [CSVData, setCSVData] = useState([]);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [addActivity] = useMutation(addActivityMutation);
  const [clearActivities] = useMutation(clearActivitiesMutation);
  const [uploading, setUploading] = useState(false);
  const { workspace } = useWorkspace();
  const { openConfirmationModal } = useModal();
  const { refetch, data: { activityCount = 0 } = {} } = useQuery(
    activityCountQuery,
    {
      variables: {
        options: {
          filters: [
            { field: 'workspaceId', operator: 'eq', value: `${workspace?.id}` },
          ],
        },
      },
    },
  );

  const { data: { users = [] } = {} } = useQuery(userQuery);

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const isConfigured = useMemo(() => {
    return activityCount > 0;
  }, [activityCount]);

  useEffect(() => {
    let activitiesAddedCount = 0;
    const upload = async () => {
      if (CSVData?.length) {
        setUploading(true);
        for (let i = 0; i < CSVData.length; i++) {
          const rowData = CSVData[i];
          const activityData = Object.keys(importHeaderMap).reduce(
            (acc, csvKey) => {
              const variableName = importHeaderMap[csvKey];
              acc[variableName] = rowData[csvKey];
              return acc;
            },
            {},
          );

          const {
            status,
            resourceId,
            externalActivityId,
            description,
            plannedStartDate,
            plannedEndDate,
            actualHours,
            wbsCode,
            progress,
            actualStartDate,
            actualEndDate,
            poNumber,
          } = activityData;

          if (
            status !== 'Completed' &&
            resourceId &&
            plannedStartDate &&
            plannedEndDate &&
            externalActivityId
          ) {
            const participants = users
              .filter((user) => user.externalActivityId === resourceId)
              .map((user) => user.id);

            activitiesAddedCount += 1;

            await addActivity({
              variables: {
                description,
                workspaceId: workspace.id,
                externalActivityId,
                plannedStartDate: moment(plannedStartDate).format(
                  'MM-DD-YYYY HH:mm:ss',
                ),
                plannedEndDate: moment(plannedEndDate).format(
                  'MM-DD-YYYY HH:mm:ss',
                ),
                status,
                participants,
                actualHours: parseFloat(actualHours) || 0,
                resourceId,
                wbsCode,
                progress:
                  typeof progress === 'number' ? parseFloat(progress) : 0,
                actualStartDate,
                actualEndDate,
                poNumber,
              },
            });
            setProgress((i / CSVData.length) * 100);
            await new Promise((r) => setTimeout(r, 25));
          }
        }
        setProgress(0);
        setUploading(false);

        refetch().then(() => {
          if (activitiesAddedCount > 0) {
            showToast({
              title: 'Activities Updated Successfully',
              message: `${activitiesAddedCount} Activities have been successfully updated!`,
              variant: toastVariant.success,
              time: toastLength.lg,
            });
          } else {
            showToast({
              title: 'No Activities Found',
              message: `Looks like we didn't find any compatible Activities. Click the Info button for import instructions!`,
              variant: toastVariant.warning,
              time: toastLength.lg,
            });
          }
        });
      }
    };
    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CSVData]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <IconButton
            sx={{
              backgroundColor: '#e9222c',
              color: 'white',
              padding: '0.25rem',
              borderRadius: '4px',
            }}
          >
            <Icon
              baseClassName="material-icons-outlined"
              className={styles.icon}
            >
              integration_instructions_icon
            </Icon>
          </IconButton>
          <Text size="lg" weight="semiBold" noMargin>
            Primavera P6
          </Text>
        </div>
        {isConfigured ? (
          <Button
            className={styles.deleteButton}
            size="md"
            icon="delete"
            variant="danger"
            outlined={true}
            onClick={() => {
              openConfirmationModal({
                title: 'Clear Activities',
                description: `Are you sure you wish to clear your P6 activities? This action cannot be undone.`,
                buttonText: 'Clear',
                variant: 'danger',
                onSubmit: () => {
                  clearActivities({
                    variables: { workspaceId: workspace?.id },
                  }).then(({ data: { clearActivities } }) => {
                    if (clearActivities) {
                      refetch();
                      showToast({
                        title: 'Activities Cleared Successfully',
                        message: `${activityCount} Activities have been cleared from this Workspace`,
                        variant: toastVariant.info,
                        time: toastLength.lg,
                      });
                    } else {
                      showToast({
                        title: 'Error Clearing Activities',
                        message: `An error occurred while clearing activities. Please try again.`,
                        variant: toastVariant.danger,
                        time: toastLength.lg,
                      });
                    }
                  });
                },
              });
            }}
          />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            className={styles.infoButton}
            onClick={() => setInfoModalOpen(true)}
          >
            info
          </Icon>
        )}
      </div>
      {!isConfigured ? (
        <div className={styles.content}>
          <img
            src={
              'https://opas-public.s3.us-east-2.amazonaws.com/webapp-assets/desktop.png'
            }
            alt="desktop"
            className={styles.image}
          />
          <Text size="lg" weight="bold" noMargin textAlign="center">
            No Project Linked to this Workspace
          </Text>
          <Text weight="semiBold" noMargin textAlign="center">
            Setting up your project is a two-step process
          </Text>
          <Text weight="semiBold" noMargin textAlign="center">
            1.{' '}
            <span
              onClick={() => navigate(`${paths.settings}/users`)}
              className={styles.link}
            >
              Assign Resource IDs
            </span>{' '}
            to users
          </Text>
          <Text weight="semiBold" noMargin textAlign="center">
            2. Import the initial XSLX from Primavera P6.
          </Text>
        </div>
      ) : (
        <div className={styles.content}>
          <br />
          <Text size="lg" weight="semiBold" noMargin textAlign="center">
            Your Project is Configured!
          </Text>
          <Text weight="bold" textAlign="center" noMargin>
            {activityCount} Active Activities
          </Text>
          <Text weight="semiBold" color="secondary" textAlign="center">
            You can update the project activities or export data back to P6.
          </Text>
          <ActivityExport
            count={activityCount}
            title={workspace?.title}
            workspaceId={workspace?.id}
          />
        </div>
      )}
      <br />
      <ExcelToCSVConverter
        setCSVData={setCSVData}
        title={configurationStyle[isConfigured]?.title}
        text={configurationStyle[isConfigured]?.text}
        icon={configurationStyle[isConfigured]?.icon}
        value={configurationStyle[isConfigured]?.value}
        outlined={configurationStyle[isConfigured]?.outlined}
      />
      <br />
      <ProgressModal
        progress={progress}
        title="Uploading Activities"
        message="Hang tight! We are uploading your P6 activities to this workspace. Please avoid changing the page or closing this window while we upload your data."
        icon="warning"
        show={uploading}
      />
      {infoModalOpen && (
        <div className={styles.background}>
          <div className={styles.modalContainer}>
            <div className={styles.header}>
              <Text noMargin size="lg" color="accentPrimary" weight="semiBold">
                P6 Import Instructions
              </Text>
              <Icon onClick={() => setInfoModalOpen(false)}>close</Icon>
            </div>
            <hr />
            <div className={styles.alert}>
              <Icon className={styles.warningIcon}>warning</Icon>
              <Text noMargin>
                Activity Uploads require the following fields, or they will not
                be imported:
              </Text>
            </div>
            <Text textAlign="center" noMargin weight="semiBold">
              Resource ID (rsrc_id)
            </Text>
            <Text weight="semiBold" textAlign="center">
              Planned Start Date (start_date)
            </Text>
            <Text weight="semiBold" textAlign="center">
              Planned End Date (end_date)
            </Text>
            <div className={styles.alert}>
              <Icon className={styles.warningIcon}>warning</Icon>
              <Text noMargin>Date Format: MM/DD/YYYY HH:mm:ss </Text>
            </div>

            <div className={styles.alert}>
              <Icon className={styles.warningIcon}>warning</Icon>
              <Text noMargin>
                Any Activity with a Completed status will not be included in the
                import.
              </Text>
            </div>
            <div className={styles.alert}>
              <Icon className={styles.warningIcon}>warning</Icon>
              <Text noMargin>Excel Sheetname must be TASK</Text>
            </div>
            <div className={styles.alert}>
              <Icon className={styles.warningIcon}>warning</Icon>
              <Text noMargin>
                Column headers must correspond with the following list:
              </Text>
            </div>
            <Text weight="semiBold" textAlign="center">
              {Object.values(headerMap).join(', ')}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
}
