import { useMutation } from '@apollo/client';
import { Icon } from '@mui/material';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import InspectionTypeBadge from '../../components/inspections/InspectionTypeBadge';
import LabelSelector from '../../components/LabelSelector';
import SimpleUserCard from '../../components/SimpleUserCard';
import { Text } from '../../components/typography';
import UserMultiSelector from '../../components/UserMultiSelector';
import { getLocalTime } from '../../utilities/time';
import styles from './IncidentSidebar.module.scss';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../graphql/cache/modal';
import { toastVariant, toastLength } from '../../constants/misc';
import { Button } from '../../components';
import { incidentStatusTypes, getLabel } from '../../utilities/incidents';
import Label from '../../components/action_items/Label';
import { getRoute } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { IncidentDocument } from '../../utilities/pdf_export/incident';
import { paths } from '../../constants/strings';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useModal } from '../../providers/ModalProvider';
import { incidentStatus } from '../../constants/strings';
import { observationStatus } from '../../utilities/observations';
const addParticipantMutation = loader(
  './IncidentSidebar.addParticipant.graphql',
);

const deleteParticipantMutation = loader(
  './IncidentSidebar.deleteParticipant.graphql',
);
const deleteIncidentMutation = loader('./IncidentSidebar.delete.graphql');

const addLabelMutation = loader('./IncidentSidebar.addLabel.graphql');
const deleteLabelMutation = loader('./IncidentSidebar.deleteLabel.graphql');

const editableStatuses = [
  incidentStatus.investigation,
  incidentStatus.initiated,
  incidentStatus.pending,
];

export default function IncidentSidebar({
  incident,
  className,
  onClose,
  title,
  refetch,
  inspections,
  incidentImages,
}) {
  const navigate = useNavigate();
  const { workspace, isWorkspaceAdmin } = useWorkspace();
  const { user: currentUser } = useCurrentUser();
  const [closeLabels, setCloseLabels] = useState(false);
  const [addParticipant] = useMutation(addParticipantMutation);
  const [deleteParticipant] = useMutation(deleteParticipantMutation);
  const [deleteIncident] = useMutation(deleteIncidentMutation);
  const [addLabel] = useMutation(addLabelMutation);
  const [deleteLabel] = useMutation(deleteLabelMutation);
  const { t } = useTranslation();
  const { openConfirmationModal } = useModal();

  const [editingParticipants, setEditingParticipants] = useState(false);

  const leaveIncident = (userId) => {
    openConfirmationModal({
      title: t(keys.action.LEAVE, { variable: t(keys.incidents.INCIDENT) }),
      description: t(keys.incidents.LEAVE_INCIDENT_MESSAGE, {
        variable: incident?.title,
      }),
      variant: 'danger',
      buttonText: t(keys.action.CONFIRM),
      onSubmit: () => {
        deleteParticipant({
          variables: {
            userId,
            incidentId: incident.id,
          },
        }).then(() => navigate(getRoute(workspace?.id, paths.incidents)));
      },
    });
  };

  return (
    <div className={classNames([styles.container, className])}>
      <div className={styles.topSpace} />
      <div className={styles.header}>
        <Text noMargin size="lg" weight="bold">
          {t(keys.common.INFORMATION)}
        </Text>
        <div
          onClick={() => {
            setCloseLabels(true);
            onClose();
          }}
          className={styles.closeIcon}
        >
          <Icon>close</Icon>
        </div>
      </div>
      <br />
      <Text
        className={styles.heading}
        noMargin
        size="md"
        weight="bold"
        color="secondary"
      >
        {t(keys.common.TYPE)}
      </Text>
      <InspectionTypeBadge type="INCIDENT" />
      <div className={styles.sectionLine} />
      <div className={styles.editable}>
        <Text
          className={styles.heading}
          noMargin
          size="md"
          weight="bold"
          color="secondary"
        >
          {t(keys.common.PARTICIPANTS)}
        </Text>
        {editableStatuses.includes(incident?.status) && (
          <Icon
            onClick={() => setEditingParticipants(!editingParticipants)}
            className={styles.icon}
            baseClassName="material-icons-outlined"
          >
            {`${editingParticipants ? 'close' : 'settings'}`}
          </Icon>
        )}
      </div>
      {editingParticipants ? (
        <UserMultiSelector
          selected={incident?.participants}
          onUserAdded={(user) => {
            addParticipant({
              variables: {
                userId: user.id,
                incidentId: incident.id,
              },
            });
          }}
          onUserRemoved={(user) => {
            user.id === currentUser.id &&
            !isWorkspaceAdmin &&
            incident.creator.id !== user.id
              ? leaveIncident(user.id)
              : deleteParticipant({
                  variables: {
                    userId: user.id,
                    incidentId: incident.id,
                  },
                }).then(() => {
                  refetch();
                });
          }}
        />
      ) : (
        <div className={styles.participantContainer}>
          {incident?.participants?.length ? (
            incident?.participants?.map((user) => (
              <div className={styles.userContainer} key={user.id}>
                <SimpleUserCard size="md" mediumAvatar user={user} />
              </div>
            ))
          ) : (
            <Text noMargin>{t(keys.common.NONE)}</Text>
          )}
        </div>
      )}
      <div className={styles.sectionLine} />
      <Text
        className={styles.heading}
        noMargin
        size="md"
        weight="bold"
        color="secondary"
      >
        {t(keys.common.STATUS)}
      </Text>
      <div className={styles.statusLabel}>
        <Label
          className={styles.label}
          name={getLabel(incident.status)}
          color={incidentStatusTypes[incident?.status]?.color}
        />
      </div>
      <div className={styles.sectionLine} />
      {incident.observation &&
        incident.observation.status !== observationStatus.INVALID.key && (
          <div>
            <Text
              className={styles.heading}
              noMargin
              weight="semiBold"
              color="secondary"
            >
              {t(keys.common.OBSERVATION)}
            </Text>
            <Text
              hover
              onClick={() => {
                navigate(
                  getRoute(
                    workspace.id,
                    paths.observation,
                    incident.observation.id,
                  ),
                );
              }}
              noMargin
              size="md"
              weight="semiBold"
              color={'accentPrimary'}
            >
              {`${t(keys.common.OBSERVATION)} ${incident.observation.id}`}
            </Text>
            <div className={styles.sectionLine} />
          </div>
        )}

      <Text
        className={styles.heading}
        noMargin
        size="md"
        weight="bold"
        color="secondary"
      >
        {t(keys.common.LABELS)}
      </Text>
      <LabelSelector
        onClose={closeLabels}
        setOnClose={setCloseLabels}
        selected={incident?.labels || []}
        readOnly={incident?.status === incidentStatus.complete ? true : false}
        onSelectionChanged={(op, label) => {
          if (op === 'ADD') {
            addLabel({
              variables: {
                incidentId: incident.id,
                labelId: label.id,
              },
            }).then(() => {
              refetch();
            });
          } else if (op === 'REMOVE') {
            deleteLabel({
              variables: {
                incidentId: incident.id,
                labelId: label.id,
              },
            }).then(() => {
              refetch();
            });
          }
        }}
      />
      <div className={styles.sectionLine} />
      <Text
        className={styles.heading}
        noMargin
        size="md"
        weight="bold"
        color="secondary"
      >
        {t(keys.common.CREATOR)}
      </Text>
      <SimpleUserCard size="md" mediumAvatar user={incident.creator} />
      <div className={styles.sectionLine} />
      <Text
        className={styles.heading}
        noMargin
        size="md"
        weight="bold"
        color="secondary"
      >
        {t(keys.common.DATE_CREATED)}
      </Text>
      <Text noMargin size="md" weight="semibold">
        {getLocalTime(parseInt(incident.dateCreated, 10)).format(
          'dddd, MMMM Do YYYY, h:mm a',
        )}
      </Text>
      <br />
      {incident.status === incidentStatus.complete && (
        <PDFDownloadLink
          document={
            <IncidentDocument
              incident={incident}
              inspections={inspections}
              incidentImages={incidentImages}
              workspace={workspace}
            />
          }
          fileName={`${getLocalTime(incident.dateCreated).format(
            'MM-DD-YYYY',
          )}_${incident.title.replace(' ', '_')}_${incident.id}.pdf`}
        >
          {({ loading, url }) => (
            <Button
              className={styles.deleteButton}
              variant="primary"
              outlined
              icon="download"
              value={
                loading || !url
                  ? t(keys.action.LOADING)
                  : t(keys.action.DOWNLOAD)
              }
              disabled={loading || !url}
            />
          )}
        </PDFDownloadLink>
      )}

      {incident.status === incidentStatus.initiated && (
        <Button
          className={styles.deleteButton}
          variant="danger"
          outlined
          icon="delete"
          value={t(keys.action.DELETE)}
          onClick={() => {
            openConfirmationModal({
              title: t(keys.action.DELETE_VARIABLE, {
                variable: t(keys.incidents.INCIDENT),
              }),
              description: t(keys.action.CONFIRMATION_MESSAGE, {
                variable: `${t(keys.action.DELETE_VARIABLE, {
                  variable: title,
                })}`,
              }),
              variant: 'danger',
              onSubmit: () => {
                deleteIncident({
                  variables: { id: incident.id },
                }).then(() => {
                  navigate(getRoute(workspace?.id, 'incidents'));
                });
                setTimeout(() => {
                  showToast({
                    title: incident.title,
                    message: t(keys.action.DELETED, {
                      variable: incident.title,
                    }),
                    variant: toastVariant.info,
                    time: toastLength.md,
                  });
                }, 500);
              },
            });
          }}
        />
      )}
    </div>
  );
}
