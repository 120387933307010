import { useState, useEffect } from 'react';
import moment from 'moment';
import papaparse from 'papaparse';
import i18n from '../../i18n';

const wordsPerMinute = 238;

export default function useSafetyShare(tbt) {
  const [safetyShare, setSafetyShare] = useState(null);
  const language = i18n.resolvedLanguage;

  useEffect(() => {
    const getReadTime = (obj) => {
      const data = Object.entries(obj)
        .map(([key, value]) => `${key} ${value}`)
        .join(', ');
      const numWords = data.split(' ').length;
      return Math.ceil(numWords / wordsPerMinute) || 0;
    };

    if (tbt) {
      const readTime = getReadTime(tbt);
      setSafetyShare({ content: tbt, readTime });
    } else {
      const fetchSafetyShares = async () => {
        const safetyShares = require(`../../assets/toolbox_talks/${language}.csv`);
        papaparse.parse(safetyShares, {
          header: true,
          download: true,
          complete: ({ data }) => {
            const content = data[moment().dayOfYear() % data.length];
            const readTime = getReadTime(content);
            setSafetyShare({ content, readTime });
          },
        });
      };

      fetchSafetyShares();
    }
  }, [tbt, language]);

  return safetyShare;
}
