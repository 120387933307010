import { useOnlineStatus } from './offline-misc';
import { useMutation } from '@apollo/client';
import { useCachedMutations } from './offline-misc';
import { v4 as uuidv4 } from 'uuid';
import { loader } from 'graphql.macro';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useCurrentUser } from '../../providers/UserProvider';
import { cleanIndexedDB } from '../../utilities';

const addObservationMutation = loader(
  '../../components/modals/provider_modals/ObservationModal.graphql',
);

export const useObservations = () => {
  const { currentWorkspace } = useWorkspace();
  const online = useOnlineStatus();
  const { addMutation } = useCachedMutations();
  const currentUser = useCurrentUser();

  const [addObservation] = useMutation(addObservationMutation);

  const addObservations = async (options) => {
    if (online) {
      return addObservation(options);
    } else {
      const uuid = uuidv4().toString();
      const mockResult = {
        __typename: 'Observation',
        id: `${uuid}`,
        actionItems: [],
        dateCreated: null,
        dateTime: options.variables.dateTime,
        creator: currentUser,
        dateModified: null,
        dateReviewed: null,
        notes: null,
        items: options.variables.items,
        location: options.variables.location,
        negative: options.variables.negative,
        images: options.variables.images,
        participants: options.variables.participants,
        reviewer: null,
        workspace: currentWorkspace,
      };

      await addMutation(
        addObservationMutation,
        cleanIndexedDB(options),
        uuid,
        mockResult,
        ['InspectionForInspectionCreation'],
        '',
      );

      return new Promise((resolve, reject) => {
        const responseData = {
          data: {
            addObservation: { id: uuid },
          },
        };
        resolve(responseData);
      });
    }
  };

  return {
    addObservations,
  };
};
