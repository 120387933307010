export const GraphQlTypes = {
  actionItem: 'ActionItem',
  actionItemComment: 'ActionItemComment',
  company: 'Company',
  inspection: 'Inspection',
  inspectionApproval: 'InspectionApproval',
  inspectionTemplate: 'InspectionTemplate',
  inspectionTemplateCategory: 'InspectionTemplateCategory',
  inspectionItem: 'InspectionItem',
  inspectionQuestion: 'InspectionQuestion',
  invite: 'Invite',
  label: 'Label',
  user: 'User',
  vehicle: 'Vehicle',
  vehicleFeature: 'VehicleFeature',
};

// Debounce values in milliseconds
export const debounce = {
  sm: 150,
  md: 300,
  lg: 1000,
  xl: 3000,
};
