import React, { useState } from 'react';
import styles from './AnnouncementCard.module.scss';
import { Text } from './typography';
import { Icon } from '@mui/material';
import SimpleUserCard from './SimpleUserCard';
import { truncate } from '../utilities';
import classNames from 'classnames';
import { getLocalTime } from '../utilities/time';
import ContextMenu from './ContextMenu';
import { useModal } from '../providers/ModalProvider';
import Button from './Button';

const getButtonColor = ({ totalSent, totalRead }) => {
  if (totalSent === null) {
    return 'primary';
  }

  const compliance = totalRead / totalSent;

  if (compliance < 0.3) {
    return 'danger';
  } else if (compliance < 0.7) {
    return 'warning';
  } else {
    return 'success';
  }
};

export default function AnnouncementCard({
  announcement,
  selected,
  onClick,
  onDelete,
  onOpenCompliance,
}) {
  const { title, description, creator, dateCreated, totalRead, totalSent } =
    announcement;
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const { openConfirmationModal } = useModal();

  return (
    <div
      onClick={onClick}
      className={classNames(styles.container, selected && styles.selected)}
    >
      <div className={styles.header}>
        <Text
          noSelect
          weight="semiBold"
          className={styles.title}
          noMargin
          size="sm"
          color="secondary"
        >
          {getLocalTime(dateCreated).format('MMMM DD, YYYY')}
        </Text>
        <div className={styles.contextMenuContainer}>
          <Icon
            onClick={(e) => {
              e.stopPropagation();
              setContextMenuOpen(!contextMenuOpen);
            }}
            sx={{ fontSize: '1rem' }}
            baseClassName="material-icons-outlined"
            className={styles.moreIcon}
          >
            more_horiz
          </Icon>
          <ContextMenu
            className={styles.contextMenu}
            open={contextMenuOpen}
            setContextMenuOpen={setContextMenuOpen}
            options={[
              {
                title: 'Delete',
                icon: 'delete',
                variant: 'red',
                onClick: () => {
                  openConfirmationModal({
                    title: `Delete ${title}`,
                    description: `Are you sure you want to delete ${title}?  This action cannot be undone.`,
                    variant: 'danger',
                    buttonText: 'Delete',
                    onSubmit: () => {
                      onDelete();
                    },
                  });
                },
              },
            ]}
            setOpen={setContextMenuOpen}
          />
        </div>
      </div>
      <Text noSelect weight="bold">
        {title}
      </Text>
      <Text noSelect weight="semiBold" color="secondary" size="sm">
        {truncate(description, 100)}
      </Text>
      <div className={styles.footer}>
        <SimpleUserCard user={creator} />
        <Button
          size="sm"
          icon="visibility"
          onClick={onOpenCompliance}
          disabled={totalSent === null}
          variant={getButtonColor({ totalRead, totalSent })}
          value={
            totalSent === null
              ? 'N/A'
              : `${Math.round((totalRead / totalSent) * 100)}%`
          }
        />
      </div>
    </div>
  );
}
