import React from 'react';
import styles from './EmptyStateView.module.scss';
import { Text } from '../typography';
import classNames from 'classnames';

export default function EmptyStateView({
  title,
  text,
  image,
  size = false,
  fullOpacity = false,
}) {
  return (
    <>
      {size ? (
        <div className={styles.smallContainer}>
          <img className={styles.smallImage} alt={title} src={image}></img>
          <div>
            <Text
              className={styles.smallTitle}
              textAlign="center"
              weight="bold"
              size="md"
            >
              {title}
            </Text>
            <Text textAlign="center" size="sm">
              {text}
            </Text>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <img
            className={classNames(
              styles.cardImage,
              fullOpacity ? styles.fullOpacity : undefined,
            )}
            alt={title}
            src={image}
          ></img>
          <div>
            <Text
              className={styles.cardTitle}
              textAlign="center"
              weight="bold"
              size="lg"
            >
              {title}
            </Text>
            <Text textAlign="center">{text}</Text>
          </div>
        </div>
      )}
    </>
  );
}
