import { Icon } from '@mui/material';
import classNames from 'classnames';
import React, { useRef, useEffect } from 'react';
import styles from './ContextMenu.module.scss';
import { Text } from './typography';
import { closeModalComponents } from '../utilities';

export default function ContextMenu({
  open,
  setOpen,
  options = [],
  className,
}) {
  const ref = useRef(null);

  useEffect(() => {
    closeModalComponents(ref, setOpen);
  }, [ref, setOpen]);
  return open ? (
    <>
      <div
        onClick={() => {
          setOpen(false);
        }}
        className={styles.background}
      ></div>
      <div
        className={classNames(styles.container, className)}
        onClick={(e) => e.stopPropagation()}
        ref={ref}
      >
        {options.map((option) =>
          option.type !== 'export' ? (
            <div
              key={`option-${option.title}`}
              className={classNames([
                styles.option,
                option.disabled && styles.disabled,
              ])}
              onClick={() => {
                if (option.disabled) {
                  return;
                }
                option.onClick();
                setOpen(false);
              }}
            >
              <Icon
                fontSize="inherit"
                baseClassName="material-icons-outlined"
                className={styles[option.variant || 'primary']}
              >
                {option.icon}
              </Icon>
              <Text
                noMargin
                noSelect
                weight="semibold"
                size="sm"
                color={'primary'}
              >
                {option.title}
              </Text>
            </div>
          ) : (
            <div
              key={`option-${option.title}`}
              className={classNames([
                styles.option,
                option.disabled && styles.disabled,
              ])}
              onClick={() => {
                if (option.disabled) {
                  return;
                }
                option.onClick();
                setOpen(false);
              }}
            >
              <Icon
                fontSize="inherit"
                baseClassName="material-icons-outlined"
                className={styles[option.variant || 'primary']}
              >
                {option.icon}
              </Icon>
              {option.component}
            </div>
          ),
        )}
      </div>
    </>
  ) : null;
}
