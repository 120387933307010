import React, { useMemo, useState } from 'react';
import styles from './Analytics.module.scss';
import { Form } from 'react-bootstrap';
import { Text } from '../../components/typography';
import Graph from './Graph';
import { getGroupMap, primaryGroupings, eventTypes } from './utilities';
import Select from 'react-select';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Button } from '../../components';
import { useQueryParams } from '../../hooks/misc';
import { searchParamKeys } from '../../constants/strings';
import { useNavigate } from 'react-router-dom';
import { clamp } from '../../utilities';
import { paths } from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const eventQuery = loader('./Graph.graphql');
const addGraphMutation = loader('./Analytics.graphql');

const filterTypes = ['Is', 'Is not'];

const today = new Date();

export default function Analytics({ onSave }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getParam } = useQueryParams();
  const boardIdParam = getParam(searchParamKeys.board, '');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [graphType, setGraphType] = useState('Line');
  const [range, setRange] = useState(14);
  const [action, setAction] = useState(eventTypes[0].key);
  const [filterType, setFilterType] = useState('None');
  const [filterOperator, setFilterOperator] = useState('Is');
  const [filterValue, setFilterValue] = useState([]);
  const [group, setGroup] = useState('None');

  const boardId = useMemo(() => {
    return boardIdParam?.length ? parseInt(boardIdParam, 10) : 0;
  }, [boardIdParam]);

  const handleChange = (selectedOptions) => {
    setFilterValue(selectedOptions);
  };

  const startDate = useMemo(() => moment().subtract(range, 'Days'), [range]);
  const [addGraph] = useMutation(addGraphMutation, { fetchPolicy: 'no-cache' });

  const { data: { events = [] } = {} } = useQuery(eventQuery, {
    variables: {
      filters: [
        {
          field: 'eventType',
          operator: 'eq',
          value: [action],
        },
        {
          field: 'dateCreated',
          operator: 'between',
          value: [startDate, today],
        },
      ],
    },
    skip: !action,
    fetchPolicy: 'no-cache',
  });

  const graphOptions = useMemo(
    () => [
      { text: t(keys.boards.LINE_GRAPH), key: 'Line' },
      { text: t(keys.boards.PIE_GRAPH), key: 'Pie' },
      { text: t(keys.boards.BAR_GRAPH), key: 'Bar' },
      { text: t(keys.boards.CALENDAR_GRAPH), key: 'Calendar' },
    ],
    [t],
  );

  const groupableGraphs = useMemo(
    () => [
      { text: t(keys.boards.LINE_GRAPH), key: 'Line' },
      { text: t(keys.boards.PIE_GRAPH), key: 'Pie' },
      { text: t(keys.boards.BAR_GRAPH), key: 'Bar' },
    ],
    [t],
  );

  const groupOptions = useMemo(() => {
    return action?.length ? getGroupMap(action) : [];
  }, [action]);

  const filterValues = useMemo(() => {
    const isSubfilter = !primaryGroupings.some(
      ({ key }) => key.toLocaleLowerCase() === filterType.toLocaleLowerCase(),
    );

    const options = [];

    events.forEach((e) => {
      let val;

      if (filterType === 'name') {
        val = { value: e.userId, label: e.name };
      } else if (filterType === 'workspace') {
        val = { value: e.workspaceId, label: e.workspace };
      } else {
        const v = isSubfilter ? e.information[filterType] : e[filterType];
        val = { value: v, label: v };
      }

      if (!options.some((vv) => vv.value === val.value)) {
        options.push(val);
      }
    });

    return options.map((o) => ({ value: o, label: o }));
  }, [filterType, events]);

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <Text size="lg" noMargin weight="semiBold">
          {t(keys.action.ADD, { variable: t(keys.boards.GRAPH) })}
        </Text>
        <br />
        <Graph
          title={title}
          filterOperator={filterOperator}
          filterType={filterType}
          filterValue={filterValue}
          description={description}
          graphType={graphType}
          action={action}
          group={group}
          range={range}
        />
      </div>
      <div className={styles.rightSidebar}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group controlId="formTitle">
            <Text className={styles.formHeader} size="md" weight="semiBold">
              {t(keys.common.TITLE)}
            </Text>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="formDescription">
            <Text className={styles.formHeader} size="md" weight="semiBold">
              {t(keys.common.DESCRIPTION)}
            </Text>
            <Form.Control
              as="textarea"
              rows={3}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="formDescription">
            <Text className={styles.formHeader} size="md" weight="semiBold">
              {t(keys.boards.RANGE)}
            </Text>
            <Form.Control
              rows={3}
              type="number"
              value={range}
              onChange={(e) => setRange(clamp(e.target.value, 1, 365))}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="formAction">
            <Text className={styles.formHeader} size="md" weight="semiBold">
              {t(keys.boards.ACTION)}
            </Text>
            <Form.Control
              as="select"
              onChange={(e) =>
                setAction(
                  eventTypes.find((event) => event.key === e.target.value).key,
                )
              }
            >
              {eventTypes.map((item, idx) => (
                <option key={idx} value={item.key}>
                  {t(item.text)}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <br />
          <Form.Group controlId="formFilterValue">
            <Text className={styles.formHeader} size="md" weight="semiBold">
              {t(keys.boards.FILTER)}
            </Text>
            <Form.Control
              as="select"
              onChange={(e) => {
                setFilterValue([]);
                setFilterType(e.target.value);
              }}
            >
              {groupOptions.map((item, idx) => (
                <option key={item.key} value={item.key}>
                  {t(item.text)}
                </option>
              ))}
            </Form.Control>
            <br />
            <Form.Control
              as="select"
              onChange={(e) => setFilterOperator(e.target.value)}
            >
              {filterTypes.map((item, idx) => (
                <option key={idx} value={item}>
                  {item}
                </option>
              ))}
            </Form.Control>
            <br />
            <Select
              isMulti
              options={filterValues.map((f) => f.label)}
              value={filterValue}
              onChange={handleChange}
            />
          </Form.Group>

          <br />
          <Form.Group controlId="formFilterType">
            <Text className={styles.formHeader} size="md" weight="semiBold">
              {t(keys.boards.GRAPH)}
            </Text>
            <Form.Control
              as="select"
              onChange={(e) => {
                const newGraph = e.target.value;
                setGraphType(newGraph);
              }}
            >
              {graphOptions.map((item, idx) => (
                <option key={idx} value={item.key}>
                  {item.text}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <br />
          {groupableGraphs.some(({ key }) => key === graphType) && (
            <Form.Group controlId="formGroup">
              <Text className={styles.formHeader} size="md" weight="semiBold">
                {t(keys.boards.GROUP)}
              </Text>
              <Form.Control
                as="select"
                onChange={(e) => setGroup(e.target.value)}
              >
                {groupOptions.map((item, idx) => (
                  <option key={`${item.key}+${idx}`} value={item.key}>
                    {t(item.text)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
        </Form>
        <Button
          disabled={!title.length || !boardId}
          className={styles.saveButton}
          value={t(keys.action.SAVE)}
          onClick={() => {
            addGraph({
              variables: {
                filterOperator,
                filterField: filterValue.length > 0 ? filterType : 'None',
                filterValue: filterValue.map((f) => f.value).join('|'),
                title,
                description,
                event: action,
                type: graphType,
                period: range,
                boardId,
                size:
                  graphType === 'Calendar' || graphType === 'Line'
                    ? 'lg'
                    : 'sm',
                group,
              },
            }).then(() => {
              navigate(`/${paths.analytics}/board/${boardId}`);
            });
          }}
        />
      </div>
    </div>
  );
}
