import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './CompanySignUp.module.scss';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Text } from '../../components/typography';
import WorkflowStageFooter from '../../components/workflows/WorkflowStageFooter';
import { useQueryParams } from '../../hooks/misc';
import Button from '../../components/Button';
import { privacyPolicy, publicSite } from '../../constants/endpoints';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';

const registerCompanyMutation = loader('./CompanySignUp.register.graphql');

export default function CompanySignUpComplete({
  newCompanyState,
  setNewCompanyState,
  workflow,
  completed,
  onCompleted,
  currentStage,
}) {
  const [errorOccured, setErrorOccured] = useState(false);
  const [terms, setTerms] = useState(false);
  const [registerCompany, { loading }] = useMutation(registerCompanyMutation);
  const [complete, setComplete] = useState(false);
  const { getParam } = useQueryParams();
  const companyId = getParam('companyId');
  const token = getParam('token');
  const { t } = useTranslation();

  const supportEmail = 'support@opasmobile.com';
  const emailSubject = 'Company Sign Up Error';
  const emailBody = `Something's gone wrong with my sign up. Here is my sign up token. \n\n${token}`;

  return (
    <>
      <div className={styles.componentContainer}>
        <div>
          <Text size="xl" weight="semiBold">
            {!complete
              ? t(keys.companySignUp.HEADER_P4_NOT_COMPLETE)
              : t(keys.companySignUp.HEADER_P4_COMPLETE)}
          </Text>
          <Text size="lg">
            {!complete
              ? t(keys.companySignUp.FINAL_NOT_COMPLETE)
              : t(keys.companySignUp.FINAL_COMPLETE)}
          </Text>
        </div>
        <Form
          noValidate
          validated={true}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {!complete && (
            <>
              <Form.Group className="mb-3">
                <Form.Check
                  required
                  checked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                  label={
                    <Text noMargin color={terms ? 'green' : 'red'}>
                      {t(keys.companySignUp.FINAL_TERMS_TEXT)}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={privacyPolicy}
                        className={
                          terms ? styles.labelLinkGreen : styles.labelLinkRed
                        }
                      >
                        {t(keys.companySignUp.FINAL_TERMS_LINK)}
                      </a>
                    </Text>
                  }
                  feedback={t(keys.companySignUp.FINAL_TERMS_WARNING)}
                  feedbackType="invalid"
                />
              </Form.Group>
            </>
          )}
          <br />
          {!complete ? (
            <WorkflowStageFooter
              loading={loading}
              nextValue={t(keys.common.FINISH)}
              nextStageDisabled={!terms}
              onNext={() => {
                registerCompany({
                  variables: {
                    id: parseInt(companyId),
                    token,
                  },
                }).then(({ errors }) => {
                  if (errors) {
                    setErrorOccured(true);
                  } else {
                    onCompleted(currentStage);
                    setComplete(true);
                  }
                });
              }}
            />
          ) : (
            <Button
              align={'right'}
              value={t(keys.companySignUp.LEAVE_PAGE)}
              onClick={() => (window.location = publicSite)}
            />
          )}
        </Form>
        {errorOccured && (
          <>
            <br />
            <Text size="md" weight="semiBold" color="red">
              {t(keys.companySignUp.FINAL_ERROR)}
            </Text>
            <a
              href={`mailto:${supportEmail}?subject=${encodeURIComponent(
                emailSubject,
              )}&body=${encodeURIComponent(emailBody)}`}
            >
              support@opasmobile.com
            </a>
          </>
        )}
      </div>
    </>
  );
}
