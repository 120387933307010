import React, { useState, useRef, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import styles from './TextInputTable.module.scss';
import { Icon } from '@mui/material';
import { Text } from '../typography';
import { keys } from '../../utilities/translator/translation_keys.js';
import { useTranslation } from 'react-i18next';

const TextInputTable = ({ headers, onChange, inputValue, title }) => {
  const [editRowId, setEditRowId] = useState(inputValue ? null : 1);
  const [fullScreen, setFullScreen] = useState(false);
  const stringToObjectConverter = (inputValue) => {
    const convertedInputValue = [];
    for (let i = 0; i < inputValue.length; i++) {
      const data = inputValue[i].split('\n').map((str) => str.split(','));
      const object = { id: i + 1, data: data };
      convertedInputValue.push(object);
    }
    return convertedInputValue;
  };

  const [rows, setRows] = useState(
    inputValue
      ? typeof inputValue === 'string'
        ? stringToObjectConverter(inputValue.split('|'))
        : inputValue
      : [{ id: 1, data: new Array(headers?.length).fill('') }],
  );

  const handleAddRow = () => {
    const newRow = {
      id: rows.length > 0 ? rows[rows.length - 1].id + 1 : 1,
      data: new Array(headers?.length).fill(''),
    };
    setRows([...rows, newRow]);
    setEditRowId(newRow.id);
  };

  const handleRemoveRow = (id) => {
    setEditRowId(null);
    const rowIndex = rows.findIndex((row) => row.id === id);
    const newRows = rows.filter((row) => row.id !== id);
    for (let i = rowIndex; i < newRows.length; i++) {
      newRows[i].id -= 1;
    }
    setRows(newRows);
    onChange(newRows?.map((row) => row.data.join('\n')));
  };

  const handleInputChange = (e, id, index) => {
    e.preventDefault();
    let value = e.target.value;
    value = value.replace(/\n/g, ' ');
    const newRows = [...rows];
    const rowIndex = newRows.findIndex((row) => row.id === id);
    if (rowIndex > -1) {
      newRows[rowIndex].data[index] = `${value}`;
      setRows(newRows);
    }
  };

  const handleBlur = () => {
    onChange(rows?.map((row) => row.data.join('\n')));
  };

  const handleEditRow = (id) => {
    setEditRowId(id);
  };

  const { t } = useTranslation();

  const tableRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (tableRef.current && !tableRef.current.contains(e.target)) {
      setTimeout(() => {
        setEditRowId(null);
      }, 100);
    }
  };

  useEffect(() => {
    if (!!rows[0].data[0])
      document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [rows]);

  const cellWidth = `${100 / headers.length}%`;

  return (
    <div
      ref={tableRef}
      className={fullScreen ? styles.fullScreen : styles.container}
    >
      {fullScreen && (
        <div className={styles.header}>
          <Text size="lg" weight="bold">
            {title}
          </Text>
          <Icon className={styles.hover} onClick={() => setFullScreen(false)}>
            close
          </Icon>
        </div>
      )}
      <div className={styles.tableContainer}>
        <Table striped bordered className={styles.table}>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th className={styles.tableHeader} key={index}>
                  <Text size="sm" noMargin>
                    {header}
                  </Text>
                </th>
              ))}
              <th>
                <div className={styles.iconColumn}>
                  <Icon
                    sx={{ fontSize: '1.2rem' }}
                    onClick={() => {
                      setFullScreen(!fullScreen);
                    }}
                  >
                    {fullScreen ? 'fullscreen_exit' : 'fullscreen'}
                  </Icon>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row) => (
              <tr key={row.id} onClick={() => handleEditRow(row.id)}>
                {row?.data.map((value, index) => (
                  <td
                    key={index}
                    className={styles.cell}
                    style={{ width: cellWidth, minWidth: '10rem' }}
                  >
                    {editRowId === row.id ? (
                      <textarea
                        className={styles.cellInput}
                        type="text"
                        rows={2}
                        value={value || ''}
                        onChange={(e) => handleInputChange(e, row.id, index)}
                        onBlur={handleBlur}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            handleAddRow();
                          }
                        }}
                      />
                    ) : (
                      <Text size="sm" noMargin noSelect>
                        {value}
                      </Text>
                    )}
                  </td>
                ))}
                <td>
                  <div className={styles.iconColumn}>
                    <Icon
                      className={
                        rows.length > 1
                          ? styles.deleteButton
                          : styles.deleteButtonDisabled
                      }
                      sx={{
                        fontSize: '1rem',
                      }}
                      onClick={(e) => {
                        if (rows.length > 1) {
                          e.stopPropagation();
                          setEditRowId(null);
                          handleRemoveRow(row.id);
                        }
                      }}
                    >
                      delete
                    </Icon>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className={styles.newRow} onClick={handleAddRow}>
        <Icon
          baseClassName="material-icons-outlined"
          className={styles.addButton}
          sx={{ fontSize: '1rem' }}
        >
          add_circle
        </Icon>
        <Text noMargin size="sm" color="accentPrimary" weight="semiBold">
          {t(keys.assessments.NEW_ROW)}
        </Text>
      </div>
    </div>
  );
};

export default TextInputTable;
