import React, { useEffect } from 'react';
import { Icon } from '@mui/material';
import classNames from 'classnames';
import styles from './Toast.module.scss';
import { toastLength, toastVariant } from '../../constants/misc';
import { Text } from '../typography';

export default function Toast({
  show,
  onHide,
  title,
  message = '',
  variant = toastVariant.info,
  time = toastLength.md,
}) {
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onHide();
      }, time);
    }
  }, [show, onHide, time]);

  return (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
        >
          <div
            className={classNames(
              styles.leftBanner,
              styles[`${variant}Banner`],
            )}
          />
          <div className={styles.leftContainer}>
            <Icon
              className={classNames(styles.leftIcon, styles[`${variant}Icon`])}
            >
              info
            </Icon>
            <div className={styles.textContainer}>
              <Text noMargin size="md" weight="semiBold">
                {title}
              </Text>
              <Text noMargin size="sm" color="secondary">
                {message}
              </Text>
            </div>
          </div>
          <Icon className={styles.closeIcon} onClick={() => onHide()}>
            close
          </Icon>
        </div>
      </div>
    </div>
  );
}
