import React, { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { inputTypes } from '../../constants/strings';
import SingleSelect from './SingleSelect';
import { Text } from '../typography';
import StatusView from './StatusView';
import TextInput from './TextInput';
import DateSelector from '../DateSelector';
import NumberInput from './NumberInput';
import MultiSelect from './MultiSelect';
import UserSelector from '../UserSelector';
import SimpleUserCard from '../SimpleUserCard';
import RiskMatrix from '../RiskMatrix';
import styles from './FormQuestion.module.scss';
import { TimePicker } from 'react-time-picker';
import './FormQuestion.customTimeCode.css';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { sanitizeArray } from '../../utilities';

const onChange = ({
  status,
  setStatus,
  update,
  newValue,
  parentId,
  objectKey,
}) => {
  setStatus({ ...status, loading: true, showing: true });
  update({
    variables: {
      id: parentId,
      [objectKey]: newValue,
    },
  }).then(({ errors }) => {
    setTimeout(() => {
      setStatus({ ...status, showing: false });
    }, 1000);
    if (errors?.length) {
      setStatus({
        loading: false,
        status: false,
        showing: true,
      });
    } else {
      setStatus({
        showing: true,
        loading: false,
        status: true,
      });
    }
  });
};

export default function FormQuestion({
  objectKey,
  question,
  value,
  update,
  parentId,
  readOnly = false,
  incident,
  legacyInput = false,
}) {
  const [status, setStatus] = useState({
    loading: false,
    status: true,
    showing: false,
  });
  const { t, i18n } = useTranslation();

  const InputComponent = useMemo(() => {
    switch (question.inputType) {
      case inputTypes.text:
      case inputTypes.textMulti:
        return (
          <TextInput
            readOnly={readOnly}
            value={value}
            type={
              question.inputType === inputTypes.textMulti ? 'multi' : 'single'
            }
            onChange={(newValue) =>
              onChange({
                status,
                setStatus,
                update,
                newValue,
                parentId,
                objectKey,
              })
            }
          />
        );
      case inputTypes.time:
        return (
          <TimePicker
            onChange={(newValue) =>
              onChange({
                status,
                setStatus,
                update,
                newValue,
                parentId,
                objectKey,
              })
            }
            value={value}
            disabled={readOnly}
            clockIcon={null}
            clearIcon={false}
            disableClock={true}
          />
        );
      case inputTypes.matrix:
        return !readOnly ? (
          <div className={styles.riskMatrix}>
            <RiskMatrix
              impact={incident.impact}
              probability={incident.probability}
              showMessage={false}
              onChange={(impact, probability) =>
                update({
                  variables: {
                    id: parentId,
                    impact,
                    probability,
                  },
                })
              }
            />
          </div>
        ) : (
          <div>
            <Text>
              <span>{`${t(keys.common.PROBABILITY)}: `}</span>
              {`${incident?.probability} / 5`}
            </Text>
            <Text>
              <span>{`${t(keys.common.IMPACT)}: `} </span>
              {`${t(incident?.impact)} / 5`}
            </Text>
          </div>
        );
      case inputTypes.date:
        return (
          <DateSelector
            readOnly={readOnly}
            disableFuture={true}
            value={value?.slice(0, 16)}
            onChange={(newValue) =>
              onChange({
                status,
                setStatus,
                update,
                newValue,
                parentId,
                objectKey,
              })
            }
          />
        );
      case inputTypes.select:
        const opt = question.additionalData;
        return (
          <>
            <SingleSelect
              title={question.title}
              readOnly={readOnly}
              onChange={(newValue) =>
                onChange({
                  status,
                  setStatus,
                  update,
                  newValue,
                  parentId,
                  objectKey,
                })
              }
              options={t(opt).split('|') || opt.split('|')}
              value={value}
              isBoolean={!!question.isBoolean}
              isInteger={!!question.isInteger}
            />
            {incident?.lostTime && question.id === 9 ? (
              <>
                <br />
                <Text noSelect weight="bold">
                  {t(keys.incidents.LOST_TIME_HRS)}
                </Text>
                <NumberInput
                  readOnly={readOnly}
                  value={incident?.lostTimeHrs}
                  onChange={(newValue) =>
                    onChange({
                      status,
                      setStatus,
                      update,
                      newValue,
                      parentId,
                      objectKey: 'lostTimeHrs',
                    })
                  }
                />
              </>
            ) : null}
          </>
        );
      case inputTypes.number:
        return (
          <NumberInput
            readOnly={readOnly}
            value={value}
            onChange={(newValue) =>
              onChange({
                status,
                setStatus,
                update,
                newValue,
                parentId,
                objectKey,
              })
            }
          />
        );
      case inputTypes.multiSelect:
        const options = question.additionalData;
        return (
          <MultiSelect
            readOnly={readOnly}
            values={value?.split('|') || []}
            options={t(options).split('|') || options.split('|')}
            title={question.title}
            onChange={(value) => {
              const newValue = sanitizeArray(value);
              onChange({
                status,
                setStatus,
                update,
                newValue,
                parentId,
                objectKey,
              });
            }}
          />
        );

      case inputTypes.user:
        return readOnly ? (
          <SimpleUserCard user={value} />
        ) : (
          <UserSelector
            selected={value}
            type="Employee"
            onClose={() => {
              onChange({
                status,
                setStatus,
                update,
                newValue: null,
                parentId,
                objectKey: `${objectKey}Id`,
              });
            }}
            onSelectionChanged={(user) => {
              onChange({
                status,
                setStatus,
                update,
                newValue: user.id,
                parentId,
                objectKey: `${objectKey}Id`,
              });
            }}
          />
        );

      default:
        throw new Error(`Unsupported input type: ${question.inputType}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    question.inputType,
    question.title,
    question.additionalData,
    question.isBoolean,
    question.isInteger,
    question.id,
    readOnly,
    value,
    incident?.probability,
    incident?.impact,
    incident?.lostTime,
    incident?.lostTimeHrs,
    status,
    update,
    parentId,
    objectKey,
    i18n.resolvedLanguage,
  ]);

  return !legacyInput || (legacyInput && value) ? (
    <div>
      <StatusView
        show={status.showing}
        loading={status.loading}
        status={status.status}
      />

      <Text noSelect weight="bold">
        {t(question.title) || question.title}
        <span className={question.required ? styles.required : styles.hide}>
          {' *'}
        </span>
      </Text>

      {InputComponent}
    </div>
  ) : null;
}

FormQuestion.propTypes = {
  objectKey: propTypes.string.isRequired,
  value: propTypes.any,
  update: propTypes.func,
  readOnly: propTypes.bool,
  parentId: propTypes.number.isRequired,
  question: propTypes.shape({
    additionalData: propTypes.string,
    inputType: propTypes.string.isRequired,
    isBoolean: propTypes.bool,
    title: propTypes.string.isRequired,
  }).isRequired,
};
