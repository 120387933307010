import React from 'react';
import { Page, Document, View, Text, Link } from '@react-pdf/renderer';
import {
  styles,
  PageHeaderLogo,
  PageFooter,
  BatchPageHeader,
} from './constants';

export const dailyReportHeaders = ['Time', 'Event', 'Link'];

export const DailyReportDocument = ({ user, events, date }) => {
  const { company } = user;

  const DailyReportHeader = () => (
    <View style={styles.row}>
      {dailyReportHeaders.map((header, index) => (
        <Text key={index} style={styles.title}>
          {header}
        </Text>
      ))}
    </View>
  );

  const DailyReport = ({ report }) => (
    <View style={styles.row}>
      <Text style={styles.text}>{report.time}</Text>
      <View style={{ marginLeft: 80, marginRight: 'auto' }}>
        <Text style={styles.title}>{report.title}</Text>
        <Text style={styles.text}>{report.description}</Text>
      </View>
      <Link src={report.fullUrl} style={styles.linkNoPosition}>
        {report.linkText}
      </Link>
    </View>
  );
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <PageHeaderLogo company={company} />
        <Text
          style={{ ...styles.title, marginBottom: 16 }}
        >{`${user.firstName} ${user.lastName}'s Daily Log ${date}`}</Text>
        <BatchPageHeader company={company} />

        <DailyReportHeader />
        {events.map((report) => (
          <DailyReport key={`${report.time}`} report={report} />
        ))}
        <PageFooter />
      </Page>
    </Document>
  );
};
