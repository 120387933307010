import { $getRoot, $getSelection } from 'lexical';
// import { useEffect } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
// import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
// import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { MuiContentEditable, placeHolderSx } from './styles';
import { Box, Divider } from '@mui/material';
import { lexicalEditorConfig } from '../../config/lexicalEditorConfig';
import LexicalEditorTopBar from '../LexicalEditorTopBar';
// import TreeViewPlugin from '../CustomPlugins/TreeViewPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import ImagesPlugin from '../CustomPlugins/ImagePlugin';
import FloatingTextFormatToolbarPlugin from '../CustomPlugins/FloatingTextFormatPlugin';
// import { useCallback, useEffect, useState } from 'react';
import CustomOnChangePlugin from '../CustomPlugins/CustomOnChangePlugin';
import './style.css';
import { useIsMobile } from '../../../../hooks/misc';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../../utilities/translator/translation_keys';

function LexicalEditorWrapper(props) {
  const { value, onChange: onChangeCallback, id, readOnly, topBarComponent } = props;
  const isMobile = useIsMobile()
  const {t} = useTranslation()

  return (
    <LexicalComposer
      initialConfig={{ ...lexicalEditorConfig, editable: !readOnly }}
    >
      <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: isMobile ? 'flex': 'undefined'}}>
      {!readOnly && <LexicalEditorTopBar />}
     {topBarComponent}
      </div>

      {!readOnly && <Divider />}
      <Box sx={{ position: 'relative', background: 'white' }}>
        <RichTextPlugin // #312D4B
          contentEditable={<MuiContentEditable />}
          placeholder={<Box sx={placeHolderSx}>{!readOnly ? t(keys.announcements.ENTERSOMETEXT) : ''}</Box>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <CustomOnChangePlugin
          id={id}
          value={value}
          onChange={onChangeCallback}
        />
        {/* <HistoryPlugin />
        <TreeViewPlugin /> */}
        <ListPlugin />
        <LinkPlugin />
        <OnChangePlugin onChange={onChange} />
        <ImagesPlugin captionsEnabled={false} />
        {!readOnly && <FloatingTextFormatToolbarPlugin />}
      </Box>
    </LexicalComposer>
  );
}

// When the editor changes, you can get notified via the
// LexicalOnChangePlugin!
function onChange(editorState) {
  editorState.read(() => {
    // Read the contents of the EditorState here.
    // eslint-disable-next-line no-unused-vars
    const root = $getRoot();
    // eslint-disable-next-line no-unused-vars
    const selection = $getSelection();
  });
}

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
// function MyCustomAutoFocusPlugin() {
//   const [editor] = useLexicalComposerContext();

//   useEffect(() => {
//     // Focus the editor when the effect fires!
//     editor.focus();
//   }, [editor]);

//   return null;
// }

export default LexicalEditorWrapper;
