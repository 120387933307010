import React, { useEffect, useState } from 'react';
import Modal from '../../components/modals/Modal';
import { Text } from '../../components/typography';
import { Form } from 'react-bootstrap';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import styles from './EditInformationModal.module.scss';

export default function EditInformationModal({
  onSubmit,
  show,
  setShow,
  originalValues,
}) {
  const [state, setState] = useState(originalValues);

  useEffect(() => {
    setState(originalValues);
  }, [originalValues, show]);

  const submit = () => {
    const delta = {};
    for (const key of Object.keys(state)) {
      if (originalValues[key] !== state[key]) {
        delta[key] = state[key];
      }
    }
    onSubmit(delta);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal
        open={show}
        onClose={() => setShow(false)}
        submitText={'Save'}
        title={'Edit User Information'}
        onSubmit={() => {
          submit();
        }}
      >
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            First Name
          </Text>
          <Form.Control
            type="text"
            onChange={(e) => setState({ ...state, firstName: e.target.value })}
            value={state?.firstName}
          />
        </div>
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            Last Name
          </Text>
          <Form.Control
            type="text"
            onChange={(e) => setState({ ...state, lastName: e.target.value })}
            value={state?.lastName}
          />
        </div>
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            Phone Number
          </Text>
          <Form.Control
            type="text"
            onChange={(e) => setState({ ...state, phone: e.target.value })}
            value={state?.phone}
          />
        </div>
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            Email
          </Text>
          <Form.Control type="email" readOnly placeholder={state?.email} />
        </div>
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            Address
          </Text>
          <Form.Control
            type="text"
            onChange={(e) => setState({ ...state, address: e.target.value })}
            value={state?.address}
          />
        </div>
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            City
          </Text>
          <Form.Control
            type="text"
            onChange={(e) => setState({ ...state, city: e.target.value })}
            value={state?.city}
          />
        </div>
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            Postal Code
          </Text>
          <Form.Control
            type="text"
            onChange={(e) => setState({ ...state, postalCode: e.target.value })}
            value={state?.postalCode}
          />
        </div>
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            Date of Birth
          </Text>
          <DatePicker
            value={state?.dateOfBirth ? moment(state?.dateOfBirth) : null}
            onChange={(date) =>
              setState({ ...state, dateOfBirth: date?.format('YYYY-MM-DD') })
            }
            renderInput={(props) => <TextField {...props} />}
          />
        </div>
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            Emergency Contact
          </Text>
          <Form.Control
            type="text"
            onChange={(e) =>
              setState({ ...state, emergencyContact: e.target.value })
            }
            value={state?.emergencyContact}
          />
        </div>
        <div className={styles.informationBox}>
          <Text noMargin weight="semiBold" size="sm">
            Important Info
          </Text>
          <Form.Control
            as="textarea"
            onChange={(e) =>
              setState({ ...state, importantInfo: e.target.value })
            }
            value={state?.importantInfo}
          />
        </div>
      </Modal>
    </LocalizationProvider>
  );
}
