import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { Button } from '../../components';
import ContextMenu from '../../components/ContextMenu';
import { Text } from '../../components/typography';
import {
  openFileViewer,
  openTextInputModal,
  showToast,
} from '../../graphql/cache/modal';
import styles from './FilesPage.module.scss';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { EMPTY_ARRAY } from '../../constants/misc';
import FileCard from '../../components/files/FileCard';
import FileCardSkeleton from '../../components/files/FileCardSkeleton';
import { getLocalTime } from '../../utilities/time';
import SimpleUserCard from '../../components/SimpleUserCard';
import { getFileSizeString } from '../../utilities';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { Breadcrumb } from 'react-bootstrap';
import { Icon, MenuItem } from '@mui/material';
import { closeModalComponents } from '../../utilities';
import TableSorting from '../../components/sorting/TableSorting';
import { sortFileStrings } from '../../constants/strings';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import emptyStateFilesImage from '../../assets/empty_state_images/templates.svg';
import { getRoute } from '../../constants/strings';
import SimpleTabSystem from '../../components/SimpleTabSystem';
import { useParams } from 'react-router-dom';
import AssetFiltering from '../../components/sorting/AssetFiltering';
import {
  convertFilesToQueryParams,
  fileFilterOptions,
} from '../../utilities/filtering';
import classNames from 'classnames';
import { useCurrentUser } from '../../providers/UserProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { useTranslation } from 'react-i18next';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';
import TablePagination from '../../components/pagination/TablePagination';
import TablePrototype from '../../components/TablePrototype';
import findFileIcon, {
  fileDescriptionTypes,
  fileStatusTypes,
  fileTypesConversion,
  fileStatusStringLookup,
  fileDescriptionStringLookup,
} from '../../utilities/files';
import Searchbar from '../../components/Searchbar';
import TypeSelectorDropdown from '../../components/dropdowns/TypeSelectorDropdown';
import moment from 'moment';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider';
import { searchParamKeys } from '../../constants/strings';
import { useQueryParams } from '../../hooks/misc';
import DatePickerComponent from '../../components/modals/DatePickerComponent';
import { useTableComponents } from '../../components/tables/MTableComponents';

const addFileMutation = loader('./FilesPage.addFile.graphql');
const filesQuery = loader('./FilesPage.files.graphql');
const addDirectoryMutation = loader('./FilesPage.addDirectory.graphql');

const updateFileMutation = loader('./FilesPage.updateFile.graphql');
const updateDirectoryMutation = loader('./FilesPage.updateDirectory.graphql');
const deleteFileMutation = loader('./FilesPage.deleteFile.graphql');
const deleteDirectoryMutation = loader('./FilesPage.deleteDirectory.graphql');

export default function FilesPage({ disabled = false }) {
  const { openModal, openConfirmationModal } = useModal();
  const { user: currentUser, isAdmin } = useCurrentUser();
  const { type } = useParams();
  const { workspace, isWorkspaceAdmin } = useWorkspace();
  const { allWorkspaceUsers } = useWorkspacePermissions();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(12);
  const [navStack, setNavStack] = useState([]);
  const [sortBy, setSortBy] = useState('dateCreated');
  const [isAscending, setIsAscending] = useState(false);
  const sortingOptions = ['dateCreated', 'dateModified', 'name', 'expires'];
  const [parent, setParent] = useState('null');
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [filesLoading, setFilesLoading] = useState([]);
  const [addFile] = useMutation(addFileMutation);
  const [addDirectory] = useMutation(addDirectoryMutation);
  const [rootFolder, setRootFolder] = useState(type || 'global');
  const [page, setPage] = useState(1);
  const [rawFilters, setRawFilters] = useState({});
  const { t } = useTranslation();
  const [tableView, setTableView] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchOn, setSearchOn] = useState(false);
  const [editExpiry, setEditExpiry] = useState(false);
  const { dateCell } = useTableComponents();

  const searchSelectorItems = useMemo(() => {
    return [
      {
        id: 1,
        title: t(keys.common.NAME),
        key: 'name',
      },
      {
        id: 2,
        title: t(keys.common.STATUS),
        key: 'status',
      },
      {
        id: 3,
        title: t(keys.files.EXTENSION),
        key: 'extension',
      },
    ];
  }, [t]);

  const [searchField, setSearchField] = useState(searchSelectorItems[0]);
  const rootFolderOptions = [
    { title: t(keys.files.GLOBAL), key: 'global' },
    { title: t(keys.files.WORKSPACE), key: 'workspace' },
  ];
  const [updateFile] = useMutation(updateFileMutation);
  const [updateDirectory] = useMutation(updateDirectoryMutation);
  const [deleteFile] = useMutation(deleteFileMutation);
  const [deleteDirectory] = useMutation(deleteDirectoryMutation);
  const { getParam } = useQueryParams();
  const fileId = getParam(searchParamKeys.file);

  const filters = useMemo(() => {
    return convertFilesToQueryParams(
      rawFilters,
      parent,
      workspace?.id,
      rootFolder,
      {
        searchField: searchField.key,
        searchText,
      },
    );
    // eslint-disable-next-line
  }, [parent, rawFilters, rootFolder, workspace?.id, searchText]);

  const {
    loading,
    refetch: refetchFiles,
    data: { files = EMPTY_ARRAY, filesCount = 0 } = {},
  } = useQuery(filesQuery, {
    skip: !workspace?.id,
    variables: {
      options: {
        page,
        pageSize,
        sort: [
          { field: sortBy, order: isAscending ? 'asc' : 'desc NULLS LAST' },
        ],
        filters: [...filters],
      },
    },
  });

  const [selected, setSelected] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (fileId) {
      setRawFilters({
        filterData: { id: fileId },
      });
      setPage(1);
      setSelected(files[0]);
      setRootFolder(type);
    }
  }, [files, fileId, type]);

  useEffect(() => {
    closeModalComponents(ref, setSelected);
  }, [ref]);

  useEffect(() => {
    setNavStack([]);
    setParent('null');
  }, [rootFolder]);

  useEffect(() => {
    if (searchText.length > 0) {
      setPage(1);
    }
  }, [searchText]);

  const onDirectoryClick = useCallback(
    (directory) => {
      setNavStack([...navStack, directory]);
      setParent(`${directory.id}`);
      setPage(1);
    },
    [navStack, setNavStack, setParent],
  );
  const onFileClick = useCallback(
    (file) => {
      openFileViewer(file, () => {
        setSelected(file);
      });
    },
    [setSelected],
  );

  const rowActions = ({ row, closeMenu }) => {
    const file = row.original;
    const isDirectory = !file.url;
    const actions = [
      <MenuItem
        className={styles.menuItem}
        key={row.id + 'rename'}
        disabled={!(!isWorkspaceAdmin || file?.creator?.id === currentUser.id)}
        onClick={() => {
          closeMenu();
          openTextInputModal({
            title: t(keys.files.RENAME, { variable: file.name }),
            inputs: ['File Name'],
            submitText: t(keys.action.SUBMIT),
            onSubmit: (value) => {
              if (isDirectory) {
                updateDirectory({
                  variables: { id: file.id, name: value['File Name'] },
                }).then(() => refetchFiles());
              } else {
                updateFile({
                  variables: {
                    id: file.id,
                    name: value['File Name'],
                  },
                }).then(() => refetchFiles());
              }
            },
            namespace: 'files',
          });
        }}
      >
        <Icon baseClassName="material-icons-outlined">edit</Icon>
        <Text noMargin size="sm" weight="semiBold">
          {t(keys.files.RENAME, { variable: null })}
        </Text>
      </MenuItem>,
      <MenuItem
        disabled={!(isWorkspaceAdmin || file?.creator?.id === currentUser.id)}
        className={styles.menuItem}
        key={row.id + 'delete'}
        onClick={() => {
          closeMenu();
          openConfirmationModal({
            title: t(keys.action.DELETE, { variable: file.name }),
            description: `${t(keys.action.DELETE_CONFIRMATION, {
              variable: file.name,
            })} ${isDirectory ? t(keys.files.DELETE_DIRECTORY_WARNING) : ''}`,
            variant: 'danger',
            buttonText: t(keys.action.DELETE),
            onSubmit: () => {
              if (isDirectory) {
                deleteDirectory({
                  variables: {
                    deleteDirectoryId: file.id,
                  },
                }).then(() => refetchFiles());
              } else {
                deleteFile({
                  variables: {
                    deleteFileId: file.id,
                  },
                }).then(() => refetchFiles());
              }
            },
          });
        }}
      >
        <Icon className={styles.red} baseClassName="material-icons-outlined">
          delete
        </Icon>
        <Text noMargin size="sm" weight="semiBold">
          {t(keys.action.DELETE, { variable: null })}
        </Text>
      </MenuItem>,
    ];

    if (!isDirectory) {
      actions.push([
        <MenuItem
          disabled={!file?.downloadAllowed}
          className={styles.menuItem}
          key={row.id + 'download'}
          onClick={() => {
            closeMenu();
            openConfirmationModal({
              title: t(keys.action.DOWNLOAD, { variable: file.name }),
              description: `${t(keys.action.DOWNLOAD_CONFIRMATION, {
                variable: file.name,
              })}`,
              variant: 'warning',
              onSubmit: () => {
                const downloadFile = (url, name) => {
                  fetch(url)
                    .then((response) => response.blob())
                    .then((blob) => {
                      const objectUrl = URL.createObjectURL(blob);
                      const link = document.createElement('a');
                      const awsExtension = new URL(file.url).pathname.split(
                        '.',
                      )[1];
                      if (name.split('.').length > 1) {
                        link.download = name;
                      } else {
                        link.download = name + '.' + awsExtension;
                      }
                      link.href = objectUrl;
                      link.click();
                      URL.revokeObjectURL(objectUrl);
                    })
                    .catch((error) => console.error(error));
                };
                downloadFile(file.url, file.name);
              },
            });
          }}
        >
          <Icon baseClassName="material-icons-outlined">download</Icon>
          <Text noMargin size="sm" weight="semiBold">
            {t(keys.action.DOWNLOAD, { variable: null })}
          </Text>
        </MenuItem>,
        <MenuItem
          className={styles.menuItem}
          key={row.id + 'revision'}
          onClick={() => {
            closeMenu();
            openModal({
              modalName: modals.fileUpload,
              variables: {
                onSubmit: ({
                  url,
                  size,
                  fileType,
                  extension,
                  name,
                  expires,
                  vehicleId,
                  userId,
                  status,
                  downloadAllowed,
                  type,
                }) =>
                  updateFile({
                    variables: {
                      id: file.id,
                      url,
                      size,
                      extension,
                      fileType,
                      name,
                      userId,
                      vehicleId,
                      expires,
                      downloadAllowed,
                      status,
                      type,
                    },
                  }),
              },
            });
          }}
        >
          <Icon baseClassName="material-icons-outlined">cloud_upload</Icon>
          <Text noMargin size="sm" weight="semiBold">
            {t(keys.files.UPLOAD_REVISION, { variable: null })}
          </Text>
        </MenuItem>,
        <MenuItem
          className={styles.menuItem}
          key={row.id + 'details'}
          onClick={() => {
            closeMenu();
            setSelected(file);
          }}
        >
          <Icon baseClassName="material-icons-outlined">info</Icon>
          <Text noMargin size="sm" weight="semiBold">
            {t(keys.common.DETAILS)}
          </Text>
        </MenuItem>,
      ]);
    }
    return actions;
  };

  const userOptions = useMemo(() => {
    const userOptions = allWorkspaceUsers.map((p) => ({
      label: `${p.firstName} ${p.lastName}`,
      value: p.id,
    }));
    return userOptions;
  }, [allWorkspaceUsers]);

  const itemToString = (item) => {
    if (Array.isArray(item)) {
      return (
        item.map((value) => t(getTranslationKey(value, 'files'))).join(', ') ||
        item.join(', ')
      );
    }
    const key = getTranslationKey(item, 'files');
    return t(key) || item;
  };

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t(keys.common.NAME),
        minSize: 150,
        grow: true,
        Cell: ({
          cell: {
            row: { original: file },
          },
        }) => (
          <div className={styles.cell}>
            <img
              className={styles.icon}
              alt={`${file.extension} file`}
              src={findFileIcon(
                !file.url
                  ? rootFolder === 'global'
                    ? 'global-folder'
                    : 'folder'
                  : file.extension,
              )}
            />
            {file.hidden && (
              <Icon
                baseClassName="material-icons-outlined"
                className={styles.hiddenIcon}
              >
                visibility
              </Icon>
            )}
            <Text noMargin size="sm" weight="semiBold">
              {file.name}
            </Text>
          </div>
        ),
      },
      {
        accessorKey: 'extension',
        header: t(keys.files.FILE_TYPE),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: Object.keys(fileTypesConversion).map((type) => {
          return {
            label: itemToString(type),
            value: type,
          };
        }),
        Cell: ({ cell }) => (
          <Text noMargin size="sm" weight="semiBold">
            {cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'user',
        header: t(keys.common.USER),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userOptions,
        Cell: ({ cell }) =>
          cell.row.original.url ? (
            <SimpleUserCard size="sm" user={cell.getValue()} />
          ) : null,
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: fileStatusTypes.map((status) => ({
          label: itemToString(status),
          value: status,
        })),
        Cell: ({ cell }) => (
          <Text noMargin size="sm" weight="semiBold">
            {t(keys.files[fileStatusStringLookup[cell.getValue()]])}
          </Text>
        ),
      },
      {
        accessorKey: 'downloadAllowed',
        header: t(keys.files.DOWNLOAD_ENABLED),
        Header: ({ column }) => (
          <div style={{ textWrap: 'nowrap' }}>{column.columnDef.header}</div>
        ),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.YES) },
          { value: 'false', label: t(keys.common.NO) },
        ],
        Cell: ({ cell }) =>
          cell.row.original.url ? (
            <Text noMargin weight="semiBold" size="sm">
              {cell.getValue() ? t(keys.common.YES) : t(keys.common.NO)}
            </Text>
          ) : null,
      },
      {
        accessorKey: 'type',
        header: t(keys.files.FILE_DETAILS),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: fileDescriptionTypes.map((description) => ({
          label: itemToString(description),
          value: description,
        })),
        Cell: ({ cell }) => (
          <Text noMargin size="sm" weight="semiBold">
            {t(keys.files[fileDescriptionStringLookup[cell.getValue()]])}
          </Text>
        ),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userOptions,
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'expires',
        header: t(keys.action.EXPIRES),
        filterVariant: 'date-range',
        minSize: 350,
        grow: true,
        Cell: ({ cell }) =>
          cell.row.original.url ? dateCell({ cell }, { dateOnly: true }) : null,
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        filterVariant: 'date-range',
        minSize: 350,
        grow: true,
        Cell: ({ cell }) => dateCell({ cell }, { dateOnly: true }),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootFolder, userOptions, t],
  );

  useEffect(() => {
    if (!tableView) {
      setPageSize(12);
      setPage(1);
      setSortBy('dateCreated');
    }
  }, [tableView]);

  return (
    <>
      <div
        className={classNames(
          styles.container,
          disabled ? styles.disabled : null,
        )}
      >
        <div className={styles.header}>
          <div className={styles.fileBox}>
            <Text
              className={styles.centerDistance}
              size="lg"
              weight="bold"
              color="accentPrimary"
            >
              {t(keys.common.FILES)}
            </Text>
            <Breadcrumb className={styles.centerDistance}>
              <Breadcrumb.Item
                active={!navStack.length}
                onClick={() => {
                  setNavStack([]);
                  setParent('null');
                }}
              >
                {t(getTranslationKey(rootFolder, 'files'))}{' '}
                {t(keys.common.HOME)}
              </Breadcrumb.Item>
              {navStack.map((directory, index) => (
                <Breadcrumb.Item
                  key={directory + index}
                  active={index === navStack.length - 1}
                  onClick={() => {
                    if (index === navStack.length - 1) {
                      return;
                    }
                    setNavStack(navStack.slice(0, index + 1));
                    setParent(`${directory.id}`);
                  }}
                >
                  {directory.name}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>

          <div className={styles.newFileButton}>
            <Button
              className={rootFolder === 'global' && !isAdmin && styles.hide}
              value={t(keys.common.NEW)}
              icon="add"
              onClick={() => setContextMenuOpen(!contextMenuOpen)}
            />
            <ContextMenu
              className={styles.menu}
              setOpen={setContextMenuOpen}
              open={contextMenuOpen}
              options={[
                {
                  title: t(keys.files.NEW_FOLDER),
                  icon: 'folder',
                  onClick: () => {
                    openTextInputModal({
                      inputs: ['Name'],
                      title: t(keys.files.ADD_DIRECTORY),
                      submitText: t(keys.action.SUBMIT),
                      namespace: 'common',
                      onSubmit: (data) => {
                        addDirectory({
                          variables: {
                            name: data.Name,
                            parentId:
                              parent === 'null' ? undefined : parseInt(parent),
                            workspaceId:
                              rootFolder === 'global' ? null : data.workspaceId,
                          },
                        }).then(() => {
                          refetchFiles();
                        });
                      },
                    });
                  },
                },
                {
                  title: t(keys.files.UPLOAD_FILE),
                  icon: 'cloud_upload',
                  onClick: () => {
                    openModal({
                      modalName: modals.fileUpload,
                      variables: {
                        onSubmit: ({
                          url,
                          size,
                          fileType,
                          extension,
                          name,
                          expires,
                          vehicleId,
                          userId,
                          status,
                          downloadAllowed,
                          type,
                          workspaceId,
                        }) => {
                          addFile({
                            variables: {
                              url,
                              size,
                              fileType,
                              extension,
                              name,
                              vehicleId,
                              userId,
                              expires: expires
                                ? moment(parseInt(expires)).format('YYYY-MM-DD')
                                : null,
                              status,
                              downloadAllowed,
                              type,
                              parentId:
                                parent === 'null'
                                  ? undefined
                                  : parseInt(parent, 10),
                              workspaceId,
                            },
                          }).then(({ errors }) => {
                            refetchFiles();
                            setFilesLoading(
                              filesLoading.filter((f) => f !== size),
                            );
                            refetchFiles();
                            if (errors) {
                              showToast({
                                variant: 'danger',
                                title: t(keys.files.UPLOAD_ERROR_TITLE),
                                message: t(keys.files.UPLOAD_ERROR_MSG),
                              });
                            }
                          });
                        },
                        onLoading: (id) =>
                          setFilesLoading([...filesLoading, id]),
                        allWorkspaces: rootFolder === 'global',
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        </div>
        <div className={styles.page}>
          <SimpleTabSystem
            options={rootFolderOptions}
            selected={rootFolder}
            setSelected={setRootFolder}
            route={paths.files}
            workspaceId={workspace?.id}
          />

          {!tableView && (
            <div className={styles.sorting}>
              <AssetFiltering
                filters={filters}
                setFilters={setRawFilters}
                rawFilters={rawFilters}
                filterOptions={fileFilterOptions}
                setPage={setPage}
                assetType="File"
              />
              <TableSorting
                setSortBy={setSortBy}
                setIsAscending={setIsAscending}
                refetch={refetchFiles}
                isAscending={isAscending}
                sortingOptions={sortingOptions}
                sortBy={sortBy}
                sortStrings={sortFileStrings}
              />
            </div>
          )}
          <div className={styles.toggleContainer}>
            {!tableView && (
              <div className={styles.searchContainer}>
                <div
                  className={styles.searchIcon}
                  onClick={() => {
                    if (!searchOn) {
                      setSearchOn(true);
                    } else {
                      setSearchField(searchSelectorItems[0]);
                      setSearchText('');
                      setSearchOn(false);
                    }
                  }}
                >
                  <Icon>{searchOn ? 'search_off' : 'search'}</Icon>
                </div>
                {searchOn && (
                  <div className={styles.searchbarContainer}>
                    <Searchbar
                      className={styles.searchbar}
                      onChange={setSearchText}
                      value={searchText}
                      debounce={true}
                    />
                    <TypeSelectorDropdown
                      items={searchSelectorItems}
                      onChange={setSearchField}
                      selected={searchField}
                      noMargin
                    />
                  </div>
                )}
              </div>
            )}
            <Icon
              sx={{ marginLeft: 'auto' }}
              onClick={() => {
                setPageSize(10);
                setRawFilters({});
                setTableView(!tableView);
              }}
            >
              {tableView ? 'apps' : 'list'}
            </Icon>
          </div>

          <div className={styles.sectionBreak} />
          <div className={styles.body}>
            {tableView ? (
              <div
                className={classNames(
                  styles.table,
                  selected && styles.reduceTable,
                )}
              >
                <TablePrototype
                  isLoading={loading}
                  data={files}
                  columns={tableColumns}
                  count={filesCount}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  setRawFilters={setRawFilters}
                  setSortBy={setSortBy}
                  setIsAscending={setIsAscending}
                  onRowClick={(file) => {
                    if (file.url) {
                      onFileClick(file);
                    } else {
                      onDirectoryClick(file);
                    }
                  }}
                  enableRowActions={true}
                  rowActions={rowActions}
                  headerButtonOptions={{
                    backButton: {
                      enabled: navStack.length > 0,
                      onClick: () => {
                        navStack.pop(0);
                        setNavStack(navStack);
                        setParent(
                          `${
                            navStack.length > 0
                              ? navStack[navStack.length - 1].id
                              : null
                          }`,
                        );
                      },
                    },
                    hiddenButton: {
                      enabled: isAdmin && rootFolder === 'global',
                      state: rawFilters?.filterData?.Hidden,
                    },
                  }}
                  emptyState={{
                    title: t(keys.action.NOT_FOUND, {
                      variable: t(keys.common.FILES),
                    }),
                    text:
                      filters.length > 4
                        ? t(keys.action.EMPTY_STATE_CHECK_FILTERS, {
                            variable: t(keys.common.FILES),
                          })
                        : t(keys.action.EMPTY_STATE_MESSAGE, {
                            variable: t(keys.common.FILES),
                          }),
                    image: emptyStateFilesImage,
                  }}
                />
              </div>
            ) : (
              <div className={styles.filesContainer}>
                {!!!filesLoading.length &&
                (!files?.length || (!files?.length && filters.length > 3)) ? (
                  <div className={styles.emptyStateContainer}>
                    {!!!filesLoading.length && (
                      <EmptyStateView
                        title={'No Files Found'}
                        text={
                          'Check your filters, or click the new button to add your files!'
                        }
                        image={emptyStateFilesImage}
                      />
                    )}
                  </div>
                ) : (
                  files?.map((file) => (
                    <FileCard
                      className={styles.fileCard}
                      directory={!file.url}
                      refetchFiles={refetchFiles}
                      openDetails={() => {
                        setSelected(file);
                      }}
                      selected={selected?.id === file.id}
                      key={`file-${file.id}`}
                      file={file}
                      onClick={() =>
                        !file?.url ? onDirectoryClick(file) : onFileClick(file)
                      }
                      global={rootFolder === 'global'}
                    />
                  ))
                )}
                {!!filesLoading?.length && <FileCardSkeleton />}
              </div>
            )}
            {!!selected?.id && (
              <div
                className={classNames(
                  styles.rightSidebar,
                  selected && styles.slideIn,
                  tableView && styles.tableSideBar,
                )}
                ref={ref}
              >
                <div className={styles.sidebarHeader}>
                  <Text color="primary" weight="bold">
                    {t(keys.files.FILE_DETAILS)}
                  </Text>
                  <div className={styles.sidebarIcons}>
                    <Icon
                      sx={{ fontSize: '1rem' }}
                      className={styles.closeIcon}
                      onClick={() => {
                        setSelected(0);
                      }}
                    >
                      close
                    </Icon>
                  </div>
                </div>
                <Text noMargin weight="bold" size="sm">
                  {t(keys.common.NAME)}
                </Text>
                <Text weight="semiBold" color="secondary">
                  {selected.name}
                </Text>
                <Text noMargin weight="bold" size="sm">
                  {t(keys.files.FILE_TYPE)}
                </Text>
                <Text weight="semiBold" color="secondary">
                  {selected.fileType}
                </Text>

                {!!selected?.expires && (
                  <>
                    <Text noMargin weight="bold" size="sm">
                      {t(keys.action.EXPIRES)}
                    </Text>
                    {!editExpiry ? (
                      <div className={styles.expiryEditContainer}>
                        <Text weight="semiBold" color="secondary" noMargin>
                          {getLocalTime(selected?.expires).format(
                            'MMMM Do YYYY',
                          )}
                        </Text>
                        {(isWorkspaceAdmin ||
                          selected?.creator?.id === currentUser.id) && (
                          <Icon
                            className={styles.editIcon}
                            baseClassName="material-icons-outlined"
                            onClick={() => setEditExpiry(true)}
                          >
                            edit
                          </Icon>
                        )}
                      </div>
                    ) : (
                      <div className={styles.sidebarDatePicker}>
                        <DatePickerComponent
                          selected={new Date(`${selected.expires}T00:00:00`)}
                          onChange={(date) => {
                            setEditExpiry(false);
                            updateFile({
                              variables: {
                                id: selected?.id,
                                expires: moment(date).format('YYYY-MM-DD'),
                              },
                            });
                            setSelected({
                              ...selected,
                              expires: moment(date).format('YYYY-MM-DD'),
                            });
                            refetchFiles();
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                <Text noMargin weight="bold" size="sm">
                  {t(keys.common.LOCATION)}
                </Text>
                <Text weight="semiBold" color="secondary">
                  {selected.parentId
                    ? `${rootFolder}/${navStack[0]?.name}/${selected.name}`
                    : `${rootFolder}/${selected.name}`}
                </Text>
                {!!selected.status && (
                  <>
                    <Text noMargin weight="bold" size="sm">
                      {t(keys.common.STATUS)}
                    </Text>
                    <Text weight="semiBold" color="secondary">
                      {t(getTranslationKey(selected.status, 'files'))}
                    </Text>
                  </>
                )}
                {!!selected.type && (
                  <>
                    <Text noMargin weight="bold" size="sm">
                      {t(keys.common.TYPE)}
                    </Text>
                    <Text weight="semiBold" color="secondary">
                      {t(getTranslationKey(selected.type, 'files'))}
                    </Text>
                  </>
                )}
                <Text noMargin weight="bold" size="sm">
                  {t(keys.common.DATE_CREATED)}
                </Text>
                <Text weight="semiBold" color="secondary">
                  {getLocalTime(selected.dateCreated).format('MMMM Do YYYY')}
                </Text>
                <Text noMargin weight="bold" size="sm">
                  {t(keys.common.DATE_MODIFIED)}
                </Text>
                <Text weight="semiBold" color="secondary">
                  {getLocalTime(selected.dateModified).format('MMMM Do YYYY')}
                </Text>
                <Text noMargin weight="bold" size="sm">
                  {t(keys.common.CREATOR)}
                </Text>
                <SimpleUserCard user={selected.creator} />
                <div className={styles.sectionBreak} />
                <Text noMargin weight="bold" size="sm">
                  {t(keys.files.SIZE)}
                </Text>
                <Text weight="semiBold" color="secondary">
                  {getFileSizeString(selected.size)}
                </Text>
                <Text noMargin weight="bold" size="sm">
                  {t(keys.files.REVISION)}
                </Text>
                <Text weight="semiBold" color="secondary">
                  {`Version ${selected.revisions?.length}`}
                </Text>
                <Text noMargin weight="bold" size="sm">
                  {t(keys.files.DOWNLOAD_ENABLED)}
                </Text>
                <Text weight="semiBold" color="secondary">
                  {selected.downloadAllowed
                    ? t(keys.common.YES)
                    : t(keys.common.NO)}
                </Text>
                {!!selected.user?.id && (
                  <>
                    <Text noMargin weight="bold" size="sm">
                      {t(keys.common.USER)}
                    </Text>
                    <SimpleUserCard user={selected.user} />
                    <div className={styles.sectionBreak} />
                  </>
                )}
                {!!selected.vehicle?.id && (
                  <>
                    <Text noMargin weight="bold" size="sm">
                      {t(keys.common.ASSET)}
                    </Text>
                    <Text
                      className={styles.link}
                      onClick={() =>
                        navigate(
                          getRoute(
                            workspace?.id,
                            paths.asset,
                            selected.vehicle.id,
                          ),
                        )
                      }
                      weight="semiBold"
                      color="accentPrimary"
                    >
                      {`${selected.vehicle.year || ''} ${
                        selected.vehicle.make
                      } ${selected.vehicle.model} (${
                        selected.vehicle.unitNumber
                      })`}
                    </Text>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!tableView && (
        <TablePagination
          pageSize={pageSize}
          count={filesCount}
          setPage={setPage}
          page={page}
        />
      )}
    </>
  );
}
