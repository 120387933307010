import styles from './ToolboxTalkModal.module.scss';
import { Text } from '../typography';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import { hideToolboxTalkModal } from '../../graphql/cache/modal';
import {
  getImage,
  toolboxTalkContentTypes,
} from '../../utilities/toolboxtalks';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import RichTextEditor from '../rich_text/RichTextEditor';
import PdfViewer from './file_viewer/PdfViewer';

export default function ToolboxTalkModal({ show = false, info }) {
  const { t } = useTranslation();
  const { contentType } = info || {};

  const renderDefaultContent = () => (
    <div>
      <div className={styles.custom}>
        <div className={styles.introHeader}>
          <Text size="md" color="accentPrimary" weight="bold">
            {t(keys.dashboard.INTRODUCTION)}
          </Text>
          <Text>{info?.introduction ?? info?.description}</Text>
        </div>
        <img
          className={styles.customImage}
          src={info?.imageUrl ?? getImage()}
          alt="Toolbox Talk"
        />
      </div>
      <Text size="md" color="accentPrimary" weight="bold">
        {t(keys.dashboard.RISKS)}
      </Text>
      <Text>{info?.risks}</Text>
      <Text size="md" color="accentPrimary" weight="bold">
        {t(keys.dashboard.MITIGATION)}
      </Text>
      <Text>{info?.mitigation}</Text>
      <Text size="md" color="accentPrimary" weight="bold">
        {t(keys.dashboard.CONCLUSION)}
      </Text>
      <Text>{info?.conclusion}</Text>
    </div>
  );

  const renderRichTextContent = () => (
    <div className={styles.richTextContainer}>
      {info?.imageUrl && (
        <img
          className={styles.customImage}
          src={info.imageUrl}
          alt="Toolbox Talk"
        />
      )}
      <Text textAlign="center" weight="semiBold" color="secondary">
        {info?.description}
      </Text>
      <RichTextEditor
        readOnly
        onChange={() => {}}
        id={info?.id}
        value={info?.content}
      />
    </div>
  );

  const renderFileContent = () => (
    <div className={styles.richTextContainer}>
      <Text>{info?.description}</Text>
      {info.fileUrl.split('.').pop() === 'pdf' ? (
        <PdfViewer file={{ url: info.fileUrl }} width={1000} />
      ) : (
        <img src={info.fileUrl} alt="TBT" />
      )}
    </div>
  );

  const getContentByType = () => {
    switch (contentType) {
      case toolboxTalkContentTypes.RICH_TEXT:
        return renderRichTextContent();
      case toolboxTalkContentTypes.FILE:
        return renderFileContent();
      case toolboxTalkContentTypes.AI:
      default:
        return renderDefaultContent();
    }
  };

  return show ? (
    <div className={styles.show}>
      <div className={styles.background}>
        <div
          className={classNames(styles.container, styles.showContainer)}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.header}>
            <Text size="lg" color="secondary">
              {`${t(keys.dashboard.TOOLBOX_TALK)}: `}
              <span className={styles.modalTitle}>{info?.title}</span>
            </Text>
            <Icon className={styles.closeIcon} onClick={hideToolboxTalkModal}>
              close
            </Icon>
          </div>
          <div className={styles.content}>{getContentByType()}</div>
          <br />
          <div className={styles.footer}>
            <Text size="sm" textAlign="center">
              {t(keys.dashboard.SAFETY_MESSAGE)}
            </Text>
            <Text size="sm" weight="semiBold" textAlign="center">
              {t(keys.dashboard.SAFETY_MOMENT_FOOTER)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
