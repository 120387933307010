import { useState } from 'react';
import { loader } from 'graphql.macro';
import { useApolloClient } from '@apollo/client';
import Button from '../Button';
import { showToast } from '../../graphql/cache/modal';
import { toastVariant, toastLength } from '../../constants/misc';
import * as XLSX from 'xlsx';
import { headerMap } from './activites';
import moment from 'moment';
import ProgressModal from '../modals/ProgressModal';

const activityQuery = loader('./ActivityConverter.fetch.graphql');

export default function ActivityExport({ count, title, workspaceId }) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const GetActivities = async () => {
    const pageSize = 500;
    const pages = Math.ceil(count / pageSize);

    try {
      setLoading(true);
      const headerRow = Object.values(headerMap);
      const worksheet = XLSX.utils.aoa_to_sheet([headerRow]);

      for (let i = 1; i <= pages; i++) {
        const { data: { activities = [] } = {} } = await client.query({
          query: activityQuery,
          fetchPolicy: 'no-cache',
          variables: {
            options: {
              page: i,
              pageSize: pageSize,
              filters: [
                {
                  field: 'workspaceId',
                  operator: 'eq',
                  value: `${workspaceId}`,
                },
              ],
            },
          },
        });
        activities.forEach((activity) => {
          const rowData = Object.keys(headerMap).map((key) => activity[key]);
          XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
        });
        setProgress((i / pages) * 100);
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'TASK');
      const date = moment().format('YYYY-MM-DD HH:mm');
      const filePath = `${title}-export-${date}.xlsx`;
      XLSX.writeFile(workbook, filePath);
    } catch (error) {
      showToast({
        title: 'Error Exporting Activities',
        message: `An error occurred while exporting activities: ${error}. Please try again.`,
        variant: toastVariant.danger,
        time: toastLength.lg,
      });
    } finally {
      setLoading(false);
      setProgress(0);
      showToast({
        title: 'Activities Successfully Exported',
        message: `Activities have been successfully exported to your download folder!`,
        variant: toastVariant.info,
        time: toastLength.lg,
      });
    }
  };

  return loading ? (
    <ProgressModal
      progress={progress}
      title="Exporting Activities"
      icon="import_export"
      message="Please wait while we export your workspace activities. This should only take a moment!"
      show={loading}
    />
  ) : (
    <Button
      disabled={loading}
      variant="secondary"
      size="md"
      outlined
      onClick={() => GetActivities()}
      value="Export"
    />
  );
}
