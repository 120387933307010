import React from 'react';
import { Form } from 'react-bootstrap';
import { useDebouncedRemoteState } from '../../hooks/misc';
import { Text } from '../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function TextInput({
  value = '',
  onChange = async () => {},
  readOnly,
  type,
}) {
  const { t } = useTranslation();
  const [text, setText] = useDebouncedRemoteState(value, onChange);

  return readOnly ? (
    <Text noMargin>{value ? value : t(keys.common.NONE)}</Text>
  ) : type === 'multi' ? (
    <Form.Control
      as={'textarea'}
      rows={3}
      onChange={(e) => setText(e.target.value)}
      value={text || ''}
    />
  ) : (
    <Form.Control
      onChange={(e) => setText(e.target.value)}
      value={text || ''}
    />
  );
}
