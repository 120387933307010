import { useReactiveVar } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { Icon } from '@mui/material';
import {
  defaultTextInputModal,
  textInputModalVar,
} from '../../graphql/cache/modal';
import styles from './TextInputModal.module.scss';
import { Text } from '../typography';
import classNames from 'classnames';
import Button from '../Button';
import { Form } from 'react-bootstrap';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { useWorkspace } from '../../providers/WorkspaceProvider';

export default function TextInputModal() {
  const [data, setData] = useState({});
  const { workspace } = useWorkspace() || {};
  const [allWorkspaces, setAllWorkspaces] = useState(false);

  const { title, onSubmit, inputs, submitText, show, namespace } =
    useReactiveVar(textInputModalVar);

  const hasRequiredData = useMemo(
    () => inputs?.every((i) => !!data[i]?.length),
    [data, inputs],
  );

  const onClose = () => {
    setData({});
    setAllWorkspaces(false);
    textInputModalVar(defaultTextInputModal);
  };
  const { t } = useTranslation();

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.header}>
            <Text
              className={styles.header}
              size="lg"
              weight="semiBold"
              noMargin
            >
              {title}
            </Text>
            <Icon className={styles.closeIcon} onClick={onClose}>
              close
            </Icon>
          </div>
          <br />

          {inputs?.map((i) => (
            <div key={i}>
              <Form.Label>{t(getTranslationKey(i, namespace)) || i}</Form.Label>
              <Form.Control
                autoFocus={false}
                type="text"
                value={data[i] || ''}
                onChange={(e) => setData({ ...data, [i]: e.target.value })}
              />
            </div>
          ))}

          <br />

          <div className={styles.header}>
            <Button
              className={styles.button}
              outlined
              variant="secondary"
              onClick={onClose}
              value={t(keys.action.CANCEL)}
            />
            <Button
              disabled={!hasRequiredData}
              className={styles.button}
              outlined
              onClick={() => {
                onSubmit({
                  [inputs]: data[inputs],
                  workspaceId: allWorkspaces ? null : workspace?.id,
                });
                onClose();
              }}
              value={submitText}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
