import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { hasWebpSupport } from '../../utilities/misc';
import Button from '../Button';
import styles from './ImageCropper.module.scss';
import { useTranslation } from 'react-i18next';
import { Text } from '../typography';
import { keys } from '../../utilities/translator/translation_keys';

const pixelRatio = 32;
const maxSize = 2560;

function getResizedCanvas(imgRef, crop) {
  const longestSize = Math.max(
    imgRef.current.naturalWidth,
    imgRef.current.naturalHeight,
  );

  let scalar = 1;

  if (longestSize > maxSize) {
    scalar = longestSize / maxSize;
  }

  const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
  const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = Math.ceil(crop.width * scaleX) / scalar;
  tmpCanvas.height = Math.ceil(crop.height * scaleY) / scalar;

  const ctx = tmpCanvas.getContext('2d');
  const image = imgRef.current;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    (crop.width * scaleX) / scalar,
    (crop.height * scaleY) / scalar,
  );

  return tmpCanvas;
}

export function generateFile(previewCanvas, crop, imgRef, transparent) {
  return new Promise(async (resolve, reject) => {
    if (!crop || !previewCanvas) {
      return;
    }

    let newCrop = { ...crop };

    if (crop.width === 0 || crop.height === 0) {
      newCrop.width = imgRef.current.width;
      newCrop.height = imgRef.current.height;
      newCrop.x = 0;
      newCrop.y = 0;
    }

    const extension = transparent ? 'png' : hasWebpSupport() ? 'webp' : 'jpeg';

    const canvas = getResizedCanvas(imgRef, newCrop);
    canvas.toBlob(
      (blob) => {
        resolve(new File([blob], `REPLACE_ME.${extension}`));
      },
      `image/${extension}`,
      0.4,
    );
  });
}

export default function ImageCropper({
  onSave,
  ratio,
  transparent,
  description,
}) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [cropping, setCropping] = useState(false);
  const [upImg, setUpImg] = useState();
  const [loading, setLoading] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: ratio,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setLoading(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    setLoading(false);
    setCropping(true);
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);

  return (
    <div className="App">
      {!cropping && (
        <div className={styles.modalButtonContainer}>
          <label>
            <Text
              noMargin
              weight="semiBold"
              className={styles.fileUploadButton}
            >
              <input
                className={styles.hide}
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={onSelectFile}
              />
              {t(keys.action.SELECT_VARIABLE, {
                variable: t(keys.common.FILE),
              })}
            </Text>
          </label>
          <Text noMargin>
            {t(keys.action.SELECTED, { variable: t(keys.common.NONE) })}
          </Text>
        </div>
      )}
      <div className={styles.reactCropContainer}>
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
          className={styles.reactCrop}
        />
      </div>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <Spinner
            style={{ width: '3rem', height: '3rem' }}
            animation="border"
            variant="primary"
          />
        </div>
      ) : null}
      <hr />
      <Button
        testId="image"
        className={styles.saveButton}
        disabled={loading || !previewCanvasRef.current}
        value={t(keys.action.SAVE)}
        onClick={() => {
          generateFile(
            previewCanvasRef.current,
            crop,
            imgRef,
            transparent,
          ).then((file) => {
            onSave(file, description);
          });
        }}
      />

      <canvas
        ref={previewCanvasRef}
        style={{
          width: completedCrop?.width ?? 0,
          height: completedCrop?.height ?? 0,
          display: 'none',
        }}
      />
    </div>
  );
}
