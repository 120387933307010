import classNames from 'classnames';
import { Icon } from '@mui/material';
import { Text } from '../typography';
import Button from '../Button';
import styles from './DailyReportModal.module.scss';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { closeDailyReportModal } from '../../graphql/cache/modal';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineContent,
  TimelineSeparator,
  Timeline,
} from '@mui/lab';
import { colors } from '../../constants/misc';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DailyReportDocument } from '../../utilities/pdf_export/daily_report';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { mapDailyReportData } from '../../utilities/daily_report';
import { useCurrentUser } from '../../providers/UserProvider';

const dataQuery = loader('./DailyReportModal.graphql');

function DailyReportModal({ show = false }) {
  const navigate = useNavigate();
  const [date, setDate] = useState(new moment());
  const { user } = useCurrentUser();

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: new moment(date).startOf('day'),
      endDate: new moment(date).endOf('day'),
    };
  }, [date]);

  const { data = {} } = useQuery(dataQuery, {
    skip: !show || !date || !user?.id,
    variables: {
      startDate,
      endDate,
      userId: `${user?.id}`,
      options: {
        filters: [
          {
            field: 'endTime',
            operator: 'between',
            value: [startDate, endDate],
          },
          {
            field: 'creatorId',
            operator: 'eq',
            value: `${user?.id}`,
          },
        ],
      },
    },
  });

  const history = useMemo(() => {
    return mapDailyReportData(data);
  }, [data]);

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.onlineContainer}>
            <div className={styles.headerContainer}>
              <div>
                <Text noMargin size="lg" color="accentPrimary" weight="bold">
                  Daily Log
                </Text>
                <Text size="sm" weight="semiBold">
                  Your Daily Work at a Glance
                </Text>
              </div>
              <Icon
                baseClassName="material-icons-outlined"
                sx={{ fontSize: '1.5rem' }}
                className={styles.closeIcon}
                onClick={() => closeDailyReportModal()}
              >
                close
              </Icon>
            </div>
            <div>
              <br />
              <Text size="md" weight="bold">
                Date
              </Text>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={styles.dateRange}>
                  <DatePicker
                    value={date}
                    label="Day"
                    onChange={(newMoment) => {
                      setDate(newMoment);
                    }}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <div className={styles.timelineContainer}>
              {history.length ? (
                <Timeline position="alternate">
                  {history.map((e, index) => (
                    <TimelineItem
                      className={styles.clickable}
                      key={index}
                      onClick={() => {
                        navigate(e.miniUrl);
                        closeDailyReportModal();
                      }}
                    >
                      <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                      >
                        <Text
                          textAlign={index % 2 === 1 ? 'left' : 'right'}
                          size="md"
                          weight="semiBold"
                          color="secondary"
                          noMargin
                        >
                          {e.time}
                        </Text>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot sx={{ backgroundColor: colors[e.color] }}>
                          <Icon>{e.icon}</Icon>
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Text
                          textAlign={index % 2 === 0 ? 'left' : 'right'}
                          size="md"
                          weight="semiBold"
                          noMargin
                        >
                          {e.title}
                        </Text>
                        <Text
                          textAlign={index % 2 === 0 ? 'left' : 'right'}
                          size="sm"
                          noMargin
                        >
                          {e.description}
                        </Text>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              ) : (
                <div>
                  <img
                    className={styles.emptyStateGraphic}
                    src="https://opas-public.s3.us-east-2.amazonaws.com/webapp-assets/tasklist.png"
                    alt="Task List"
                  />
                  <br />
                  <Text size="lg" weight="bold" textAlign="center">
                    You have no recorded history for this date.
                  </Text>
                  <Text
                    size="md"
                    weight="semiBold"
                    color="secondary"
                    textAlign="center"
                  >
                    Go make today productive!
                  </Text>
                </div>
              )}
            </div>
            {user?.id && !!history.length && (
              <PDFDownloadLink
                document={
                  <DailyReportDocument
                    user={user}
                    events={history}
                    date={date.format('MM-DD-YYYY')}
                  />
                }
                fileName={`Daily_Report_${date.format('MM-DD-YYYY')}`}
              >
                {({ loading, url }) =>
                  loading || !url ? (
                    <Spinner
                      className={styles.spinner}
                      animation="border"
                      variant="primary"
                    />
                  ) : (
                    <Button
                      className={styles.continueButton}
                      variant="primary"
                      icon="download"
                      value="Download"
                    />
                  )
                }
              </PDFDownloadLink>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default DailyReportModal;
