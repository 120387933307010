import React from 'react';
import styles from './CustomSpinner.module.scss';
import logo from '../assets/logo_chain.svg';
import { Spinner } from 'react-bootstrap';
import { Text } from './typography';

export default function CustomSpinner({ text }) {
  return (
    <>
      <div>
        <img alt="loading icon" className={styles.logo} src={logo} />
        <Spinner className={styles.spinner} animation="border" />
      </div>
      <Text className={styles.text}>{text}</Text>
    </>
  );
}
