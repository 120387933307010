import React, { useState, useMemo } from 'react';
import { useEffect } from 'react';
import styles from './OptionsSelector.module.scss';
import { Text } from './typography';
import { Icon } from '@mui/material';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import emptyStateGraphic from '../assets/empty_state_images/templates.svg';
import { loader } from 'graphql.macro';
import Searchbar from './Searchbar';
import { useQuery } from '@apollo/client';
import { pollInterval } from '../constants/misc';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';

const optionSetQuery = loader('./OptionsSelector.query.graphql');

export default function OptionsSelector({
  selected,
  onSelectionChanged,
  type,
  className,
}) {
  const { data: { optionSets = [] } = {} } = useQuery(optionSetQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: pollInterval.sm,
  });
  const { t } = useTranslation();
  const options = `${t(keys.common.OPTIONS)}`;
  const [search, setSearch] = useState('');
  const [addNewOptions, setAddNewOptions] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [header, setHeader] = useState(options);

  useEffect(() => {
    setSearch('');
    setHeader(options);
    setAddNewOptions(false);
  }, [modalOpen, options]);

  const filteredOptions = useMemo(() => {
    return search?.length
      ? optionSets.filter(
          (opt) =>
            opt.title.toLowerCase().includes(search.toLowerCase()) ||
            opt.options
              .map((o) => o.text)
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()),
        )
      : optionSets;
  }, [optionSets, search]);
  return (
    <div className={className}>
      {!!selected ? (
        <div
          className={styles.optionsDisplayContainer}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <div className={styles.selectedOptions}>
            <Text
              size="md"
              weight="bold"
              color="accentPrimary"
              noSelect
              noMargin
            >
              {`${selected.title}`}
            </Text>
            <Text
              size="sm"
              weight="regular"
              noSelect
              noMargin
              color="secondary"
            >
              {`${selected.options.map((o) => o.text)} `}
            </Text>
          </div>
          <Icon
            className={styles.deselectIcon}
            fontSize="inherit"
            baseClassName="material-icons-outlined"
            onClick={(e) => {
              e.stopPropagation();
              onSelectionChanged(null);
            }}
          >
            close
          </Icon>
        </div>
      ) : (
        <div
          className={styles.buttonContainer}
          onClick={() => setModalOpen(true)}
        >
          <Icon fontSize="inherit" baseClassName="material-icons-outlined">
            list
          </Icon>
          <Text size="md" noSelect weight="semiBold" color="secondary" noMargin>
            {t(keys.templates.SELECT_OPTIONS)}
          </Text>
        </div>
      )}
      {modalOpen && (
        <div className={styles.background}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <Icon
                className={styles.hiddenIcon}
                fontSize="inherit"
                baseClassName="material-icons-outlined"
              >
                close
              </Icon>
              <Text
                size="md"
                weight="semiBold"
                color="secondary"
                noSelect
                noMargin
              >
                {header}
              </Text>

              <Icon
                className={styles.closeIcon}
                fontSize="inherit"
                baseClassName="material-icons-outlined"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                close
              </Icon>
            </div>
            <hr />

            {addNewOptions ? (
              <MultipleChoiceQuestion
                setModalOpen={setModalOpen}
                setHeader={setHeader}
                type={type}
              />
            ) : (
              <div className={styles.modalHeader}>
                <Searchbar
                  value={search}
                  onChange={(newValue) => setSearch(newValue)}
                />
                <Text
                  onClick={() => {
                    setAddNewOptions(true);
                  }}
                  className={styles.createButton}
                  color="accentPrimary"
                  noMargin
                >
                  {t(keys.action.CREATE)}
                </Text>
              </div>
            )}

            <div
              className={
                addNewOptions ? styles.hide : styles.optionsListContainer
              }
            >
              <br />
              {filteredOptions?.length ? (
                filteredOptions?.map((opt) => (
                  <div
                    key={opt.id}
                    className={styles.optionsContainer}
                    onClick={(e) => {
                      onSelectionChanged(opt);
                      setModalOpen(false);
                    }}
                  >
                    <Text
                      size="md"
                      weight="semiBold"
                      color="accentPrimary"
                      noSelect
                      noMargin
                    >
                      {`${opt.title}`}
                    </Text>
                    <Text
                      size="sm"
                      noMargin
                      color="secondary"
                    >{`${opt.options.map((o) => o.text)} `}</Text>
                  </div>
                ))
              ) : (
                <div className={styles.emptyStateContainer}>
                  <img
                    src={emptyStateGraphic}
                    className={styles.emptyStateGraphic}
                    alt="no options"
                  />

                  <br />
                  <Text
                    color="secondary"
                    noMargin
                    textAlign="center"
                    size="md"
                    weight="semibold"
                  >
                    {t(keys.templates.OPTION_SET_TITLE)}
                  </Text>
                  <Text color="secondary" textAlign="center" size="sm">
                    {t(keys.templates.OPTION_SET_SUBTEXT)}
                  </Text>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
