import styles from './NoAccess.module.scss';
import { Text } from '../components/typography';
import { Button } from '../components';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../providers/ModalProvider';
import { modals } from '../providers/modals';
import { useWorkspace } from '../providers/WorkspaceProvider';
import { useCurrentUser } from '../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';

const noAccessImage = 'https://app.opasmobile.com/assets/graphics/barrier.png';

export default function NoAccess({ children }) {
  const { validUrl, availableWorkspaces, isWorkspaceRoute } = useWorkspace();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const available = availableWorkspaces?.map((a) => a.id);
  return !validUrl && isWorkspaceRoute ? (
    <div className={styles.container}>
      <img className={styles.image} alt="no-access" src={noAccessImage} />
      <Text textAlign="center" size="xl" weight="bold">
        {t(keys.utilities.NO_ACCESS_TITLE)}
      </Text>
      <div className={styles.content}>
        <Text textAlign="center" size="lg" weight="semiBold" color="secondary">
          {t(keys.utilities.NO_ACCESS_DESCRIPTION)}
        </Text>
        {available?.some((id) => id === user.lastWorkspaceId) && (
          <Text textAlign="center" size="md" color="secondary">
            {t(keys.utilities.NO_ACCESS_REDIRECT)}
            <span className={styles.link} onClick={() => navigate(`/`)}>
              {t(keys.dashboard.DASHBOARD)}
            </span>
            {t(keys.utilities.NO_ACCESS_CONTACT)}
          </Text>
        )}
        <Button
          className={styles.button}
          onClick={() =>
            openModal({
              modalName: modals.noWorkspace,
            })
          }
          value={t(keys.utilities.VIEW_WORKSPACES)}
        />
      </div>
    </div>
  ) : (
    <>{children}</>
  );
}
