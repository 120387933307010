import styles from './SimpleFileCard.module.scss';
import { downloadFile } from '../../utilities/inspection';
import findFileIcon from '../../utilities/files';
import { Text } from '../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import Icon from '../Icon';
import { openFileViewer } from '../../graphql/cache/modal';
import { imageExtensions } from '../../utilities/files';
import { useModal } from '../../providers/ModalProvider';

export default function SimpleFileCard({
  extension,
  file,
  fileName,
  readOnly = true,
  onDelete,
}) {
  const { t } = useTranslation();
  const url = file?.url ? file.url : file.imageUrl;
  const src = imageExtensions.includes(extension)
    ? url
    : findFileIcon(extension);
  const { openConfirmationModal } = useModal();

  return (
    <div className={styles.fileDisplay}>
      <div
        onClick={() => {
          openFileViewer(file);
        }}
        className={styles.file}
      >
        <img className={styles.fileImage} src={src} alt="file" />
        <Text weight="semiBold" noMargin size="sm" truncate textAlign="center">
          {fileName ??
            `${extension.toUpperCase()} ${t(keys.assessments.ATTACHMENT)}`}
        </Text>
      </div>
      <div className={styles.buttonContainer}>
        <Icon
          hover
          style={{ fontSize: '1.2rem' }}
          color={'primary'}
          className={styles.icon}
          onClick={() => {
            openConfirmationModal({
              title: t(keys.action.DOWNLOAD_VARIABLE, {
                variable: fileName,
              }),
              description: t(keys.action.DOWNLOAD_CONFIRMATION, {
                variable: fileName,
              }),
              variant: 'warning',
              onSubmit: () => {
                downloadFile(url, fileName);
              },
            });
          }}
        >
          download
        </Icon>
        {!readOnly && (
          <Icon
            onClick={onDelete}
            style={{ fontSize: '1.2rem' }}
            hover
            color="red"
          >
            delete
          </Icon>
        )}
      </div>
    </div>
  );
}
