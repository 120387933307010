import styles from './CreateVehicleMaintenance.module.scss';
import { loader } from 'graphql.macro';
import { Text } from '../../components/typography';
import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useState, useEffect } from 'react';
import { vehicleKeys, assetStatus } from '../../utilities/vehicles';
import { Row, Form, Col } from 'react-bootstrap';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import WorkflowStageFooter from '../../components/workflows/WorkflowStageFooter';
import UserSelector from '../../components/UserSelector';
import { DropdownText } from '../../components/dropdowns/DropdownText';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import WorkflowStageHeader from '../../components/workflows/WorkflowStageHeader';

import moment from 'moment';
import { getLocalTime } from '../../utilities/time';

const updateVehicleMutation = loader('./CreateVehicleInfo.update.graphql');
const fetchVehicleQuery = loader('./CreateVehicleMaintenance.fetch.graphql');

export default function CreateVehicleMaintenance({
  onCompleted,
  currentStage,
  vehicleId,
  onBack,
}) {
  const [updateVehicle] = useMutation(updateVehicleMutation);
  const [assignee, setAssignee] = useState('');

  const { t } = useTranslation();

  const { data } = useQuery(fetchVehicleQuery, {
    skip: !vehicleId,
    variables: {
      vehicleId: `${vehicleId}`,
    },
  });
  useEffect(() => {
    if (data?.vehicles?.length) {
      setVehicle({ ...data.vehicles[0] });
    }
  }, [data]);

  const [vehicle, setVehicle] = useState();
  const dataType = useMemo(
    () => (vehicleKeys.includes(vehicle?.type) ? 'vehicle' : 'asset'),
    [vehicle?.type],
  );

  return !vehicle?.id ? (
    <></>
  ) : (
    <div className={styles.container}>
      <div>
        <WorkflowStageHeader
          title={t(keys.assets.MAINTENANCE_INFO)}
          subtitle={t(keys.assets.MAINTENANCE_SUBHEADER)}
        />
        {dataType === 'vehicle' && (
          <Row className="mb-3 flex-md-row flex-column align-items-center">
            <Form.Group as={Col}>
              <Text noMargin weight="semiBold" size="md">
                {t(keys.assets.ODOMETER)}
              </Text>
              <div className={styles.spacer} />
              <Form.Control
                type="number"
                data-cy="input-odometer"
                placeholder="12010, 240000, etc."
                value={vehicle?.odometer || undefined}
                onChange={(e) =>
                  setVehicle({
                    ...vehicle,
                    odometer: parseInt(e.target.value, 10),
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Text noMargin weight="semiBold" size="md">
                {t(keys.assets.UNITS)}
              </Text>
              <div className={styles.spacer} />
              <Form.Select
                data-cy="input-odometer"
                onChange={(option) =>
                  setVehicle({
                    ...vehicle,
                    isMetric:
                      option.target.value === 'kilometers' ? true : false,
                  })
                }
              >
                <option value="miles">{t(keys.assets.MILES)}</option>
                <option value="kilometers">{t(keys.assets.KILOMETERS)}</option>
              </Form.Select>
            </Form.Group>
          </Row>
        )}
        <Row className="mb-3 flex-md-row flex-column align-items-center">
          <Form.Group as={Col}>
            <Text noMargin weight="semiBold" size="md">
              {t(keys.assets.OPERATING_HOURS)}
            </Text>
            <div className={styles.spacer} />
            <Form.Control
              type="number"
              placeholder={t(keys.assets.HOURS_PLACEHOLDER)}
              onChange={(e) =>
                setVehicle({
                  ...vehicle,
                  hours: parseFloat(e.target.value),
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Text noMargin weight="semiBold" size="md">
              {t(keys.assets.END_OF_LIFE_HOURS)}
            </Text>
            <div className={styles.spacer} />
            <Form.Control
              type="number"
              placeholder={t(keys.assets.END_OF_LIFE_PLACEHOLDER)}
              onChange={(e) =>
                setVehicle({
                  ...vehicle,
                  endOfLifeHours: Math.ceil(parseInt(e.target.value)),
                })
              }
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 flex-md-row flex-column align-items-center">
          <Form.Group as={Col} className={styles.dateContainer}>
            <Text
              size="md"
              weight="semiBold"
              noSelect
              noMargin
              className={styles.subtitle}
            >
              {t(keys.assets.LAST_INSPECTION)}
            </Text>
            <div className={styles.spacer} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                slotProps={{
                  textField: {
                    error: false,
                    InputProps: {
                      style: {
                        height: '2.5rem',
                        backgroundColor: 'white',
                        width: '100%',
                      },
                    },
                  },
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                sx={{ width: '100%' }}
                maxDate={moment()}
                value={getLocalTime(parseInt(vehicle.lastInspectionDate))}
                onChange={(newMoment) => {
                  setVehicle({
                    ...vehicle,
                    lastInspectionDate: newMoment,
                  });
                }}
              />
            </LocalizationProvider>
          </Form.Group>
          <Form.Group as={Col}>
            <Text size="md" weight="semiBold" noSelect noMargin>
              {t(keys.assets.END_OF_LIFE_DATE)}
            </Text>
            <div className={styles.spacer} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                slotProps={{
                  textField: {
                    error: false,
                    InputProps: {
                      style: {
                        height: '2.5rem',
                        backgroundColor: 'white',
                        width: '100%',
                      },
                    },
                  },
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                sx={{ width: '100%' }}
                value={moment(vehicle.endOfLife)}
                onChange={(newMoment) => {
                  setVehicle({
                    ...vehicle,
                    endOfLife: newMoment,
                  });
                }}
              />
            </LocalizationProvider>
          </Form.Group>
        </Row>
        <Row className="mb-3 flex-md-row flex-column align-items-center">
          <Form.Group as={Col}>
            <DropdownText
              namespace="assets"
              className={styles.noMargin}
              items={assetStatus}
              title={t(keys.common.STATUS)}
              selected={vehicle.status}
              onChange={(option) =>
                setVehicle({
                  ...vehicle,
                  status: option,
                })
              }
              onRemove={() =>
                setVehicle({
                  ...vehicle,
                  status: '',
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Text size="md" weight="semiBold" color="primary" noMargin>
              {t(keys.common.ASSIGNEE)}
            </Text>
            <div className={styles.spacer} />
            <UserSelector
              className={styles.avatarBox}
              type="assignee"
              selected={assignee}
              onClose={() => {
                setVehicle({ ...vehicle, assigneeId: '' });
                setAssignee('');
              }}
              onSelectionChanged={(user) => {
                setVehicle({
                  ...vehicle,
                  assigneeId: user ? user.id : null,
                });
                setAssignee(user);
              }}
            />
          </Form.Group>
        </Row>
        {/* <div className={styles.check}>
          <Form.Check />
          <Text noMargin>{t(keys.assets.UPDATES)}</Text>
        </div> 
        //FOR NOTIFICATIONS DOWN THE ROAD
        */}
        <br />
      </div>
      <div className={styles.footer}>
        <WorkflowStageFooter
          onNext={() => {
            updateVehicle({
              variables: {
                id: vehicleId,
                ...vehicle,
              },
            }).then(() => onCompleted(currentStage));
          }}
          onBack={onBack}
        />
      </div>
    </div>
  );
}
