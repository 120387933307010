/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import styles from './Graph.module.scss';
import { Text } from '../../components/typography';
import { Icon } from '@mui/material';
import CalendarChart from './CalendarChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import BarChart from './BarChart';
import classNames from 'classnames';
import { useApolloClient, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import moment from 'moment';
import { primaryGroupings } from './utilities';
import ContextMenu from '../../components/ContextMenu';
import { showToast } from '../../graphql/cache/modal';
import { Spinner } from 'react-bootstrap';
import { toastLength, toastVariant } from '../../constants/misc';
import { exportToCSV } from '../../components/excel/ExcelExport';
import { getLocalTime } from '../../utilities/time';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useModal } from '../../providers/ModalProvider';

const eventQuery = loader('./Graph.graphql');
const deleteGraphMutation = loader('./Graph.delete.graphql');

const PAGE_SIZE = 2_500;
const csvHeaderMap = {};

const today = new Date();

export default function Graph({
  id,
  title = '',
  description = '',
  range = 7,
  graphType,
  action,
  group,
  className,
  filterValue,
  filterOperator,
  filterType = 'None',
  onLoaded = () => {},
}) {
  const { t } = useTranslation();
  const REQUEST_THROTTLE = 500 + Math.random() * 2000;
  const { openConfirmationModal } = useModal();

  const [events, setEvents] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isAllDataFetched, setIsAllDataFetched] = useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [deleteGraph] = useMutation(deleteGraphMutation, {
    refetchQueries: ['GetBoard'],
  });

  const client = useApolloClient();

  const startDate = useMemo(() => moment().subtract(range, 'Days'), [range]);

  let currentPage = 1;

  const filter = useMemo(() => {
    const splitFilterValue =
      typeof filterValue === 'string'
        ? filterValue?.split('|').map((v) => ({
            value: v,
          }))
        : filterValue;
    if (filterType === 'None' || !splitFilterValue?.length) {
      return null;
    }
    const rawFilters = splitFilterValue.map((f) => f.value);
    const isSubfilter = !primaryGroupings.some(
      ({ key }) => key.toLocaleLowerCase() === filterType.toLocaleLowerCase(),
    );
    let rawFiltersArray;
    if (
      filterType === 'name' ||
      filterType.toLocaleLowerCase() === 'workspace'
    ) {
      rawFiltersArray = rawFilters.map((r) => `${r}`);
    } else {
      rawFiltersArray = Array.isArray(rawFilters) ? rawFilters : [rawFilters];
    }

    const isSingle = rawFiltersArray.length === 1;
    const operator =
      filterOperator === 'Is'
        ? isSingle
          ? 'eq'
          : 'in'
        : isSingle
        ? 'neq'
        : 'notIn';

    let field;

    // Note: this will break for any workspaces or users with pure numerical names, but come on...
    const isIdField =
      (rawFiltersArray?.length && !isNaN(rawFiltersArray[0])) ||
      rawFiltersArray[0] === 'null';

    switch (filterType.toLocaleLowerCase()) {
      case 'name':
        field = isIdField ? 'userId' : 'name';
        break;
      case 'workspace':
        field = isIdField ? 'workspaceId' : 'workspace';
        break;
      default:
        field = isSubfilter ? `information.${filterType}` : filterType;
        break;
    }

    return rawFilters && operator && field
      ? {
          field,
          operator: operator,
          value: rawFiltersArray,
        }
      : null;
  }, [filterOperator, filterType, filterValue]);

  const fetchEvents = async () => {
    setIsFetching(true);
    try {
      const { data } = await client.query({
        query: eventQuery,
        variables: {
          eventType: action,
          startDate,
          endDate: today,
          page: currentPage,
          pageSize: PAGE_SIZE,

          filters: [
            { field: 'eventType', operator: 'eq', value: [action] },
            {
              field: 'dateCreated',
              operator: 'between',
              value: [startDate, today],
            },
            ...(filter ? [filter] : []),
          ],
        },
        fetchPolicy: 'no-cache',
      });

      if (data && data.events.length > 0) {
        setEvents((prevEvents) => [...prevEvents, ...data.events]);
        if (data.events.length < PAGE_SIZE) {
          setIsAllDataFetched(true);
          setIsFetching(false);
        } else {
          currentPage++;
          setTimeout(fetchEvents, REQUEST_THROTTLE);
        }
      } else {
        setIsAllDataFetched(true);
        setIsFetching(false);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      setIsAllDataFetched(true);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    setEvents([]);
    currentPage = 0;
    setIsAllDataFetched(false);
    fetchEvents();
  }, [action, startDate, filter]);

  useEffect(() => {
    if (!isFetching && isAllDataFetched) {
      setTimeout(() => {
        onLoaded();
      }, 1500);
    }
  }, [isFetching, isAllDataFetched]);

  return (
    <div className={classNames(styles.container, className)}>
      {isFetching && !isAllDataFetched ? (
        <div className={styles.loading}>
          <div className={styles.loadingState}>
            <img
              className={styles.loadingGraphicCoffee}
              src="https://app.opasmobile.com/assets/graphics/graph_loading_sm.png"
              alt="loading"
            />
            <Spinner variant="primary" />
            <Text
              textAlign="center"
              weight="semiBold"
              color="secondary"
              noMargin
            >
              {t(keys.boards.GRAPH_GET_READY)}
            </Text>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.leftHeader}>
              <Text noMargin size="md" weight="semiBold">
                {title}
              </Text>
            </div>
            <div className={styles.rightHeader}>
              {!!id && (
                <Icon
                  onClick={() => setContextMenuOpen(!contextMenuOpen)}
                  className={styles.icon}
                  sx={{ fontSize: '1.25rem' }}
                >
                  more_horiz
                </Icon>
              )}
              <ContextMenu
                className={styles.contextMenu}
                open={contextMenuOpen}
                setContextMenuOpen={setContextMenuOpen}
                options={[
                  {
                    title: t(keys.action.EXPORT, { variable: 'CSV' }),
                    icon: 'download',
                    onClick: () => {
                      if (events?.length < 1) {
                        showToast({
                          title: t(keys.boards.NO_EXPORT_TITLE),
                          message: t(keys.boards.NO_EXPORT_MESSAGE),
                          variant: toastVariant.info,
                          time: toastLength.md,
                        });
                        return;
                      }
                      const today = new moment().format('MM-DD-YYYY');
                      const start = new moment()
                        .subtract(range, 'days')
                        .format('MM-DD-YYYY');

                      const sanitizedTitle = title.replace(
                        /[^a-zA-Z0-9_\-.]/g,
                        '_',
                      );

                      exportToCSV({
                        data: events.map((e) => ({
                          ...e,
                          dateCreated: getLocalTime(e.dateCreated).format(
                            'yyyy-MM-DD',
                          ),
                          information: JSON.stringify(e.information),
                        })),
                        sheetName: `${sanitizedTitle}_${start}-${today}`,
                        headerMap: csvHeaderMap,
                      });
                    },
                  },
                  {
                    title: t(keys.action.DELETE),
                    icon: 'delete',
                    variant: 'red',
                    onClick: () => {
                      openConfirmationModal({
                        title: t(keys.action.DELETE_VARIABLE, {
                          variable: title,
                        }),
                        description: t(keys.action.DELETE_CONFIRMATION, {
                          variable: title,
                        }),
                        variant: 'danger',
                        buttonText: t(keys.action.DELETE),
                        onSubmit: () => {
                          deleteGraph({ variables: { id: parseInt(id, 10) } });
                        },
                      });
                    },
                  },
                ]}
                setOpen={setContextMenuOpen}
              />
              <Text
                className={styles.rightText}
                size="sm"
                weight="semiBold"
                color="secondary"
              >
                {t(keys.boards.TOTAL_RANGE, { variable: range })}
              </Text>
            </div>
          </div>
          <Text size="sm" weight="semiBold" color="secondary">
            {description}
          </Text>
          {events?.length > 0 ? (
            <div className={styles.graph}>
              {graphType === 'Calendar' && <CalendarChart events={events} />}
              {graphType === 'Pie' && (
                <PieChart group={group} events={events} />
              )}
              {graphType === 'Line' && (
                <LineChart events={events} group={group} zeroFillDays={range} />
              )}
              {graphType === 'Bar' && (
                <BarChart events={events} group={group} />
              )}
            </div>
          ) : (
            <div className={styles.emptyStateParent}>
              <img
                className={styles.emptyStateGraphic}
                alt="Graphs"
                src="https://app.opasmobile.com/assets/graphics/empty_data_es.png"
              />
              <div className={styles.emptyStateContainer}>
                <Text textAlign="center" size="lg" weight="semiBold">
                  {t(keys.boards.EMPTY_STATE_GRAPH_HEADER)}
                </Text>
                <Text
                  textAlign="center"
                  size="md"
                  weight="semiBold"
                  color="secondary"
                >
                  {t(keys.boards.EMPTY_STATE_GRAPH_MESSAGE)}
                </Text>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
