import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Spinner, Form, Alert } from 'react-bootstrap';
import styles from './WorkspaceDetailsPage.module.scss';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider';
import { Text } from '../../components/typography';
import { Icon } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import SimpleUserCard from '../../components/SimpleUserCard';
import { Button } from '../../components';
import { DropdownText } from '../../components/dropdowns/DropdownText';
import {
  workspaceDateSelectors,
  workspaceInputOptions,
  workspacePhaseOptions,
} from '../../utilities';
import ImageCropModal from '../../components/image_uploads/ImageCropModal';
import axios from 'axios';
import { useApolloClient } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { CF_DOMAIN } from '../../constants/aws';
import VehicleCardList from '../../components/vehicles/VehicleCardList';
import workspaceImage from '../../assets/workspace_image.png';
import UserMultiSelector from '../../components/UserMultiSelector';
import SingleSelect from '../../components/forms/SingleSelect';
import DateSelector from '../../components/DateSelector';
import Searchbar from '../../components/Searchbar';
import ActivityConverter from '../../components/activities/ActivityConverter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AddAssetModal from '../../components/modals/AddAssetModal';
import moment from 'moment';
import { useCurrentUser } from '../../providers/UserProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useTranslation } from 'react-i18next';
import {
  keys,
  getTranslationKey,
} from '../../utilities/translator/translation_keys';
import DropdownCombo from '../../components/dropdowns/DropdownCombo';
import { dropdownTypes } from '../../components/dropdowns/dropdown';

const updateWorkspaceMutation = loader(
  './WorkspaceDetailsPage.updateWorkspace.graphql',
);
const deleteWorkspaceMutation = loader(
  './WorkspaceDetailsPage.deleteWorkspace.graphql',
);
const uploadUrlQuery = loader('../../graphql/queries/s3.graphql');
const removeUserMutation = loader('./WorkspaceDetailsPage.removeUser.graphql');
const addWorkspaceUserMutation = loader(
  './WorkspaceDetailsPage.addUser.graphql',
);

const removeAssetFromWorkspaceMutation = loader(
  './WorkspaceDetailsPage.removeAsset.graphql',
);
const getAllVehiclesQuery = loader(
  './WorkspaceDetailsPage.allVehicles.graphql',
);
const addAssetToWorkspaceMutation = loader(
  './WorkspaceDetailsPage.addAsset.graphql',
);

const templateQuery = loader('./WorkspaceDetailsPage.templates.graphql');

export default function WorkspaceDetailsPage() {
  const { user: currentUser, isAdmin } = useCurrentUser();
  const {
    workspace,
    isWorkspaceAdmin,
    loading,
    refetchWorkspace,
    workspaceVehicles,
    observationTemplate,
  } = useWorkspace();
  const { openModal, openConfirmationModal } = useModal();
  const [state, setState] = useState(workspace || null);
  const [logoModalOpen, setLogoModalOpen] = useState(false);
  const { enableActivities, enableExpenses } = useFlags();
  const [updateWorkspace] = useMutation(updateWorkspaceMutation);
  const client = useApolloClient();
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [search, setSearch] = useState('');
  const [assetModalOpen, setAssetModalOpen] = useState(false);
  const [addUser] = useMutation(addWorkspaceUserMutation);
  const [removeUser] = useMutation(removeUserMutation);
  const [deleteWorkspace] = useMutation(deleteWorkspaceMutation);
  const [addAssetToWorkspace] = useMutation(addAssetToWorkspaceMutation);

  const { data: { vehicles = [] } = {} } = useQuery(getAllVehiclesQuery, {
    skip: !assetModalOpen,
  });
  const { data: { inspectionTemplates = [] } = {} } = useQuery(templateQuery, {
    variables: {
      type: 'OBSERVATION',
    },
  });
  const [removeAssetFromWorkspace] = useMutation(
    removeAssetFromWorkspaceMutation,
  );
  const { workspaceUsers, workspaceAdmins, refetchPermissions } =
    useWorkspacePermissions();

  const { t } = useTranslation();
  useEffect(() => {
    setState(workspace);
  }, [workspace, loading]);

  const { creator } = workspace || {};

  const filteredVehicles = useMemo(() => {
    return workspaceVehicles?.length
      ? vehicles?.filter((l) => !workspaceVehicles?.some((m) => m.id === l.id))
      : vehicles;
  }, [vehicles, workspaceVehicles]);

  const filterVehiclesOnSearch = useMemo(() => {
    return workspaceVehicles?.length
      ? search?.length
        ? workspaceVehicles?.filter(
            (vehicle) =>
              vehicle.make.toLowerCase().includes(search.toLowerCase()) ||
              vehicle.model.toLowerCase().includes(search.toLowerCase()) ||
              vehicle.unitNumber.toLowerCase().includes(search.toLowerCase()),
          )
        : workspaceVehicles
      : null;
  }, [search, workspaceVehicles]);

  const leaveWorkspace = (userId) => {
    openConfirmationModal({
      title: t(keys.workspace.LEAVE_WORKSPACE),
      description: t(keys.workspace.LEAVE_WORKSPACE_DESCRIPTION, {
        variable: workspace.title,
      }),
      variant: 'danger',
      buttonText: t(keys.action.LEAVE, { variable: null }),
      onSubmit: () => {
        removeUser({
          variables: {
            id: workspace.id,
            userId: userId,
          },
        }).then(() => {
          refetchWorkspace().then(() => {
            openModal({
              modalName: modals.noWorkspace,
              variables: { allowClose: false },
            });
          });
        });
      },
    });
  };

  return !workspace || !state ? (
    <div className={styles.spinnerContainer}>
      <Spinner
        className={styles.spinner}
        animation="border"
        variant="primary"
      />
    </div>
  ) : (
    <div className={styles.page}>
      <Text weight="bold" color="accentPrimary" size="lg">
        {t(keys.workspace.WORKSPACE_SETTINGS)}
      </Text>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.header}>
            <div className={styles.leftHeader}>
              <Text noMargin weight="semiBold">
                {t(keys.common.TITLE)}
              </Text>

              <div className={styles.input}>
                {isWorkspaceAdmin ? (
                  <>
                    <Form.Control
                      type="text"
                      placeholder={t(keys.action.ENTER, {
                        variable: t(keys.common.TITLE),
                      })}
                      value={state?.title || ''}
                      onChange={(e) =>
                        setState({ ...state, title: e.target.value })
                      }
                    />
                    <Button
                      disabled={workspace?.title === state?.title}
                      value={t(keys.action.SAVE)}
                      onClick={() => {
                        updateWorkspace({
                          variables: {
                            id: workspace.id,
                            title: state?.title,
                          },
                        });
                      }}
                    />
                  </>
                ) : (
                  <Text>{workspace?.title}</Text>
                )}
              </div>
              <Text noMargin weight="semiBold">
                {t(keys.common.DESCRIPTION)}
              </Text>
              <div className={styles.input}>
                {isWorkspaceAdmin ? (
                  <>
                    <Form.Control
                      as="textarea"
                      placeholder={t(keys.action.ENTER, {
                        variable: t(keys.common.DESCRIPTION),
                      })}
                      value={state?.description || ''}
                      onChange={(e) =>
                        setState({ ...state, description: e.target.value })
                      }
                    />
                    <Button
                      disabled={state?.description === workspace.description}
                      value={t(keys.action.SAVE)}
                      onClick={() => {
                        updateWorkspace({
                          variables: {
                            id: workspace.id,
                            description: state?.description,
                          },
                        });
                      }}
                    />
                  </>
                ) : (
                  <Text>{workspace?.description ?? t(keys.common.NONE)}</Text>
                )}
              </div>
            </div>

            <div className={styles.rootLogoContainer}>
              <div className={styles.logoContainer}>
                <img
                  className={styles.logo}
                  src={workspace?.logoUrl ? workspace.logoUrl : workspaceImage}
                  alt="logo"
                />
                <div
                  className={
                    isWorkspaceAdmin ? styles.logoButtonContainer : styles.hide
                  }
                  onClick={() => setLogoModalOpen(!logoModalOpen)}
                >
                  <Icon
                    className={styles.logoEditButton}
                    baseClassName="material-icons-outlined"
                  >
                    edit
                  </Icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formComponent}>
            <Text noMargin weight="semiBold">
              {t(keys.common.STATUS)}
            </Text>
            <div className={styles.statusRadio}>
              {isWorkspaceAdmin && !!workspace?.id ? (
                <SingleSelect
                  namespace="workspace"
                  title="Status"
                  options={['Active', 'Inactive']}
                  value={workspace?.status}
                  onChange={(status) =>
                    updateWorkspace({
                      variables: {
                        id: workspace.id,
                        status: status,
                      },
                    })
                  }
                />
              ) : (
                <Text>{workspace?.status}</Text>
              )}
            </div>
          </div>
        </div>
        <div className={styles.templateDropdown}>
          <Text className={styles.subheader} weight="semiBold">
            {t(keys.observations.OBSERVATION_TEMPLATE)}
          </Text>
          {isAdmin || isWorkspaceAdmin ? (
            <DropdownCombo
              items={inspectionTemplates}
              type={dropdownTypes.TEMPLATE}
              preventStateChange
              selected={observationTemplate}
              onChange={(template) => {
                updateWorkspace({
                  variables: {
                    id: workspace?.id,
                    observationTemplateId: template?.id,
                  },
                }).then(() => refetchWorkspace());
              }}
              onRemove={() =>
                updateWorkspace({
                  variables: { id: workspace?.id, observationTemplateId: null },
                })
              }
            />
          ) : (
            <Text>{observationTemplate?.title ?? t(keys.common.NONE)}</Text>
          )}
        </div>
        <br />
        <div className={styles.workspaceDetails}>
          <div className={styles.header}>
            <Text size="lg" weight="semiBold" noMargin>
              {t(keys.workspace.WORKSPACE_DETAILS)}
            </Text>
            {!isEditingDetails && (
              <div className={!isWorkspaceAdmin ? styles.hide : undefined}>
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles.editIcon}
                  onClick={() => setIsEditingDetails(true)}
                >
                  edit
                </Icon>
              </div>
            )}
          </div>
          <br />
          <Alert show={isEditingDetails} variant="success">
            <Alert.Heading>{t(keys.workspace.EDITING_TITLE)}</Alert.Heading>
            <p>{t(keys.workspace.EDITING_MESSAGE)}</p>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                value={t(keys.action.CANCEL)}
                onClick={() => {
                  setState({
                    ...state,
                    ...workspace,
                  });
                  setIsEditingDetails(false);
                }}
                variant="secondary"
              />
              <Button
                value={t(keys.action.SAVE)}
                icon="save"
                onClick={() => {
                  updateWorkspace({
                    variables: {
                      id: workspace.id,
                      phase: state.phase,
                      scheduledStartDate: state.scheduledStartDate,
                      scheduledEndDate: state.scheduledEndDate,
                      jobId: state.jobId,
                      internalJobId: state.internalJobId,
                      jobCode: state.jobCode,
                      value: state.value,
                      isMetric: state.isMetric,
                      distanceRate: state.distanceRate,
                      accountingEmail: state.accountingEmail,
                    },
                  });
                  setIsEditingDetails(false);
                }}
                variant="success"
              />
            </div>
          </Alert>
          <div className={styles.formRow}>
            <div className={styles.formComponent}>
              {isEditingDetails ? (
                <DropdownText
                  items={workspacePhaseOptions}
                  title={t(keys.workspace.PROJECT_PHASE)}
                  selected={state?.phase}
                  onChange={(phase) => setState({ ...state, phase: phase })}
                  namespace="workspace"
                />
              ) : (
                <div className={styles.formComponent}>
                  <Text className={styles.subheader} noMargin weight="semiBold">
                    {t(keys.workspace.PROJECT_PHASE)}
                  </Text>
                  <Text>
                    {state?.phase
                      ? t(getTranslationKey(state.phase, 'workspace'))
                      : t(keys.common.NONE)}
                  </Text>
                </div>
              )}
            </div>
          </div>

          <div className={styles.formRow}>
            {workspaceDateSelectors.map((selector) => (
              <div key={selector.title} className={styles.formComponent}>
                <Text className={styles.subheader} noMargin weight="semiBold">
                  {t(selector.title)}
                </Text>
                {isEditingDetails ? (
                  <DateSelector
                    blockDefaultDate
                    value={
                      state[selector.query] ? moment(state[selector.query]) : ''
                    }
                    onChange={(date) =>
                      setState({
                        ...state,
                        [selector.query]: moment(date).format('YYYY-MM-DD'),
                      })
                    }
                  />
                ) : (
                  <Text>
                    {state[selector.query]
                      ? moment(state[selector.query]).format('MMM D, YYYY')
                      : t(keys.common.NONE)}
                  </Text>
                )}
              </div>
            ))}
          </div>
          <div className={styles.formRow}>
            {workspaceInputOptions.map((input) => (
              <div key={input.title} className={styles.formComponent}>
                <Text className={styles.subheader} noMargin weight="semiBold">
                  {t(input.title)}
                </Text>
                {isEditingDetails ? (
                  <Form.Control
                    type={input.query === 'value' ? 'number' : 'text'}
                    value={state[input.query] || ''}
                    placeholder={t(keys.action.ENTER, {
                      variable: t(input.title),
                    })}
                    onChange={(e) =>
                      setState({ ...state, [input.query]: e.target.value })
                    }
                  ></Form.Control>
                ) : (
                  <Text>
                    {state[input.query]
                      ? state[input.query]
                      : t(keys.common.NONE)}
                  </Text>
                )}
              </div>
            ))}
          </div>
          {!!enableExpenses && (
            <div className={styles.formRow}>
              <div className={styles.formComponent}>
                <Text className={styles.subheader} noMargin weight="semiBold">
                  {t(keys.workspace.MILEAGE_RATE)}
                </Text>
                <div className={styles.miles}>
                  <Form.Control
                    disabled={!isEditingDetails}
                    type="number"
                    value={state.distanceRate}
                    onChange={(e) =>
                      setState({
                        ...state,
                        distanceRate: parseFloat(e.target.value),
                      })
                    }
                  />
                  <Form.Select
                    disabled={!isEditingDetails}
                    value={state.isMetric ? 'kilometers' : 'miles'}
                    onChange={(e) => {
                      const isMetric = e.target.value === 'kilometers';
                      setState({ ...state, isMetric });
                    }}
                  >
                    <option value="kilometers">$/km</option>
                    <option value="miles">$/mi</option>
                  </Form.Select>
                </div>
              </div>
              <div className={styles.formComponent}>
                <Text className={styles.subheader} noMargin weight="semiBold">
                  {t(keys.workspace.ACCOUNTING_EMAIL)}
                </Text>
                {isEditingDetails ? (
                  <Form.Control
                    type="text"
                    value={state.accountingEmail}
                    onChange={(e) =>
                      setState({ ...state, accountingEmail: e.target.value })
                    }
                    placeholder={t(keys.action.ENTER, {
                      variable: t(keys.workspace.ACCOUNTING_EMAIL),
                    })}
                  />
                ) : (
                  <Text noMargin>
                    {state.accountingEmail || t(keys.common.NONE)}
                  </Text>
                )}
              </div>
            </div>
          )}

          <br />
        </div>
        <div className={styles.header}>
          <Text size="lg" weight="semiBold" noMargin>
            {t(keys.workspace.WORKSPACE_USERS)}
          </Text>
        </div>
        <div className={styles.creator}>
          <Text noMargin weight="semiBold">
            {t(keys.common.CREATOR)}
          </Text>
          <SimpleUserCard user={creator} size="md" />
        </div>
        <div className={styles.userContainer}>
          <br />
          <div className={styles.header}>
            <Text
              className={styles.subheader}
              noMargin
              noSelect
              weight="semiBold"
            >
              {t(keys.workspace.ADMINISTRATORS)}
            </Text>
          </div>
          <div className={styles.userContainer}>
            <UserMultiSelector
              readOnly={!isWorkspaceAdmin}
              selected={[
                { ...workspace.creator, readOnly: true },
                ...workspaceAdmins.filter((u) => u.id !== creator.id),
              ]}
              type="all"
              placeholder={t(keys.workspace.ADMINISTRATORS)}
              onUserAdded={(user) => {
                addUser({
                  variables: {
                    id: workspace.id,
                    userId: user.id,
                    accessLevel: 'ADMIN',
                  },
                }).then(() => refetchPermissions());
              }}
              onUserRemoved={(user) => {
                user.id === currentUser.id
                  ? leaveWorkspace(user.id)
                  : removeUser({
                      variables: {
                        id: workspace.id,
                        userId: user.id,
                      },
                    }).then(() => refetchPermissions());
              }}
            />
          </div>
          <br />
          <div className={styles.header}>
            <Text
              className={styles.subheader}
              noMargin
              noSelect
              weight="semiBold"
            >
              {t(keys.common.USERS)}
            </Text>
          </div>
          <div>
            <UserMultiSelector
              readOnly={!isWorkspaceAdmin}
              selected={workspaceUsers}
              type="all"
              onUserAdded={(user) => {
                addUser({
                  variables: {
                    id: workspace.id,
                    userId: user.id,
                    accessLevel: 'USER',
                  },
                }).then(() => refetchPermissions());
              }}
              onUserRemoved={(user) => {
                user.id === currentUser.id
                  ? leaveWorkspace(user.id)
                  : removeUser({
                      variables: {
                        id: workspace.id,
                        userId: user.id,
                      },
                    }).then(() => refetchPermissions());
              }}
            />
          </div>
        </div>
        <br />
        <div className={styles.header}>
          <Text size="lg" weight="semiBold" noMargin>
            {t(keys.workspace.WORKSPACE_ASSETS)}
          </Text>
          {isWorkspaceAdmin || isAdmin ? (
            <Icon
              color="primary"
              className={styles.plusIcon}
              onClick={() => setAssetModalOpen(true)}
            >
              add
            </Icon>
          ) : null}
        </div>
        <br />
        <div className={styles.assetContainer}>
          <div className={styles.searchBar}>
            <Searchbar
              value={search}
              onChange={setSearch}
              title={t(keys.common.ASSETS)}
            />
          </div>
          <div className={styles.assetDisplay}>
            {filterVehiclesOnSearch?.length ? (
              filterVehiclesOnSearch?.map((v) => (
                <div key={v.id}>
                  <VehicleCardList
                    readOnly={!isWorkspaceAdmin}
                    vehicle={v}
                    onAssetRemoved={(assetId) => {
                      removeAssetFromWorkspace({
                        variables: {
                          workspaceId: workspace.id,
                          vehicleId: assetId,
                        },
                      }).then(() => refetchWorkspace());
                    }}
                  />
                </div>
              ))
            ) : (
              <div className={styles.noAssetDisplay}>
                <Text>{t(keys.workspace.NO_ASSETS)}</Text>
              </div>
            )}
          </div>
        </div>
        <br />
        {!!isAdmin && !!enableActivities && (
          <div className={styles.activitiies}>
            <ActivityConverter />
            <br />
          </div>
        )}

        <div className={styles.header}>
          <Text size="lg" weight="semiBold" noMargin>
            {t(keys.workspace.WORKSPACE_NOTES)}
          </Text>
          <div className={isWorkspaceAdmin ? styles.notes : styles.hide}>
            {!isEditingNotes && (
              <Icon
                baseClassName="material-icons-outlined"
                className={styles.editIcon}
                onClick={() => setIsEditingNotes(!isEditingNotes)}
              >
                edit
              </Icon>
            )}
          </div>
        </div>
        <br />

        <Alert show={isEditingNotes} variant="success">
          <Alert.Heading>{t(keys.workspace.EDITING_TITLE)}</Alert.Heading>
          <p>{t(keys.workspace.EDITING_MESSAGE)}</p>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              value={t(keys.action.CANCEL)}
              onClick={() => {
                setState({
                  ...state,
                  notes: workspace.notes,
                });
                setIsEditingNotes(!isEditingNotes);
              }}
              variant="secondary"
            />
            <Button
              value={t(keys.action.SAVE)}
              icon="save"
              onClick={() => {
                updateWorkspace({
                  variables: { id: workspace.id, notes: state.notes },
                });
                setIsEditingNotes(!isEditingNotes);
              }}
              variant="success"
            />
          </div>
        </Alert>
        <div className={styles.notesContainer}>
          {isEditingNotes ? (
            <Form.Control
              as="textarea"
              rows="10"
              value={state.notes || ''}
              onChange={(e) => setState({ ...state, notes: e.target.value })}
            />
          ) : (
            <div className={styles.notes}>
              <Text>
                {state.notes ? state.notes : t(keys.workspace.NO_NOTES)}
              </Text>
            </div>
          )}
        </div>
        <br />
        <br />
        <div className={styles.deleteButton}>
          <Button
            value={t(keys.action.DELETE_VARIABLE, {
              variable: t(keys.common.WORKSPACE),
            })}
            disabled={!isAdmin}
            variant="danger"
            onClick={() => {
              openConfirmationModal({
                title: t(keys.action.DELETE_VARIABLE, {
                  variable: t(keys.common.WORKSPACE),
                }),
                description: t(keys.workspace.DELETE_WORKSPACE, {
                  variable: workspace.title,
                }),
                variant: 'danger',
                buttonText: t(keys.action.DELETE),
                doubleConfirm: true,
                confirmText: workspace.title,
                onSubmit: () => {
                  deleteWorkspace({ variables: { id: workspace.id } }).then(
                    () => {
                      refetchWorkspace().then(() => {
                        openModal({
                          modalName: modals.noWorkspace,
                          variables: { allowClose: false },
                        });
                      });
                    },
                  );
                },
              });
            }}
          />
          {!isAdmin && (
            <Text noMargin weight="semiBold" color="secondaryLight">
              {t(keys.workspace.DELETE_MESSAGE)}
            </Text>
          )}
        </div>
      </div>
      {assetModalOpen && (
        <div className={styles.assetModalContainer}>
          <AddAssetModal
            show={true}
            workspaceId={workspace.id}
            assets={filteredVehicles}
            onHide={() => setAssetModalOpen(false)}
            addAsset={(asset) => {
              addAssetToWorkspace({
                variables: {
                  workspaceId: workspace?.id,
                  vehicleId: asset.id,
                },
              });
            }}
            title={t(keys.workspace.ADD_ASSET)}
            allowCreate={true}
            multiSelect={true}
            modalSize="lg"
          />
        </div>
      )}

      {logoModalOpen && (
        <ImageCropModal
          title="Logo"
          ratio={1}
          transparent
          onClose={() => setLogoModalOpen(!logoModalOpen)}
          onSave={(file) => {
            setLogoModalOpen(!logoModalOpen);
            const fileUUID = uuidv4();
            const fileParts = file.name.split('.');
            const fileName = fileUUID;
            const fileType = fileParts[1];
            client
              .query({
                query: uploadUrlQuery,
                variables: {
                  fileName: `assets/${currentUser.company.id}/${fileName}.${fileType}`,
                  fileType,
                },
              })
              .then((data) => {
                const signedUrl = data.data.simpleStorageUploadUrl;
                const options = {
                  headers: {
                    'Content-Type': fileType,
                  },
                };
                axios.put(signedUrl, file, options).then((result) => {
                  if (result.status === 200) {
                    updateWorkspace({
                      variables: {
                        id: workspace.id,
                        logoUrl: `${CF_DOMAIN(
                          currentUser,
                        )}${fileName}.${fileType}`,
                      },
                    });
                  }
                });
              });
          }}
        />
      )}
    </div>
  );
}
