import styles from './CalendarScheduler.module.scss';
import { Text } from '../../components/typography';
import { useState, useEffect, useMemo } from 'react';
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  addMonths,
  subMonths,
  parseISO,
  compareAsc,
} from 'date-fns';
import Icon from '../../components/Icon';
import classNames from 'classnames';
import { useIsMobile } from '../../hooks/misc';
import { formatTime } from '../../utilities/time';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useModal } from '../../providers/ModalProvider';

export default function CalendarScheduler({
  selectedDates = [],
  setSelectedDates = () => {},
  setSelectedMonth = () => {},
  dateData = {},
  onRemove = () => {},
}) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const startDate = startOfWeek(startOfMonth(currentDate));
  const endDate = endOfWeek(endOfMonth(currentDate));
  const isMobile = useIsMobile();
  const hasSelectedDates = !!selectedDates.length;
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  const { openConfirmationModal } = useModal();

  useEffect(() => {
    setSelectedMonth(currentDate);
  }, [currentDate, setSelectedMonth]);

  const displayFormat = 'd';
  const dateFormat = 'yyyy-MM-dd';
  let days = [];
  let day = startDate;
  let formattedDate = '';

  while (day <= endDate) {
    const value = format(day, dateFormat);
    formattedDate = format(day, displayFormat);
    const isSameMonth = day.getMonth() === currentDate.getMonth();
    const isSelected = selectedDates?.includes(value);
    const isToday = format(new Date(), dateFormat) === value;
    const pastDate = format(new Date(), dateFormat) > value;

    const style = () => {
      let color = 'primary';
      let weight = undefined;
      if (pastDate && isSameMonth) {
        color = 'secondaryLight';
      }
      if (isToday) {
        color = 'accentPrimary';
        weight = 'semiBold';
      }
      return { color, weight };
    };

    days.push(
      <div
        className={classNames(
          styles.cell,
          { [styles.today]: isToday },
          { [styles.disabled]: !isSameMonth },
          { [styles.selected]: isSelected },
        )}
        key={day}
        id={value}
        onClick={() => toggleDate(value)}
      >
        <Text weight="semiBold" color={style().color} noMargin>
          {formattedDate}
        </Text>
        {isMobile && dateData[value] ? (
          <Icon
            color="green"
            style={{ marginTop: '0.3rem' }}
            opacity={pastDate ? 0.2 : 0.9}
          >
            check_circle
          </Icon>
        ) : (
          <Text
            weight={isToday ? 'semiBold' : undefined}
            color={isToday ? 'primary' : style().color}
            size="sm"
            noMargin
          >
            {dateData[value]}
          </Text>
        )}
      </div>,
    );
    day = addDays(day, 1);
  }

  const toggleDate = (date) => {
    let updatedDates;
    if (!selectedDates.includes(date)) {
      updatedDates = [...selectedDates, date];
    } else {
      updatedDates = selectedDates.filter((d) => d !== date);
    }
    const sortedDates = updatedDates.sort((a, b) =>
      compareAsc(parseISO(a), parseISO(b)),
    );
    setSelectedDates(sortedDates);
  };

  const nextMonth = () => {
    setSelectedDates([]);
    setCurrentDate(addMonths(currentDate, 1));
  };
  const prevMonth = () => {
    setSelectedDates([]);
    setCurrentDate(subMonths(currentDate, 1));
  };

  const weekdays = i18n.getResourceBundle(lang, 'toolbox').weekdays;

  const selectedMonth = useMemo(() => {
    const today = currentDate;
    const year = today.getFullYear();
    const month = today.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const datesArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = format(new Date(year, month, day), 'yyyy-MM-dd');
      datesArray.push(date);
    }
    return datesArray;
  }, [currentDate]);

  const month = formatTime(currentDate, { month: 'long', year: 'numeric' });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Icon onClick={prevMonth} color="blue" hover>
          chevron_left
        </Icon>
        <Text weight="semiBold" noMargin>
          {month}
        </Text>
        <Icon hover onClick={nextMonth} color="blue">
          chevron_right
        </Icon>
      </div>
      <div className={styles.weekdays}>
        {Object.values(weekdays)?.map((day, index) => (
          <div className={styles.day} key={index}>
            <Text
              weight="semiBold"
              color="secondary"
              textAlign="center"
              noMargin
            >
              {day}
            </Text>
          </div>
        ))}
      </div>
      <div className={styles.grid}>{days}</div>
      <div className={styles.footer}>
        <Text
          noMargin
          hover
          size={isMobile ? 'sm' : 'md'}
          color={'accentPrimary'}
          onClick={() => {
            if (selectedDates?.length) {
              setSelectedDates([]);
            } else {
              setSelectedDates(selectedMonth);
            }
          }}
        >
          {selectedDates?.length
            ? t(keys.toolbox.CLEAR_SELECTION).toUpperCase()
            : t(keys.toolbox.SELECT_ALL).toUpperCase()}
        </Text>
        <Text
          noMargin
          size={isMobile ? 'sm' : 'md'}
          hover={hasSelectedDates}
          color={hasSelectedDates ? 'accentPrimary' : 'secondaryLight'}
          onClick={() =>
            openConfirmationModal({
              title: t(keys.toolbox.DELETE_DATA),
              description: t(keys.toolbox.DELETE_DATA_MESSAGE),
              variant: 'danger',
              onSubmit: () => onRemove(),
            })
          }
        >
          {t(keys.toolbox.DELETE_SELECTED).toUpperCase()}
        </Text>
      </div>
    </div>
  );
}
