import React from 'react';
import styles from './MultiAvatar.module.scss';
import { Text } from './typography';
import classNames from 'classnames';

export default function MultiAvatar({ users = [], maxLength = 2, className }) {
  return (
    <div className={classNames(styles.container, className)}>
      {users.slice(0, maxLength).map(({ id, avatarUrl, firstName }) => (
        <img
          key={`avatar:${id}`}
          className={styles.avatar}
          alt={firstName}
          src={avatarUrl}
        />
      ))}
      {users.length > maxLength && (
        <Text
          className={styles.overflowText}
          size="sm"
          weight="semiBold"
          color="accentPrimary"
          noMargin
        >
          {`+ ${users.length - maxLength}`}
        </Text>
      )}
    </div>
  );
}
