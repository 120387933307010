import React, { useEffect } from 'react';
import moment from 'moment';
import { debounce } from '../constants/graphql';
import { useDebouncedRemoteState } from '../hooks/misc';
import { Text } from './typography';
import PikadayWrap from './external/PIkadayWrap';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys.js';

const today = new Date();

export default function DateSelector({
  value,
  onChange,
  disableFuture = false,
  readOnly = false,
  blockDefaultDate = false,
}) {
  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useDebouncedRemoteState(
    value ? new Date(value) : undefined,
    onChange,
    debounce.sm,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!value?.length && !blockDefaultDate) {
      onChange(today);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return readOnly ? (
    <Text noMargin>{new moment(value).format('LL')}</Text>
  ) : (
    <PikadayWrap
      placeholder={t(keys.action.SELECT_VARIABLE, {
        variable: t(keys.common.DATE),
      })}
      value={date}
      maxDate={disableFuture ? today : undefined}
      onChange={(newDate) => {
        setDate(newDate);
      }}
    />
  );
}
