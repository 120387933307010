import { useModal } from '../../../providers/ModalProvider';
import Modal from '../Modal';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { modals } from '../../../providers/modals';
import styles from './CreateTemplateModal.module.scss';
import TypeSelectorDropdown from '../../../components/dropdowns/TypeSelectorDropdown';
import { Text } from '../../typography';
import { Form } from 'react-bootstrap';
import { useCurrentUser } from '../../../providers/UserProvider';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../../constants/strings';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';

const createTemplateMutation = loader('./CreateTemplateModal.graphql');

export default function CreateTemplateModal() {
  const { updateModal, closeModal, modalState } = useModal();
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const [createTemplate] = useMutation(createTemplateMutation);
  const { workspaceId } = useWorkspace();
  const { t } = useTranslation();
  const {
    show,
    modalTitle,
    type,
    types,
    title,
    description,
    reviewRequired,
    analysisRequested,
    allWorkspaces,
    options,
  } = modalState.createTemplateModal;

  const update = (variables) =>
    updateModal({
      modalName: modals.createTemplate,
      variables: {
        ...variables,
      },
    });
  const onHide = () => {
    closeModal({ modalName: modals.createTemplate });
  };
  const template = useMemo(() => {
    const allWorkspaceCheck = type.key === 'OBSERVATION' || allWorkspaces;
    return {
      title,
      description,
      templateType: type.key,
      reviewRequired,
      analysisRequested,
      workspaceId: allWorkspaceCheck ? null : workspaceId,
    };
  }, [
    allWorkspaces,
    analysisRequested,
    description,
    reviewRequired,
    title,
    type.key,
    workspaceId,
  ]);

  return (
    <Modal
      open={show || false}
      title={
        modalTitle ||
        t(keys.action.CREATE_VARIABLE, {
          variable: t(keys.common.TEMPLATE),
        })
      }
      onClose={onHide}
      submitText={t(keys.action.CREATE)}
      submitDisabled={!title}
      hideCancel
      onSubmit={() =>
        createTemplate({
          variables: template,
        }).then(
          ({
            data: {
              addInspectionTemplate: { id },
            },
          }) => {
            navigate(getRoute(workspaceId, paths.templates, id));
          },
        )
      }
    >
      <div className={styles.container}>
        <TypeSelectorDropdown
          onChange={(newType) => {
            update({ type: newType });
          }}
          selected={type}
          items={types?.map((t, index) => ({
            title: t.title,
            icon: t.icon,
            color: t.color,
            key: t.key,
            id: index,
          }))}
          type={t(keys.common.TYPE)}
        />
        <div className={styles.content}>
          <Text weight="semiBold" size="md" noMargin>
            {t(keys.common.TITLE)}
          </Text>
          <Form.Control
            type="text"
            placeholder={t(keys.templates.CREATE_MODAL_TITLE_PLACEHOLDER)}
            value={title}
            onChange={(e) => update({ title: e.target.value })}
          />
          <Text weight="semiBold" size="md" noMargin>
            {`${t(keys.common.DESCRIPTION)} (${t(keys.common.OPTIONAL)})`}
          </Text>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder={t(keys.templates.DESCRIPTION_PLACEHOLDER)}
            value={description}
            onChange={(e) => update({ description: e.target.value })}
          />
        </div>
        <div className={styles.options}>
          {options.approvalRequired && type.key !== 'OBSERVATION' && (
            <Form.Check
              inline
              label={t(keys.templates.APPROVAL_REQUIRED)}
              checked={reviewRequired}
              onChange={(e) => {
                update({ reviewRequired: e.target.checked });
              }}
            />
          )}
          {options.secondSight && type.key !== 'OBSERVATION' && (
            <Form.Check
              inline
              label={t(keys.templates.SECOND_SIGHT)}
              checked={analysisRequested}
              onChange={(e) => {
                update({ analysisRequested: e.target.checked });
              }}
            />
          )}
          {user?.role !== 'USER' &&
            options.allWorkspaces &&
            type.key !== 'OBSERVATION' && (
              <Form.Check
                inline
                label={t(keys.templates.ALL_WORKSPACES)}
                checked={allWorkspaces}
                onChange={(e) => {
                  update({ allWorkspaces: e.target.checked });
                }}
              />
            )}
        </div>
      </div>
    </Modal>
  );
}
