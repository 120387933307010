import Modal from './Modal.jsx';
import { DropdownText } from '../dropdowns/DropdownText.jsx';
import { useState, useMemo, useEffect } from 'react';
import { useCurrentUser } from '../../providers/UserProvider.jsx';
import { useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Text } from '../typography/index.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { timecardStatus } from '../../constants/misc.js';
import {
  formatCSVData,
  organizeTimecardsByActivity,
  CSVHeaders,
  formatDataPayroll,
} from '../../pages/hr/Timecards.js';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ActivityDocument } from '../../utilities/pdf_export/activities.js';
import Button from '../Button.jsx';
import moment from 'moment';
import { ProgressBar } from 'react-bootstrap';
import { exportToExcel } from '../excel/ExcelExport.jsx';
import { showToast } from '../../graphql/cache/modal.js';
import { toastVariant, toastLength } from '../../constants/misc.js';
import { TimecardDocument } from '../../utilities/pdf_export/timecards.js';
import styles from './ExpenseReportModal.module.scss';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { timecardStatusOptions } from '../../utilities/filtering';
import SelectableTag from '../SelectableTag.jsx';
import { strings } from '../../pages/hr/Expenses.js';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';

const timecardsQuery = loader('./TimecardReportExportModal.fetch.graphql');
const timecardCountQuery = loader('./TimecardReportExportModal.count.graphql');
const activityQuery = loader('./TimecardReportExportModal.activities.graphql');

export default function TimecardReportExportModal({ show, onClose, title }) {
  const { isAdmin, user } = useCurrentUser();
  const client = useApolloClient();
  const [data, setData] = useState(null);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  const personnelOptions = { PERSONAL: 'Personal', TEAM: 'Team' };

  const exportErrorCallback = (error) => {
    onHide();
    showToast({
      title: 'Error Exporting Report',
      message: `There was an error during export: ${error}`,
      time: toastLength.lg,
      variant: toastVariant.danger,
    });
  };

  const [generatingReport, setGeneratingReport] = useState(false);
  const reportTypes = {
    weeklyReport: 'Weekly Report',
    detailedPDF: 'Detailed PDF',
    payrollXLSX: 'Payroll XLSX',
    detailedXLSX: 'Detailed XLSX',
  };
  const defaultState = {
    personnel: 'Personal',
    startDate: null,
    endDate: null,
  };
  const options = {
    Personal: {
      PDF: [reportTypes.weeklyReport, reportTypes.detailedPDF],
      XLSX: [reportTypes.payrollXLSX, reportTypes.detailedXLSX],
    },
    Team: {
      XLSX: [reportTypes.payrollXLSX, reportTypes.detailedXLSX],
    },
  };
  const [selectedOptions, setSelectedOptions] = useState(defaultState);
  const onHide = () => {
    setProgress(0);
    onClose();
    setData(null);
    setGeneratingReport(false);
    setSelectedOptions(defaultState);
    setSelectedActivities([]);
    setSelectedStatus([]);
  };

  useEffect(() => {
    if (selectedOptions?.personnel === 'Team') {
      setSelectedActivities([]);
    }
  }, [selectedOptions?.personnel]);

  const { data: { activities = [] } = {} } = useQuery(activityQuery, {
    variables: {
      options: {
        filters: [
          {
            field: 'resourceId',
            operator: 'eq',
            value: `${user?.externalActivityId}`,
          },
        ],
      },
    },
  });

  const activityOptions =
    activities?.map((a) => ({
      value: a.id,
      label: `${a.wbsCode} - ${a.description}`,
    })) || [];

  const filters = useMemo(() => {
    let baseFilters = [];
    const status = selectedStatus.map((s) => strings[s]) || [];

    baseFilters.push(
      status.length
        ? { field: 'status', operator: 'or', value: status }
        : { field: 'status', operator: 'ne', value: timecardStatus.draft },
    );

    if (selectedOptions.personnel === 'Personal') {
      baseFilters.push({
        field: 'creatorId',
        operator: 'eq',
        value: `${user?.id}`,
      });
    }
    if (selectedOptions.startDate && selectedOptions.endDate) {
      baseFilters.push({
        field: 'startTime',
        operator: 'between',
        value: [selectedOptions.startDate, selectedOptions.endDate],
      });
    }
    if (selectedActivities?.length) {
      const activityIds = selectedActivities.map((a) => `${a.value}`);
      baseFilters.push({
        field: 'activityId',
        operator: 'or',
        value: activityIds,
      });
    }

    return baseFilters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions, selectedActivities, selectedStatus]);

  const dateRange = useMemo(() => {
    return (
      `${selectedOptions.startDate?.split(' ')[0]} - ${
        selectedOptions.endDate?.split(' ')[0]
      }` || null
    );
  }, [selectedOptions]);

  const fileName = useMemo(() => {
    return selectedOptions.personnel === personnelOptions.PERSONAL
      ? `${user.lastName}_${user.firstName}`
      : `${personnelOptions.TEAM.toUpperCase()}`;
  }, [
    personnelOptions.PERSONAL,
    personnelOptions.TEAM,
    selectedOptions.personnel,
    user.firstName,
    user.lastName,
  ]);

  async function generateTimecardReport() {
    if (!selectedOptions.reportType) return;
    let allTimecards = [];
    let hasMoreData = true;
    let page = 1;
    const pageSize = 500;
    setGeneratingReport(true);

    const {
      data: { timecardsCount: count },
    } = await client.query({
      query: timecardCountQuery,
      fetchPolicy: 'network-only',
      variables: {
        filters,
      },
    });

    try {
      while (hasMoreData) {
        const { data: { timecards = [] } = {} } = await client.query({
          query: timecardsQuery,
          fetchPolicy: 'network-only',
          variables: {
            options: {
              pageSize,
              filters,
              page,
              sort: [{ field: 'startTime', order: 'asc' }],
            },
          },
        });
        allTimecards = [...allTimecards, ...timecards];
        ++page;
        await new Promise((resolve) => setTimeout(resolve, 100));
        setProgress((allTimecards.length / count) * 100);
        if (timecards.length < 500) break;
      }
    } catch (error) {
      showToast({
        title: 'Error Exporting Timecards',
        variant: toastVariant.danger,
        message: `An Error occured: ${error} `,
      });
      onHide();
    }
    if (!!!allTimecards.length) {
      showToast({
        title: 'Check Your Filters',
        variant: toastVariant.warning,
        message: 'No data available for export.',
        time: toastLength.md,
      });
      onHide();
      return;
    }
    let PDFData;
    switch (selectedOptions.reportType) {
      case reportTypes.weeklyReport:
        PDFData = organizeTimecardsByActivity(
          selectedOptions.startDate,
          allTimecards,
        );
        setData(
          <ActivityDocument
            user={user}
            data={PDFData.forPDF}
            headers={PDFData.headers}
            totals={PDFData.finalTotals}
          />,
        );
        setGeneratingReport(false);
        break;
      case reportTypes.detailedXLSX:
        exportToExcel({
          data: allTimecards.map((timecard) => formatCSVData(timecard)),
          sheetName: dateRange,
          headerMap: CSVHeaders,
          exportedBy: `${user.firstName} ${user.lastName}`,
          dateRange,
          type: 'Timesheet',
          fileName: `${fileName}_Timecards-${dateRange}`,
          reportType: ['Report Type', `Timecard Details - ${fileName}`],
          errorCallback: exportErrorCallback,
        });
        onHide();
        break;
      case reportTypes.detailedPDF:
        setData(
          <TimecardDocument
            timecards={allTimecards}
            user={user}
            filters={filters.filter((f) => f.field !== 'status')}
          />,
        );
        setGeneratingReport(false);
        break;
      case reportTypes.payrollXLSX:
        exportToExcel({
          data: formatDataPayroll(allTimecards),
          sheetName: dateRange,
          exportedBy: `${user.firstName} ${user.lastName}`,
          dateRange,
          type: 'Timesheet',
          fileName: `${fileName}_Payroll-Timecards-${dateRange}`,
          reportType: ['Report Type', `Timecards for Payroll - ${fileName}`],
          errorCallback: exportErrorCallback,
        });
        onHide();
        break;
      default:
        return null;
    }
  }
  async function handleGenerateReport() {
    await generateTimecardReport();
  }

  const isWeeklyReport =
    selectedOptions.reportType === reportTypes.weeklyReport;

  useEffect(() => setData(null), [filters]);

  return (
    <Modal open={show} onClose={onHide} title={title} hideSubmit hideCancel>
      {generatingReport ? (
        <div>
          <Text weight="semiBold" color="secondary">
            {t(keys.common.GETTING_REPORTS)}
          </Text>
          <ProgressBar animated={true} variant={'primary'} now={progress} />
        </div>
      ) : (
        <div className={styles.container}>
          <DropdownText
            title="Personnel Type"
            items={isAdmin ? Object.keys(options) : ['Personal']}
            onChange={(selected) =>
              setSelectedOptions({
                personnel: selected,
                startDate: null,
                endDate: null,
              })
            }
            selected={selectedOptions.personnel}
          />
          <DropdownText
            title="File Type"
            items={Object.keys(options[selectedOptions?.personnel]) || []}
            highlight
            onChange={(selected) =>
              setSelectedOptions({
                ...selectedOptions,
                fileType: selected,
                reportType: null,
              })
            }
            selected={selectedOptions.fileType}
            disabled={!selectedOptions.personnel}
          />
          <DropdownText
            title="Report Type"
            highlight
            items={Object.values(
              options[selectedOptions?.personnel][selectedOptions?.fileType] ||
                [],
            )}
            disabled={!selectedOptions.fileType}
            selected={selectedOptions.reportType}
            onChange={(selected) =>
              setSelectedOptions({ ...selectedOptions, reportType: selected })
            }
          />
          {selectedOptions?.personnel === 'Personal' && (
            <div className={styles.activitySelector}>
              <Text noMargin weight="semiBold">
                Project/Work Order (optional)
              </Text>
              <Select
                disabled={!selectedOptions.reportType}
                isMulti
                options={activityOptions}
                value={selectedActivities}
                onChange={(activity) => {
                  setSelectedActivities(activity);
                }}
              />
            </div>
          )}
          <div className={styles.activitySelector}>
            <Text weight="semiBold" noMargin>
              Status (optional)
            </Text>
            <div className={styles.statusSelector}>
              {timecardStatusOptions?.map((filter) => (
                <SelectableTag
                  key={filter}
                  text={filter}
                  size="sm"
                  value={filter}
                  selected={selectedStatus}
                  namespace={'utilities'}
                  onSelect={(stat) => {
                    if (selectedStatus?.includes(stat)) {
                      setSelectedStatus((prevStatus) =>
                        prevStatus.filter((s) => s !== stat),
                      );
                    } else {
                      setSelectedStatus((prevStatus) => [...prevStatus, stat]);
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <Text weight="semiBold" noMargin>
            {isWeeklyReport ? 'Week End' : 'Date Range'}
          </Text>
          <div className={styles.dateSelectors}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {!isWeeklyReport && (
                <DatePicker
                  value={moment(selectedOptions?.startDate)}
                  onChange={(newMoment) => {
                    const updatedDate = moment(newMoment).format(
                      'YYYY-MM-DD 00:00:00',
                    );
                    setSelectedOptions({
                      ...selectedOptions,
                      startDate: updatedDate,
                    });
                  }}
                />
              )}
              <DatePicker
                value={moment(selectedOptions?.endDate)}
                onChange={(newMoment) => {
                  const updatedEndDate = moment(newMoment).format(
                    'YYYY-MM-DD 23:59:59',
                  );
                  let updatedStartDate = selectedOptions.startDate;

                  if (isWeeklyReport) {
                    updatedStartDate = moment(updatedEndDate)
                      .subtract(7, 'days')
                      .format('YYYY-MM-DD 00:00:00');
                  }

                  setSelectedOptions({
                    ...selectedOptions,
                    startDate: updatedStartDate,
                    endDate: updatedEndDate,
                  });
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      )}
      <br />
      {!data && !generatingReport ? (
        <Button
          align="right"
          onClick={handleGenerateReport}
          icon="check_circle"
          value="Get Report"
          variant="success"
          disabled={
            !moment(selectedOptions.startDate).isValid() ||
            !moment(selectedOptions.endDate).isValid() ||
            !selectedOptions.reportType ||
            generatingReport
          }
        ></Button>
      ) : (
        <PDFDownloadLink
          document={data}
          fileName={`${fileName}_${
            isWeeklyReport ? 'Weekly-Report-' : 'Timecards-'
          }${selectedOptions.startDate.split(' ')[0]}_${
            selectedOptions.endDate.split(' ')[0]
          }`}
        >
          {({ loading, url }) => (
            <Button
              align="right"
              disabled={loading || !url || !data}
              value="Download PDF"
              onClick={() => {
                onHide();
              }}
            />
          )}
        </PDFDownloadLink>
      )}
    </Modal>
  );
}
