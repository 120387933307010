import React from 'react';
import styles from './WorkflowStageHeader.module.scss';
import { Text } from '../typography';

export default function WorkflowStageHeader({ title, subtitle }) {
  return (
    <div className={styles.container}>
      <Text noMargin weight="semiBold" size="lg">
        {title}
      </Text>
      <Text>{subtitle}</Text>
    </div>
  );
}
