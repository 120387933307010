import styles from './SectionContainer.module.scss';
import { Text } from '../../../components/typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import Icon from '../../../components/Icon';
import classNames from 'classnames';

export default function SectionContainer({
  title,
  children,
  onClick,
  icon,
  className,
  isLoading = false,
  actionText,
  hasChildren = true,
  emptyState,
  footer,
}) {
  const { t } = useTranslation();
  return isLoading ? (
    <></>
  ) : (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Text noMargin weight="semiBold" color="secondary">
            {title}
          </Text>
          <Icon size="lg" color="secondaryLight">
            {icon}
          </Icon>
        </div>
        {!!onClick && hasChildren && (
          <Text
            size="sm"
            weight="semiBold"
            noMargin
            color="accentPrimary"
            hover
            onClick={onClick}
          >
            {actionText || t(keys.dashboard.SEE_ALL)}
          </Text>
        )}
      </div>
      <div className={classNames(styles.content, styles[className])}>
        {hasChildren ? children : emptyState}
      </div>
      <div className={styles.footer}>{footer}</div>
    </div>
  );
}
