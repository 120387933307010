import { showToast } from '../../graphql/cache/modal';
import { toastVariant } from '../../constants/misc';

export async function fetchAllData({
  filters = [],
  setIsFetching = () => {},
  query,
  client,
  table = null,
  count = null,
  setProgress,
  subfilters = [],
}) {
  let allData = [];
  const pageSize = 500;
  let total = 0;
  setIsFetching(true);

  try {
    for (let i = 0; ; i++) {
      const { data } = await client.query({
        query,
        fetchPolicy: 'network-only',
        variables: {
          options: {
            pageSize,
            filters,
            subfilters,
            page: i + 1,
            sort: [{ field: 'dateCreated', order: 'asc' }],
          },
        },
      });

      allData = [...allData, ...data[table]];

      if (i === 0) {
        total = data[count];
      }
      setProgress((allData.length / total) * 100);

      if (data[table].length === 0) break;
      await new Promise((resolve) => setTimeout(resolve, 500));

      if (allData.length >= total) break;
    }
  } catch (error) {
    console.error('Error fetching all data', error);
    showToast({
      title: 'Error Exporting Data',
      variant: toastVariant.danger,
      message: `An error occurred: ${error.message}`,
    });
  }

  setIsFetching(false);
  setProgress(0);

  return allData;
}
