import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryParams } from '../hooks/misc';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useWorkspace } from './WorkspaceProvider';
import CustomSpinner from '../components/CustomSpinner';
import { sortItemsByDateModified } from '../utilities';

const templateQuery = loader('./TemplateProvider.graphql');
const paginatedTemplateQuery = loader('./TemplateProvider.paginated.graphql');

const PAGE_SIZE = 20;
const DEBOUCE_MS = 150;

const TemplateProviderContext = createContext();
export const TemplateProvider = ({ children }) => {
  const client = useApolloClient();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const { workspaceId } = useWorkspace();
  const { getParam } = useQueryParams();
  const type = getParam('type');

  const { data: { inspectionTemplates = [] } = {} } = useQuery(templateQuery, {
    fetchPolicy: 'cache-only',
  });

  const [fetchTemplates] = useLazyQuery(paginatedTemplateQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    async function fetchData() {
      while (true) {
        const existingData = client.readQuery({ query: templateQuery }) ?? {
          inspectionTemplates: [],
        };

        const sortedTemplates = sortItemsByDateModified(
          [...existingData?.inspectionTemplates] || [],
        );

        const minDate = sortedTemplates.length
          ? new Date(
              parseInt(
                sortedTemplates[sortedTemplates.length - 1]?.dateModified,
                10,
              ),
            )
          : new Date(0);

        const { data } = await fetchTemplates({
          variables: {
            minDate,
            pageSize: PAGE_SIZE,
          },
        });

        const startData = existingData ?? { inspectionTemplates: [] };

        const newTemplateIds = data.inspectionTemplates.map((t) => t.id);

        const oldTemplates = startData.inspectionTemplates.filter(
          (t) => !newTemplateIds.includes(t.id),
        );

        const newTemplates = sortItemsByDateModified([
          ...oldTemplates,
          ...data.inspectionTemplates,
        ]);

        client.writeQuery({
          query: templateQuery,
          data: { inspectionTemplates: newTemplates },
        });

        setProgress(
          Math.round(
            (newTemplates.length / data.inspectionTemplatesCount) * 100,
          ),
        );

        if (data?.inspectionTemplates?.length !== PAGE_SIZE) {
          setProgress(100);
          setLoading(false);
          break;
        }

        await new Promise((r) => setTimeout(r, DEBOUCE_MS));
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templates = useMemo(() => {
    if (!!type) {
      // if there's a type it means the create modal is open, therefore we filter the list, otherwise we want acces to all of them
      return inspectionTemplates
        ?.filter((f) => !f.internalType)
        .filter((t) => t.templateType === type)
        .filter((a) => !a.isArchived)
        .filter((t) => t.workspaceId === workspaceId || t.workspaceId === null);
    }
    return inspectionTemplates;
  }, [inspectionTemplates, type, workspaceId]);

  if (loading) {
    return <CustomSpinner text={`Fetching templates.. ${progress}%`} />;
  }

  return (
    <TemplateProviderContext.Provider value={{ templates }}>
      {children}
    </TemplateProviderContext.Provider>
  );
};
export const useTemplates = () => useContext(TemplateProviderContext);
