import i18n from 'i18next';
import { keys } from './translator/translation_keys';

export const incidentTypes = {
  'Health and Safety': [
    'Fatality',
    'Lost Time',
    'Medical Aid',
    'First Aid',
    'Near Miss',
  ],
  Environment: [
    'Spill to Land',
    'Spill to Water',
    'Waste Segregation',
    'Near Miss',
  ],
  Quality: [
    'Internal Non Conformance (NCR)',
    'Vendor Non Conformance (NCR)',
    'Customer Complaint',
    'Opportunity for Improvement',
  ],
  Commercial: ['Change'],
  Asset: ['Maintenance', 'Failure'],
};

export const incidentDetailQuestions = [
  {
    title: keys.incidents.DATE_OF_INCIDENT,
    convertToQuery: 'dateOfIncident',
    inputType: 'DATE',
    id: 1,
    required: true,
  },
  {
    title: keys.incidents.TIME_OF_INCIDENT,
    convertToQuery: 'timeOfDay',
    inputType: 'TIME',
    id: 2,
    required: true,
  },
  {
    title: keys.incidents.LOCATION,
    convertToQuery: 'location',
    inputType: 'TEXT',
    id: 3,
    required: true,
  },
  {
    title: keys.incidents.INCIDENT_DESCRIPTION,
    convertToQuery: 'description',
    inputType: 'TEXT_MULTI',
    id: 4,
    required: true,
  },
  {
    title: keys.incidents.WEATHER_CONDITIONS,
    convertToQuery: 'weather',
    inputType: 'SELECT_MULTI',
    additionalData: keys.incidents.WEATHER_OPTIONS,
    id: 5,
    required: true,
  },

  {
    title: keys.incidents.INITIAL_ROOT_CAUSE,
    convertToQuery: 'rootCause',
    inputType: 'SELECT_SINGLE',
    additionalData: keys.incidents.ROOT_CAUSE_OPTIONS,
    id: 6,
    required: false,
    legacyInput: true,
  },
];

export const employeeDetailQuestions = [
  {
    title: keys.incidents.INTERNAL_EMPLOYEE,
    convertToQuery: 'employee',
    inputType: 'USER',
    id: 7,
  },
  {
    title: keys.incidents.EXTERNAL_EMPLOYEE,
    convertToQuery: 'externalEmployee',
    inputType: 'TEXT',
    id: 8,
  },
];
export const investigationQuestions = [
  {
    title: keys.incidents.LOST_TIME,
    convertToQuery: 'lostTime',
    inputType: 'SELECT_SINGLE',
    isBoolean: true,
    additionalData: keys.incidents.YES_NO_OPTIONS,
    id: 9,
    skipOnSubtype: ['Lost Time'],
    required: true,
  },
  {
    title: keys.incidents.LOST_TIME_HRS,
    inputType: 'NUMBER',
    convertToQuery: 'lostTimeHrs',
    id: 10,
    skipOnSubtype: ['Fatality', 'Medical Aid', 'First Aid', 'Near Miss'],
    skipOnType: ['Environment', 'Quality', 'Commercial', 'Asset'],
    required: true,
  },
  {
    title: keys.incidents.LOST_TIME_COST,
    convertToQuery: 'cost',
    inputType: 'NUMBER',
    id: 11,
    legacyInput: true,
  },
  {
    title: keys.incidents.POTENTIAL_FATALITY,
    convertToQuery: 'potentialFatality',
    inputType: 'SELECT_SINGLE',
    isBoolean: true,
    additionalData: keys.incidents.YES_NO_OPTIONS,
    id: 12,
    skipOnSubtype: ['Fatality', 'Customer Complaint'],
    skipOnType: ['Environment', 'Commercial', 'Asset'],
    required: true,
  },
  {
    title: keys.incidents.IMPACT_PROBABILITY,
    convertToQuery: 'impact',
    inputType: 'MATRIX',
    isInteger: false,
    id: 13,
    required: false,
    skipOnSubtype: ['Customer Complaint'],
    skipOnType: ['Commercial'],
    legacyInput: true,
  },
];

export const incidentStatusTypes = {
  INITIATED: {
    color: 'red',
    icon: 'play_circle_outline_icon',
  },
  INVESTIGATION: {
    color: 'yellow',
    icon: 'person_search',
  },
  COMPLETE: {
    color: 'green',
    icon: 'check_circle',
  },
  PENDING: {
    color: 'orange',
    icon: 'timer',
  },
  CLOSED: {
    color: 'gray',
    icon: 'check_circle',
  },
  INVALID: {
    color: 'graylight',
    icon: 'highlight_off',
  },
};

export const getLabel = (status) => {
  switch (status) {
    case 'INITIATED':
      return `${i18n.t(keys.incidents.INITIATED)}`;
    case 'INVESTIGATION':
      return `${i18n.t(keys.incidents.INVESTIGATION)}`;
    case 'COMPLETE':
      return `${i18n.t(keys.common.COMPLETE)}`;
    case 'PENDING':
      return `${i18n.t(keys.incidents.PENDING)}`;
    case 'CLOSED':
      return `${i18n.t(keys.incidents.CLOSED)}`;
    case 'INVALID':
      return `${i18n.t(keys.incidents.INVALID)}`;
    default:
      return '-';
  }
};
