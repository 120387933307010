import { useEffect, useState } from 'react';
import pjson from '../../../package.json';
import { useCurrentUser } from '../../providers/UserProvider';
import { useUserIndexedDB } from './userMutationIndexedDB';

export const useOnlineStatus = () => {
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false);
    });
    window.addEventListener('online', () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });
      window.removeEventListener('online', () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  return onlineStatus;
};

export const useCachedMutations = () => {
  const packageVersion = pjson.version.split('.');
  const { addMutationToQueue } = useUserIndexedDB(
    `userSpecificMutation-${packageVersion[0]}.${packageVersion[1]}`,
  );
  const { user: currentUser } = useCurrentUser(); // Assume this is a hook/function that returns the current user

  const addMutation = async (
    document,
    options,
    id,
    mock,
    refetchQueries = [],
    newIdPath,
    hasMultipleItemIds,
  ) => {
    const mutationData = {
      document,
      options,
      id,
      mock,
      refetchQueries,
      newIdPath,
      hasMultipleItemIds,
    };
    if (currentUser) {
      await addMutationToQueue(currentUser.id, mutationData);
    } else {
      throw new Error('Current user is not found');
    }
  };

  return { addMutation };
};
