import { Dialog } from '@mui/material';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import { useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import { Text } from '../../typography';
import Button from '../../Button';
import styles from './ConfirmationModal.module.scss';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { useIsMobile } from '../../../hooks/misc';

export default function ConfirmationModal() {
  const { modalState, closeModal } = useModal();
  const {
    show,
    variant,
    icon,
    title,
    highlight,
    wordsToHighlight,
    description,
    doubleConfirm,
    confirmText,
    textInput,
    cancelText,
    textInputTitle,
    onCancel,
    onSubmit,
    buttonText,
  } = modalState.confirmationModal;
  const onHide = () => closeModal({ modalName: modals.confirmation });
  const [input, setInput] = useState('');
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return show ? (
    <Dialog open={show}>
      <div className={styles.container}>
        <div
          className={classNames(styles.topbar, styles[`${variant}Topbar`])}
        />
        <div className={styles.contentContainer}>
          <div className={styles.middleContainer}>
            <div className={styles.iconContainer}>
              <Icon
                className={classNames(styles.icon, styles[`${variant}Icon`])}
                sx={{ fontSize: '3.5rem' }}
              >
                {icon}
              </Icon>
            </div>
            <div className={styles.textContainer}>
              <Text
                noMargin
                size="lg"
                weight="semibold"
                textAlign={isMobile ? 'center' : undefined}
              >
                {title}
              </Text>
              <Text
                noMargin
                size="md"
                highlight={highlight}
                wordsToHighlight={wordsToHighlight}
                textAlign={isMobile ? 'center' : undefined}
              >
                {description}
              </Text>
            </div>
          </div>
          {doubleConfirm && (
            <div className={styles.confirmTextContainer}>
              <Text size="md" weight="semiBold" textAlign="center">
                {t(keys.action.DOUBLE_CONFIRMATION_TEXT)}
              </Text>
              <Form.Control
                className={styles.textInput}
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={confirmText}
              />
            </div>
          )}
          {textInput && (
            <div className={styles.confirmTextContainer}>
              <Text size="md" weight="semiBold" textAlign="center">
                {textInputTitle}
              </Text>
              <Form.Control
                className={styles.textInput}
                as="textarea"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              ></Form.Control>
            </div>
          )}
          <div className={styles.buttonContainer}>
            <Button
              size="md"
              variant="secondary"
              value={cancelText || t(keys.action.CANCEL)}
              onClick={() => {
                if (typeof onCancel === 'function') {
                  onCancel();
                } else {
                  onHide();
                  setInput('');
                }
              }}
            />
            <Button
              disabled={doubleConfirm && input !== confirmText}
              size="md"
              variant={variant}
              value={buttonText || t(keys.action.SUBMIT)}
              onClick={() => {
                onSubmit(input);
                setInput('');
                onHide();
              }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  ) : null;
}
