import styles from './InspectionItemAsset.module.scss';
import noImage from '../../../assets/icons/image_blank.png';
import { Text } from '../../typography';
import { useTranslation } from 'react-i18next';
import {
  getTranslationKey,
  keys,
} from '../../../utilities/translator/translation_keys';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../../constants/strings';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import Icon from '../../Icon';
import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { vehicleKeys } from '../../../utilities';
import classNames from 'classnames';
import { vehicleString } from '../../../utilities';
import { useInspection } from '../../../hooks/offline-hooks/createInspectionhook';

export default function InspectionItemAsset({
  vehicle,
  readOnly,
  size = 'sm',
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { workspaceId } = useWorkspace();
  const [isEditing, setIsEditing] = useState(readOnly ? false : true);
  const [state, setState] = useState(vehicle);
  const { updateAsset } = useInspection();

  useEffect(() => setState(vehicle), [vehicle]);

  const { id, hours, odometer } = state || {};

  return vehicle?.id ? (
    <div className={styles.container}>
      <img
        src={vehicle?.imageUrl ?? noImage}
        onClick={() =>
          navigate(getRoute(workspaceId, paths.asset, vehicle?.id))
        }
        className={classNames([styles.image, styles[size]])}
        alt="vehicle"
      />
      <div className={styles.content}>
        <Text
          hover
          noMargin
          color="accentPrimary"
          onClick={() =>
            navigate(getRoute(workspaceId, paths.asset, vehicle?.id))
          }
        >
          {vehicleString(vehicle)}
        </Text>
        <Text size="sm" color="secondary" noMargin>
          {`${t(getTranslationKey(vehicle?.type, 'assets'))} - ${t(
            getTranslationKey(vehicle?.subtype, 'assets'),
          )}`}
        </Text>
        <div className={styles.details}>
          <div>
            {vehicleKeys.includes(vehicle.type) ? (
              <div className={styles.row}>
                <Text noMargin color="secondary" weight="semiBold">
                  {t(keys.assets.ODOMETER)}
                </Text>
                {isEditing ? (
                  <Form.Control
                    className={styles.highlight}
                    type="number"
                    value={state?.odometer || 0}
                    onChange={(e) =>
                      setState({ ...state, odometer: parseInt(e.target.value) })
                    }
                    onBlur={() => {
                      updateAsset({ id, odometer });
                      setIsEditing(false);
                    }}
                  />
                ) : (
                  <Text noMargin weight="semiBold">
                    {`${vehicle?.odometer ?? 0} ${
                      vehicle?.isMetric ? 'km' : 'mi'
                    }`}
                  </Text>
                )}
              </div>
            ) : null}
            {vehicle.type !== 'Light Vehicle' ? (
              <div className={styles.row}>
                <Text noMargin color="secondary" weight="semiBold">
                  {t(keys.timecards.HOURS)}
                </Text>
                {isEditing ? (
                  <Form.Control
                    type="number"
                    className={styles.highlight}
                    value={state?.hours || 0}
                    onChange={(e) =>
                      setState({ ...state, hours: parseFloat(e.target.value) })
                    }
                    onBlur={() => {
                      updateAsset({ id, hours });
                      setIsEditing(false);
                    }}
                  />
                ) : (
                  <Text
                    noMargin
                    weight="semiBold"
                    color={
                      !!vehicle.endOfLifeHours &&
                      !isNaN(vehicle?.endOfLifeHours) &&
                      vehicle?.endOfLifeHours <= vehicle?.hours
                        ? 'red'
                        : 'primary'
                    }
                  >
                    {`${vehicle?.hours ?? 0} hours`}
                  </Text>
                )}
              </div>
            ) : null}
          </div>
          {!readOnly && !isEditing && (
            <Icon
              onClick={() => {
                setIsEditing(!isEditing);
              }}
              hover
              style={{ opacity: '0.7', fontSize: '1.5rem' }}
            >
              edit
            </Icon>
          )}
        </div>
      </div>
    </div>
  ) : size !== 'sm' ? null : (
    <Text className={styles.textResponse} color="accentPrimary" noMargin>
      {t(keys.assessments.NO_RESPONSE)}
    </Text>
  );
}
