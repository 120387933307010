import React, { createContext, useContext, useEffect } from 'react';
import { useCurrentUser } from './UserProvider';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useOnlineStatus } from '../hooks/offline-hooks/offline-misc';
import CustomSpinner from '../components/CustomSpinner';

const workspaceQuery = loader('./WorkspaceProvider.workspace.graphql');
const updateUserWorkspaceMutation = loader(
  './WorkspaceProvider.update.graphql',
);

const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const { user } = useCurrentUser();
  const { pathname } = useLocation();
  const isOnline = useOnlineStatus();
  const isWorkspaceRoute = pathname.startsWith('/workspace');
  const fromUrl = pathname.split('/')[2];
  const workspaceId = isWorkspaceRoute
    ? parseInt(fromUrl)
    : user.lastWorkspaceId;
  const [updateLastWorkspaceId] = useMutation(updateUserWorkspaceMutation);

  const {
    networkStatus,
    loading,
    refetch: refetchWorkspace,
    data: { workspaces = [] } = {},
  } = useQuery(workspaceQuery, {
    fetchPolicy: isOnline ? 'network-only' : 'cache-only',
  });

  const workspace = workspaces?.find(
    (workspace) => workspace.id === workspaceId,
  );
  const { observationTemplate } = workspace || {};

  const isWorkspaceAdmin =
    workspace?.permissions?.length &&
    workspace?.permissions[0]?.accessLevel === 'ADMIN';

  const validUrl =
    !isNaN(fromUrl) &&
    workspaces?.map((w) => w.id)?.some((id) => id === workspaceId);

  const inWorkspaces = workspaces?.filter((w) => w.permissions.length);

  const notInWorkspaces = workspaces
    ?.filter((w) => !w.permissions.length)
    .map((w) => w.id);

  useEffect(() => {
    if (validUrl && user?.lastWorkspaceId !== workspaceId) {
      updateLastWorkspaceId({
        variables: { id: user?.id, lastWorkspaceId: workspaceId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, pathname]);

  return networkStatus === NetworkStatus.loading ? (
    <CustomSpinner text={'Loading Workspace...'} />
  ) : (
    <WorkspaceContext.Provider
      value={{
        workspace,
        isWorkspaceAdmin,
        availableWorkspaces: workspaces || [],
        workspaceId,
        refetchWorkspace,
        validUrl,
        loading,
        inWorkspaces,
        notInWorkspaces,
        workspaceVehicles: workspace?.workspaceVehicles || [],
        isWorkspaceRoute,
        observationTemplate,
        allWorkspaces: workspaces || [],
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace = () => useContext(WorkspaceContext);
