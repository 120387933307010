import styles from './RiskMatrix.module.scss';
import { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { Text } from './typography';
import {
  impactData,
  probabilityData,
  calculateRisk,
} from '../utilities/riskMatrix';

export default function RiskMatrix({
  showMessage = true,
  onChange = () => {},
  impact = null,
  probability = null,
}) {
  const renderCell = (p, i) => {
    const cellRisk = p * i;
    const cellSeverity = calculateRisk(cellRisk);
    const selected =
      p === probability && i === impact && cellRisk === selectedRisk;
    return (
      <div
        className={selected ? styles.highlight : styles.cell}
        onClick={() => {
          onChange(i, p);
        }}
        style={{
          backgroundColor: cellSeverity.color,
          opacity: selected ? 1 : 0.7,
        }}
      >
        <Text
          className={styles.hideOnMobile}
          noMargin
          noSelect
          color="white"
          size="sm"
          weight={selected ? 'semiBold' : 'regular'}
        >
          {cellSeverity.level}
        </Text>
      </div>
    );
  };

  const selectedRisk = useMemo(() => {
    return impact * probability;
  }, [impact, probability]);

  return (
    <div className={styles.container}>
      <Text noMargin noSelect color="secondary" textAlign="center" size="sm">
        Consequence/Impact
      </Text>
      <div className={styles.tableDisplay}>
        <div className={styles.rotate}>
          <Text noMargin noSelect color="secondary" size="sm">
            Likelihood/Probability
          </Text>
        </div>
        <Table className={styles.table}>
          <thead>
            <tr>
              <th></th>
              {impactData.map((i, index) => (
                <th key={index}>
                  <div
                    onClick={() => {
                      onChange(i.value, probability);
                    }}
                    className={
                      i.value === impact
                        ? styles.headerHighlight
                        : styles.header
                    }
                  >
                    <Text
                      noMargin
                      size="sm"
                      noSelect
                      className={styles.hideOnMobile}
                    >
                      {i.impact}
                    </Text>
                    <Text
                      noMargin
                      size="sm"
                      noSelect
                      className={styles.hideOnDesktop}
                    >
                      {i.value}
                    </Text>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {probabilityData.map((p, index) => (
              <tr key={index}>
                <th>
                  <div
                    onClick={() => {
                      onChange(impact, p.value);
                    }}
                    className={
                      p.value === probability
                        ? styles.headerHighlight
                        : styles.header
                    }
                  >
                    <Text
                      noMargin
                      size="sm"
                      noSelect
                      className={styles.hideOnMobile}
                    >
                      {p.probability}
                    </Text>
                    <Text
                      noMargin
                      size="sm"
                      noSelect
                      className={styles.hideOnDesktop}
                    >
                      {p.value}
                    </Text>
                  </div>
                </th>
                {impactData.map((i, index) => (
                  <td key={index}>{renderCell(p.value, i.value)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {showMessage && (
        <>
          <div
            className={styles.message}
            style={{ display: calculateRisk(selectedRisk) ? 'block' : 'none' }}
          >
            <Text
              color={calculateRisk(selectedRisk)?.textColor}
              weight="bold"
              noMargin
              size="lg"
              noSelect
              textAlign="center"
            >
              {calculateRisk(selectedRisk)?.level.toUpperCase()}
            </Text>
            <Text
              color="secondary"
              weight="semiBold"
              size="md"
              textAlign="center"
              noMargin
            >
              {calculateRisk(selectedRisk)?.message}
            </Text>
          </div>
        </>
      )}
    </div>
  );
}
