import styles from './FilterModal.module.scss';
import classNames from 'classnames';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import UserSelector from '../UserSelector';
import Button from '../Button';
import { useMemo, useState, useEffect } from 'react';
import { vehicles } from '../../utilities/vehicles';
import { DropdownText } from '../dropdowns/DropdownText';
import {
  fileDescriptionTypes,
  fileStatusTypes,
  fileTypesConversion,
} from '../../utilities/files';
import DropdownCombo from '../dropdowns/DropdownCombo';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { dropdownTypes } from '../dropdowns/dropdown';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useCurrentUser } from '../../providers/UserProvider';

const vehicleTypes = Object.keys(vehicles).map((k) => vehicles[k]?.string);

export default function AssetFilterModal({
  show = false,
  onHide,
  onSave,
  assetType,
  filters,
}) {
  const { isAdmin } = useCurrentUser();
  const [filterData, setFilterData] = useState({});
  const [state, setState] = useState({});
  const { workspaceVehicles } = useWorkspace();
  const { t } = useTranslation();

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const subtypeOptions = useMemo(() => {
    const key = Object.keys(vehicles).find(
      (k) => vehicles[k].string === state.vehicleType,
    );

    if (!key) {
      return [];
    }
    const subtypes = Object.keys(vehicles[key]).map(
      (k) => k && vehicles[key][k]?.string,
    );
    return subtypes.filter((e) => e !== undefined);
  }, [state.vehicleType]);

  useEffect(() => setSelectedVehicle(null), [show]);

  useEffect(() => {
    setFilterData({});
    setSelectedVehicle(null);
  }, [assetType]);

  const globalTab =
    assetType === 'File' &&
    filters.some(
      (filter) => filter.field === 'workspaceId' && filter.value === 'null',
    );

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.infoContainer}>
            <div className={styles.modalHeader}>
              <Text color="accentPrimary" size="lg" noMargin weight="bold">
                {t(keys.utilities.FILTERS)}
              </Text>
              <div className={styles.spacer} />
              <div className={styles.closeIcon}>
                <Icon
                  baseClassName="material-icons-outlined"
                  className={classNames(styles.iconButton, styles.red)}
                  onClick={() => {
                    onHide();
                  }}
                >
                  close
                </Icon>
              </div>
            </div>
            <div className={styles.filterContainer}>
              {assetType === 'File' && (
                <div>
                  <DropdownText
                    namespace="files"
                    items={Object.keys(fileTypesConversion)}
                    title={t(keys.utilities.FILETYPE)}
                    selected={filterData.fileType}
                    onChange={(option) =>
                      setFilterData({
                        ...filterData,
                        fileType: option,
                      })
                    }
                  />
                  <DropdownText
                    namespace="files"
                    items={fileStatusTypes}
                    title={t(keys.utilities.STATUS)}
                    selected={filterData.Status}
                    onChange={(option) =>
                      setFilterData({
                        ...filterData,
                        Status: option,
                      })
                    }
                  />
                  <Text noMargin weight="semiBold">
                    {t(keys.utilities.ASSIGNEE)}
                  </Text>
                  <div className={styles.spacer} />
                  <UserSelector
                    type="Assignee"
                    selected={filterData?.Assignee ? filterData.Assignee : null}
                    onClose={() => {
                      delete filterData.Assignee;
                      setFilterData({
                        ...filterData,
                      });
                    }}
                    onSelectionChanged={(user) => {
                      setFilterData({
                        ...filterData,
                        Assignee: user ? user : null,
                      });
                    }}
                  />
                  <div className={styles.spacer} />
                  <DropdownCombo
                    title={t(keys.utilities.ASSET)}
                    show={show}
                    type={dropdownTypes.ASSET}
                    items={workspaceVehicles}
                    onChange={(asset) => {
                      setFilterData({ ...filterData, Asset: asset });
                      setSelectedVehicle(asset);
                    }}
                    selected={selectedVehicle}
                  />
                  <div className={styles.spacer} />
                  {isAdmin && globalTab && (
                    <>
                      <div>
                        <Text weight="semiBold" noMargin>
                          {`${t(keys.files.HIDDEN)} ${t(keys.common.FILES)}`}
                        </Text>
                        <div className={styles.spacer} />
                        <Form onSubmit={(e) => e.preventDefault()}>
                          <Form.Check
                            label={t(keys.common.SHOW)}
                            checked={filterData?.Hidden || false}
                            onChange={({ target: { checked } }) => {
                              setFilterData({
                                ...filterData,
                                Hidden: checked ? checked : null,
                              });
                              setState({
                                ...state,
                                hidden: checked ? checked : null,
                              });
                            }}
                          />
                        </Form>
                      </div>
                      <div className={styles.spacer} />
                    </>
                  )}
                </div>
              )}
              <DropdownText
                title={t(keys.utilities.TYPE)}
                selected={filterData.Type}
                onChange={(option) => {
                  setFilterData({
                    ...filterData,
                    Type: option,
                    Subtype: null,
                  });
                  setState({
                    ...state,
                    vehicleType: option,
                  });
                }}
                items={
                  assetType === 'Asset' ? vehicleTypes : fileDescriptionTypes
                }
                namespace={assetType === 'Asset' ? 'assets' : 'files'}
              />

              {assetType === 'Asset' && (
                <DropdownText
                  namespace="assets"
                  items={subtypeOptions}
                  title={t(keys.utilities.SUBTYPE)}
                  disabled={!state.vehicleType}
                  selected={filterData.Subtype}
                  onChange={(option) => {
                    setFilterData({
                      ...filterData,
                      Subtype: option,
                    });
                  }}
                />
              )}

              <Text noMargin weight="semiBold">
                {t(keys.utilities.CREATOR)}
              </Text>
              <div className={styles.spacer} />
              <UserSelector
                type="Creator"
                selected={filterData?.Creator ? filterData.Creator : null}
                onClose={() => {
                  delete filterData.Creator;
                  setFilterData({
                    ...filterData,
                  });
                }}
                onSelectionChanged={(user) => {
                  setFilterData({
                    ...filterData,
                    Creator: user ? user : null,
                  });
                }}
              />
              <br />
            </div>

            <div className={styles.spacer} />
            <div className={styles.saveButton}>
              <Button
                value={t(keys.action.RESET)}
                onClick={() => {
                  setState({});
                  onSave({});
                  setFilterData({});
                }}
              ></Button>
              <Button
                value={t(keys.action.SAVE)}
                onClick={() => {
                  onHide();
                  onSave({ filterData });
                }}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
