/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import styles from './MTable.module.scss';
import { Text } from '../typography';
import { useQueryParams } from '../../hooks/misc';
import { useTableURLParams } from './table';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { tableOptions, tableStyle } from './MTableComponents';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useURLParams } from '../../providers/URLParamProvider';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { Menu, MenuItem } from '@mui/material';

export default function MTTable({
  data = [],
  columns = [],
  header,
  rowCount,
  isLoading,
  customToolbar = null,
  onRowClick = () => {},
  rightClickNavigation = null,
  onClearAllFilters = () => {},
  renderDetailPanel = undefined,
  enableExpanding = false,
  enableExpandAll = false,
}) {
  const { upsertParams } = useQueryParams();
  const { tableFiltersToURL } = useTableURLParams();
  const { tableFilters, tablePageSize, tablePageIndex } = useURLParams();
  const { t } = useTranslation();
  const [sorting, setSorting] = useState([{ id: 'dateModified', desc: true }]);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const { workspaceId } = useWorkspace();

  const handleRightClick = (event, row) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
    setSelectedRow(row);
  };

  const openInNewWindow = () => {
    if (selectedRow) {
      window.open(
        `/workspace/${
          selectedRow.original.workspaceId || workspaceId
        }/${rightClickNavigation}/${selectedRow.original.id}`,
      );
    }
    setContextMenu(null);
  };

  const [pagination, setPagination] = useState({
    pageIndex: tablePageIndex,
    pageSize: tablePageSize,
  });

  const [filters, setFilters] = useState(tableFilters);

  useEffect(() => tableFiltersToURL(filters), [filters]);

  useEffect(() => {
    upsertParams({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sort: sorting[0]?.id || 'dateCreated',
      desc: sorting[0]?.desc,
    });
  }, [pagination, sorting]);

  const table = useMaterialReactTable({
    data,
    columns,
    ...tableOptions,
    ...tableStyle,
    state: {
      pagination,
      columnFilters: filters,
      isLoading,
      sorting,
    },
    initialState: {
      pagination,
      columnFilters: filters,
      density: 'compact',
      showColumnFilters: true,
    },
    rowCount,
    enableExpandAll,
    enableExpanding,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    renderDetailPanel,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onRowClick(row.original),
      onContextMenu: (e) => {
        if (rightClickNavigation) {
          handleRightClick(e, row);
        }
      },
    }),
    renderTopToolbarCustomActions: () => (
      <div className={styles.toolbar}>
        <Text
          noMargin
          hover={!!filters?.length}
          color={!!filters?.length ? 'accentPrimary' : 'secondaryLight'}
          onClick={() => {
            setFilters([]);
            onClearAllFilters();
          }}
          disabled={!!!filters}
        >
          {t(keys.common.CLEAR_ALL_FILTERS).toUpperCase()}
        </Text>
        {customToolbar}
      </div>
    ),
  });

  return (
    <div
      className={`${styles.page} ${
        table.getState().isFullScreen ? styles.fullScreen : ''
      }`}
    >
      <div className={styles.header}>{header}</div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MaterialReactTable table={table} />
        <Menu
          open={contextMenu !== null}
          onClose={() => setContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={() => openInNewWindow()}>
            {t(keys.action.OPEN_IN_NEW_WINDOW)}
          </MenuItem>
        </Menu>
      </LocalizationProvider>
    </div>
  );
}
