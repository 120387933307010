import React from 'react';
import styles from './QuickAction.module.scss';
import { Icon } from '@mui/material';
import { Text } from '../../components/typography';
import classNames from 'classnames';

export default function QuickAction({
  title,
  icon,
  color,
  onClick,
  disabled = false,
  className,
}) {
  return (
    <div
      className={classNames(
        styles.container,
        className,
        styles[color],
        disabled ? styles.disabled : null,
      )}
      onClick={onClick}
    >
      <Icon
        fontSize="inherit"
        baseClassName="material-icons-outlined"
        className={styles.icon}
      >
        {icon}
      </Icon>
      <Text
        size="sm"
        color={'white'}
        noMargin
        weight="semiBold"
        textAlign="center"
      >
        {title}
      </Text>
    </div>
  );
}
