export const deepReplace = (object, oldValue, newValue) => {
  if (typeof object !== 'object') {
    if (object === oldValue) {
      return newValue;
    }
    return object;
  }

  for (var key in object) {
    if (!object.hasOwnProperty(key)) continue;
    object[key] = deepReplace(object[key], oldValue, newValue);
  }

  return object;
};

export const isValidUUID = (uuid) => {
  var pattern = new RegExp(
    '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
    'i',
  );
  return pattern.test(uuid);
};
