import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { useURLParams } from '../../providers/URLParamProvider';

export const useTableURLParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { segment } = useURLParams();

  const tableFiltersToURL = (filters) => {
    const searchParams = new URLSearchParams(location.search);

    for (const key of Array.from(searchParams.keys())) {
      if (tableFilterParams[segment]?.includes(key)) {
        searchParams.delete(key);
      }
    }

    if (!!filters) {
      filters?.forEach(({ id, value }) => {
        if (!!value) {
          if (Array.isArray(value)) {
            if (id === 'labels') {
              searchParams.set(id, value.join(','));
            } else {
              searchParams.set(id, `${value[0]}__${value[1]}`);
            }
          } else {
            searchParams.set(id, value);
          }
        }
      });
      subFilterParams[segment]?.forEach((key) => {
        if (!filters.find((filter) => filter.id === key)) {
          searchParams.delete(key);
        }
      });

      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    }
  };

  return { tableFiltersToURL };
};

export const formatFilters = (originalField, originalValue, userId) => {
  let operator = 'eq';
  let value = originalValue;
  let field = originalField;

  switch (originalField) {
    case 'allWorkspaces':
      field = 'assignee';
      operator = 'eq';
      value = userId;
      break;
    case 'description':
    case 'title':
      operator = 'iLike';
      value = `%${value}%`;
      break;
    case 'reviewer':
    case 'creator':
    case 'workspace':
    case 'closer':
    case 'assignee':
    case 'vehicle':
      field = `${field}Id`;
      break;
    case 'Passed':
      field = 'passed';
      break;
    case 'closedAt':
    case 'dateCompleted':
    case 'dateTime':
    case 'dateCreated':
    case 'dateModified':
    case 'dueDate':
    case 'dateOfIncident':
      const [min, max] = originalValue;
      const minDateFormat =
        originalField === 'dateOfIncident'
          ? 'YYYY-MM-DD'
          : 'YYYY-MM-DD 00:00:00';

      const maxDateFormat =
        originalField === 'dateOfIncident'
          ? 'YYYY-MM-DD'
          : 'YYYY-MM-DD 23:59:59';
      const dateMin =
        min && moment(min).isValid() ? moment(min).format(minDateFormat) : null;
      const dateMax =
        max && moment(max).isValid() ? moment(max).format(maxDateFormat) : null;
      if (dateMin && !dateMax) {
        operator = 'gte';
        value = [dateMin];
      } else if (!dateMin && dateMax) {
        operator = 'lte';
        value = [dateMax];
      } else if (dateMin && dateMax) {
        operator = 'between';
        value = [dateMin, dateMax];
      } else {
        return null;
      }
      break;
    default:
      break;
  }

  return { field, operator, value };
};
export const formatSubFilter = (originalField, originalValue) => {
  switch (originalField) {
    case 'template.title':
      return {
        association: originalField.split('.')[0],
        field: originalField.split('.')[1],
        operator: 'iLike',
        value: [`%${originalValue}%`],
      };
    case 'labels':
      return {
        association: originalField,
        field: 'labelId',
        operator: 'or',
        value: [...originalValue.toString().split(',')],
      };
    default:
      return {};
  }
};

export const commonFilters = ['dateCreated', 'dateModified', 'creator', 'id'];

export const tableFilterParams = {
  actionitems: [
    ...commonFilters,
    'title',
    'assignee',
    'priority',
    'status',
    'dueDate',
    'dateCompleted',
    'workspaceId',
  ],
  assessments: [
    ...commonFilters,
    'inspectionType',
    'isDraft',
    'Passed',
    'dateCompleted',
    'vehicle',
    'workspace',
  ],
  incidents: [
    ...commonFilters,
    'title',
    'type',
    'subtype',
    'status',
    'workspace',
    'dateOfIncident',
    'closedAt',
  ],
  observations: [
    ...commonFilters,
    'description',
    'dateTime',
    'status',
    'workspace',
    'reviewer',
    'closer',
    'negative',
  ],
};
export const subFilterParams = {
  assessments: ['template.title', 'labels'],
  actionitems: ['labels'],
  incidents: ['labels'],
};

export const companyWideTables = ['incidents', 'observations'];

export const getWorkspace = (workspaceId, availableWorkspaces) => {
  return availableWorkspaces?.find((w) => w.id === workspaceId);
};
