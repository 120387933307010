import React, { useState, useEffect, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import icons from '../../assets/icons';
import styles from './EditCompany.module.scss';
import { Button } from '../../components';
import { loader } from 'graphql.macro';
import { deepCompareObjects } from '../../utilities/misc';
import { CF_DOMAIN } from '../../constants/aws';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ImageCropModal from '../../components/image_uploads/ImageCropModal';
import { Text } from '../../components/typography';
import { useTranslation } from 'react-i18next';
import { onSave } from './settings_strings';
import { keys } from '../../utilities/translator/translation_keys';
import { useCurrentUser } from '../../providers/UserProvider';

const companyQuery = loader('./EditCompany.graphql');
const updateCompanyMutation = loader(
  '../../graphql/mutations/company_update.graphql',
);
const uploadUrlQuery = loader('../../graphql/queries/s3.graphql');
const customerPortalQuery = loader('./EditCompany.customer.graphql');

export default function EditCompany() {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { user } = useCurrentUser();
  const [logoModalOpen, setLogoModalOpen] = useState(false);
  const [company, setCompany] = useState({});
  const { data: { company: originalCompany = {} } = {} } =
    useQuery(companyQuery);
  const [updateCompany] = useMutation(updateCompanyMutation);

  const { data: { fetchCustomerPortalUrl: url = '' } = {} } = useQuery(
    customerPortalQuery,
    {
      skip: !user?.id || !company?.registrationComplete,
      variables: {
        returnUrl: `${window.location.origin}${window.location.pathname}`,
      },
      fetchPolicy: 'network-only',
    },
  );

  const buttonDisabled = useMemo(
    () =>
      deepCompareObjects(
        { ...company, logoUrl: '' },
        { ...originalCompany, logoUrl: '' },
      ),
    [company, originalCompany],
  );

  useEffect(() => {
    setCompany(originalCompany);
  }, [originalCompany]);

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <Text size="lg" weight="semiBold" noMargin>
          {t(keys.settings.COMPANY_LOGO)}
        </Text>
        <br />
        <div className={styles.avatarContainer}>
          <img className={styles.avatar} src={company?.logoUrl} alt="avatar" />
          <div
            className={styles.avatarButtonContainer}
            onClick={() => setLogoModalOpen(true)}
          >
            <img
              className={styles.editButton}
              src={icons.white.edit}
              alt="edit icon"
            />
          </div>
        </div>
        <br />
        <Text size="lg" weight="semiBold">
          {t(keys.settings.BASIC_INFO)}
        </Text>
        <Form noValidate>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.COMPANY_NAME)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.COMPANY_NAME),
                })}
                value={company?.name}
                onChange={(e) =>
                  setCompany({ ...company, name: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.CONTACT_EMAIL)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.EMAIL),
                })}
                value={company?.contactEmail}
                onChange={(e) =>
                  setCompany({
                    ...company,
                    contactEmail: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.WEBSITE)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.WEBSITE),
                })}
                value={company?.website}
                onChange={(e) =>
                  setCompany({ ...company, website: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.ADDRESS)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.ADDRESS),
                })}
                value={company?.address}
                onChange={(e) =>
                  setCompany({ ...company, address: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.CITY)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.CITY),
                })}
                value={company?.city}
                onChange={(e) =>
                  setCompany({ ...company, city: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.POSTAL_CODE)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.POSTAL_CODE),
                })}
                value={company?.postalCode}
                onChange={(e) =>
                  setCompany({
                    ...company,
                    postalCode: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.PHONE)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.PHONE),
                })}
                value={company?.phone}
                onChange={(e) =>
                  setCompany({ ...company, phone: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.OBSERVATIONNAVINFO)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.OBSERVATIONNAVINFO),
                })}
                value={company?.observationName}
                onChange={(e) =>
                  setCompany({ ...company, observationName: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.INCIDENTNAVINFO)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.INCIDENTNAVINFO),
                })}
                value={company?.incidentName}
                onChange={(e) =>
                  setCompany({ ...company, incidentName: e.target.value })
                }
              />
            </Form.Group>
          </Row>
        </Form>
        <div className={styles.buttonContainer}>
          {user?.company?.registrationComplete && user?.role === 'ADMIN' && (
            <Button
              variant="primary"
              outlined
              type="submit"
              value={t(keys.settings.BILLING)}
              onClick={() => (window.location = url)}
            />
          )}
          <Button
            align={'right'}
            disabled={buttonDisabled}
            type="submit"
            value={t(keys.action.SAVE)}
            onClick={() => {
              updateCompany({ variables: { ...company } }).then((res) => {
                const errors = res.errors;
                onSave({ errors });
              });
            }}
          />
        </div>
        <br />
      </div>
      {logoModalOpen && (
        <ImageCropModal
          title="Logo"
          transparent
          onClose={() => setLogoModalOpen(false)}
          onSave={(file) => {
            setLogoModalOpen(false);

            const fileUUID = uuidv4();
            const fileParts = file.name.split('.');
            const fileName = fileUUID;
            const fileType = fileParts[1];
            client
              .query({
                query: uploadUrlQuery,
                variables: {
                  fileName: `assets/${user.company.id}/${fileName}.${fileType}`,
                  fileType,
                },
              })
              .then((data) => {
                const signedUrl = data.data.simpleStorageUploadUrl;
                const options = {
                  headers: {
                    'Content-Type': fileType,
                  },
                };
                axios.put(signedUrl, file, options).then((result) => {
                  if (result.status === 200) {
                    const newLogo = `${CF_DOMAIN(user)}${fileName}.${fileType}`;
                    updateCompany({
                      variables: { ...company, logoUrl: newLogo },
                    });
                    setCompany({
                      ...company,
                      logoUrl: newLogo,
                    });
                  }
                });
              });
          }}
        />
      )}
    </div>
  );
}
