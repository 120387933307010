import styles from './FilterModal.module.scss';
import classNames from 'classnames';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import UserSelector from '../UserSelector';
import Button from '../Button';
import { useState, useEffect } from 'react';
import SelectableTag from '../SelectableTag';
import { incidentTypes } from '../../utilities/incidents';
import { DropdownText } from '../dropdowns/DropdownText';
import DropdownCombo from '../dropdowns/DropdownCombo';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Form } from 'react-bootstrap';
import { dropdownTypes } from '../dropdowns/dropdown';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
export default function TableFilterModal({
  show = false,
  onHide,
  filterOptions,
  statusOptions,
  priorityOptions,
  onSave,
  userQuery,
}) {
  const { t } = useTranslation();

  const badgeTypes = [
    { value: 'HSE', icon: 'health_and_safety', text: t(keys.common.HSE) },
    { value: 'VEHICLE', icon: 'local_shipping', text: t(keys.common.VEHICLE) },
    { value: 'QUALITY', icon: 'auto_awesome', text: t(keys.common.QUALITY) },
    {
      value: 'COMMISSIONING',
      icon: 'engineering',
      text: t(keys.common.COMMISSIONING),
    },
    {
      value: 'MEETING',
      icon: 'calendar_month',
      text: t(keys.common.MEETING),
    },
    {
      value: 'DAILY_REPORT',
      icon: 'assignment_ind_icon',
      text: t(keys.common.DAILY_REPORT),
    },
  ];

  const [filterData, setFilterData] = useState(filterOptions);
  const [state, setState] = useState({});
  const { workspaceVehicles, availableWorkspaces } = useWorkspace();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [dateRangeStart, setDateRangeStart] = useState(null);
  const [dateRangeEnd, setDateRangeEnd] = useState(null);

  const handleFilter = (filterType, filterValue) => {
    if (filterData[filterType]?.includes(filterValue)) {
      setFilterData({
        ...filterData,
        [filterType]: filterData[filterType].filter((f) => f !== filterValue),
      });
    } else {
      setFilterData({
        ...filterData,
        [filterType]: filterData[filterType]
          ? [...filterData[filterType], filterValue]
          : [filterValue],
      });
    }
  };

  useEffect(() => {
    setFilterData({});
    setSelectedVehicle(null);
    setSelectedWorkspace(null);
    setDateRangeEnd(null);
    setDateRangeStart(null);
    setState({});
  }, [statusOptions, priorityOptions, filterOptions]);

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.infoContainer}>
            <div className={styles.modalHeader}>
              <Text color="accentPrimary" size="lg" noMargin weight="bold">
                {t(keys.utilities.FILTERS)}
              </Text>
              <div className={styles.spacer} />
              <div className={styles.closeIcon}>
                <Icon
                  baseClassName="material-icons-outlined"
                  className={classNames(styles.iconButton, styles.red)}
                  onClick={() => {
                    onHide();
                  }}
                >
                  close
                </Icon>
              </div>
            </div>
            <div className={styles.filterContainer}>
              {filterOptions?.includes('Type') &&
                !filterOptions?.includes('INCIDENT') && (
                  <>
                    <Text noMargin weight="bold" color="secondary">
                      {t(keys.common.TYPE)}
                    </Text>
                    <div className={styles.spacer} />
                    <div role="button" className={styles.badges}>
                      {badgeTypes.map((badge) => (
                        <div key={badge.text}>
                          <SelectableTag
                            value={badge.value}
                            icon={badge.icon}
                            text={badge.text}
                            selected={filterData.Type}
                            onSelect={(filter) => handleFilter('Type', filter)}
                            namespace={'common'}
                          />
                        </div>
                      ))}
                    </div>
                    <br />
                  </>
                )}
              {filterOptions?.includes('INCIDENT') && (
                <>
                  <DropdownText
                    items={Object.keys(incidentTypes)}
                    onChange={(option) => {
                      setFilterData({
                        ...filterData,
                        Type: option,
                      });
                      setState({
                        ...state,
                        Type: option,
                        Subtype: '',
                      });
                    }}
                    selected={state.Type}
                    title={t(keys.common.TYPE)}
                  />
                  <DropdownText
                    items={incidentTypes[state.Type]}
                    onChange={(option) => {
                      setFilterData({
                        ...filterData,
                        Subtype: option,
                      });
                      setState({
                        ...state,
                        Subtype: option,
                      });
                    }}
                    selected={state.Subtype}
                    title={t(keys.utilities.SUBTYPE)}
                    disabled={!state.Type}
                  />
                </>
              )}
              {filterOptions?.includes('Asset') && (
                <>
                  <Text noMargin weight="bold" color="secondary">
                    {t(keys.common.ASSET)}
                  </Text>
                  <DropdownCombo
                    show={show}
                    type={dropdownTypes.ASSET}
                    items={workspaceVehicles}
                    onChange={(asset) => {
                      setFilterData({ ...filterData, Asset: asset });
                      setSelectedVehicle(asset);
                    }}
                    selected={selectedVehicle}
                  />
                </>
              )}

              {filterOptions?.includes('Creator') && (
                <>
                  <Text noMargin weight="bold" color="secondary">
                    {t(keys.common.CREATOR)}
                  </Text>
                  <div className={styles.spacer} />
                  <UserSelector
                    userQuery={userQuery}
                    type="Creator"
                    selected={filterData.Creator || null}
                    onClose={() => {
                      delete filterData.Creator;
                      setFilterData({
                        ...filterData,
                      });
                    }}
                    onSelectionChanged={(user) => {
                      setFilterData({
                        ...filterData,
                        Creator: user ? user : null,
                      });
                    }}
                  />
                  <br />
                </>
              )}
              {filterOptions?.includes('Status') && (
                <>
                  <Text noMargin weight="bold" color="secondary">
                    {t(keys.common.STATUS)}
                  </Text>
                  <div className={styles.spacer} />
                  <div className={styles.statusButtons}>
                    {statusOptions?.map((filter) => (
                      <SelectableTag
                        multiSelect={false}
                        key={filter}
                        text={filter}
                        value={filter}
                        selected={filterData.Status}
                        onSelect={(filter) =>
                          setFilterData({ ...filterData, Status: filter })
                        }
                        namespace={
                          filterOptions.includes('INCIDENT')
                            ? 'incidents'
                            : 'utilities'
                        }
                      />
                    ))}
                  </div>
                  <br />
                </>
              )}

              {filterOptions?.includes('Priority') && (
                <>
                  <Text noMargin weight="bold" color="secondary">
                    {t(keys.utilities.PRIORITY)}
                  </Text>
                  <div className={styles.spacer} />
                  <div className={styles.statusButtons}>
                    {priorityOptions?.map((priority) => (
                      <SelectableTag
                        multiSelect={false}
                        key={priority}
                        text={priority}
                        value={priority}
                        selected={filterData.Priority}
                        onSelect={(filter) =>
                          setFilterData({ ...filterData, Priority: filter })
                        }
                        namespace={'utilities'}
                      />
                    ))}
                  </div>
                  <br />
                </>
              )}
              {filterOptions?.includes('Assignee') && (
                <>
                  <Text noMargin weight="bold" color="secondary">
                    {t(keys.common.ASSIGNEE)}
                  </Text>
                  <div className={styles.spacer} />
                  <UserSelector
                    type="Assignee"
                    selected={filterData.Assignee || null}
                    onClose={() => {
                      delete filterData.Assignee;
                      setFilterData({
                        ...filterData,
                      });
                    }}
                    onSelectionChanged={(user) => {
                      setFilterData({
                        ...filterData,
                        Assignee: user ? user : null,
                      });
                    }}
                  />
                  <br />
                </>
              )}
              {filterOptions?.includes('Date Range') && (
                <>
                  <Text noMargin weight="bold" color="secondary">
                    {t(keys.utilities.DATERANGE)}
                  </Text>
                  <div className={styles.spacer} />
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className={styles.dateRange}>
                      <DatePicker
                        maxDate={dateRangeEnd}
                        value={dateRangeStart}
                        label={t(keys.utilities.START)}
                        onChange={(newMoment) => {
                          setDateRangeStart(newMoment);
                          setFilterData({
                            ...filterData,
                            DateRange: [newMoment, dateRangeEnd || newMoment],
                          });
                        }}
                      />
                      <div className={styles.dash}>-</div>
                      <DatePicker
                        minDate={dateRangeStart}
                        value={dateRangeEnd}
                        label={t(keys.utilities.END)}
                        onChange={(newMoment) => {
                          setDateRangeEnd(newMoment);
                          setFilterData({
                            ...filterData,
                            DateRange: [dateRangeStart || newMoment, newMoment],
                          });
                        }}
                      />
                    </div>
                  </LocalizationProvider>
                  <br />
                </>
              )}
              {filterOptions?.includes('Workspace') && (
                <>
                  <Text noMargin weight="bold" color="secondary">
                    Workspace
                  </Text>
                  <div className={styles.spacer} />
                  <DropdownCombo
                    disabled={true}
                    items={availableWorkspaces || []}
                    type={dropdownTypes.WORKSPACE}
                    selected={selectedWorkspace}
                    onChange={(workspace) => {
                      setFilterData({
                        ...filterData,
                        Workspace: workspace,
                      });
                      setSelectedWorkspace(workspace);
                    }}
                  />
                  <br />
                </>
              )}
              {filterOptions?.includes('Project') && (
                <>
                  <Text noMargin weight="bold" color="secondary">
                    Project (work order, job number, etc.)
                  </Text>
                  <div className={styles.spacer} />
                  <Form.Control
                    type="text"
                    onChange={({ target: { value } }) => {
                      setFilterData({ ...filterData, Project: value });
                    }}
                  />
                  <br />
                </>
              )}
            </div>

            <div className={styles.spacer} />
            <div className={styles.tableSaveButton}>
              <Button
                value={t(keys.action.RESET)}
                onClick={() => {
                  onSave({});
                  setState({});
                  setFilterData({});
                  setSelectedWorkspace(null);
                  setDateRangeStart(null);
                  setDateRangeEnd(null);
                }}
              ></Button>
              <Button
                value={t(keys.action.SAVE)}
                onClick={() => {
                  if (filterData.DateRange) {
                    const dateInvalid = filterData.DateRange.some(
                      (date) => date === null,
                    );
                    if (dateInvalid) {
                      delete filterData.DateRange;
                      setFilterData({ ...filterData });
                    }
                  }
                  onHide();
                  onSave({ filterData });
                }}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
