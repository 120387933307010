import React from 'react';
import styles from './Searchbar.module.scss';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { useDebouncedRemoteState } from '../hooks/misc';

export default function Searchbar({
  className,
  value,
  onChange,
  openMenu,
  title,
  debounce = false,
}) {
  const [search, setSearch] = useDebouncedRemoteState(value, onChange);
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.container, className)}>
      <Icon
        className={styles.icon}
        fontSize="inherit"
        baseClassName="material-icons-outlined"
      >
        search
      </Icon>
      <input
        className={styles.input}
        value={debounce ? search : value}
        onChange={(e) =>
          debounce ? setSearch(e.target.value) : onChange(e.target.value)
        }
        placeholder={t(keys.action.SEARCH, { variable: title || null })}
        onFocus={openMenu}
      />
    </div>
  );
}
