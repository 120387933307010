import React, { useRef, useState, useMemo } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import styles from './CompanySignUp.module.scss';
import WorkflowStageFooter from '../../components/workflows/WorkflowStageFooter';
import { Text } from '../../components/typography';
import { emailRegex } from '../../utilities/validation';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const regex = new RegExp(emailRegex);

const createCompanyMutation = loader('./CompanySignUp.create.graphql');
const createSessionMutation = loader('./CompanySignUp.session.graphql');

export default function CompanySignUpInfo({
  newCompanyState,
  setNewCompanyState,
  workflow,
  completed,
  onCompleted,
  currentStage,
}) {
  const { t } = useTranslation();
  const [captchaToken, setCaptchaToken] = useState('');
  const recaptchaRef = useRef();
  const [createCompany] = useMutation(createCompanyMutation);
  const [createSession] = useMutation(createSessionMutation);

  const cancelUrl = `${window.location.origin}${window.location.pathname}`;
  const successUrl = `${window.location.origin}${window.location.pathname}?payment=success`;

  const nextStageDisabled = useMemo(
    () => !regex.test(newCompanyState.contactEmail) || !captchaToken,
    [newCompanyState, captchaToken],
  );

  return (
    <>
      <div className={styles.componentContainer}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div>
            <Text size="xl" weight="semiBold">
              {t(keys.companySignUp.HEADER_P1)}
            </Text>
          </div>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>{t(keys.companySignUp.INFO_NAME)}</Form.Label>
              <Form.Control
                isValid={!!newCompanyState?.name?.length}
                isInvalid={!newCompanyState?.name?.length}
                required
                type="text"
                placeholder={t(keys.companySignUp.INFO_NAME)}
                value={newCompanyState?.name}
                onChange={({ target: { value } }) =>
                  setNewCompanyState({ ...newCompanyState, name: value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>{t(keys.companySignUp.INFO_EMAIL)}</Form.Label>
              <Form.Control
                required
                isValid={regex.test(newCompanyState.contactEmail)}
                isInvalid={!regex.test(newCompanyState.contactEmail)}
                type="text"
                placeholder={t(keys.companySignUp.INFO_EMAIL)}
                value={newCompanyState?.contactEmail}
                onChange={({ target: { value } }) =>
                  setNewCompanyState({
                    ...newCompanyState,
                    contactEmail: value,
                  })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>{t(keys.companySignUp.INFO_PHONE)}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t(keys.companySignUp.INFO_PHONE)}
                value={newCompanyState?.phone}
                onChange={({ target: { value } }) =>
                  setNewCompanyState({
                    ...newCompanyState,
                    phone: value,
                  })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="mb-3">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size="normal"
                ref={recaptchaRef}
                onChange={(e) => setCaptchaToken(e)}
              />
            </Form.Group>
          </Row>
          <WorkflowStageFooter
            hideBackButton
            nextStageDisabled={nextStageDisabled}
            onNext={() => {
              createCompany({
                variables: { ...newCompanyState, captchaToken },
              }).then(
                ({
                  data: {
                    createCompany: { id },
                  },
                }) => {
                  // setNewCompanyState({
                  //   ...newCompanyState,
                  //   id,
                  // });
                  createSession({
                    variables: {
                      companyId: id,
                      cancelUrl,
                      successUrl,
                    },
                  }).then(({ data, errors }) => {
                    window.location = data.createSignupSession;
                  });
                },
              );
            }}
          />
        </Form>
      </div>
    </>
  );
}
