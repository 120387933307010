import React from 'react';
import styles from './WorkflowSidebar.module.scss';
import WorkflowStage from './WorkflowStage';

export default function WorkflowSidebar({
  selected,
  onChange,
  workflow,
  completed,
}) {
  return (
    <div className={styles.container}>
      {workflow.map(({ key, title, subtitle, icon }) => (
        <WorkflowStage
          key={key}
          workflowKey={key}
          title={title}
          subtitle={subtitle}
          completed={completed.includes(key)}
          icon={icon}
          selected={selected === key}
          selectedTitle={selected}
          workflow={workflow}
          onClick={() => onChange(key)}
        />
      ))}
    </div>
  );
}
