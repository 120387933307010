import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import VehicleMain from '../../components/vehicles/VehicleMain';
import WorkflowStageFooter from '../../components/workflows/WorkflowStageFooter';
import WorkflowStageHeader from '../../components/workflows/WorkflowStageHeader';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { cache } from '../../graphql/cache';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { getRoute } from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import styles from './ReviewVehicle.module.scss';

const vehicleQuery = loader('./ReviewVehicle.fetch.graphql');

export default function ReviewVehicle({
  vehicleId,
  workflow,
  completed,
  onCompleted,
  currentStage,
  onBack,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { workspace, refetchWorkspace } = useWorkspace();
  const { data: { vehicles: [vehicle] = [{}] } = {} } = useQuery(vehicleQuery, {
    skip: !vehicleId,
    returnPartialData: true,
    variables: {
      vehicleId: `${vehicleId}`,
    },
  });
  return (
    <div className={styles.container}>
      <div>
        <WorkflowStageHeader
          title={t(keys.assets.P4_HEADER)}
          subtitle={t(keys.assets.P4_SUBTEXT)}
          workflow={workflow}
          completed={completed}
        />
        <VehicleMain vehicle={vehicle} />
      </div>
      <br />
      <WorkflowStageFooter
        onBack={onBack}
        nextValue={t(keys.action.PUBLISH)}
        onNext={() => {
          onCompleted(currentStage);
          cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'vehicles',
          });
          cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'vehiclesCount',
          });
          refetchWorkspace();
          navigate(getRoute(workspace?.id, paths.assets));
        }}
      />
    </div>
  );
}
