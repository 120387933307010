import { Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { vehicleString } from '../strings';
import noImage from '../../assets/icons/image_blank.png';
import { vehicleKeys } from '../vehicles';

export const VehicleCard = ({ vehicle }) => {
  Font.register({
    family: 'eina03semibold',
    src: '/eina-03-semibold.woff',
  });

  Font.register({
    family: 'eina03regular',
    src: '/eina-03-regular.woff',
  });
  const fontSize = 8;
  const blue = '#0d6efd';

  const { odometer, hours, imageUrl } = vehicle || {};
  const styles = StyleSheet.create({
    container: {
      width: 250,
      flexDirection: 'row',
      gap: 5,
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 10,
      padding: 5,
    },
    vehicleImage: {
      width: 'auto',
      height: 50,
      display: 'block',
    },
    heading: { fontSize: fontSize + 2, color: blue },
    header: { flexDirection: 'row', alignItems: 'center', gap: 5 },
    text: { fontSize, wordBreak: 'break-all' },
  });
  return (
    <View style={styles.container}>
      <Image src={imageUrl ?? noImage} style={styles.vehicleImage} />
      <View>
        <Text style={styles.heading}>{vehicleString(vehicle)}</Text>
        {vehicleKeys.includes(vehicle.type) ? (
          <View style={styles.header}>
            <Text style={styles.text}>Odometer</Text>
            <Text style={styles.text}>{odometer || 0}</Text>
          </View>
        ) : null}
        {vehicle.type !== 'Light Vehicle' ? (
          <View style={styles.header}>
            <Text style={styles.text}>Hours</Text>
            <Text style={styles.text}>{hours || 0}</Text>
          </View>
        ) : null}
      </View>
    </View>
  );
};
