import React from 'react';
import styles from './ImageViewer.module.scss';

export default function ImageViewer({ file }) {
  return (
    <div className={styles.container}>
      <img className={styles.image} src={file.url} alt={file.name} />
    </div>
  );
}
