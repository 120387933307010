import React from 'react';
import Button from '../Button';
import styles from './WorkflowStageFooter.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function WorkflowStageFooter({
  onNext,
  onBack,
  nextStageDisabled,
  hideBackButton,
  nextValue,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Button
        className={hideBackButton ? styles.hide : styles.nextButton}
        outlined={true}
        value="Back"
        onClick={onBack}
      />
      <Button
        testId={'workflow-next'}
        className={styles.nextButton}
        disabled={nextStageDisabled}
        value={nextValue || t(keys.action.NEXT)}
        onClick={onNext}
      />
    </div>
  );
}
