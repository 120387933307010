import React, { useEffect, useMemo, useRef, useState } from 'react';
import RichTextEditor from '../../components/rich_text/RichTextEditor';
import styles from './Announcements.module.scss';
import { Text } from '../../components/typography';
import AnnouncementCard from '../../components/AnnouncementCard';
import Modal from '../../components/modals/Modal';
import Button from '../../components/Button';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useQueryParams } from '../../hooks/misc';
import debounce from 'lodash.debounce';
import { cache } from '../../graphql/cache';
import moment from 'moment';
import { ANNOUNCEMENT_INTERACTION_STATUS } from '../../constants/misc';
import SimpleUserCard from '../../components/SimpleUserCard';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const addAnnouncementMutation = loader('./Announcements.add.graphql');
const updateAnnouncementMutation = loader('./Announcements.update.graphql');
const publishAnnouncementMutation = loader('./Announcements.publish.graphql');
const deleteAnnouncementMutation = loader('./Announcements.delete.graphql');

const announcementQuery = loader('./Announcements.fetch.graphql');
const announcementInteractionQuery = loader(
  './Announcements.interactions.graphql',
);

const UPDATE_DEBOUNCE = 400;

const customStyles = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: '165px',
    overflow: 'auto',
  }),
};

export default function Announcements() {
  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [complianceModalOpen, setComplianceModalOpen] = useState(false);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [newAnnouncementName, setNewAnnouncementName] = useState('');
  const { availableWorkspaces } = useWorkspace();
  const [releaseDate, setReleaseDate] = useState(
    new moment().format('yyyy-MM-DD'),
  );
  const { t } = useTranslation();

  const { getParam, upsertParam } = useQueryParams();
  const selected = parseInt(getParam('selected') || '0');

  const [addAnnouncement] = useMutation(addAnnouncementMutation, {
    refetchQueries: ['GetAnnouncements', 'GetAnnouncementsForAdmin'],
  });
  const [updateAnnouncement] = useMutation(updateAnnouncementMutation);
  const [publishAnnouncement] = useMutation(publishAnnouncementMutation);
  const [deleteAnnouncement] = useMutation(deleteAnnouncementMutation);

  const {
    refetch: refetchAnnouncements,
    loading,
    data: { announcements = [] } = {},
  } = useQuery(announcementQuery);

  const { data: { announcementInteractions = [] } = {} } = useQuery(
    announcementInteractionQuery,
    { variables: { id: `${selected}` }, skip: !complianceModalOpen },
  );

  const workspaceOptions = useMemo(
    () => availableWorkspaces.map((w) => ({ value: w.id, label: w.title })),
    [availableWorkspaces],
  );

  const debouncedUpdateAnnouncementRef = useRef(
    debounce((updateFunction, variables) => {
      updateFunction({
        variables,
      });
    }, UPDATE_DEBOUNCE),
  );

  useEffect(() => {
    if (!selected && announcements.length) {
      upsertParam('selected', announcements[0].id);
    }
  }, [selected, announcements, upsertParam]);

  useEffect(() => {
    debouncedUpdateAnnouncementRef.current = debounce(
      (updateFunction, variables) => {
        updateFunction({
          variables,
        });
      },
      UPDATE_DEBOUNCE,
    );
  }, []);

  const selectedAnnoucement = useMemo(
    () => announcements?.find((a) => a.id === selected),
    [selected, announcements],
  );

  return (
    <div className={styles.parent}>
      <Modal
        title={t(keys.announcements.COMPLIANCE)}
        open={complianceModalOpen}
        onClose={() => {
          setComplianceModalOpen(false);
        }}
        hideSubmit
        cancelText="Okay"
      >
        <div className={styles.complianceContent}>
          <div className={styles.complianceColumn}>
            <Text size="md" textAlign="center" weight="semiBold">
              {t(keys.announcements.NOTSEEN)}
            </Text>
            <div className={styles.complianceUsers}>
              {announcementInteractions
                .filter(
                  (a) => a.status === ANNOUNCEMENT_INTERACTION_STATUS.unread,
                )
                .map(({ user }) => (
                  <SimpleUserCard key={user.id} user={user} />
                ))}
            </div>
          </div>
          <div className={styles.complianceColumn}>
            <Text size="md" textAlign="center" weight="semiBold">
              {t(keys.announcements.SNOOZED)}
            </Text>
            <div className={styles.complianceUsers}>
              {announcementInteractions
                .filter(
                  (a) => a.status === ANNOUNCEMENT_INTERACTION_STATUS.snoozed,
                )
                .map(({ user }) => (
                  <SimpleUserCard key={user.id} user={user} />
                ))}
            </div>
          </div>
          <div className={styles.complianceColumn}>
            <Text size="md" textAlign="center" weight="semiBold">
              {t(keys.announcements.ACCEPTED)}
            </Text>
            <div className={styles.complianceUsers}>
              {announcementInteractions
                .filter(
                  (a) => a.status === ANNOUNCEMENT_INTERACTION_STATUS.read,
                )
                .map(({ user }) => (
                  <SimpleUserCard key={user.id} user={user} />
                ))}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={t(keys.announcements.LAUNCHANNOUNCEMENT)}
        open={publishModalOpen}
        submitText={t(keys.announcements.LAUNCH)}
        submitDisabled={!selectedWorkspaces.length || !releaseDate.length}
        onClose={() => {
          setPublishModalOpen(false);
          setReleaseDate(new Date());
          setSelectedWorkspaces([]);
        }}
        onSubmit={() => {
          setPublishModalOpen(false);
          publishAnnouncement({
            variables: {
              id: selected,
              workspaceIds: selectedWorkspaces.map((w) => w.value),
              dateOfRelease: releaseDate,
            },
          }).then(() => {
            setReleaseDate(new Date());
            setSelectedWorkspaces([]);
            refetchAnnouncements();
          });
        }}
      >
        <div className={styles.publishContent}>
          <img
            className={styles.launchGraphic}
            src="https://app.opasmobile.com/assets/graphics/announcement_launch.png"
            alt="no announcements"
          />
          <Text textAlign="center" weight="semiBold" color="secondary">
            {t(keys.announcements.SCHEDULEMESSAGE)}
          </Text>
          <Form.Label>{t(keys.common.WORKSPACES)}</Form.Label>
          <Select
            closeMenuOnSelect={false}
            isMulti
            styles={customStyles}
            options={workspaceOptions}
            value={selectedWorkspaces}
            onChange={(newData) => {
              setSelectedWorkspaces(newData);
            }}
          />
          <br />
          <Form.Label>{t(keys.announcements.RELEASEDATE)}</Form.Label>
          <Form.Control
            type="date"
            placeholder={t(keys.announcements.RELEASEPLACEHOLDER)}
            value={releaseDate}
            onChange={(e) => setReleaseDate(e.target.value)}
          />
          <br />
          <br />
        </div>
      </Modal>
      <Modal
        title={t(keys.action.ADD, {
          variable: t(keys.announcements.ANNOUNCEMENT),
        })}
        open={creationModalOpen}
        submitDisabled={!newAnnouncementName.length}
        submitText={t(keys.action.CREATE)}
        onSubmit={() => {
          setCreationModalOpen(false);
          addAnnouncement({
            variables: {
              title: newAnnouncementName,
            },
          }).then(
            ({
              data: {
                addAnnouncement: { id },
              },
            }) => {
              setNewAnnouncementName('');
              refetchAnnouncements().then(() => {
                upsertParam('selected', id);
              });
            },
          );
        }}
        onClose={() => {
          setCreationModalOpen(false);
          setNewAnnouncementName('');
        }}
      >
        <div className={styles.modalContent}>
          <img
            className={styles.graphic}
            src="https://app.opasmobile.com/assets/graphics/megaphone_01.png"
            alt="megaphone"
          />
          <Text textAlign="center" size="lg" weight="semiBold">
            {t(keys.announcements.NAMEYOURANNOUNCEMENT)}
          </Text>
          <Text textAlign="center" weight="semiBold" color="secondary">
            {t(keys.announcements.CUSTOMIZEYOURANNOUNCEMENT)}
          </Text>
          <br />
          <Form.Label>{t(keys.announcements.ANNOUNCEMENTNAME)}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t(keys.announcements.ENTERANNOUNCEMENTNAME)}
            value={newAnnouncementName}
            onChange={(e) => setNewAnnouncementName(e.target.value)}
          />
        </div>
      </Modal>
      <div className={styles.header}>
        <Text size="lg" color="accentPrimary" weight="Bold">
          {t(keys.announcements.ANNOUNCEMENTS)}
        </Text>
        <div className={styles.headerButtons}>
          <Button
            value={t(keys.announcements.LAUNCH)}
            icon="rocket_launch"
            outlined
            disabled={!selectedAnnoucement?.isDraft}
            onClick={() => setPublishModalOpen(true)}
          />
          <Button
            icon="add"
            value={t(keys.common.NEW)}
            onClick={() => setCreationModalOpen(true)}
          />
        </div>
      </div>
      <div className={styles.container}>
        {!announcements?.length && !loading && (
          <div className={styles.emptyStateContainer}>
            <img
              className={styles.emptyStateGraphic}
              src="https://app.opasmobile.com/assets/graphics/announcement_empty.png"
              alt="no announcements"
            />
            <Text textAlign="center" size="lg" weight="semiBold">
              {t(keys.announcements.EMPTYSTATETITLE)}
            </Text>
            <Text textAlign="center" weight="semiBold" color="secondary">
              {t(keys.announcements.EMPTYSTATEMESSAGE)}
            </Text>
            <Text textAlign="center" weight="semiBold" color="secondary">
              {t(keys.announcements.EMPTYSTATESUBMESSAGE)}
            </Text>
          </div>
        )}
        {!!announcements?.length && (
          <div className={styles.leftSide}>
            {announcements.map((announcement) => (
              <AnnouncementCard
                key={announcement.id}
                selected={announcement.id === selected}
                onOpenCompliance={() => setComplianceModalOpen(true)}
                onDelete={() => {
                  deleteAnnouncement({
                    variables: { id: announcement.id },
                  }).then(() => {
                    cache.evict(cache.identify(announcement));
                  });
                }}
                onClick={() => {
                  upsertParam('selected', announcement.id);
                }}
                announcement={announcement}
              />
            ))}
          </div>
        )}
        {selectedAnnoucement?.id && (
          <RichTextEditor
            key={selectedAnnoucement.id}
            readOnly={!selectedAnnoucement.isDraft}
            id={selectedAnnoucement.id}
            value={selectedAnnoucement.content}
            onChange={(newState, newDescription) => {
              if (selectedAnnoucement.isDraft) {
                debouncedUpdateAnnouncementRef.current(updateAnnouncement, {
                  id: selected,
                  content: newState,
                  description: newDescription,
                });
              }
            }}
            className={styles.rightSide}
          />
        )}
      </div>
    </div>
  );
}
