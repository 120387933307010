import { createContext, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  formatFilters,
  formatSubFilter,
  subFilterParams,
  tableFilterParams,
  companyWideTables,
} from '../components/tables/table';
import { useWorkspace } from './WorkspaceProvider';
import { useQueryParams } from '../hooks/misc';

const URLParamContext = createContext();

export const URLParamProvider = ({ children }) => {
  const location = useLocation();
  const { workspaceId, isWorkspaceRoute } = useWorkspace();
  const { deleteParam, getParam } = useQueryParams();
  const params = new URLSearchParams(location.search);
  const segment = window.location.pathname.split('/')[isWorkspaceRoute ? 3 : 1];
  const hasURLParams = Object.keys(tableFilterParams).includes(segment);

  const getParams = useMemo(() => {
    if (!!hasURLParams) {
      const queryParams = {};
      const subfilters = [];
      const allWorkspaces = getParam('allWorkspaces');
      const paramWorkspaceId = getParam('workspaceId');

      const filters =
        companyWideTables.includes(segment) || !!allWorkspaces
          ? []
          : [
              {
                field: 'workspaceId',
                operator: 'eq',
                value: `${paramWorkspaceId ?? workspaceId}`,
              },
            ];

      if (segment === 'assessments' && !allWorkspaces) {
        filters.push({
          field: 'workspaceId',
          operator: 'or',
          value: [`${paramWorkspaceId ?? workspaceId}`, 'null'],
        });
      }

      if (segment === 'actionitems') {
        filters.push({
          field: 'status',
          operator: getParam('open') === 'true' ? 'not' : 'or',
          value: ['DONE', 'DUPLICATE'],
        });
      }

      for (const [key, value] of params.entries()) {
        queryParams[key] = value;
      }
      queryParams.pageIndex = parseInt(queryParams.pageIndex);
      queryParams.pageSize = parseInt(queryParams.pageSize);

      for (const [key, value] of Object.entries(queryParams)) {
        let newValue = undefined;
        if (
          tableFilterParams[segment].includes(key) &&
          value !== '__' &&
          !!value
        ) {
          if (value.includes('__')) {
            newValue = value.split('__');
          }
          const filter = formatFilters(key, newValue || value);
          filters.push(filter);
        }
        if (subFilterParams[segment]?.includes(key) && !!value) {
          const subfilter = formatSubFilter(key, value);
          subfilters.push(subfilter);
        }
      }
      queryParams.sort = [
        {
          field: queryParams.sort,
          order:
            queryParams.desc === 'true' ? 'desc NULLS last' : 'asc NULLS last',
        },
      ];

      queryParams.filters = filters;
      queryParams.subfilters = subfilters;
      return queryParams;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, workspaceId, params]);

  const { pageSize, pageIndex, filters, sort, subfilters } = getParams || {};

  const tableParams = useMemo(() => {
    if (!!hasURLParams) {
      const searchParams = new URLSearchParams(location.search);
      const params = {};

      searchParams.forEach((value, key) => {
        if (key === 'pageIndex' || key === 'pageSize') {
          params[key] = parseInt(value);
        }
        if (value === '__') {
          deleteParam(key);
        }
        if (value.includes('__')) {
          params[key] = value.split('__');
        } else {
          params[key] = value;
        }
      });

      const tableFilters = Object.entries(params)
        .filter(([key]) => tableFilterParams[segment].includes(key))
        .map(([key, value]) => ({ id: key, value }));

      const tablePageIndex = params.pageIndex || 0;
      const tablePageSize = params.pageSize || 10;

      return { tableFilters, tablePageIndex, tablePageSize };
    }
  }, [deleteParam, hasURLParams, location.search, segment]);

  const { tableFilters, tablePageIndex, tablePageSize } = tableParams || {};

  return (
    <URLParamContext.Provider
      value={{
        pageSize,
        page: pageIndex + 1,
        filters,
        sort,
        subfilters,
        tableFilters,
        tablePageIndex,
        tablePageSize,
        segment,
      }}
    >
      {children}
    </URLParamContext.Provider>
  );
};
export const useURLParams = () => useContext(URLParamContext);
