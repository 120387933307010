import React from 'react';
import { Form } from 'react-bootstrap';
import { clamp } from '../../utilities/misc';
import { useDebouncedRemoteState } from '../../hooks/misc';
import { Text } from '../typography';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';

const numberRange = {
  min: 0,
  max: 1_000_000_000,
};

export default function NumberInput({ value, onChange, readOnly }) {
  const [number, setNumber] = useDebouncedRemoteState(value, onChange);
  const { t } = useTranslation();

  return readOnly ? (
    <Text noMargin>{value || 0}</Text>
  ) : (
    <Form.Control
      value={number || ''}
      onChange={(e) => {
        const maxLength = `${numberRange.max}`.length - 1;
        const newNumber = parseInt(e.target.value.slice(0, maxLength), 10);
        setNumber(clamp(newNumber, numberRange.min, numberRange.max));
      }}
      type="number"
      placeholder={t(keys.action.ENTER, { variable: t(keys.common.NUMBER) })}
    />
  );
}
