import { useApolloClient, useMutation } from '@apollo/client';
import { Icon } from '@mui/material';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useMemo } from 'react';
import InspectionTypeBadge from '../../components/inspections/InspectionTypeBadge';
import LabelSelector from '../../components/LabelSelector';
import SimpleUserCard from '../../components/SimpleUserCard';
import { Text } from '../../components/typography';
import UserMultiSelector from '../../components/UserMultiSelector';
import { inspectionApprovalTypes, paths } from '../../constants/strings';
import { getLocalTime } from '../../utilities/time';
import styles from './RightSidebar.module.scss';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../graphql/cache/modal';
import { toastVariant, toastLength, reviewStatus } from '../../constants/misc';
import { useQuery } from '@apollo/client';
import ActionItemCard from '../../components/action_items/ActionItemCard';
import { Button } from '../../components';
import { AssessmentDocument } from '../../utilities/pdf_export/assessment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useActionItemModal } from '../../hooks/misc';
import { getRoute } from '../../constants/strings';
import { useOnlineStatus } from '../../hooks/offline-hooks/offline-misc';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { getTranslationKey } from '../../utilities/translator/translation_keys';
import { keys } from '../../utilities/translator/translation_keys.js';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import Bugsnag from '@bugsnag/js';
import { useModal } from '../../providers/ModalProvider.jsx';
import Label from '../../components/action_items/Label.jsx';

const addLabelMutation = loader('./RightSidebar.addLabel.graphql');
const deleteLabelMutation = loader('./RightSidebar.deleteLabel.graphql');
const addReviewerMutation = loader('./RightSidebar.addReviewer.graphql');
const deleteReviewerMutation = loader('./RightSidebar.deleteReviewer.graphql');
const addRegretMutation = loader('./RightSidebar.addRegret.graphql');
const deleteRegretMutation = loader('./RightSidebar.deleteRegret.graphql');
const addParticipantMutation = loader('./RightSidebar.addParticipant.graphql');
const deleteParticipantMutation = loader(
  './RightSidebar.deleteParticipant.graphql',
);
const addActionItemMutation = loader('./RightSideBar.addActionItem.graphql');
const actionItemsQuery = loader('./RightSidebar.fetchActionItems.graphql');

const deleteInspectionMutation = loader(
  './RightSidebar.deleteInspection.graphql',
);
const revertInspectionMutation = loader('./Inspection.revert.graphql');

export default function RightSidebar({
  inspection,
  refetch,
  className,
  onClose,
}) {
  const client = useApolloClient();
  const { workspace, isWorkspaceAdmin } = useWorkspace() || {};
  const { user, isAdmin } = useCurrentUser();
  const online = useOnlineStatus();
  const navigate = useNavigate();
  const { openActionItemModal } = useActionItemModal();
  const [addLabel] = useMutation(addLabelMutation);
  const [deleteLabel] = useMutation(deleteLabelMutation);
  const [addParticipant] = useMutation(addParticipantMutation);
  const [deleteParticipant] = useMutation(deleteParticipantMutation);
  const [deleteReviewer] = useMutation(deleteReviewerMutation);
  const [addActionItem] = useMutation(addActionItemMutation);
  const [deleteInspection] = useMutation(deleteInspectionMutation);
  const [revertInspection] = useMutation(revertInspectionMutation);
  const [closeLabels, setCloseLabels] = useState(false);
  const { t } = useTranslation();
  const [PDFError, setPDFError] = useState(false);
  const { openConfirmationModal } = useModal();

  const [addReviewer] = useMutation(addReviewerMutation, {
    update(cache, { data: { addInspectionReviewer: newReviewer } }) {
      cache.modify({
        id: cache.identify(inspection),
        fields: {
          reviewers(existingReviewers = []) {
            const newReviewerRef = cache.identify(newReviewer);
            return [...existingReviewers, { __ref: newReviewerRef }];
          },
        },
      });
    },
  });

  const [addRegret] = useMutation(addRegretMutation, {
    update(cache, { data: { addInspectionRegret: newRegret } }) {
      cache.modify({
        id: cache.identify(inspection),
        fields: {
          regrets(existingRegrets = []) {
            const newRegretRef = cache.identify(newRegret);
            return [...existingRegrets, { __ref: newRegretRef }];
          },
        },
      });
    },
  });
  const [deleteRegret] = useMutation(deleteRegretMutation);

  const {
    refetch: refetchActionItems = () => {},
    loading: loadingActionItems,
    data: inspectionActionItems,
  } = useQuery(actionItemsQuery, {
    skip: !inspection?.id,
    variables: {
      inspectionId: `${inspection.id}`,
      actionItemType: inspection.inspectionType,
    },
  });
  const [editingParticipants, setEditingParticipants] = useState(false);
  const [editingReviewers, setEditingReviewers] = useState(false);
  const [editingRegrets, setEditingRegrets] = useState(false);

  const approvalIds = useMemo(() => {
    if (!inspection?.approvals?.length) {
      return [];
    }
    const approvedUserIds = inspection?.approvals
      ?.filter((a) => a.status === inspectionApprovalTypes.approved)
      .map((a) => a.creator.id);

    return approvedUserIds;
  }, [inspection?.approvals]);

  const isCreator = user?.id === inspection?.creator?.id;

  return !inspection?.id ? (
    <></>
  ) : (
    <div className={classNames([styles.container, className])}>
      <div className={styles.topSpace} />
      <div className={styles.header}>
        <Text noMargin size="lg" weight="bold">
          {t(keys.common.INFORMATION)}
        </Text>
        <div
          onClick={() => {
            setCloseLabels(true);
            onClose();
          }}
          className={styles.closeIcon}
        >
          <Icon>close</Icon>
        </div>
      </div>
      <br />
      <Text
        className={styles.heading}
        noMargin
        size="md"
        weight="bold"
        color="secondary"
      >
        {t(keys.common.TYPE)}
      </Text>
      <InspectionTypeBadge type={inspection.inspectionType} />
      {inspection?.incident && (
        <>
          <div className={styles.spacer} />
          <Text
            className={styles.vehicleLink}
            size="md"
            color="accentPrimary"
            weight="semiBold"
            onClick={() =>
              navigate(
                getRoute(
                  workspace?.id,
                  paths.incident,
                  inspection?.incident.id,
                ),
              )
            }
          >
            {t(getTranslationKey(inspection.incident.type, 'incidents'))} -{' '}
            {t(getTranslationKey(inspection.incident.subtype, 'incidents'))}
          </Text>
        </>
      )}
      {inspection?.template?.reviewRequired && (
        <>
          <div className={styles.sectionLine} />
          <div className={styles.editable}>
            <Text
              className={styles.heading}
              noMargin
              size="md"
              weight="bold"
              color="secondary"
            >
              {t(keys.common.REVIEWERS)}
            </Text>
            {inspection?.isDraft && online && (
              <Icon
                onClick={() => setEditingReviewers(!editingReviewers)}
                className={styles.icon}
                baseClassName="material-icons-outlined"
              >
                {`${editingReviewers ? 'close' : 'settings'}`}
              </Icon>
            )}
          </div>
          {editingReviewers ? (
            <UserMultiSelector
              selected={inspection?.reviewers.map((r) => r.user)}
              onUserAdded={(user) =>
                addReviewer({
                  variables: {
                    userId: user.id,
                    inspectionId: inspection.id,
                  },
                })
              }
              onUserRemoved={(user) => {
                const reviewer = inspection.reviewers.find(
                  (r) => r.user.id === user.id,
                );

                if (!reviewer) {
                  throw new Error('Reviewer not found');
                }

                deleteReviewer({
                  variables: {
                    reviewerId: reviewer.id,
                  },
                }).then(() => {
                  client.cache.evict(client.cache.identify(reviewer));
                  client.cache.gc();
                });
              }}
            />
          ) : inspection?.reviewers?.length ? (
            <div className={styles.participantContainer}>
              {inspection?.reviewers?.map((reviewer) => (
                <div className={styles.userContainer} key={reviewer.user.id}>
                  <SimpleUserCard size="md" mediumAvatar user={reviewer.user} />
                  {reviewer.status === reviewStatus.approved && (
                    <Icon
                      fontSize="inherit"
                      baseClassName="material-icons-outlined"
                      className={styles.approveIcon}
                    >
                      done
                    </Icon>
                  )}
                  {reviewer.status === reviewStatus.changeRequest && (
                    <Icon
                      fontSize="inherit"
                      baseClassName="material-icons-outlined"
                      className={styles.changeRequestIcon}
                    >
                      warning
                    </Icon>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <Text noMargin color="secondary">
              {t(keys.common.NONE)}
            </Text>
          )}
        </>
      )}
      <div className={styles.sectionLine} />
      <Text
        className={styles.heading}
        noMargin
        size="md"
        weight="bold"
        color="secondary"
      >
        {t(keys.common.STATUS)}
      </Text>
      <div className={styles.statusLabel}>
        <Label
          className={styles.label}
          name={
            inspection.isDraft
              ? t(keys.common.IN_PROGRESS)
              : t(keys.common.COMPLETE)
          }
          color={inspection.isDraft ? 'yellow' : 'green'}
        />
      </div>
      {!inspection?.incident && (
        <div>
          <div className={styles.sectionLine} />
          <div className={styles.editable}>
            <Text
              className={styles.heading}
              noMargin
              size="md"
              weight="bold"
              color="secondary"
            >
              {t(keys.common.PARTICIPANTS)}
            </Text>
            {inspection?.isDraft && online && (
              <Icon
                onClick={() => setEditingParticipants(!editingParticipants)}
                className={styles.icon}
                baseClassName="material-icons-outlined"
              >
                {`${editingParticipants ? 'close' : 'settings'}`}
              </Icon>
            )}
          </div>
          {editingParticipants ? (
            <UserMultiSelector
              selected={inspection?.participants}
              onUserAdded={(user) =>
                addParticipant({
                  variables: {
                    userId: user.id,
                    inspectionId: inspection.id,
                  },
                })
              }
              onUserRemoved={(user) =>
                deleteParticipant({
                  variables: {
                    userId: user.id,
                    inspectionId: inspection.id,
                  },
                })
              }
              type={inspection?.workspaceId ? 'users' : 'all'}
            />
          ) : inspection?.participants?.length ? (
            <div className={styles.participantContainer}>
              {inspection?.participants?.map((user) => (
                <div className={styles.userContainer} key={user.id}>
                  <SimpleUserCard size="md" mediumAvatar user={user} />
                  {approvalIds.includes(user.id) && (
                    <Icon
                      fontSize="inherit"
                      baseClassName="material-icons-outlined"
                      className={styles.approveIcon}
                    >
                      done
                    </Icon>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <Text noMargin color="secondary">
              {t(keys.common.NONE)}
            </Text>
          )}
        </div>
      )}
      {inspection.inspectionType === 'MEETING' ? (
        <div>
          <div className={styles.sectionLine} />
          <div className={styles.editable}>
            <Text
              className={styles.heading}
              noMargin
              size="md"
              weight="bold"
              color="secondary"
            >
              {t(keys.common.REGRETS)}
            </Text>
            {inspection?.isDraft && online && (
              <Icon
                onClick={() => setEditingRegrets(!editingRegrets)}
                className={styles.icon}
                baseClassName="material-icons-outlined"
              >
                {`${editingRegrets ? 'close' : 'settings'}`}
              </Icon>
            )}
          </div>
          {editingRegrets ? (
            <UserMultiSelector
              selected={inspection?.regrets}
              onUserAdded={(user) =>
                addRegret({
                  variables: {
                    userId: user.id,
                    inspectionId: inspection.id,
                  },
                })
              }
              onUserRemoved={(user) => {
                deleteRegret({
                  variables: {
                    userId: user.id,
                    regretableId: inspection?.id,
                  },
                }).then(() => {
                  client.cache.evict(client.cache.identify(user));
                  client.cache.gc();
                });
              }}
            />
          ) : inspection?.regrets?.length ? (
            <div className={styles.participantContainer}>
              {inspection?.regrets?.map((user) => (
                <SimpleUserCard
                  key={`regret-${user.id}`}
                  size="md"
                  mediumAvatar
                  user={user}
                />
              ))}
            </div>
          ) : (
            <Text noMargin color="secondary">
              {t(keys.common.NONE)}
            </Text>
          )}
        </div>
      ) : null}
      {inspection?.timecard?.id && (
        <>
          <div className={styles.sectionLine} />
          <Text
            className={styles.heading}
            noMargin
            size="md"
            weight="bold"
            color="secondary"
          >
            Timecard
          </Text>
          <Text
            className={isAdmin || isCreator ? styles.vehicleLink : null}
            size="md"
            color={isAdmin ? 'accentPrimary' : 'primary'}
            weight="semiBold"
            onClick={() => {
              (isAdmin || isCreator) &&
                navigate(`/${paths.timecard}/?id=${inspection.timecard.id}`);
            }}
          >
            {inspection.creator.firstName}'s Timecard
          </Text>
          <div className={styles.sectionLine} />
        </>
      )}
      <div className={styles.sectionLine} />
      <Text
        className={styles.heading}
        noMargin
        size="md"
        weight="bold"
        color="secondary"
      >
        {t(keys.common.CREATOR)}
      </Text>
      <SimpleUserCard size="md" mediumAvatar user={inspection.creator} />
      <div className={styles.sectionLine} />
      <Text
        className={styles.heading}
        noMargin
        size="md"
        weight="bold"
        color="secondary"
      >
        {t(keys.common.DATE_CREATED)}
      </Text>
      <Text noMargin size="md" weight="semibold">
        {getLocalTime(parseInt(inspection.dateCreated, 10)).format(
          'ddd, MMMM Do YYYY, h:mma',
        )}
      </Text>
      {inspection?.reviewers?.find((r) => r.status !== 'OPEN') && (
        <>
          <div className={styles.sectionLine} />
          <Text
            className={styles.heading}
            noMargin
            size="md"
            weight="bold"
            color="secondary"
          >
            {t(keys.common.DATE_REVIEWED)}
          </Text>
          <Text noMargin size="md" weight="semibold">
            {getLocalTime(inspection?.reviewers[0]?.dateCreated).format(
              'ddd, MMMM Do YYYY, h:mma',
            )}
          </Text>
        </>
      )}
      {!inspection?.isDraft && (
        <>
          <div className={styles.sectionLine} />
          <Text
            className={styles.heading}
            noMargin
            size="md"
            weight="bold"
            color="secondary"
          >
            {t(keys.common.DATE_COMPLETED)}
          </Text>
          <Text noMargin size="md" weight="semibold">
            {getLocalTime(parseInt(inspection.dateCompleted, 10)).format(
              'ddd, MMMM Do YYYY, h:mma',
            )}
          </Text>
        </>
      )}
      {online && (
        <>
          <div className={styles.sectionLine} />
          <Text
            className={styles.heading}
            noMargin
            size="md"
            weight="bold"
            color="secondary"
          >
            Labels
          </Text>
          <LabelSelector
            onClose={closeLabels}
            setOnClose={setCloseLabels}
            selected={inspection?.labels || []}
            readOnly={inspection?.isDraft ? false : true}
            onSelectionChanged={(op, label) => {
              if (op === 'ADD') {
                addLabel({
                  variables: {
                    inspectionId: inspection.id,
                    labelId: label.id,
                  },
                }).then(() => {
                  refetch();
                });
              } else if (op === 'REMOVE') {
                deleteLabel({
                  variables: {
                    inspectionId: inspection.id,
                    labelId: label.id,
                  },
                }).then(() => {
                  refetch();
                });
              }
            }}
          />
        </>
      )}
      {!inspection?.incident && online && (
        <>
          <div className={styles.sectionLine} />
          <div className={styles.editable}>
            <Text
              className={styles.heading}
              noMargin
              size="md"
              weight="bold"
              color="secondary"
            >
              {t(keys.common.ACTION_ITEMS)}
            </Text>
            <Icon
              onClick={() => {
                addActionItem({
                  variables: {
                    title: inspection?.template?.title,
                    description: '',
                    type: inspection.inspectionType,
                    inspectionId: inspection.id,
                    vehicleId: inspection.vehicle?.id,
                    workspaceId: workspace?.id,
                  },
                }).then(
                  ({
                    data: {
                      addActionItem: { id },
                    },
                  }) => {
                    openActionItemModal(id, 'VEHICLE', refetchActionItems);
                  },
                );
              }}
              className={styles.addIcon}
              baseClassName="material-icons-outlined"
            >
              add
            </Icon>
          </div>
        </>
      )}
      {inspectionActionItems?.actionItems?.length ? (
        <div className={styles.actionItemList}>
          {inspectionActionItems?.actionItems?.map((actionItem) => (
            <ActionItemCard
              key={actionItem.id}
              onClick={() =>
                openActionItemModal(actionItem.id, null, refetchActionItems)
              }
              className={styles.actionItemCard}
              actionItem={actionItem}
            />
          ))}
        </div>
      ) : loadingActionItems ? (
        <></>
      ) : (
        <Text noMargin color="secondary">
          {t(keys.common.NONE)}
        </Text>
      )}
      <div className={styles.sectionLine} />
      {!inspection?.isDraft &&
        (!PDFError ? (
          <div>
            <PDFDownloadLink
              onError={(event) => {
                Bugsnag.notify(event.error);
                console.error(event);
                setPDFError(true);
              }}
              document={
                <AssessmentDocument
                  inspection={inspection}
                  workspace={workspace}
                  actionItems={inspectionActionItems?.actionItems || []}
                />
              }
              fileName={`${getLocalTime(inspection.dateCreated).format(
                'MM-DD-YYYY',
              )}_${inspection.template?.title.replace(' ', '_')}_${
                inspection.id
              }.pdf`}
            >
              {({ loading, url, error }) => {
                if (error) {
                  Bugsnag.notify(error);
                  console.error(error);
                  setPDFError(true);
                } else
                  return (
                    <Button
                      className={styles.deleteButton}
                      variant="primary"
                      outlined
                      icon="download"
                      disabled={!url || loading}
                      value={
                        loading || !url
                          ? t(keys.action.LOADING)
                          : t(keys.action.DOWNLOAD_VARIABLE, { variable: null })
                      }
                    />
                  );
              }}
            </PDFDownloadLink>
            <br />
          </div>
        ) : (
          <>
            <Button
              outlined
              icon="refresh"
              className={styles.deleteButton}
              value="Error Loading PDF"
              onClick={() => {
                window.location.reload();
              }}
            />
            <br />
          </>
        ))}
      {!inspection.isDraft && (isAdmin || isWorkspaceAdmin || isCreator) && (
        <>
          <Button
            value={t(keys.common.DRAFT)}
            outlined
            variant="warning"
            icon="change_circle"
            className={styles.deleteButton}
            onClick={() => {
              openConfirmationModal({
                title: t(keys.assessments.REQUEST_ASSESSMENT_CHANGES),
                description: t(keys.assessments.REQUEST_CHANGE_MESSAGE),
                buttonText: t(keys.timecards.REVERT),
                variant: 'warning',
                onSubmit: () => {
                  revertInspection({
                    variables: { id: inspection?.id },
                  });
                },
              });
            }}
          />
          <br />
        </>
      )}
      {inspection?.incident?.status !== 'COMPLETE' && online && (
        <Button
          className={styles.deleteButton}
          variant="danger"
          outlined
          disabled={!isAdmin && inspection?.creator?.id !== user.id}
          icon="delete"
          value={t(keys.action.DELETE)}
          onClick={() => {
            openConfirmationModal({
              title: t(keys.action.DELETE_VARIABLE, {
                variable: t(keys.common.ASSESSMENT),
              }),
              description: t(keys.action.DELETE_CONFIRMATION, {
                variable: inspection.template.title,
              }),
              variant: 'danger',
              onSubmit: () => {
                deleteInspection({
                  variables: { id: inspection.id },
                }).then(() => {
                  navigate(getRoute(workspace?.id, paths.assessments));
                });
                setTimeout(() => {
                  showToast({
                    title: inspection.template.title,
                    message: t(keys.action.DELETED, {
                      variable: inspection.template.title,
                    }),
                    variant: toastVariant.info,
                    time: toastLength.md,
                  });
                }, 500);
              },
            });
          }}
        />
      )}
    </div>
  );
}
