import moment from 'moment';
import 'moment/locale/fr-ca';
import i18n from '../i18n';
import { isToday, isYesterday, differenceInDays, parseISO } from 'date-fns';

export function getDaysAgo(timestamp) {
  const date =
    typeof timestamp === 'string' ? parseISO(timestamp) : new Date(timestamp);
  if (isToday(date)) {
    return 'Today';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }
  const daysAgo = differenceInDays(new Date(), date);
  return `${daysAgo} days ago`;
}
export function getLocalTime(timestamp) {
  if (typeof timestamp === 'string' && timestamp.includes('-')) {
    const m = new moment(timestamp);
    return m;
  }
  const epoch = parseInt(`${timestamp}`);
  const m = new moment(epoch);
  const currentLanguage = i18n.resolvedLanguage;
  moment.locale(currentLanguage || 'en');
  return m;
}

export async function sleep(milliseconds = 2500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}
export function getDayDescription(timestamp) {
  const time = getLocalTime(timestamp);
  const now = moment();

  if (time.isSame(now, 'day')) {
    return 'Today';
  } else if (time.isSame(now.clone().subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  } else {
    return time.format('DD/MM/YYYY');
  }
}

export function formatTime(date, customOptions = {}) {
  let options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  let newDate = new Date(date);
  return newDate.toLocaleDateString(
    i18n.resolvedLanguage,
    customOptions || options,
  );
}
