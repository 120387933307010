import React, { useMemo } from 'react';
import styles from './SettingsTabSystem.module.scss';
import EditProfile from './settings_pages/EditProfile';
import EditUsers from './settings_pages/EditUsers';
import EditCompany from './settings_pages/EditCompany';
import { Text } from '../components/typography';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from '../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { useCurrentUser } from '../providers/UserProvider';

export default function SettingsTabSystem({ disabled = false, type, parent }) {
  const { isAdmin } = useCurrentUser();
  const navigate = useNavigate();
  const { category } = useParams();
  const { t } = useTranslation();

  const options = [
    {
      title: t(keys.settings.PROFILE),
      element: EditProfile,
      key: 'profile',
    },
    {
      title: t(keys.settings.COMPANY),
      element: EditCompany,
      key: 'company',
    },
    {
      title: t(keys.common.USERS),
      element: EditUsers,
      key: 'users',
    },
  ];

  const SubPage = useMemo(() => {
    const option = options.find((o) => o.key === category);
    return option.element;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <div
      className={classNames(
        styles.container,
        disabled ? styles.disabled : null,
      )}
    >
      <Text size="lg" weight="bold" color="accentPrimary">
        {t(keys.settings.SETTINGS)}
      </Text>
      <div>
        {isAdmin ? (
          <div className={styles.tabsContainer}>
            {options.map((option) => (
              <div
                role="button"
                tabIndex={0}
                onClick={() => navigate(`${paths.settings}/${option.key}`)}
                className={styles.optionContainer}
                key={option.title}
              >
                <Text
                  textAlign="center"
                  noMargin
                  color={category === option.key ? 'primary' : 'secondary'}
                >
                  {option.title}
                </Text>
                <div
                  className={classNames(
                    styles.selectedFooter,
                    category === option.key ? styles.show : styles.hide,
                  )}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className={styles.subPage}>
        <SubPage parent={parent} />
      </div>
    </div>
  );
}
