import React from 'react';
import { Page, Document, Text, Image } from '@react-pdf/renderer';
import {
  styles,
  PageHeaderLogo,
  PageFooter,
  BatchPageHeader,
} from './constants';

export const BoardDocument = ({ user, image, title, date }) => {
  const { company } = user;

  return (
    <Document>
      <Page style={styles.page} wrap={false}>
        <PageHeaderLogo company={company} />
        <Text
          style={{ ...styles.title, marginBottom: 16 }}
        >{`${title} - ${date.format('MM-DD-YYYY')}`}</Text>
        <BatchPageHeader company={company} />
        {!!image && <Image src={image} />}
        <PageFooter />
      </Page>
    </Document>
  );
};
