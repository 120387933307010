import { Icon as MUIIcon } from '@mui/material';
import classNames from 'classnames';
import styles from './Icon.module.scss';
export default function Icon({
  color,
  className,
  hover,
  opacity,
  onClick = () => {},
  hide,
  size = 'lg',
  children,
  baseClassName = 'material-icons-outlined',
  style,
}) {
  const combinedClassName = classNames(
    styles[size],
    styles[color],
    className,
    hover && styles.hover,
    hide && styles.hide,
  );

  return (
    <MUIIcon
      onClick={onClick}
      baseClassName={baseClassName}
      className={combinedClassName}
      sx={{ opacity, ...style }}
    >
      {children}
    </MUIIcon>
  );
}
