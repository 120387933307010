import styles from './TypeSelectorDropdown.module.scss';
import { Icon } from '@mui/material';
import { useSelect } from 'downshift';
import { Text } from '../typography';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export default function ContextMenuDropdown({
  items,
  onChange,
  selected,
  type = null,
  noMargin = false,
}) {
  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } =
    useSelect({
      items: items,
      onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
    });
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.container, noMargin && styles.noMargin)}>
      {type && (
        <Text noMargin weight="semiBold">
          {type}
        </Text>
      )}
      {items?.length > 1 ? (
        <div className={styles.selectable}>
          <div
            className={classNames(styles.selector, noMargin && styles.noMargin)}
            {...getToggleButtonProps()}
          >
            <div className={styles.selectorDisplay}>
              {selected?.icon && (
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles[selected.color]}
                >
                  {selected.icon}
                </Icon>
              )}
              <Text noMargin weight="semiBold">
                {t(selected.title) || selected.title}
              </Text>
            </div>
            <div>{isOpen ? <ExpandLess /> : <ExpandMore />}</div>
          </div>
          <div className={styles.options} {...getMenuProps()}>
            {isOpen && (
              <div>
                {items.map((item, index) => (
                  <div
                    className={styles.option}
                    key={item.id}
                    {...getItemProps({
                      item,
                      index,
                    })}
                  >
                    {item?.icon && (
                      <Icon
                        baseClassName="material-icons-outlined"
                        className={styles[item.color]}
                      >
                        {item.icon}
                      </Icon>
                    )}
                    <Text noMargin weight="semiBold">
                      {t(item.title) || item.title}
                    </Text>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.selector} {...getToggleButtonProps()}>
            <div className={styles.selectorDisplay} {...getMenuProps()}>
              <Icon
                baseClassName="material-icons-outlined"
                className={styles[selected.color]}
              >
                {selected.icon}
              </Icon>
              <Text noMargin weight="semiBold">
                {t(selected.title) || selected.title}
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
