import { ResponsiveLineCanvas } from '@nivo/line';
import React, { useMemo } from 'react';
import { getLocalTime } from '../../utilities/time';
import styles from './Graph.module.scss';
import moment from 'moment';
import { bootstrapColors, primaryGroupings } from './utilities';
import { Text } from '../../components/typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { capatalizeFirstLetter } from '../../utilities';

export default function LineChart({ events, group, zeroFillDays = 7 }) {
  const { t } = useTranslation();
  const data = useMemo(() => {
    const groups = Array.from(
      events
        .reduce((acc, event) => {
          const date = getLocalTime(event.dateCreated).format('YYYY-MM-DD');
          const isSubGroup = !primaryGroupings.some(
            ({ key }) => key.toLocaleLowerCase() === group.toLocaleLowerCase(),
          );

          const groupName =
            group === 'None'
              ? 'All'
              : isSubGroup
              ? event.information[group]
              : event[group];

          if (!acc.has(groupName)) {
            acc.set(groupName, { id: groupName, data: {} });
          }

          const groupData = acc.get(groupName);

          if (!groupData.data[date]) {
            groupData.data[date] = { x: date, y: 0 };
          }

          groupData.data[date].y++;

          return acc;
        }, new Map())
        .values(),
    );

    let latestEventDate = moment
      .max(events.map((e) => getLocalTime(e.dateCreated)))
      .format('YYYY-MM-DD');
    let startDate = moment()
      .subtract(zeroFillDays, 'days')
      .format('YYYY-MM-DD');
    const endDate = moment
      .max(moment(), moment(latestEventDate))
      .format('YYYY-MM-DD');

    groups.forEach((group) => {
      let currentDate = startDate;

      while (moment(currentDate).isSameOrBefore(endDate)) {
        if (!group.data[currentDate]) {
          group.data[currentDate] = { x: currentDate, y: 0 };
        }

        currentDate = moment(currentDate).add(1, 'days').format('YYYY-MM-DD');
      }

      group.data = Object.values(group.data).sort((a, b) =>
        moment.utc(a.x).diff(moment.utc(b.x)),
      );
    });

    return groups;
  }, [events, group, zeroFillDays]);

  return (
    <div
      style={{
        width: '100%',
        height: 400,
      }}
    >
      <ResponsiveLineCanvas
        data={data}
        margin={{ top: 50, right: 140, bottom: 50, left: 60 }}
        xScale={{
          format: '%Y-%m-%d',
          type: 'time',
        }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
        }}
        axisBottom={{
          format: '%d',
          tickPadding: 8,
          tickValues: 8,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t(keys.boards.COUNT),
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        pointSize={10}
        curve="monotoneX"
        colors={bootstrapColors}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        theme={{
          grid: { line: { stroke: '#0001', strokeOpacity: 0.5 } },
        }}
        tooltip={(point) => (
          <div className={styles.tooltipContainer}>
            <Text noMargin size="sm" weight="semiBold">{`Date: ${moment(
              point.point.data.x,
            ).format('MMM Do')}`}</Text>
            <Text
              noMargin
              size="sm"
              weight="semiBold"
            >{`${capatalizeFirstLetter(t(keys.boards.COUNT))}: ${
              point.point.data.y
            }`}</Text>
          </div>
        )}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 100,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
