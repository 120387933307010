import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import styles from './LanguageSelector.module.scss';
import { languages, getTranslationKey } from './translation_keys';

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.buttons}>
      {Object.keys(languages).map((lng) => {
        const isSelected =
          i18n.language.substring(0, 2) === lng.substring(0, 2);
        const l = languages[lng].nativeName;
        return (
          <Button
            key={lng}
            style={{
              fontWeight: isSelected ? 'bold' : 'normal',
              fontSize: '0.6rem',
              backgroundColor: isSelected ? '#0d6efd' : 'white',
              color: isSelected ? 'white' : 'black',
              padding: '0.3rem',
            }}
            type="button"
            onClick={() => i18n.changeLanguage(lng)}
          >
            {t(getTranslationKey(l, 'dashboard')) || l}
          </Button>
        );
      })}
    </div>
  );
};
