import { useMemo, useState } from 'react';
import background from '../../assets/logo_white_icon.svg';
import logo from '../../assets/logo_white_horiz.svg';
import blueLogo from '../../assets/logo_blue_black.svg';
import CompanySignUpInfo from './CompanySignUpInfo';
// import CompanySignUpDetails from './CompanySignUpDetails';
// import CompanySignUpPayment from './CompanySignUpPayment';
import CompanySignUpComplete from './CompanySignUpComplete';
import WorkflowFooter from '../../components/workflows/WorkflowFooter';
import styles from './CompanySignUp.module.scss';
import { useQueryParams } from '../../hooks/misc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Widget } from '@typeform/embed-react';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useIsMobile } from '../../hooks/misc';

export default function CompanySignUp() {
  const { getParam } = useQueryParams();
  const payment = getParam('payment');
  const { zeroTouchSignup } = useFlags();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const companySignUpWorkflow = useMemo(
    () => [
      {
        title: 'General Information',
        key: 'General Information',
        icon: 'edit',
        component: CompanySignUpInfo,
      },
      // {
      //   title: 'Company Details',
      //   key: 'Company Details',
      //   icon: 'summarize',
      //   component: CompanySignUpDetails,
      // },
      // {
      //   title: 'Plan Details',
      //   key: 'Plan Details',
      //   icon: 'paid',
      //   component: CompanySignUpPayment,
      // },
      {
        title: 'Complete',
        key: 'Complete',
        icon: 'done',
        component: CompanySignUpComplete,
      },
    ],
    [],
  );

  let stage = 0;
  let completedStagesArray = [];
  if (payment === 'success') {
    stage = 1;
    completedStagesArray = [
      'General Information',
      // 'Company Details',
      // 'Plan Details',
    ];
  }

  const [newCompanyState, setNewCompanyState] = useState({});
  const [currentWorkflow, setCurrentWorkflow] = useState(
    companySignUpWorkflow[stage].key,
  );
  const [completedWorkflows, setCompletedWorkflows] =
    useState(completedStagesArray);

  const Page = useMemo(
    () =>
      companySignUpWorkflow.find((workflow) => workflow.key === currentWorkflow)
        .component,
    [currentWorkflow, companySignUpWorkflow],
  );

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.leftChild}>
          <img alt="Opas logo" className={styles.logo} src={logo} />
          <p className={styles.leftTitle}>{t(keys.companySignUp.MESSAGE_1)}</p>
          <p className={styles.leftSubtitle}>
            {t(keys.companySignUp.MESSAGE_2)}
          </p>
          <img
            alt="background graphic"
            className={styles.backgroundGraphic}
            src={background}
          />
        </div>
      </div>
      <div className={styles.rightContainer}>
        {isMobile && (
          <img alt="Opas logo" className={styles.mobileLogo} src={blueLogo} />
        )}
        {zeroTouchSignup ? (
          <div className={styles.subPageContainer}>
            <Page
              newCompanyState={newCompanyState}
              setNewCompanyState={setNewCompanyState}
              workflow={companySignUpWorkflow}
              currentStage={currentWorkflow}
              completed={completedWorkflows}
              onCompleted={(completedWorkflow) => {
                setCompletedWorkflows([
                  ...completedWorkflows,
                  completedWorkflow,
                ]);
                const currentIndex = companySignUpWorkflow.findIndex(
                  (item) => item.key === currentWorkflow,
                );

                if (currentIndex + 1 === companySignUpWorkflow.length) {
                } else {
                  setCurrentWorkflow(
                    companySignUpWorkflow[currentIndex + 1].key,
                  );
                }
              }}
            />
            <div className={styles.footerContainer}>
              <WorkflowFooter
                workflow={companySignUpWorkflow}
                selected={currentWorkflow}
                completed={completedWorkflows}
                onChange={(newSelection) => setCurrentWorkflow(newSelection)}
                disableBackwardFlow={currentWorkflow === 'Complete'}
              />
            </div>
          </div>
        ) : (
          <Widget id="q2j4tU6a" className={styles.typeformWidget} />
        )}
      </div>
    </div>
  );
}
