import React, { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Auth0ProviderWithHistory from './providers/Auth0Provider';
import { register } from './serviceWorkerRegistration';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { pdfjs } from 'react-pdf';
import './i18n';
import defaultFlags from './flagdata.json';
import CustomSpinner from './components/CustomSpinner';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LD_KEY,
  flags: defaultFlags,
})(App);

const LoadingComponentWrapper = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fontLoadTimeout = 5000;

    const fontPromises = [
      document.fonts.load('1em "eina03regular"'),
      document.fonts.load('1em "eina03semibold"'),
      document.fonts.load('1em "eina03bold"'),
    ];

    const timeoutPromise = new Promise((resolve) =>
      setTimeout(resolve, fontLoadTimeout),
    );

    Promise.race([Promise.all(fontPromises), timeoutPromise]).then(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <div>
      {loading ? (
        <CustomSpinner text={'Loading App...'} />
      ) : (
        <Auth0ProviderWithHistory>
          <LDProvider />
        </Auth0ProviderWithHistory>
      )}
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<CustomSpinner text={'Loading Text...'} />}>
      <LoadingComponentWrapper />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

//this does the service worker registration.
register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
