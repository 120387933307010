import { Icon } from '@mui/material';
import React from 'react';
import styles from './SelectableTag.module.scss';
import { Text } from './typography';
import { getTranslationKey } from '../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';

export default function SelectableTag({
  text,
  icon,
  value,
  selected,
  onSelect,
  size = 'md',
  multiSelect = true,
  namespace,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={
        styles[
          `container${
            selected?.includes(value) | (selected === value) ? 'Selected' : ''
          }`
        ]
      }
      onClick={() => onSelect(value)}
    >
      {!!icon && (
        <Icon
          sx={{ fontSize: size === 'sm' ? '1rem' : '1.25rem' }}
          baseClassName="material-icons-outlined"
          className={
            styles[
              `icon${
                selected?.includes(value) || selected === value
                  ? 'Selected'
                  : ''
              }`
            ]
          }
        >
          {icon}
        </Icon>
      )}
      <Text
        size={size}
        noMargin
        weight="semiBold"
        color={
          selected?.includes(value) || selected === value
            ? 'accentPrimary'
            : 'secondary'
        }
      >
        {t(getTranslationKey(text, namespace)) || text}
      </Text>
    </div>
  );
}
