import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Text } from '../typography';
import { useTranslation } from 'react-i18next';
import {
  getEnglishKey,
  getTranslationKey,
} from '../../utilities/translator/translation_keys';
import { keys } from '../../utilities/translator/translation_keys';

export default function SingleSelect({
  title = '',
  options = [],
  value = '',
  isBoolean = false,
  isInteger = false,
  onChange = async () => {},
  readOnly,
  namespace,
}) {
  const { i18n, t } = useTranslation();
  const language = i18n.resolvedLanguage;

  const rootCauseMap = i18n.getResourceBundle(
    language,
    'incidents',
  ).dropdownOptions;

  const booleanSelectOptions = {
    true: t(keys.common.YES),
    false: t(keys.common.NO),
  };

  const handleChange = (value) => {
    if (language !== 'en') {
      return getEnglishKey(value, rootCauseMap);
    }
    return value;
  };

  return readOnly ? (
    <Text noMargin>
      {isBoolean ? booleanSelectOptions[`${value}`] : rootCauseMap[value]}
    </Text>
  ) : (
    <Form
      onChange={(e) => {
        e.persist();
        onChange(
          isBoolean
            ? e.target.id === booleanSelectOptions.true
            : isInteger
            ? parseInt(e.target.value, 10)
            : handleChange(e.target.id),
        );
      }}
    >
      <Form.Group style={{ display: 'flex', flexWrap: 'wrap' }} name={title}>
        {options.map((option) => {
          const englishKey = getEnglishKey(option, rootCauseMap);
          const isChecked = value === englishKey;
          return (
            <Form.Check
              inline
              defaultChecked={
                isBoolean
                  ? option === booleanSelectOptions[`${value}`]
                  : isChecked
              }
              label={
                namespace ? t(getTranslationKey(option, namespace)) : option
              }
              type={'radio'}
              name={title}
              id={option}
              key={option}
            />
          );
        })}
      </Form.Group>
    </Form>
  );
}

SingleSelect.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  isBoolean: PropTypes.bool,
  isInteger: PropTypes.bool,
};
