import React from 'react';
import styles from './WorkflowFooter.module.scss';
import WorkflowStage from './WorkflowStage';

export default function WorkflowFooter({
  selected,
  onChange,
  workflow,
  completed,
  disableBackwardFlow,
}) {
  return (
    <div className={styles.container}>
      {workflow.map(({ key, title, subtitle, icon }, index) => (
        <WorkflowStage
          key={`${key}-${index}`}
          workflowKey={key}
          title={title}
          subtitle={subtitle}
          completed={completed.includes(key)}
          icon={icon}
          selected={selected === key}
          selectedTitle={selected}
          workflow={workflow}
          onClick={() => onChange(key)}
          disableBackwardFlow={disableBackwardFlow}
          showTitle={false}
        />
      ))}
    </div>
  );
}
