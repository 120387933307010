import React, { useMemo } from 'react';
import { ResponsiveBarCanvas } from '@nivo/bar';
import { bootstrapColors, primaryGroupings } from './utilities'; // Assumed bootstrapColors is available

const stringToHash = (string = 'default') => {
  let hash = 5381;

  for (let i = 0; i < string.length; i++) {
    hash = (hash << 5) + hash + string.charCodeAt(i);
    hash = hash & hash;
  }

  return hash >>> 0;
};

export default function BarChart({ events, group }) {
  const data = useMemo(() => {
    const categoryMap = new Map();
    const isSubGroup = !primaryGroupings.some(
      ({ key }) => key.toLocaleLowerCase() === group.toLocaleLowerCase(),
    );

    for (const event of events) {
      const groupName = isSubGroup ? event.information[group] : event[group];

      categoryMap.set(
        groupName,
        categoryMap.has(groupName) ? categoryMap.get(groupName) + 1 : 1,
      );
    }

    const result = Array.from(categoryMap.entries()).map(([id, value]) => ({
      [group]: id || 'Default',
      count: value,
    }));

    return result;
  }, [events, group]);

  return (
    <div style={{ width: '100%', height: 500, margin: 'auto' }}>
      <ResponsiveBarCanvas
        data={data}
        keys={['count']}
        indexBy={group}
        margin={{ top: 50, right: 0, bottom: 100, left: 50 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) => {
          return bootstrapColors[
            stringToHash(data[group]) % bootstrapColors.length
          ];
        }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 3,
          tickPadding: 5,
          tickRotation: 45,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'count',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={100}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      />
    </div>
  );
}
