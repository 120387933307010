import Modal from '../Modal';
import { useModal } from '../../../providers/ModalProvider';
import { loader } from 'graphql.macro';
import { modals } from '../../../providers/modals';
import { Text } from '../../typography';
import { Form } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../../constants/strings';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import styles from './CreateWorkspaceModal.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
const createWorkspaceMutation = loader('./CreateWorkspaceModal.graphql');

export default function CreateWorkspaceModal() {
  const { updateModal, closeModal, modalState } = useModal();
  const { refetchWorkspace } = useWorkspace();
  const { show, title, description } = modalState.createWorkspaceModal;
  const onHide = () => closeModal({ modalName: modals.createWorkspace });
  const [createWorkspace] = useMutation(createWorkspaceMutation);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const update = (variables) =>
    updateModal({
      modalName: modals.createWorkspace,
      variables,
    });
  return (
    <Modal
      open={show || false}
      title={t(keys.workspace.CREATE_NEW_WORKSPACE)}
      hideCancel
      onClose={onHide}
      onSubmit={() =>
        createWorkspace({
          variables: {
            title,
            description,
          },
        }).then(({ data }) => {
          const workspaceId = data.addWorkspace.id;
          refetchWorkspace().then(() =>
            navigate(getRoute(workspaceId, paths.workspaceSettings)),
          );
        })
      }
    >
      <div className={styles.content}>
        <Text weight="semiBold" size="md" noMargin>
          {t(keys.common.TITLE)}
        </Text>
        <Form.Control
          type="text"
          placeholder={t(keys.action.ENTER, {
            variable: t(keys.workspace.WORKSPACE_NAME),
          })}
          value={title}
          onChange={(e) => update({ title: e.target.value })}
        />
        <Text weight="semiBold" size="md" noMargin>
          {t(keys.common.DESCRIPTION)}
        </Text>
        <Form.Control
          as="textarea"
          rows={3}
          value={description}
          placeholder={t(keys.action.ENTER, {
            variable: t(keys.common.DESCRIPTION),
          })}
          onChange={(e) => update({ description: e.target.value })}
        />
      </div>
    </Modal>
  );
}
