import { useApolloClient, useReactiveVar } from '@apollo/client';
import { useOnlineStatus } from '../hooks/offline-hooks/offline-misc';
import { useInspection } from '../hooks/offline-hooks/createInspectionhook';
import { useIndexedDB } from '../hooks/offline-hooks/indexedDBhook';
import {
  closeNetworkModal,
  networkModalVar,
  openNetworkModal,
} from '../graphql/cache/modal';
import { useUserIndexedDB } from '../hooks/offline-hooks/userMutationIndexedDB';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { isValidUUID } from '../utilities/offline_hooks_setup_apollo/offlineUtils';
import { inputTypes } from '../utilities/inspection';
import { get } from 'lodash';
import { getRoute, paths } from '../constants/strings';
import React from 'react';
import pjson from '../../package.json';
import { useWorkspace } from './WorkspaceProvider';

const REQUEST_DEBOUNCE = 250;

export const useCachedMutationFlush = (user) => {
  const { workspace } = useWorkspace();
  const online = useOnlineStatus();
  const client = useApolloClient();
  const currentUser = user;
  const { uploadImageFile, uploadAudioFile } = useInspection();
  const { show: showingNetworkModal } = useReactiveVar(networkModalVar);
  const { isDBOpen, getFile } = useIndexedDB('offline-file-db');
  const packageVersion = pjson.version.split('.');
  const {
    isMutationDBOpen,
    dequeueMutation,
    updateQueueWithDeepReplace,
    getQueue,
  } = useUserIndexedDB(
    `userSpecificMutation-${packageVersion[0]}.${packageVersion[1]}`,
  );
  const navigate = useNavigate();
  const dbOpen = useMemo(() => isDBOpen(), [isDBOpen]);
  const mutationDBOpen = useMemo(() => isMutationDBOpen(), [isMutationDBOpen]);

  const processUUIDSStringToURL = (uuidString, isImage, isAudio) => {
    return new Promise(async (resolve, reject) => {
      try {
        const elements = uuidString.split('|'); // Split string
        const newElements = [];
        for (let index = 0; index < elements.length; index++) {
          let element = elements[index];
          if (isValidUUID(element) && isImage) {
            // if element is a valid UUID
            const fileBlob = await getFile(element);
            const link = await uploadImageFile(
              element,
              fileBlob.file,
              '',
              () => {},
              true,
              null,
              null,
              null,
            );
            newElements.push(link);
          } else if (isValidUUID(element) && isAudio) {
            const fileBlob = await getFile(element);
            const audioFileDetails = {
              blob: fileBlob.file,
              fileUUID: element,
              onSubmit: () => {},
              transcriptRef: null,
              recordingName: null,
              setFile: () => {},
              setLoading: () => {},
            };
            const audioURL = await uploadAudioFile(audioFileDetails);
            newElements.push(audioURL);
          } else {
            newElements.push(element);
          }
        }

        const newString = newElements.join('|'); // Recreate the string
        resolve(newString);
      } catch (error) {
        reject(error);
      }
    });
  };

  const processUUIDObservationsToURL = (observationImages) => {
    return new Promise(async (resolve, reject) => {
      try {
        const urlObservationImages = [];
        for (let index = 0; index < observationImages.length; index++) {
          let observationImage = observationImages[index];
          if (isValidUUID(observationImage)) {
            const fileBlob = await getFile(observationImage);
            const link = await uploadImageFile(
              observationImage,
              fileBlob.file,
              '',
              () => {},
              true,
              null,
              null,
              null,
            );
            urlObservationImages.push(link);
          } else {
            urlObservationImages.push(observationImage);
          }
          resolve(urlObservationImages);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const processQueueItem = async (item) => {
    const { document, options, mock } = item;
    try {
      if (
        mock.inputType &&
        (mock.inputType === inputTypes.image ||
          mock.inputType === inputTypes.multiImage ||
          mock.inputType === inputTypes.signature ||
          mock.inputType === inputTypes.multiSignature ||
          mock.inputType === inputTypes.file ||
          mock.inputType === inputTypes.multiFile)
      ) {
        //if there is an offline image then I upload the image first.
        //this will handle the urls and uuid logic
        const newValue = await processUUIDSStringToURL(
          options.variables.value,
          true,
          false,
        );
        options.variables.value = newValue;
      }

      if (
        mock.inputType === inputTypes.audio &&
        isValidUUID(options.variables.additional)
      ) {
        const newAudioValue = await processUUIDSStringToURL(
          options.variables.additional,
          false,
          true,
        );
        options.variables.additional = newAudioValue;
      }
      if (isValidUUID(options.variables.failImage)) {
        const fileBlob = await getFile(options.variables.failImage);
        const imageURL = await uploadImageFile(
          options.variables.failImage,
          fileBlob.file,
          '',
          () => {},
          true,
          null,
          null,
          null,
        );
        options.variables.failImage = imageURL;
      }

      if (
        mock.__typename === 'Observation' &&
        options.variables.images.length
      ) {
        const newObservationImagesURL = await processUUIDObservationsToURL(
          options.variables.images,
        );
        options.variables.images = newObservationImagesURL;
      }

      const { data, errors } = await client.mutate({
        mutation: document,
        ...options,
      });

      if (!errors) {
        client.cache.evict(`${mock.__typename}:${mock.id}`);
        client.cache.gc();

        //NOTE:
        //This id used here is the uuid this is to make sure that the update method don't use the mock.id cause the mock.id
        //in update is not a uuid but the other object's id and it could delete the previous id

        if (item.hasMultipleItemIds) {
          return data;
        } else if (item.newIdPath) {
          const newId = get(data, item.newIdPath);
          if (!newId) {
            throw new Error('Could not fetch new ID');
          }
          return { ...item, id: newId };
        }
      }
    } catch (error) {
      console.error('Error processing mutation:', error);
    }
    return null;
  };

  useEffect(() => {
    const processQueue = async () => {
      if (!online) {
        return;
      }

      if (!dbOpen) {
        return;
      }

      if (!mutationDBOpen) {
        return;
      }

      const parts = window.location.pathname.split('/');
      const isUuid = parts[parts.length - 1];
      if (isValidUUID(isUuid) && online) {
        navigate(getRoute(workspace.id, paths.dashboard));
      }

      //this wait time is for not overloading the backend
      await new Promise((r) => setTimeout(r, REQUEST_DEBOUNCE));
      let nextRequest = await dequeueMutation(currentUser.id);
      while (true) {
        if (!nextRequest) break; // Exit loop if the queue is empty
        //this wait time is for not overloading the backend
        await new Promise((r) => setTimeout(r, REQUEST_DEBOUNCE));
        const updatedRequest = await processQueueItem(nextRequest);

        if (updatedRequest?.submitBundle) {
          for (const [
            i,
            newItem,
          ] of updatedRequest.submitBundle.items.entries()) {
            // this will be updated later to be more dynamic
            await updateQueueWithDeepReplace(
              currentUser.id,
              nextRequest.mock.items[i].id,
              newItem.id,
            );
            await updateQueueWithDeepReplace(
              currentUser.id,
              nextRequest.mock.items[i].bundleId,
              newItem.bundleId,
            );
          }
        }
        if (updatedRequest && !updatedRequest?.submitBundle) {
          await updateQueueWithDeepReplace(
            currentUser.id,
            nextRequest.id,
            updatedRequest.id,
          );
          if (window.location.pathname.includes(nextRequest.id)) {
            navigate(
              getRoute(
                nextRequest.mock.workspaceId,
                paths.assessment,
                updatedRequest.id,
              ),
            );
          }
        }
        nextRequest = await dequeueMutation(currentUser.id);
        if (!nextRequest) {
          window.location.reload();
        }
      }
    };

    if (mutationDBOpen && online && dbOpen && !showingNetworkModal) {
      const openProgressModal = async () => {
        let numberOfMutations = await getQueue(currentUser.id);
        if (numberOfMutations?.length > 0) {
          openNetworkModal({
            isOnline: online,
            onSubmit: closeNetworkModal,
          });
        }
      };
      openProgressModal();
    }

    processQueue(); // Call the async function

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, dbOpen, mutationDBOpen]);
};

export const MutationQueueProvider = ({ user, children }) => {
  useCachedMutationFlush(user);

  // MutationQueueProvider does not provide any values, just ensures the hook is called
  return <>{children}</>;
};
