import { notFound } from '../../constants/endpoints';
import { checkGraphError } from './graphql';

export function handleErrors(errors) {
  console.log(errors);
  const { graphQLErrors, networkError } = errors;

  if (networkError && networkError.statusCode === 401) {
    localStorage.clear();
  }
  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.forEach((error) => {
      checkGraphError(error);
    });
  }
  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.forEach((error) => {
      if (error.extensions.code === 403) {
        window.location = notFound;
      }
    });
  }
}
