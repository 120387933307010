import { showToast } from '../../graphql/cache/modal';
import i18n from 'i18next';
import { keys } from '../../utilities/translator/translation_keys';

export const onSave = ({ errors }) => {
  if (!errors?.length) {
    showToast({
      title: i18n.t(keys.action.UPDATE_SUCCESSFUL),
      variant: 'info',
      message: i18n.t(keys.action.UPDATE_SUCCESS_MESSAGE, {
        variable: i18n.t(keys.settings.SETTINGS),
      }),
    });
  } else {
    showToast({
      title: i18n.t(keys.action.UPDATE_FAIL),
      variant: 'danger',
      message: i18n.t(keys.action.UPDATE_FAIL_MESSAGE, {
        variable: i18n.t(keys.settings.SETTINGS),
      }),
    });
  }
};
