import { Icon } from '@mui/material';
import React from 'react';
import { Text } from '../typography';
import styles from './NotificationIcon.module.scss';

export default function NotificationIcon({ count, onClick }) {
  return (
    <div className={styles.container}>
      {count ? (
        <div className={styles.countContainer}>
          <Text
            size="xxxs"
            color="white"
            weight="semibold"
            textAlign="center"
            noMargin
          >
            {count > 99 ? '99+' : count}
          </Text>
        </div>
      ) : null}
      <Icon
        className={styles.notificationIcon}
        baseClassName="material-icons-outlined"
        onClick={onClick}
      >
        notifications
      </Icon>
    </div>
  );
}
