import { Icon } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Text } from '../../../../components/typography';
import styles from './SidebarOption.module.scss';
import { useSpring, animated } from 'react-spring';
import { formatTestId } from '../../../../utilities/cypress';

export default function SidebarOption({
  collapsed,
  selected,
  onSelect,
  title,
  icon,
}) {
  const [hideText, setHideText] = useState(collapsed);

  useEffect(() => {
    if (collapsed && !hideText) {
      setHideText(true);
    } else if (!collapsed && hideText) {
      setTimeout(() => {
        setHideText(false);
        setDrawText(true);
      }, 375);
    }
  }, [collapsed, hideText]);

  const [drawText, setDrawText] = useState(!collapsed);

  const { opacity } = useSpring({
    config: { duration: 125 },
    from: { opacity: 1 },
    onRest: () => {
      if (collapsed) {
        setDrawText(false);
      }
    },
    opacity: !hideText ? 1 : 0,
  });

  return (
    <div
      onClick={() => onSelect(title)}
      className={styles[`container${selected ? 'Selected' : ''}`]}
    >
      <div
        className={styles.left}
        data-cy={`left-sidebar-${formatTestId(title)}`}
      >
        <Icon
          sx={{
            stroke: 'red',
            fontSize: '1.4rem',
            color: selected ? '#0d6efd' : '#1d1d1f',
            WebkitTextStroke: '0.4px white',
          }}
          baseClassName="material-icons-outlined"
          className={styles.icon}
        >
          {icon}
        </Icon>
        <animated.div style={{ opacity, display: drawText ? 'block' : 'none' }}>
          <Text
            weight="semibold"
            noMargin
            noSelect
            color={selected ? 'accentPrimary' : 'primary'}
          >
            {title}
          </Text>
        </animated.div>
      </div>
      <div className={selected ? styles.selectBox : ''} />
    </div>
  );
}
