import React from 'react';
import {
  Page,
  Document,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
} from '@react-pdf/renderer';
import { PageHeaderLogo, PageFooter, BatchPageHeader } from './constants';
import { expenseTopInputs, expenseBottomInputs } from '../../pages/hr/Expenses';
import { getLocalTime } from '../time';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { keys } from '../translator/translation_keys';

const fontSize = 10;
const blue = '#0d6efd';
const textPrimary = '#1d1d1f';
const grayLight = '#abb5be';
const styles = StyleSheet.create({
  field: {
    fontFamily: 'eina03semibold',
    fontSize,
  },
  value: {
    fontFamily: 'eina03regular',
    fontSize,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 1,
    borderColor: grayLight,
    alignItems: 'center',
    margin: 1,
    marginTop: 2,
    fontSize,
  },
  line: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: fontSize - 1,
    marginTop: 0.5,
  },
  header: {
    fontSize: fontSize + 2,
    color: textPrimary,
    fontFamily: 'eina03bold',
  },
  page: {
    backgroundColor: '#fff',
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    paddingTop: 50,
  },
  content: {
    flexDirection: 'column',
  },
  title: {
    fontSize: fontSize + 2,
    color: blue,
    fontFamily: 'eina03bold',
    marginBottom: 5,
  },
  details: {
    marginVertical: 10,
    borderColor: grayLight,
    border: 0.5,
    padding: 30,
  },
  notes: {
    fontSize: fontSize,
    marginTop: 10,
  },
  top: {
    marginTop: 10,
  },
  image: {
    maxWidth: '100%',
    maxHeight: 350,
    height: 'auto',
    padding: 5,
  },
  link: {
    padding: 15,
    fontSize: fontSize + 2,
  },
});

export const ExpenseDocument = ({
  user,
  expense,
  files,
  PDFImages,
  hasFiles,
}) => {
  const { company } = user;
  const { t } = useTranslation();

  const getValue = (query) => {
    return get(expense, query, '');
  };
  const headerInfo = [
    { title: keys.expenses.PDF_EXPENSE_ID, query: 'id' },
    { title: keys.common.WORKSPACE, query: 'workspace.title' },
    {
      title: keys.expenses.PDF_ACTIVITY,
      query: 'activity.description' || 'activity.externalActivityId',
    },
    { title: keys.common.STATUS, query: 'status' },
    { title: keys.expenses.PDF_PAYROLL_ID, query: 'creator.payrollId' },
    {
      title: keys.expenses.PDF_RESOURCE_ID,
      query: 'creator.externalActivityId',
    },
  ];

  const expenseInfo = [
    { title: keys.common.DATE_CREATED, query: 'dateCreated' },
    { title: keys.common.DATE_SUBMITTED, query: 'dateSubmitted' },
    { title: keys.common.DATE_REVIEWED, query: 'dateReviewed' },
    { title: keys.common.DATE_COMPLETED, query: 'datePaid' },
  ];

  const mileageInfo = [
    {
      title: keys.expenses.DISTANCE_NO_VARIABLE,
      key: 'Distance',
      value: `${getValue('distance')} ${expense.isMetric ? 'km' : 'mi'}`,
    },
    {
      title: keys.expenses.MILEAGE_RATE,
      key: 'Rate',
      value: `${getValue('rate')}$/${expense.isMetric ? 'km' : 'mi'}`,
    },
    {
      title: keys.expenses.TOTAL,
      key: 'Total',
      value: `$${expense['total'].toFixed(2)}`,
    },
  ];
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

  const getTranslatedValue = ({ field, storedKey }) => {
    const translationKey = field.options.find(
      ({ key }) => key === storedKey,
    ).text;

    return t(translationKey);
  };

  const ExpenseReport = () => (
    <View style={styles.content}>
      <View style={styles.top}>
        <View style={styles.details}>
          <Text style={styles.title}>{t(keys.expenses.DETAILS)}</Text>
          {expenseTopInputs.map((field, index) => (
            <View style={styles.row} key={index}>
              <Text style={styles.field}>{t(field.title)}</Text>
              <Text style={styles.value}>
                {field?.options
                  ? getTranslatedValue({
                      field,
                      storedKey: expense[field.query],
                    })
                  : expense[field.query]}
              </Text>
            </View>
          ))}
          {expense.type !== 'Mileage' ? (
            <View>
              {expenseBottomInputs.map((field, index) => (
                <View style={styles.row} key={index}>
                  <Text style={styles.field}>{t(field.title)}</Text>
                  <Text style={styles.value}>
                    {`${field.key === 'Total' ? '$' : ''}${
                      expense[field.query]?.toFixed(2) || '0.00'
                    }`}
                  </Text>
                </View>
              ))}
            </View>
          ) : (
            <View>
              {mileageInfo.map((info, index) => (
                <View style={styles.row} key={index}>
                  <Text style={styles.field}>{t(info.title)}</Text>
                  <Text style={styles.value}>{info.value}</Text>
                </View>
              ))}
            </View>
          )}
          <View style={styles.notes}>
            <Text style={styles.field}>{t(keys.common.NOTES)}</Text>
            <Text style={styles.value}>
              {expense.comment || t(keys.common.NONE)}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.bottom}>
        {!!expense.reviewer?.id && (
          <View style={styles.line}>
            <Text style={styles.field}>{t(keys.common.REVIEWER)}</Text>
            <Text
              style={styles.value}
            >{`${expense.reviewer.firstName} ${expense.reviewer.lastName}`}</Text>
          </View>
        )}
        {expenseInfo.map((field, index) =>
          expense[field.query] ? (
            <View style={styles.line} key={index}>
              <Text style={styles.field}>{t(field.title)}</Text>
              <Text style={styles.value}>
                {getLocalTime(expense[field.query]).format(
                  'dddd, MMMM Do YYYY',
                )}
              </Text>
            </View>
          ) : null,
        )}
      </View>
    </View>
  );
  const ExpenseAttachments = () => (
    <View>
      <Text style={styles.title}>{`${t(keys.expenses.ATTACHEMENTS)} ${
        files.length ? `(${files.length})` : ''
      }`}</Text>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {hasFiles ? (
          <>
            {PDFImages?.map((p, index) => (
              <Image key={index} src={p} style={styles.image} />
            ))}
            {files.map((file, index) =>
              imageExtensions.includes(file.extension) ? (
                <Image
                  key={index}
                  source={{ uri: file.url }}
                  style={styles.image}
                />
              ) : (
                file.extension !== 'pdf' && (
                  <View key={index} style={styles.link}>
                    <Text
                      style={{
                        fontFamily: 'eina03bold',
                      }}
                    >
                      {t(keys.expenses.NOT_SUPPORTED)}{' '}
                    </Text>
                    <Link src={file.url}>{`${file.name}`}</Link>
                  </View>
                )
              ),
            )}
          </>
        ) : (
          <Text style={styles.value}>{t(keys.common.NONE)}</Text>
        )}
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <PageHeaderLogo company={company} />
        <Text style={{ ...styles.header }}>
          {t(keys.expenses.REPORT_FOR, {
            variable: `${expense.creator.firstName} ${expense.creator.lastName}`,
          })}
        </Text>
        <Text style={{ fontSize: fontSize - 2, marginBottom: 10 }}>
          {t(keys.expenses.EXPORT_BY, {
            variable: `${user.firstName} ${user.lastName} ${new moment().format(
              'YYYY-MM-DD HH:mm:ss',
            )}`,
          })}
        </Text>
        <BatchPageHeader
          company={company}
          info={headerInfo.map((item) => ({
            title: t(item.title),
            value: getValue(item.query),
          }))}
        />
        <ExpenseReport />
        <PageFooter />
      </Page>
      <Page size="A4" style={styles.page} wrap>
        <ExpenseAttachments />
        <PageFooter />
      </Page>
    </Document>
  );
};
