import styles from './ActivitySelector.module.scss';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import DropdownCombo from '../dropdowns/DropdownCombo';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import moment from 'moment';
import { dropdownTypes } from '../dropdowns/dropdown';
import { useWorkspace } from '../../providers/WorkspaceProvider';

const activityParticipantQuery = loader('./ActivityConverter.fetch.graphql');

export default function ActivitySelector({
  activity,
  workspace,
  update = () => {},
  readOnly = false,
  id,
  isMandatory = true,
  lineSeparated = false,
  creator,
}) {
  const today = moment().format('MM/DD/YYYY');
  const { inWorkspaces } = useWorkspace();
  const { data: { activities = [] } = {} } = useQuery(
    activityParticipantQuery,
    {
      skip: readOnly || !creator,
      variables: {
        options: {
          filters: [
            {
              field: 'resourceId',
              operator: 'eq',
              value: `${creator?.externalActivityId}`,
            },
            {
              field: 'internalStartDate',
              operator: 'lte',
              value: today,
            },
            {
              field: 'internalEndDate',
              operator: 'gte',
              value: today,
            },
            { field: 'workspaceId', operator: 'eq', value: `${workspace?.id}` },
          ],
        },
      },
    },
  );

  return (
    <div className={styles.container}>
      <div className={styles.spacer} />
      <Text size="md" weight="bold" color="secondary">
        Workspace
      </Text>
      {!readOnly ? (
        <DropdownCombo
          type={dropdownTypes.WORKSPACE}
          preventStateChange
          highlight={isMandatory}
          items={inWorkspaces || []}
          selected={workspace}
          onChange={(workspace) => {
            update({
              variables: {
                id,
                workspaceId: workspace?.id,
                activityId: null,
                vehicleId: null,
                progress: 100,
              },
            });
          }}
          placeholder="Workspace"
          required={true}
        />
      ) : (
        <Text weight="bold">{workspace?.title}</Text>
      )}
      <div className={lineSeparated ? styles.sectionLine : styles.spacer} />
      <Text size="md" weight="bold" color="secondary">
        Activity{isMandatory ? '*' : ''}
      </Text>
      {!workspace?.projectConfigured && !readOnly ? (
        <div className={styles.warning}>
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ color: 'red', opacity: 0.6 }}
          >
            warning
          </Icon>
          <Text color="secondary" weight="semiBold" noMargin>
            No Activities have been configured for this Workspace. Try selecting
            a different one, or contact your Administrator!
          </Text>
        </div>
      ) : (
        <>
          {!readOnly ? (
            <div className={styles.selector}>
              <DropdownCombo
                highlight={isMandatory}
                type={dropdownTypes.ACTIVITY}
                onRemove={() => {
                  update({
                    variables: {
                      id,
                      activityId: null,
                    },
                  });
                }}
                preventStateChange
                disabled={!workspace}
                items={activities || []}
                selected={activity}
                onChange={(activity) => {
                  update({
                    variables: {
                      id,
                      activityId: activity.id,
                      poNumber: activity.poNumber || null,
                    },
                  });
                }}
                placeholder="Activity"
              />
            </div>
          ) : (
            <Text weight="bold">
              {activity?.description || activity?.externalActivityId || 'None'}
            </Text>
          )}
        </>
      )}
    </div>
  );
}
