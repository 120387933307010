import { keys } from '../../utilities/translator/translation_keys';
export const hasObservations = {
  message: keys.assessments.HAS_OBSERVATIONS,
  disclaimer: keys.assessments.HAS_OBSERVATIONS_DISCLAIMER,
};
export const noObservations = {
  message: keys.assessments.NO_OBSERVATIONS,
  disclaimer: keys.assessments.NO_OBSERVATIONS_DISCLAIMER,
};
export const modalLoadingMessage = {
  title: keys.assessments.MODAL_LOADING_TITLE,
  subtitle: keys.assessments.MODAL_LOADING_SUBTITLE,
};

export const modalAnalysisMessage = {
  title: keys.assessments.MODAL_ANALYSIS_TITLE,
  subtitle: keys.assessments.MODAL_ANALYSIS_SUBTITLE,
  feedback: keys.assessments.MODAL_ANALYSIS_FEEDBACK,
  feedback_placeholder: keys.assessments.MODAL_ANALYSIS_FEEDBACK_PLACEHOLDER,
};

export const modalNoAnalysisMessage = {
  title: keys.assessments.MODAL_NO_ANALYSIS_TITLE,
  subtitle: keys.assessments.MODAL_NO_ANALYSIS_SUBTITLE,
};
