import React, { useState, useMemo, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import styles from './MultipleChoiceQuestion.module.scss';
import { Text } from './typography';
import Button from './Button';
import { Icon } from '@mui/material';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';

const optionSetMuation = loader('./MultipleChoiceQuestion.create.graphql');
const optionMutation = loader('./MultipleChoiceQuestion.createOption.graphql');

export default function MultipleChoiceQuestion({
  title,
  setModalOpen,
  setHeader,
}) {
  const [optionSetId, setOptionSetId] = useState(0);
  const { t } = useTranslation();

  const [addOptionSet] = useMutation(optionSetMuation);
  const [addOption] = useMutation(optionMutation);

  function MultiSelect({
    title,
    optionSet,
    disabled = true,
    onCloseOption,
    canEdit = true,
    type,
  }) {
    return (
      <div className={styles.checkBoxDisplay}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Label>{title}</Form.Label>
          {optionSet.map((option, index) => (
            <div className={styles.optionDisplay} key={index}>
              <Form.Check
                type={type}
                inline
                label={option}
                disabled={disabled}
              />

              {canEdit && (
                <Icon
                  className={styles.deselectIcon}
                  fontSize="inherit"
                  baseClassName="material-icons-outlined"
                  onClick={() => {
                    onCloseOption(option);
                  }}
                >
                  close
                </Icon>
              )}
            </div>
          ))}
        </Form>
      </div>
    );
  }

  const CreateTitlePage = () => {
    const [title, setTitle] = useState('');

    useEffect(() => {
      setHeader(t(keys.action.ADD, { variable: t(keys.common.TITLE) }));
    }, []);

    return (
      <div className={styles.titleContainer}>
        <div>
          <Text size="md" weight="semiBold" color="primary" noSelect noMargin>
            {t(keys.common.TITLE)}
          </Text>
          <Form onSubmit={(e) => e.preventDefault()}>
            <div className={styles.input}>
              <Form.Group>
                <Form.Control
                  placeholder={t(keys.templates.OPTIONS_TITLE_PLACEHOLDER)}
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
            </div>
          </Form>
        </div>
        <div className={styles.buttonDisplay}>
          <Button
            variant="secondary"
            onClick={(e) => {
              setModalOpen(false);
            }}
            value={t(keys.action.CANCEL)}
          />
          <Button
            disabled={!title.length}
            onClick={() => {
              addOptionSet({ variables: { title } }).then(
                ({ data: { addOptionSet: newOptionSet } }) => {
                  setOptionSetId(newOptionSet.id);
                },
              );
            }}
            value={t(keys.action.NEXT)}
          />
        </div>
      </div>
    );
  };

  const OptionsPage = () => {
    const [input, setInput] = useState('');
    const [optionSet, setOptionSet] = useState([]);
    const [sendingOptions, setSendingOptions] = useState(false);
    const addOptions = t(keys.templates.ADD_OPTIONS);

    useEffect(() => {
      setHeader(addOptions);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <div>
        <Text size="md" weight="semiBold" color="primary" noSelect noMargin>
          {t(keys.common.OPTIONS)}
        </Text>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={styles.optionSet}>
            <Form.Group>
              <Form.Control
                className={styles.optionInput}
                placeholder={t(keys.templates.OPTION_SET_PLACEHOLDER)}
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </Form.Group>
            <Button
              className={styles.addButton}
              outlined={true}
              onClick={() => {
                setOptionSet([...optionSet, input]);
                setInput('');
              }}
              disabled={!input.length}
              value={t(keys.action.ADD, { variable: null })}
            />
          </div>
          <div className={styles.optionOutput}>
            {optionSet && (
              <MultiSelect
                title={title}
                optionSet={optionSet}
                onCloseOption={(optionToDelete) => {
                  setOptionSet(
                    optionSet.filter((option) => option !== optionToDelete),
                  );
                }}
              />
            )}
          </div>

          <div className={styles.buttonDisplay}>
            <Button
              onClick={() => {
                setOptionSetId(0);
              }}
              variant="secondary"
              value={t(keys.action.BACK)}
            />

            <Button
              disabled={!optionSet.length || sendingOptions}
              onClick={async () => {
                setSendingOptions(true);
                for (let i = 0; i < optionSet.length; i += 1) {
                  await addOption({
                    variables: {
                      optionSetId,
                      text: optionSet[i],
                    },
                  });
                }
                setSendingOptions(false);
                setOptionSet([]);
                setInput('');
                setOptionSetId(0);
                setModalOpen(false);
              }}
              value={t(keys.action.SUBMIT)}
            />
          </div>
        </Form>
      </div>
    );
  };
  const CurrentPage = useMemo(
    () => (optionSetId ? OptionsPage : CreateTitlePage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionSetId],
  );

  return (
    <div>
      <CurrentPage />
    </div>
  );
}
