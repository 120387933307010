export const low = {
  level: 'Low',
  mobile: 'L',
  color: '#28a745',
  textColor: 'green',
  message:
    'Acceptable risk level - No further action required. Proceed with task.',
};
export const medium = {
  level: 'Medium',
  mobile: 'MD',
  color: '#ffc107',
  textColor: 'yellow',
  message:
    'Task should be reviewed for improvement strategies. Proceed with caution.',
};
export const high = {
  level: 'High',
  mobile: 'H',
  textColor: 'red',
  color: '#e9222c',
  message:
    'Unacceptable risk level - Immediate Reassessment/Approval Required. Do not proceed.',
};
export const impactData = [
  {
    impact: 'Insignificant',
    message: "Won't cause serious injuries/illness",
    value: 1,
  },
  {
    impact: 'Minor',
    message: 'May cause mild injuries or illnesses',
    value: 2,
  },
  {
    impact: 'Moderate',
    message:
      'May cause injuries/illnesses that require medical attention with limited treatment',
    value: 3,
  },
  {
    impact: 'Major',
    message:
      'May cause irreversible injuries or illnesses that require constant medical attention',
    value: 4,
  },
  { impact: 'Catastrophic', message: 'Possible fatality', value: 5 },
];

export const probabilityData = [
  {
    probability: 'Rare',
    message:
      'Unusual, infrequent or rare. Once every 10 years, less than 1% chance of occuring',
    value: 1,
  },
  {
    probability: 'Unlikely',
    message:
      'May occur but not anticipated. Once every 5 years or 1% to 20% chance of occuring',
    value: 2,
  },
  {
    probability: 'Possible',
    message:
      'Not uncommon and reasonably expected to occur. Annually or 21%-50% chance of occurring',
    value: 3,
  },
  {
    probability: 'Likely',
    message:
      'Has occurred before and will occur in most circumstances. Weekly or 51% to 90% chance occuring',
    value: 4,
  },
  {
    probability: 'Certain',
    message:
      'Occurs regularly or expected to occur. Greater than 90% chance of occurring',
    value: 5,
  },
];

export const nullValue = { level: '' };

export const colorLookup = {
  Extreme: '#b02a37',
  High: '#e9222c',
  'Med-High': '#fd7e14',
  Medium: '#ffc107',
  'Low-Med': '#198754',
  Low: '#28a745',
};

export function calculateRisk(value) {
  switch (true) {
    case value > 0 && value <= 6:
      return low;

    case value > 6 && value <= 12:
      return medium;
    case value > 12:
      return high;
    default:
      return nullValue;
  }
}
