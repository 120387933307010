import { Icon } from '@mui/material';
import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import { Text } from '../typography';
import styles from './ActionItemCard.module.scss';
import { getLocalTime } from '../../utilities/time';
import LabelsDisplay from '../LabelsDisplay';

export default function ActionItemCard({
  actionItem,
  className,
  onClick,
  maxLabels = 2,
  disabled = false,
}) {
  return (
    <div
      className={classNames(
        styles.container,
        className,
        disabled ? styles.disabled : null,
      )}
      onClick={onClick}
    >
      <div>
        <div className={styles[actionItem.status.toLowerCase()]} />
      </div>
      <div className={styles.cardContainers}>
        <div className={styles.leftContainer}>
          <div className={styles.titleContainer}>
            <Text
              className={styles.titleText}
              noMargin
              size="sm"
              weight="semibold"
            >
              {actionItem.title}
            </Text>
          </div>
          <LabelsDisplay labels={actionItem.labels} maxLabels={maxLabels} />
          <div className={styles.creationDate}>
            <Icon
              sx={{ fontSize: '1rem' }}
              baseClassName="material-icons-outlined"
              className={styles.timerIcon}
            >
              timer
            </Icon>
            <Text noMargin size="xxs" color="secondary" weight="semibold">
              {getLocalTime(actionItem.dateCreated).fromNow()}
            </Text>
          </div>
        </div>
        <div className={styles.rightContainer}>
          {actionItem?.assignee?.id && (
            <img
              alt="user avatar"
              className={styles.avatar}
              src={actionItem.assignee.avatarUrl}
            />
          )}
          {actionItem?.dueDate && (
            <div
              className={
                getLocalTime(actionItem.dueDate).isBefore(new moment(Date()))
                  ? styles.lateDueDateButton
                  : styles.dueDateButton
              }
            >
              <Icon
                sx={{ fontSize: '1rem' }}
                baseClassName="material-icons-outlined"
                className={styles.dueDateIcon}
              >
                timer
              </Icon>
              <Text size="xxs" color="primaryLight" weight="semibold" noMargin>
                {getLocalTime(actionItem.dueDate).format('MMM Do')}
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
