import { loader } from 'graphql.macro';
import styles from './InspectionItemComments.module.scss';
import { Text } from '../../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { Form } from 'react-bootstrap';
import { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useParams } from 'react-router-dom';
import { getLocalTime } from '../../../utilities/time';
import QuickFileDropzone from '../../image_uploads/QuickFileDropzone';
import findFileIcon, { imageExtensions } from '../../../utilities/files';
import { openFileViewer } from '../../../graphql/cache/modal';
import { downloadFile } from '../../../utilities/inspection';
import { useIsMobile } from '../../../hooks/misc';
import { Icon } from '@mui/material';
import { customColors } from '../../../utilities';

const addInspectionItemCommentMutation = loader(
  './InspectionItemComments.addComment.graphql',
);

export default function InspectionItemComments({
  comments,
  itemId,
  refetch = () => {},
}) {
  const { id } = useParams();
  const [text, setText] = useState('');
  const { t } = useTranslation();
  const [addComment] = useMutation(addInspectionItemCommentMutation);
  const { user } = useCurrentUser();
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [comments]);
  const isMobile = useIsMobile();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Text weight="bold" noMargin>
            Comments
          </Text>
          <Text noMargin size="lg">
            💬
          </Text>
        </div>
        <QuickFileDropzone
          icon={true}
          onSubmit={({ url, name }) =>
            addComment({
              variables: {
                text: name,
                attachmentUrl: url,
                itemId,
                inspectionId: parseInt(id),
              },
            }).then(() => refetch())
          }
        />
      </div>
      <div key={id} className={styles.commentsContainer} ref={ref}>
        {comments?.map(
          ({ creator, id, text, dateCreated, attachmentUrl }, idx) => {
            const extension = attachmentUrl?.split('.').pop();
            const isImage = imageExtensions.includes(extension);
            const file = {
              name: text,
              fileType: extension,
              downloadAllowed: true,
              url: attachmentUrl,
            };
            return (
              <div
                key={id}
                className={
                  idx % 2 === 0
                    ? styles.commentContainer
                    : styles.commentContainerOdd
                }
              >
                <img
                  className={styles.userAvatar}
                  src={creator?.avatarUrl}
                  alt="your avatar"
                />
                <div>
                  <div className={styles.comment}>
                    <Text
                      size="md"
                      weight="semiBold"
                      noMargin
                    >{`${creator.firstName} ${creator.lastName}`}</Text>
                    <Text size="sm" noMargin color="secondary">
                      {getLocalTime(dateCreated).fromNow()}
                    </Text>
                  </div>
                  <div className={styles.commentText}>
                    {!!attachmentUrl ? (
                      <div>
                        <img
                          className={styles.attachment}
                          src={
                            isImage ? attachmentUrl : findFileIcon(extension)
                          }
                          alt="attachment"
                          onClick={() => openFileViewer(file)}
                        />
                        <Text
                          onClick={() => downloadFile(attachmentUrl, text)}
                          noMargin
                          size="sm"
                          color="accentPrimary"
                          hover
                        >
                          {text}
                        </Text>
                      </div>
                    ) : (
                      <Text noMargin>{text}</Text>
                    )}
                  </div>
                </div>
              </div>
            );
          },
        )}
      </div>
      <div className={styles.input}>
        <img
          className={styles.userAvatar}
          src={user?.avatarUrl}
          alt="your avatar"
        />
        <Form.Control
          value={text}
          as={isMobile ? 'textarea' : undefined}
          onChange={(e) => setText(e.target.value)}
          placeholder={t(keys.action_items.COMMENT)}
        />
        <Icon
          testId="action-item-comment"
          onClick={() => {
            if (text) {
              addComment({
                variables: { text, itemId, inspectionId: parseInt(id) },
              }).then(() => {
                refetch();
                setText('');
              });
            }
          }}
          disabled={!text}
          sx={{
            fontSize: isMobile ? '1rem' : undefined,
            color: !!text ? customColors.BLUE : customColors.SECONDARY_LIGHT,
            cursor: !!text ? 'pointer' : 'auto',
          }}
        >
          send_rounded
        </Icon>
      </div>
    </div>
  );
}
