import { Icon } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { formatTestId } from '../utilities/cypress';
import styles from './Button.module.scss';
import { Text } from './typography';

export default function Button({
  value,
  testId,
  onClick = () => {},
  align,
  className,
  icon,
  iconStylesClassName = null,
  variant = 'primary',
  disabled,
  type,
  size = 'md',
  outlined = false,
}) {
  const textColorLut = {
    primary: {
      outlined: 'accentPrimary',
      solid: 'white',
    },
    secondary: {
      outlined: 'primary',
      solid: 'primary',
    },
    success: {
      outlined: 'green',
      solid: 'white',
    },
    warning: {
      outlined: 'yellow',
      solid: 'white',
    },
    danger: {
      outlined: 'red',
      solid: 'white',
    },
    purple: {
      outlined: 'purple',
      solid: 'white',
    },
  };

  return (
    <button
      type={type}
      data-cy={`button-${formatTestId(value)}${
        testId ? `-${formatTestId(testId)}` : ''
      }`}
      className={classNames(
        className,
        styles[size],
        disabled ? styles.disabled : styles[variant],
        outlined ? styles.outlinedContainer : styles.container,
        outlined ? styles[`${variant}OutlinedContainer`] : null,
        disabled && outlined ? styles.disabledOutlinedContainer : null,
        align === 'right' ? styles.alignRight : null,
      )}
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {icon ? (
        <Icon
          baseClassName="material-icons-outlined"
          className={classNames(iconStylesClassName, styles.icon)}
        >
          {icon}
        </Icon>
      ) : null}
      {value ? (
        <Text
          size={size}
          weight="semiBold"
          noSelect
          noMargin
          color={textColorLut[variant][outlined ? 'outlined' : 'solid']}
          textAlign="center"
        >
          {value}
        </Text>
      ) : null}
    </button>
  );
}
