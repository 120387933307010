import { useEffect, useState } from 'react';
import Dexie from 'dexie';

//This indexedDB is for storing blob of image

export const useIndexedDB = (dbName) => {
  const [db, setDB] = useState(null);

  useEffect(() => {
    const database = new Dexie(dbName);
    database.version(1).stores({ files: '&id' });

    database
      .open()
      .then((dbInstance) => {
        setDB(dbInstance);
      })
      .catch((err) => {
        console.error(`Failed to open the database: ${err.stack || err}`);
      });
    return () => {
      // Close the DB when the component is unmounting
      if (database.isOpen()) database.close();
    };
  }, [dbName]);

  const isDBOpen = () => {
    return db !== null;
  };

  const addFile = async (file, id) => {
    if (db) {
      return db.table('files').add({ id, file });
    }
    throw new Error('Database is not opened yet!');
  };

  const getFile = async (id) => {
    if (db) {
      return db.table('files').get(id);
    }
    throw new Error('Database is not opened yet!');
  };

  const getFileURL = async (id) => {
    const record = await getFile(id);
    return record ? URL.createObjectURL(record.file) : null;
  };

  const deleteFile = async (id) => {
    if (db) {
      return db.table('files').delete(id);
    }
    throw new Error('Database is not opened yet!');
  };

  const getFileReaderURL = async (id) => {
    const record = await getFile(id);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(record.file);
    });
  };

  const clearFileImageDatabase = async () => {
    if (!db) throw new Error('The database is not initialized');

    return db.delete().catch((err) => {
      console.error('Failed to delete database:', err);
    });
  };

  return {
    isDBOpen,
    addFile,
    getFile,
    getFileURL,
    deleteFile,
    getFileReaderURL,
    clearFileImageDatabase,
  };
};
