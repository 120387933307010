import { loader } from 'graphql.macro';
import { useMutation, useApolloClient } from '@apollo/client';
import { useOnlineStatus } from './offline-misc';
import { useCachedMutations } from './offline-misc';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { v4 as uuidv4 } from 'uuid';

const updateVehicleMutation = loader(
  '../../components/inspections/inspection_items/InspectionItemAsset.update.graphql',
);

export const useUpdateVehicle = () => {
  const [updateVehicle] = useMutation(updateVehicleMutation);
  const client = useApolloClient();
  const online = useOnlineStatus();
  const { addMutation } = useCachedMutations();
  const { workspaceVehicles } = useWorkspace();

  const updateAsset = async ({ id, odometer, hours }) => {
    const options = {
      variables: {
        id,
        odometer,
        hours,
      },
    };

    const vehicle = workspaceVehicles?.find((v) => v.id === id);

    if (online) {
      return updateVehicle(options);
    } else {
      const updatedVehicle = {
        ...vehicle,
        odometer: odometer ?? vehicle?.odometer,
        hours: hours ?? vehicle?.hours,
      };

      client.writeQuery({
        query: updateVehicleMutation,
        data: { updateVehicle: updatedVehicle },
        variables: { id },
      });

      const mockResponse = {
        __typename: 'Vehicle',
        ...updatedVehicle,
      };
      const uuid = uuidv4().toString();

      await addMutation(
        updateVehicleMutation,
        options,
        uuid,
        updatedVehicle,
        [],
        '',
        false,
      );

      return Promise.resolve({
        data: {
          updateVehicle: mockResponse,
        },
      });
    }
  };

  return updateAsset;
};
