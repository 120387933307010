import Modal from '../Modal';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import { Text } from '../../typography';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../../constants/strings';
import noImage from '../../../assets/workspace_image.png';
import { truncate } from '../../../utilities';
import styles from './NoWorkspaceModal.module.scss';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { useWorkspacePermissions } from '../../../providers/WorkspacePermissionsProvider';

export default function NoWorkspaceModal() {
  const { closeModal, modalState, openModal } = useModal();
  const { availableWorkspaces, inWorkspaces, notInWorkspaces } = useWorkspace();
  const { setChangingWorkspace } = useWorkspacePermissions();
  const { show, modalTitle, allowClose } = modalState.noWorkspaceModal;
  const onHide = () => closeModal({ modalName: modals.noWorkspace });
  const navigate = useNavigate();
  const { isAdmin } = useCurrentUser();
  const { t } = useTranslation();
  const hasWorkspaces = availableWorkspaces?.length;

  return (
    <Modal
      open={show || false}
      title={
        hasWorkspaces
          ? t(keys.action.SELECT_VARIABLE, {
              variable: t(keys.common.WORKSPACE),
            }) || modalTitle
          : t(keys.action.NOT_FOUND, { variable: t(keys.common.WORKSPACE) })
      }
      onClose={onHide}
      allowClose={allowClose}
      submitText={t(keys.workspace.CREATE_NEW_WORKSPACE)}
      hideCancel
      hideSubmit={!isAdmin}
      submitButtonIcon={'add'}
      onSubmit={() => openModal({ modalName: modals.createWorkspace })}
    >
      <div className={styles.content}>
        {!hasWorkspaces ? (
          <Text textAlign="center" noMargin>
            {t(keys.utilities.PLEASE_CONTACT)}{' '}
            <span>support@opasmobile.com</span> {t(keys.utilities.OR)}{' '}
            <span
              className={styles.link}
              onClick={() =>
                (window.location.href = 'https://www.opasmobile.com/contact')
              }
            >
              {t(keys.utilities.LET_US_KNOW)}
            </span>
          </Text>
        ) : (
          <div className={styles.container}>
            {availableWorkspaces?.map((workspace) => (
              <div
                key={workspace.id}
                onClick={() => {
                  if (notInWorkspaces.some((id) => id === workspace.id)) {
                    setChangingWorkspace(true);
                  }
                  navigate(getRoute(workspace?.id, paths.dashboard));
                  onHide();
                }}
                className={
                  inWorkspaces?.some((w) => w.id === workspace.id)
                    ? styles.card
                    : styles.disabledCard
                }
              >
                <img
                  src={workspace?.logoUrl || noImage}
                  alt={`${workspace.title}'s logo`}
                  className={styles.logo}
                />
                <div>
                  <Text weight="semiBold" noMargin size="md">
                    {truncate(workspace?.title, 10)}
                  </Text>
                  <Text weight="semiBold" color="secondary" noMargin size="sm">
                    {workspace?.status}
                  </Text>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}
