import Modal from '../../components/modals/Modal';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Text } from '../../components/typography';
import { loader } from 'graphql.macro';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import styles from './ToolboxSchedule.module.scss';
import defaultLogo from '../../assets/workspace_image.png';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';

const tbtScheduleQuery = loader('./ToolboxSchedule.fetch.graphql');

export default function ToolboxScheduleModal({ show, setShow }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const { t } = useTranslation();

  const { data: { tbtSchedule = [] } = {} } = useQuery(tbtScheduleQuery, {
    skip: !selectedDate,
    variables: {
      options: {
        sort: [{ field: 'scheduleDate', order: 'ASC' }],
        filters: [
          {
            field: 'scheduleDate',
            operator: 'eq',
            value: selectedDate,
          },
        ],
      },
    },
  });
  return (
    <Modal
      open={show}
      onClose={() => {
        setShow(false);
        setSelectedDate(null);
      }}
      title={t(keys.toolbox.TOOLBOX_TALK_SCHEDULE)}
      hideCancel
      hideSubmit
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          sx={{ width: '100%' }}
          onChange={async (newDate) => {
            const date = moment(newDate, 'YYYY-MM-DD');
            setSelectedDate(date);
          }}
        />
      </LocalizationProvider>
      <div className={styles.workspaceView}>
        {tbtSchedule.length ? (
          tbtSchedule?.map(({ workspace, tbt }) => (
            <div className={styles.list} key={workspace.id}>
              <img
                src={workspace.logoUrl || defaultLogo}
                alt="tbt"
                className={styles.avatar}
              />
              <div className={styles.listText}>
                <Text noMargin weight="semiBold" truncate>
                  {workspace?.title}
                </Text>
                <Text>{tbt.title}</Text>
              </div>
            </div>
          ))
        ) : (
          <Text color="yellow" weight="semiBold" textAlign="center">
            No Toolbox Talks Scheduled
          </Text>
        )}
      </div>
    </Modal>
  );
}
