import { Icon } from '@mui/material';
import React, { useMemo } from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Text } from './typography';
import styles from './UserSelector.module.scss';
import { closeModalComponents } from '../utilities/modal';
import { useWorkspacePermissions } from '../providers/WorkspacePermissionsProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

const allUsersQuery = loader('./UserMultiSelector.graphql');
export default function UserSelector({
  selected,
  onSelectionChanged,
  type,
  className,
  onClose,
  userQuery = '',
  disableRemove = false,
  usersToLeaveOut = [],
}) {
  const [search, setSearch] = useState('');
  const { allWorkspaceUsers } = useWorkspacePermissions();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const { data: { users = [] } = {} } = useQuery(allUsersQuery, {
    skip: userQuery !== 'all',
  });

  useEffect(() => setSearch(''), [modalOpen]);

  const filteredUsers = useMemo(() => {
    const availableUsers = userQuery === 'all' ? users : allWorkspaceUsers;
    return search?.length
      ? availableUsers
          .filter(
            (u) =>
              u.firstName.toLowerCase().includes(search.toLowerCase()) ||
              u.lastName.toLowerCase().includes(search.toLowerCase()),
          )
          .filter((u) => !usersToLeaveOut.some((s) => s.id === u.id))
      : availableUsers.filter(
          (u) => !usersToLeaveOut.some((s) => s.id === u.id),
        );
  }, [userQuery, users, allWorkspaceUsers, search, usersToLeaveOut]);

  const ref = useRef(null);
  useEffect(() => {
    closeModalComponents(ref, setModalOpen);
  }, [ref]);

  return (
    <div className={className}>
      {!!selected ? (
        <div
          className={styles.userContainer}
          onClick={() => {
            if (!disableRemove) setModalOpen(true);
          }}
        >
          <img
            className={styles.avatar}
            src={selected.avatarUrl}
            alt={`${selected.firstName}s avatar`}
          />
          <Text
            size="md"
            weight="semiBold"
            noSelect
            noMargin
          >{`${selected.firstName} ${selected.lastName}`}</Text>
          {!disableRemove && (
            <Icon
              className={styles.deselectIcon}
              fontSize="inherit"
              baseClassName="material-icons-outlined"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              close
            </Icon>
          )}
        </div>
      ) : (
        <div
          className={styles.buttonContainer}
          onClick={() => setModalOpen(true)}
        >
          <Icon fontSize="inherit" baseClassName="material-icons-outlined">
            person
          </Icon>
          <Text size="md" noSelect noMargin>
            {t(keys.action.ADD, {
              variable: t(keys.common[type]) || t(keys.common.USER),
            })}
          </Text>
        </div>
      )}
      {modalOpen && (
        <div ref={ref} className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <Icon
              className={styles.hiddenIcon}
              fontSize="inherit"
              baseClassName="material-icons-outlined"
            >
              close
            </Icon>
            <Text
              size="md"
              weight="semiBold"
              color="secondary"
              noSelect
              noMargin
            >
              {t(keys.common.USERS)}
            </Text>
            <Icon
              className={styles.closeIcon}
              fontSize="inherit"
              baseClassName="material-icons-outlined"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              close
            </Icon>
          </div>
          <hr />
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              className={styles.input}
              type="text"
              placeholder={t(keys.action.SEARCH, {
                variable: t(keys.common.USERS),
              })}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form>
          <div className={styles.userListContainer}>
            {filteredUsers?.map((user) => (
              <div
                key={user.id}
                className={styles.userContainer}
                onClick={() => {
                  onSelectionChanged(user);
                  setModalOpen(false);
                }}
              >
                <img
                  className={styles.avatar}
                  src={user.avatarUrl}
                  alt={`${user.firstName}s avatar`}
                />
                <Text
                  size="md"
                  weight="semiBold"
                  noSelect
                  noMargin
                >{`${user.firstName} ${user.lastName}`}</Text>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
