import classNames from 'classnames';
import styles from './AddAssetModal.module.scss';
import Searchbar from '../Searchbar';
import { useState, useMemo } from 'react';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import VehicleCardList from '../vehicles/VehicleCardList';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function AddAssetModal({
  show,
  onHide,
  assets = {},
  workspaceId,
  title,
  addAsset = () => {},
  allowCreate = false,
  modalSize = 'md',
  multiSelect = false,
}) {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const filteredAssets = useMemo(() => {
    return assets?.length
      ? search?.length
        ? assets?.filter(
            (asset) =>
              asset.make.toLowerCase().includes(search.toLowerCase()) ||
              asset.model.toLowerCase().includes(search.toLowerCase()) ||
              asset.unitNumber.toLowerCase().includes(search.toLowerCase()),
          )
        : assets
      : null;
  }, [assets, search]);

  return (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            modalSize === 'lg' ? styles.largeContainer : styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
        >
          <div className={styles.headerContainer}>
            <Text weight="bold" size="lg" color="accentPrimary">
              {title}
            </Text>
            <Icon className={styles.closeButton} onClick={() => onHide()}>
              close
            </Icon>
          </div>
          <Searchbar
            onChange={setSearch}
            value={search}
            title={t(keys.common.ASSETS)}
          />
          <div className={styles.contentContainer}>
            <div className={styles.assetList}>
              {filteredAssets?.length ? (
                filteredAssets?.map((asset) => (
                  <div key={asset.id}>
                    <VehicleCardList
                      readOnly={true}
                      vehicle={asset}
                      workspaceId={workspaceId}
                      onAssetAdded={(asset) => {
                        addAsset(asset);
                        if (!multiSelect) {
                          onHide();
                        }
                      }}
                    />
                  </div>
                ))
              ) : (
                <div className={styles.noAssets}>
                  <Text>{t(keys.workspace.NO_ASSETS_TO_ADD)}</Text>
                </div>
              )}
            </div>
            {allowCreate && (
              <div
                className={styles.modalFooter}
                onClick={() => {
                  onHide();
                  navigate(getRoute(workspaceId, paths.asset, 'create'));
                }}
              >
                <Icon className={styles.plusIcon}>add</Icon>
                <Text
                  className={styles.buttonText}
                  color="accentPrimary"
                  size="md"
                  noMargin
                  weight="bold"
                >
                  {t(keys.action.CREATE_VARIABLE, {
                    variable: t(keys.common.ASSET),
                  })}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
