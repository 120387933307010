import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import styles from './LabelCreationModal.module.scss';
import { Text } from '../typography';
import { Form } from 'react-bootstrap';
import ColorPicker from '../ColorPicker';
import Label from '../action_items/Label';
import Button from '../Button';
import { useMemo } from 'react';
import { showToast } from '../../graphql/cache/modal';
import { toastLength } from '../../constants/misc';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const labelMutation = loader('./LabelCreationModal.graphql');

export default function LabelCreationModal({
  show = false,
  onHide,
  labels,
  onCompleted = () => {},
}) {
  const [addLabel] = useMutation(labelMutation);
  const [name, setName] = useState('');
  const [color, setColor] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (!show) {
        setName('');
        setColor('');
      }
    }, 1000); // Time delay for animation
  }, [show]);

  const labelColors = [
    'PINK',
    'RED',
    'PURPLE',
    'BLUE',
    'CYAN',
    'GREEN',
    'YELLOW',
    'ORANGE',
    'GRAY',
  ];

  const labelNames = useMemo(() => labels.map((l) => l.name), [labels]);

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.header}>
            <Text
              className={styles.header}
              size="lg"
              weight="semiBold"
              noMargin
            >
              {t(keys.action.CREATE_VARIABLE, {
                variable: t(keys.common.LABEL),
              })}
            </Text>
            <Icon className={styles.closeIcon} onClick={() => onHide()}>
              close
            </Icon>
          </div>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group>
              <Form.Label>{t(keys.common.NAME)}</Form.Label>
              <Form.Control
                type="text"
                required
                data-cy="input-label-creation-modal-name"
                placeholder={t(keys.common.NAME_OF, {
                  variable: t(keys.common.LABEL),
                })}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>{t(keys.common.COLOUR)}</Form.Label>
              <ColorPicker
                colors={labelColors}
                selected={color}
                onChange={(c) => setColor(c)}
              />
            </Form.Group>
          </Form>
          <br />
          <div className={styles.footer}>
            {name && color && (
              <Label
                rowSized={false}
                className={styles.label}
                name={name}
                color={color}
              />
            )}
            <Button
              className={styles.button}
              testId="label-creation"
              disabled={!name || !color}
              value={t(keys.action.SAVE)}
              onClick={() => {
                if (labelNames.includes(name)) {
                  showToast({
                    title: t(keys.action.ALREADY_EXISTS, { variable: name }),
                    message: t(keys.common.LABEL_EXISTS_MESSAGE),
                    variant: 'warning',
                    time: toastLength.lg,
                  });
                } else {
                  addLabel({ variables: { name, color } }).then(() => {
                    onCompleted();
                    onHide();
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
