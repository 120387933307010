import styles from './WorkspaceSelector.module.scss';
import { Text } from './typography';
import { Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { paths } from '../constants/strings';
import { useState, useMemo } from 'react';
import defaultLogo from '../assets/workspace_image.png';
import Searchbar from './Searchbar';
import { truncate } from '../utilities';
import { getRoute } from '../constants/strings';
import { useCurrentUser } from '../providers/UserProvider';
import { useWorkspace } from '../providers/WorkspaceProvider';
import { useModal } from '../providers/ModalProvider';
import { modals } from '../providers/modals';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { useWorkspacePermissions } from '../providers/WorkspacePermissionsProvider';

export default function WorkspaceSelector({ show, setShow }) {
  const navigate = useNavigate();
  const { isAdmin } = useCurrentUser();
  const { workspace, availableWorkspaces, inWorkspaces, notInWorkspaces } =
    useWorkspace();
  const { setChangingWorkspace } = useWorkspacePermissions();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(() => {
    return search?.length
      ? availableWorkspaces?.filter((workspace) =>
          workspace.title.toLowerCase().includes(search.toLowerCase()),
        )
      : availableWorkspaces?.filter((w) => w.id !== workspace?.id);
  }, [availableWorkspaces, search, workspace?.id]);

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.container}>
        <div className={styles.modalHeader}>
          <Text color="accentPrimary" size="md" noMargin weight="bold">
            {truncate(workspace?.title, 25)}
          </Text>
          <div className={styles.optionsContainer}>
            <Icon
              className={styles.closeIcon}
              onClick={() => {
                navigate(getRoute(workspace?.id, paths.workspaceSettings));
                setShow(false);
              }}
            >
              settings
            </Icon>
            <Icon className={styles.closeIcon} onClick={() => setShow(false)}>
              close
            </Icon>
          </div>
        </div>
        <div>
          <Searchbar value={search} onChange={(value) => setSearch(value)} />
          <div
            className={
              isAdmin ? styles.dropdownContainer : styles.dropdownContainerLong
            }
          >
            {filteredOptions?.map((workspace) => (
              <div
                key={workspace.id}
                className={styles.dropdown}
                onClick={() => {
                  if (notInWorkspaces.some((id) => id === workspace.id)) {
                    setChangingWorkspace(true);
                  }
                  navigate(getRoute(workspace.id, paths.dashboard));
                  setShow(false);
                }}
              >
                <div
                  className={
                    inWorkspaces?.some((w) => w.id === workspace.id)
                      ? styles.workspaceCard
                      : styles.availableWorkspaceCard
                  }
                >
                  <img
                    className={styles.logo}
                    src={workspace.logoUrl ? workspace.logoUrl : defaultLogo}
                    alt="default"
                  ></img>
                  <Text noMargin weight="semiBold" size="sm">
                    {workspace.title}
                  </Text>
                </div>
                <Icon
                  sx={{
                    fontSize: '0.75rem',
                    color:
                      workspace?.status === 'Active' ? '#28a745' : '#e9222c',
                  }}
                  className={styles.statusButton}
                >
                  circle
                </Icon>
              </div>
            ))}
          </div>
        </div>
        <div
          className={isAdmin ? styles.buttonContainer : styles.hide}
          onClick={() => {
            setShow(false);
            openModal({ modalName: modals.createWorkspace });
          }}
        >
          <Icon className={styles.plusIcon}>add</Icon>
          <Text
            className={styles.buttonText}
            color="accentPrimary"
            size="md"
            noMargin
            weight="bold"
            textAlign="center"
          >
            {t(keys.action.CREATE_VARIABLE, {
              variable: t(keys.common.WORKSPACE),
            })}
          </Text>
        </div>
      </div>
    </div>
  ) : null;
}
