import React from 'react';
import { Text } from '../components/typography';
import styles from './NotFound404.module.scss';
import logo from '../assets/logo_blue_black.svg';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';

export function NotFound404() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img src={logo} alt="logo" className={styles.logo} />
      <div className={styles.subContainer}>
        <Text textAlign="center" size="xl" weight="bold" color="accentPrimary">
          {t(keys.utilities.NOT_FOUND_404_TITLE)}
        </Text>
        <Text textAlign="center" noMargin size="md" weight="semiBold">
          {t(keys.utilities.NOT_FOUND_404_DESCRIPTION)}
        </Text>
        <Text textAlign="center" size="md" weight="semiBold">
          {t(keys.utilities.NOT_FOUND_404_HOME)}
        </Text>
        <img
          src="https://opas-public.s3.us-east-2.amazonaws.com/webapp-assets/robot.png"
          alt="robot"
          className={styles.robot}
        />

        <Button value={t(keys.common.HOME)} onClick={() => navigate('/')} />
      </div>
    </div>
  );
}
