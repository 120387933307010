import { useModal } from '../../../providers/ModalProvider';
import Modal from '../Modal';
import { modals } from '../../../providers/modals';
import { Text } from '../../typography';
import { Form } from 'react-bootstrap';
import { DropdownText } from '../../dropdowns/DropdownText';
import { incidentTypes } from '../../../utilities/incidents';
import UserMultiSelector from '../../UserMultiSelector';
import { useMemo } from 'react';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../../constants/strings';
import styles from './CreateIncidentModal.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import Icon from '../../Icon';
import { useIncidents } from '../../../hooks/offline-hooks/incidentHook';

export default function CreateIncidentModal() {
  const { updateModal, closeModal, modalState } = useModal();
  const onHide = () => closeModal({ modalName: modals.createIncident });
  const { workspaceId } = useWorkspace();
  const navigate = useNavigate();
  const {
    show,
    title,
    modalTitle,
    type,
    subtype,
    participants,
    observation,
    description,
  } = modalState.createIncidentModal;
  const { t } = useTranslation();
  const { addIncident } = useIncidents();
  const update = (variables) =>
    updateModal({
      modalName: modals.createIncident,
      variables,
    });

  const incident = useMemo(() => {
    return {
      title,
      type,
      subtype,
      participants: participants?.map((user) => user?.id) || null,
      status: 'INITIATED',
      workspaceId,
      lostTime: subtype === 'Lost Time',
      nearMiss: subtype === 'Near Miss',
      potentialFatality: subtype === 'Fatality',
      observationId: observation?.id ?? null,
      description,
    };
  }, [
    description,
    observation?.id,
    participants,
    subtype,
    title,
    type,
    workspaceId,
  ]);

  return (
    <Modal
      open={show || false}
      title={t(keys.incidents.REPORT_INCIDENT) || modalTitle}
      submitDisabled={!type || !subtype || !title || !description}
      onClose={onHide}
      submitText={t(keys.incidents.START_REPORT)}
      titleImage={
        <Icon style={{ fontSize: '2rem' }} color="red">
          fmd_bad
        </Icon>
      }
      onSubmit={() =>
        addIncident({
          variables: incident,
        }).then(
          ({
            data: {
              addIncident: { id },
            },
          }) => {
            navigate(getRoute(workspaceId, paths.incident, id));
          },
        )
      }
    >
      <div className={styles.field}>
        <Text noMargin weight="semiBold">
          {`${t(keys.common.TITLE)}*`}
        </Text>
        <Form.Control
          type="text"
          placeholder={t(keys.incidents.PLACEHOLDER)}
          value={title}
          onChange={(e) => update({ title: e.target.value })}
        />
      </div>
      <div className={styles.field}>
        <Text noMargin weight="semiBold">
          {`${t(keys.common.DESCRIPTION)}*`}
        </Text>
        <Form.Control
          as="textarea"
          rows={4}
          value={description}
          onChange={(e) => update({ description: e.target.value })}
        />
      </div>
      <DropdownText
        selected={type}
        highlight
        required={true}
        onChange={(newType) => update({ type: newType, subtype: null })}
        items={Object.keys(incidentTypes)}
        title={`${t(keys.incidents.TYPE)}*`}
      />
      <DropdownText
        highlight
        required={true}
        selected={subtype}
        onChange={(newSubtype) => update({ subtype: newSubtype })}
        items={incidentTypes[type]}
        title={`${t(keys.incidents.SUBTYPE)}*`}
        disabled={!type}
      />
      <div className={styles.field}>
        <Text weight="semiBold" noMargin>
          {t(keys.common.PARTICIPANTS)}
        </Text>
        <UserMultiSelector
          selected={participants}
          type="users"
          onUserAdded={(user) => {
            update({ participants: [...participants, user] });
          }}
          onUserRemoved={(user) => {
            update({
              participants: participants?.filter((u) => u.id !== user.id),
            });
          }}
        />
      </div>
      <br />
    </Modal>
  );
}
