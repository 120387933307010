import { useEffect, useState } from 'react';
import Dexie from 'dexie';
import { deepReplace } from '../../utilities/offline_hooks_setup_apollo/offlineUtils';

export const useUserIndexedDB = (dbName) => {
  const [db, setDB] = useState(null);

  useEffect(() => {
    // Initialize the IndexedDB database with Dexie
    const database = new Dexie(dbName);

    // Define the store schema
    database.version(1).stores({
      mutationQueues: '&userId',
    });

    database
      .open()
      .then((dbInstance) => {
        setDB(dbInstance);
      })
      .catch((err) => {
        console.error('Failed to open db:', err);
      });

    return () => {
      if (database.isOpen()) database.close();
    };
  }, [dbName]);

  const isMutationDBOpen = () => {
    return db !== null;
  };

  const addMutationToQueue = async (userId, mutation) => {
    if (!db) {
      throw new Error('The database is not initialized');
    }

    let queueRecord = (await getQueue(userId)) || [];

    queueRecord.push(mutation);

    await db.table('mutationQueues').put({ userId, queue: queueRecord });
  };

  const exchangeMutationQueue = async (userId, mutationQueue) => {
    if (!db) {
      throw new Error('The database is not initialized');
    }
    await db.table('mutationQueues').put({ userId, queue: mutationQueue });
  };

  const getQueue = async (userId) => {
    if (!db) throw new Error('The database is not initialized');

    let queueRecord = await db.table('mutationQueues').get(userId);
    return queueRecord ? queueRecord.queue : null;
  };

  const dequeueMutation = async (userId) => {
    if (!db) throw new Error('The database is not initialized');

    let queueRecord = await getQueue(userId);
    if (!queueRecord || queueRecord.length === 0) {
      return null;
    }

    const item = queueRecord.shift();

    await exchangeMutationQueue(userId, queueRecord);

    return item;
  };

  const updateQueueWithDeepReplace = async (userId, oldValue, newValue) => {
    if (!db) {
      throw new Error('The database is not initialized');
    }

    let queueRecord = await getQueue(userId);
    if (!queueRecord) {
      // If there is no record for that user, there's nothing to replace.
      return;
    }

    const updatedQueue = queueRecord.map((item) =>
      deepReplace(item, oldValue, newValue),
    );

    await exchangeMutationQueue(userId, updatedQueue);
  };

  const clearMutationDatabase = async (userId) => {
    await exchangeMutationQueue(userId, []);
  };

  return {
    isMutationDBOpen,
    addMutationToQueue,
    exchangeMutationQueue,
    getQueue,
    dequeueMutation,
    updateQueueWithDeepReplace,
    clearMutationDatabase,
  };
};
