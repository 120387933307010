import styles from './FlagDisabled.module.scss';
import { Text } from '../../components/typography';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';

export default function FlagDisabled() {
  const { t } = useTranslation();

  const constructionPng =
    'https://app.opasmobile.com/assets/graphics/construction.png';
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={constructionPng}
          alt="construction-graphic"
        />
      </div>
      <Text noMargin weight="semiBold">
        {t(keys.common.FLAG_DISABLED)}
      </Text>
    </div>
  );
}
