export const capatalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const isValidPassword = (password) => {
  let valid = true;
  let reason = '';

  if (password.length < 8) {
    valid = false;
    reason = 'Password must be at least 8 characters long';
  } else if (!/([a-z])$/.test(password)) {
    valid = false;
    reason = 'Password must contain at least one lowercase letter';
  } else if (!/([A-Z])$/.test(password)) {
    valid = false;
    reason = 'Password must contain at least one uppercase letter';
  }

  return { valid, reason };
};

export const truncate = (input, length) =>
  (input?.length || 0) > length ? `${input.substring(0, length)}...` : input;

export const convertToHumanReadable = (input) => {
  const rough = input.replace('_', ' ').toLowerCase();
  return rough.charAt(0).toUpperCase() + rough.slice(1);
};

export const vehicleString = (vehicle) => {
  return `${vehicle?.year || ''} ${vehicle?.make || ''} ${
    vehicle?.model ?? ''
  } ${vehicle?.unitNumber ?? ''}`;
};

export const generateTaskTargetString = (task) => {
  const { target, targetInterval, targetPeriod } = task;

  if (!target || !targetInterval || !targetPeriod) {
    return 'Target not set';
  }

  const interval = targetInterval !== 1 ? `${targetInterval} ` : '';
  const period = `${targetPeriod.toLowerCase()}${
    targetInterval > 1 ? 's' : ''
  }`;
  return `${target} every ${interval}${period}`;
};
