import React from 'react';
import DatePicker from 'react-datepicker';
import styles from './DatePickerComponent.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { customColors } from '../../utilities';

export default function DatePickerComponent({
  selected,
  onChange,
  isClearable = false,
}) {
  const { t } = useTranslation();
  const placeholder = t(keys.action.SELECT_VARIABLE, {
    variable: t(keys.common.DATE),
  });
  return (
    <div className={styles.wrapper}>
      <Icon sx={{ fontSize: '1.5rem', color: customColors.SECONDARY_LIGHT }}>
        calendar_month
      </Icon>
      <DatePicker
        className={styles.datePicker}
        selected={selected}
        onChange={(date) => onChange(date)}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        onClick={(e) => e.target.blur()}
        placeholderText={placeholder}
        isClearable={isClearable}
      />
    </div>
  );
}
