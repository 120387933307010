import React from 'react';
import { getLocalTime } from '../../utilities/time';
import { Text } from '../typography';
import styles from './ActionItemComment.module.scss';

export default function ActionItemComment({ comment }) {
  return (
    <div className={styles.container}>
      <img
        className={styles.avatar}
        src={comment.creator.avatarUrl}
        alt={`${comment.creator.firstName} ${comment.creator.lastName}s avatar`}
      />
      <div className={styles.rightContainer}>
        <div className={styles.header}>
          <Text
            size="md"
            weight="bold"
            noMargin
          >{`${comment.creator.firstName} ${comment.creator.lastName}`}</Text>
          <Text size="sm" noMargin color="secondary">
            {getLocalTime(comment.dateCreated).fromNow()}
          </Text>
        </div>
        <Text size="md" noMargin>
          {comment.text}
        </Text>
      </div>
    </div>
  );
}
