export const headerMap = {
  description: 'task_name',
  externalActivityId: 'task_code',
  plannedStartDate: 'start_date',
  plannedEndDate: 'end_date',
  status: 'status_code',
  resourceId: 'rsrc_id',
  actualHours: 'act_drtn_hr_cnt',
  actualStartDate: 'act_start_date',
  actualEndDate: 'act_end_date',
  poNumber: 'user_field_257',
  wbsCode: 'wbs_id',
  progress: 'complete_pct',
};
export const importHeaderMap = Object.fromEntries(
  Object.entries(headerMap).map(([key, value]) => [value, key]),
);

export const activityStatusOptions = {
  notStarted: 'Not Started',
  inProgress: 'In Progress',
  completed: 'Completed',
};

export const configurationStyle = {
  true: {
    title: `Ready to update your workspace's activities?`,
    text: 'Click to browse files, or drag and drop your newest p6 project export here to keep your project seamlessly synchronized with the latest data.',
    value: 'Choose File',
    icon: null,
    outlined: true,
  },
  false: {
    title: `Initialize Project Integration`,
    text: 'Drag and drop your p6 project export here or click to upload and configure your workspace with Primavera P6 activities.',
    value: 'Import XLSX',
    outlined: false,
    icon: 'file_upload',
  },
};
