import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useState, useEffect, useMemo } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import ColorPicker from '../../components/ColorPicker';
import WorkflowStageFooter from '../../components/workflows/WorkflowStageFooter';
import styles from './CreateVehicleInfo.module.scss';
import { assetFields, vehicleKeys, vehicles } from '../../utilities/vehicles';
import { DropdownText } from '../../components/dropdowns/DropdownText';
import { Text } from '../../components/typography';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import WorkflowStageHeader from '../../components/workflows/WorkflowStageHeader';

const createVehicleMutation = loader('./CreateVehicleInfo.create.graphql');

const updateVehicleMutation = loader('./CreateVehicleInfo.update.graphql');

const fetchVehicleQuery = loader('./CreateVehicleInfo.fetch.graphql');

const addAssetToWorkspaceMutation = loader(
  './CreateVehicleInfo.addToWorkspace.graphql',
);

const colors = [
  'White',
  'Gray',
  'Black',
  'Blue',
  'Purple',
  'Pink',
  'Red',
  'Orange',
  'Yellow',
  'Green',
];

export default function CreateVehicleInfo({
  onCompleted,
  currentStage,
  vehicleId,
  setVehicleId,
  onBack,
}) {
  const [typeKey, setTypeKey] = useState('');
  const { t } = useTranslation();
  const namespace = 'assets';

  const dataType = useMemo(
    () => (vehicleKeys.includes(typeKey) ? 'vehicle' : 'asset'),
    [typeKey],
  );

  const [vehicle, setVehicle] = useState({
    make: '',
    model: '',
    year: null,
    unitNumber: '',
    type: '',
    subtype: '',
    color: '',
    licensePlate: '',
    operational: true,
    isMetric: false,
    location: '',
    vehicleIdentificationNumber: '',
  });

  const { data } = useQuery(fetchVehicleQuery, {
    skip: !vehicleId,
    variables: {
      vehicleId: `${vehicleId}`,
    },
  });

  const [addVehicle] = useMutation(createVehicleMutation);

  const [updateVehicle] = useMutation(updateVehicleMutation);

  const [addAssetToWorkspace] = useMutation(addAssetToWorkspaceMutation);
  const { workspace } = useWorkspace();

  useEffect(() => {
    if (data?.vehicles?.length) {
      setVehicle({ ...data.vehicles[0] });
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <div>
        <WorkflowStageHeader
          title={t(keys.assets.P1_HEADER)}
          subtitle={t(keys.assets.P1_SUBTEXT)}
        />

        <Row className="mb-3 flex-md-row flex-column align-items-center">
          <Form.Group as={Col}>
            <div className={styles.dropdown}>
              <DropdownText
                className={styles.noMargin}
                required
                title={t(keys.common.TYPE)}
                selected={vehicle.type}
                onChange={(option) => {
                  setVehicle({ ...vehicle, type: option, subtype: '' });
                  setTypeKey(
                    Object.keys(vehicles).find(
                      (v) => vehicles[v].string === option,
                    ),
                  );
                }}
                items={Object.keys(vehicles).map((key) => vehicles[key].string)}
                namespace={namespace}
              />
            </div>
          </Form.Group>
          <Form.Group as={Col}>
            <div className={styles.dropdown}>
              <DropdownText
                className={styles.noMargin}
                required
                title={t(keys.common.SUBTYPE)}
                selected={vehicle.subtype}
                onChange={(option) =>
                  setVehicle({ ...vehicle, subtype: option })
                }
                disabled={!typeKey}
                items={
                  typeKey
                    ? Object.keys(vehicles[typeKey])
                        .map((key) => vehicles[typeKey][key].string)
                        .filter((val) => !!val)
                    : []
                }
                namespace={namespace}
              />
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-3 flex-md-row flex-column align-items-center">
          <Form.Group as={Col}>
            <Text noMargin weight="semiBold" size="md">
              {t(assetFields.make[dataType].title)}
            </Text>
            <div className={styles.spacer} />
            <Form.Control
              type="text"
              data-cy="input-make"
              minLength={1}
              placeholder={t(assetFields.make[dataType].tooltip)}
              value={vehicle?.make || undefined}
              onChange={(e) =>
                setVehicle({
                  ...vehicle,
                  make: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Text
              noMargin
              weight="semiBold"
              size="md"
              className={styles.textMobile}
            >
              {t(assetFields.model[dataType].title)}
            </Text>
            <div className={styles.spacer} />
            <Form.Control
              type="text"
              data-cy="input-model"
              minLength={1}
              placeholder={t(assetFields.model[dataType].tooltip)}
              value={vehicle?.model || undefined}
              onChange={(e) =>
                setVehicle({
                  ...vehicle,
                  model: e.target.value,
                })
              }
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 flex-md-row flex-column align-items-center">
          <Form.Group as={Col}>
            <Text noMargin weight="semiBold" size="md">
              {t(keys.assets.YEAR)}
            </Text>
            <div className={styles.spacer} />
            <Form.Control
              type="number"
              data-cy="input-year"
              min={1800}
              max={new Date().getFullYear() + 1}
              placeholder="2017, 2006, etc."
              value={vehicle?.year || undefined}
              onChange={(e) =>
                setVehicle({
                  ...vehicle,
                  year: parseInt(e.target.value, 10),
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Text
              noMargin
              weight="semiBold"
              size="md"
              className={styles.textMobile}
            >
              {t(keys.assets.UNIT_NUMBER)}
            </Text>
            <div className={styles.spacer} />
            <Form.Control
              type="text"
              data-cy="input-unit-number"
              placeholder="N2006, etc."
              value={vehicle?.unitNumber || undefined}
              onChange={(e) =>
                setVehicle({
                  ...vehicle,
                  unitNumber: e.target.value,
                })
              }
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 flex-md-row flex-column align-items-center">
          <Form.Group as={Col}>
            <Text noMargin weight="semiBold" size="md">
              {t(keys.common.LOCATION)}
            </Text>
            <div className={styles.spacer} />
            <Form.Control
              type="text"
              data-cy="input-location"
              placeholder="Ohio, Ontario, etc."
              value={vehicle?.location || undefined}
              onChange={(e) =>
                setVehicle({
                  ...vehicle,
                  location: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Text
              noMargin
              weight="semiBold"
              size="md"
              className={styles.textMobile}
            >
              {t(keys.common.COLOUR)}
            </Text>
            <div className={styles.spacer} />
            <ColorPicker
              colors={colors}
              selected={vehicle?.color || undefined}
              onChange={(color) => setVehicle({ ...vehicle, color })}
            />
          </Form.Group>
        </Row>
        {dataType === 'vehicle' && (
          <>
            <Row className="mb-3 flex-md-row flex-column align-items-center">
              <Form.Group as={Col}>
                <Text noMargin weight="semiBold" size="md">
                  {t(assetFields.vin[dataType].title)}
                </Text>
                <div className={styles.spacer} />
                <Form.Control
                  data-cy="input-vin"
                  type="text"
                  placeholder={assetFields.vin[dataType].tooltip}
                  value={vehicle?.vehicleIdentificationNumber || undefined}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      vehicleIdentificationNumber: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Text
                  noMargin
                  weight="semiBold"
                  size="md"
                  className={styles.textMobile}
                >
                  {t(keys.assets.LICENSE_PLATE)}
                </Text>
                <div className={styles.spacer} />
                <Form.Control
                  type="text"
                  data-cy="input-license-plate"
                  minLength={1}
                  placeholder="AHA-724, etc."
                  value={vehicle?.licensePlate || undefined}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      licensePlate: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Row>
          </>
        )}
        <br />
      </div>
      <div className={styles.footer}>
        <WorkflowStageFooter
          hideBackButton
          onBack={onBack}
          nextStageDisabled={!vehicle?.type || !vehicle?.subtype}
          onNext={() => {
            if (vehicleId) {
              updateVehicle({
                variables: {
                  id: vehicleId,
                  ...vehicle,
                },
              }).then(() => onCompleted(currentStage));
            } else {
              addVehicle({
                variables: { ...vehicle },
              }).then(
                ({
                  data: {
                    addVehicle: { id },
                  },
                }) => {
                  addAssetToWorkspace({
                    variables: { workspaceId: workspace.id, vehicleId: id },
                  });
                  setVehicleId(id);
                  onCompleted(currentStage);
                },
              );
            }
          }}
        />
      </div>
    </div>
  );
}
