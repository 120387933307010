import { Close } from '@mui/icons-material';
import classNames from 'classnames';
import React from 'react';
import { formatTestId } from '../utilities/cypress';
import styles from './ColorPicker.module.scss';

export default function ColorPicker({ colors, selected, onChange }) {
  return (
    <div className={styles.container}>
      {selected ? (
        <div className={styles.selectedContainer}>
          <div
            className={styles[selected.toLowerCase()]}
            onClick={() => onChange('')}
          />

          <Close className={styles.closeIcon} onClick={() => onChange('')} />
        </div>
      ) : (
        colors.map((color) => (
          <div
            data-cy={`input-color-${formatTestId(color)}`}
            key={color}
            onClick={() => onChange(color)}
            className={classNames(
              styles[color.toLowerCase()],
              selected === color ? styles.selected : null,
            )}
          />
        ))
      )}
    </div>
  );
}
