import React, { useState } from 'react';
import styles from './BoardCard.module.scss';
import { Icon } from '@mui/material';
import classNames from 'classnames';
import { Text } from '../../components/typography';
import { getLocalTime } from '../../utilities/time';
import { truncate } from '../../utilities';
import Delete from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function BoardCard({ className, board, onClick, onDelete }) {
  const { t } = useTranslation();
  const {
    title,
    thumbnail,
    dateModified,
    creator: { firstName, avatarUrl },
  } = board;

  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onDelete();
    handleClose(event);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  return (
    <div className={classNames(styles.parent, className)} onClick={onClick}>
      <div
        style={{
          backgroundImage: `url("${
            thumbnail ||
            'https://app.opasmobile.com/assets/graphics/empty_graph_thumb.png'
          }")`,
        }}
        className={styles.container}
      >
        <div className={styles.contextMenuContainer}>
          <Icon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClick(e);
            }}
            sx={{ fontSize: '1.5rem' }}
            baseClassName="material-icons-outlined"
            className={styles.moreIcon}
          >
            more_horiz
          </Icon>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleSelect}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <Text weight="semiBold" noMargin>
                {t(keys.action.DELETE)}
              </Text>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className={styles.overlay} />
      <div className={styles.textContainer}>
        <Text size="md" noMargin weight="semiBold" noSelect>
          {truncate(title, 20)}
        </Text>
        <Text size="sm" color="secondary" noSelect weight="semiBold" noMargin>
          {`${t(keys.common.EDITED)} ${getLocalTime(dateModified).fromNow()}`}
        </Text>
        <img className={styles.avatar} src={avatarUrl} alt={firstName} />
      </div>
    </div>
  );
}
