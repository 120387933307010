import React, { useMemo } from 'react';
import styles from './TabSystem.module.scss';
import classNames from 'classnames';
import { Text } from './typography';
import { searchParamKeys } from '../constants/strings';
import { useQueryParams } from '../hooks/misc';

export default function TabSystem({ options, type, parent }) {
  const { upsertParam } = useQueryParams();

  const SubPage = useMemo(() => {
    const option = options.find((o) => o.key === type);
    return option.element;
  }, [type, options]);

  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        {options.map((option) => (
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              upsertParam(searchParamKeys.tab, option.key);
            }}
            className={styles.optionContainer}
            key={option.title}
          >
            <Text
              textAlign="center"
              noMargin
              color={type === option.key ? 'primary' : 'secondary'}
            >
              {option.title}
            </Text>
            <div
              className={classNames(
                styles.selectedFooter,
                type === option.key ? styles.show : styles.hide,
              )}
            />
          </div>
        ))}
      </div>
      <div className={styles.subPage}>
        <SubPage parent={parent} />
      </div>
    </div>
  );
}
