import { Icon } from '@mui/material';
import React from 'react';
import { openImageUploadModal } from '../../graphql/cache/modal';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

import Button from '../Button';
import styles from './ImageDropzone.module.scss';

export default function ImageDropzone({
  title,
  onSubmit,
  onLoading,
  showDescription = false,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Icon sx={{ fontSize: '3rem' }} className={styles.icon}>
        photo_camera
      </Icon>
      <Button
        className={styles.button}
        variant="secondary"
        size="md"
        outlined
        value={t(keys.action.CHOOSE_IMAGE)}
        onClick={() =>
          openImageUploadModal(title, onSubmit, onLoading, showDescription)
        }
      />
    </div>
  );
}
