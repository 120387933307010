import {
  csvAdditionalDataInputTypes,
  csvRequiredColumnData,
  csvRequiredColumns,
} from '../constants/strings';

export const validateCsv = (rows) => {
  if (!rows?.length) {
    return { error: 'CSV does not contain any data' };
  }

  const headerValues = Object.keys(rows[0]).map((h) => h.trim().toLowerCase());

  const hasAllColumns = csvRequiredColumns.every((c) =>
    headerValues.includes(c),
  );

  if (!hasAllColumns) {
    return {
      error: 'One or more required columns are missing',
    };
  }

  rows.forEach((row) => {
    Object.keys(row).forEach((c) => {
      row[c.toLowerCase().trim()] = row[c];
    });
  });

  const badRows = [];

  const hasAllRequiredColumnData = rows.every((row) =>
    csvRequiredColumnData.every((col) => {
      const hasData = !!row[col]?.length;
      if (!hasData) {
        badRows.push(row);
        return false;
      }
      return true;
    }),
  );

  if (!hasAllRequiredColumnData) {
    return {
      error: `Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". Row is missing required data`,
    };
  }

  const hasCorrectRequiredValues = rows.every((row) => {
    const hasValidRequiredField =
      row.required.toLowerCase() === 'yes' ||
      row.required.toLowerCase() === 'no';
    if (!hasValidRequiredField) {
      badRows.push(row);
      return false;
    }
    return true;
  });

  if (!hasCorrectRequiredValues) {
    return {
      error: `Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(
          ', ',
        )}". Row has incorrect values for required fields IE (YES, NO)`,
    };
  }

  const hasRequiredAdditionalData = rows.every((row) => {
    const requiresAdditionalData = csvAdditionalDataInputTypes.includes(
      row['input type'],
    );
    if (requiresAdditionalData && !row.additional?.length) {
      badRows.push(row);
      return false;
    }
    return true;
  });

  if (!hasRequiredAdditionalData) {
    return {
      error: ` Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". Row missing required additional data`,
    };
  }

  return { rows };
};
