import { useEffect, useState } from 'react';
import sassVariables from '../styles/variables.scss';
import { debounce } from '../constants/graphql';
import { useSearchParams } from 'react-router-dom';
import { actionItemModalVar } from '../graphql/cache/modal';
import { searchParamKeys } from '../constants/strings';
import { mobileThreshold } from '../constants/misc';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useWorkspacePermissions } from '../providers/WorkspacePermissionsProvider';

export const useMobileListener = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const { mobileThreshold } = sassVariables;
  const [num, format] = [
    mobileThreshold.slice(0, mobileThreshold.length - 2),
    mobileThreshold.slice(mobileThreshold.length - 2),
  ];

  if (format !== 'px') {
    throw new Error(
      'useMobileListener only supports pixel values ending in px',
    );
  }

  const mobileThresholdPixels = parseInt(num, 10);
  return width <= mobileThresholdPixels;
};

export const useDebouncedRemoteState = (
  initialValue,
  mutation,
  debounceValue = debounce.lg,
) => {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    if (state === initialValue) {
      return;
    }
    const updateData = setTimeout(() => {
      mutation(state);
    }, debounceValue);

    return () => clearTimeout(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return [state, setState];
};

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = (param, defaultParam) =>
    searchParams.get(param) || defaultParam;

  const upsertParam = (param, value) => {
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set(param, value);
    setSearchParams(updatedSearchParams.toString());
  };

  const deleteParam = (param) => {
    if (searchParams.has(param)) {
      const token = searchParams.get(param);
      if (token) {
        searchParams.delete(param);
        setSearchParams(searchParams);
      }
    }
  };

  //allows for more then one param to be upserted/deleted
  const upsertParams = (params) => {
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    Object.entries(params).forEach(([param, value]) => {
      updatedSearchParams.set(param, value);
    });
    setSearchParams(updatedSearchParams.toString());
  };

  const deleteParams = (params) => {
    params?.forEach((param) => {
      if (searchParams.has(param)) {
        searchParams.delete(param);
      }
      setSearchParams(searchParams);
    });
  };

  return { getParam, upsertParam, deleteParam, upsertParams, deleteParams };
};

export const useActionItemModal = () => {
  const { upsertParam } = useQueryParams();

  const openActionItemModal = (id, type = 'VEHICLE', onComplete = () => {}) => {
    actionItemModalVar({ type, onComplete });
    upsertParam(searchParamKeys.actionItem, id);
  };

  return { openActionItemModal };
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= mobileThreshold,
  );

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setIsMobile(window.innerWidth <= mobileThreshold);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isMobile;
};

export const useChangeWorkspace = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { setChangingWorkspace } = useWorkspacePermissions();

  const changeWorkspace = (newWorkspaceId) => {
    const { pathname, search } = location;
    const pathSegments = pathname.split('/');

    if (pathSegments.length > 2 && pathSegments[2] === workspaceId) {
      setChangingWorkspace(true);
      pathSegments[2] = newWorkspaceId;
    }
    const newPathname = pathSegments.join('/');
    navigate(`${newPathname}${search}`);
    setChangingWorkspace(false);
  };

  return { changeWorkspace };
};
