import { Icon } from '@mui/material';
import React from 'react';
import { actionMap } from '../../utilities/notifications';
import styles from './NotificationCard.module.scss';
import { Text } from '../typography';
import { useNavigate } from 'react-router-dom';
import { getLocalTime } from '../../utilities/time';

export default function NotificationCard({ notifier, onHide, showFullText }) {
  const navigate = useNavigate();

  const { avatar, icon, iconColor, header, title, description, url } =
    actionMap[notifier.notification.action](notifier.notification);

  return (
    <div
      className={styles.container}
      onClick={() => {
        navigate(url);
        onHide();
      }}
    >
      <div className={styles.leftContainer}>
        <div className={styles.header}>
          <Icon
            baseClassName="material-icons-outlined"
            className={styles[iconColor]}
            sx={{ fontSize: '1rem' }}
          >
            {icon}
          </Icon>
          <Text noMargin weight="semiBold" size="sm">
            {`${header}  -  ${getLocalTime(
              notifier.notification.dateCreated,
            ).fromNow()}`}
          </Text>
        </div>
        <div>
          <Text
            noMargin
            size="sm"
            weight="semiBold"
            className={!showFullText && styles.textHide}
          >
            {title}
          </Text>
          <div>
            <Text
              noMargin
              size="sm"
              className={!showFullText && styles.textHide}
            >
              {description}
            </Text>
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <img src={avatar} className={styles.avatar} alt="avatar" />
      </div>
    </div>
  );
}
