import { keys } from '../../utilities/translator/translation_keys';

export const statusStringLookup = {
  BACKLOG: { title: keys.common.BACKLOG, variant: 'danger', color: 'red' },
  TODO: { title: keys.common.TODO, variant: 'primary', color: 'blue' },
  IN_PROGRESS: {
    title: keys.common.IN_PROGRESS,
    variant: 'warning',
    color: 'yellow',
  },
  DONE: { title: keys.common.COMPLETED, variant: 'success', color: 'green' },
  DUPLICATE: {
    title: keys.action_items.DUPLICATE,
    variant: 'secondary',
    color: 'secondary',
  },
};
export const statusStringLookupReverse = {
  Backlog: 'BACKLOG',
  'To Do': 'TODO',
  'In Progress': 'IN_PROGRESS',
  Completed: 'DONE',
  Duplicate: 'DUPLICATE',
};
export const priorityStringLookupReverse = {
  Low: 'LOW',
  Medium: 'MEDIUM',
  High: 'HIGH',
};
export const statusStringsNoTranslation = {
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete',
};
export const priorityStringLookup = {
  LOW: {
    name: keys.action_items.LOW,
    color: 'green',
  },
  MEDIUM: {
    name: keys.action_items.MEDIUM,
    color: 'yellow',
  },
  HIGH: {
    name: keys.action_items.HIGH,
    color: 'red',
  },
};
