// config/Auth0Provider.tsx
import React from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import CustomSpinner from '../components/CustomSpinner';

export default function Auth0ProviderWithHistory({ children }) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const callback = process.env.REACT_APP_AUTH0_CALLBACK;

  // Fail fast if the environment variables aren't set
  if (!domain || !clientId)
    throw new Error(
      'Please set REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID env. variables',
    );

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={callback}
      audience={audience}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <Auth0ProviderWrapper> {children}</Auth0ProviderWrapper>
    </Auth0Provider>
  );
}
function Auth0ProviderWrapper({ children }) {
  const { isLoading } = useAuth0();

  return isLoading ? <CustomSpinner text={'Authorizing...'} /> : children;
}
