import React from 'react';
import noImage from '../assets/icons/image_blank.png';

export default function VehicleImage({ vehicle, className }) {
  return (
    <img
      className={className}
      src={vehicle.imageUrl ? vehicle.imageUrl : noImage}
      alt={`${vehicle.make} ${vehicle.model}`}
    />
  );
}
