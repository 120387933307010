import React from 'react';
import styles from './CustomQuickAction.module.scss';
import { Icon } from '@mui/material';
import { Text } from '../../components/typography';
import { useModal } from '../../providers/ModalProvider';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const deleteQuickActionMutation = loader('./CustomQuickAction.delete.graphql');

export default function CustomQuickAction({ refetch, isEditing, quickAction }) {
  const [deleteQuickAction] = useMutation(deleteQuickActionMutation);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, name, color, icon, url } = quickAction;
  const { openConfirmationModal } = useModal();

  return (
    <div>
      <div
        className={classNames(styles.container, isEditing && styles.isEditing)}
        onClick={() => {
          if (!isEditing) {
            navigate(url);
          }
        }}
        style={{ backgroundColor: isEditing ? `${color}50` : color }}
      >
        {isEditing && (
          <div className={styles.edit}>
            <Icon
              className={styles.editIcon}
              baseClassName="material-icons-outlined"
              sx={{
                fontSize: '1.5rem',
                color: 'red',
                zIndex: 100,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openConfirmationModal({
                  title: t(keys.action.DELETE_VARIABLE, { variable: name }),
                  description: t(keys.action.DELETE_CONFIRMATION, {
                    variable: name,
                  }),
                  variant: 'danger',
                  buttonText: t(keys.action.DELETE),
                  onSubmit: () => {
                    deleteQuickAction({ variables: { id } }).then(() =>
                      refetch(),
                    );
                  },
                });
              }}
            >
              delete
            </Icon>
          </div>
        )}
        <Icon
          fontSize="inherit"
          baseClassName="material-icons-outlined"
          className={styles.icon}
        >
          {icon}
        </Icon>
        <Text
          size="sm"
          color="white"
          noMargin
          weight="semiBold"
          textAlign="center"
          className={styles.text}
        >
          {name}
        </Text>
      </div>
    </div>
  );
}
