import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Text } from '../typography';
import { useTranslation } from 'react-i18next';
import {
  getEnglishKey,
  keys,
} from '../../utilities/translator/translation_keys';

export default function MultiSelect({
  title = '',
  options = [],
  values = [],
  onChange = async () => {},
  readOnly = false,
}) {
  const { i18n, t } = useTranslation();
  const language = i18n.resolvedLanguage;

  const weatherMap = i18n.getResourceBundle(
    language,
    'incidents',
  ).dropdownOptions;

  const handleChange = (e) => {
    e.persist();
    const selected = e.target.id;
    let newValues;

    if (language !== 'en') {
      const englishKey = getEnglishKey(selected, weatherMap);
      newValues = values.includes(englishKey)
        ? values.filter((v) => v !== englishKey)
        : [...values, englishKey];
    } else {
      newValues = values.includes(selected)
        ? values.filter((v) => v !== selected)
        : [...values, selected];
    }
    onChange(newValues);
  };

  const translate = (values) => {
    let valuesToDisplay = [];
    if (language !== 'en') {
      values.forEach((v) => valuesToDisplay.push(weatherMap[v]));
      return valuesToDisplay.join(', ');
    }
    return values.join(', ');
  };

  return readOnly ? (
    <Text noMargin>
      {values?.length ? translate(values) : t(keys.incidents.NOT_SPECIFIED)}
    </Text>
  ) : (
    <Form.Group style={{ display: 'flex', flexWrap: 'wrap' }} name={title}>
      {options.map((option) => {
        const englishKey = getEnglishKey(option, weatherMap);
        const isChecked = values.includes(englishKey);
        return (
          <Form.Check
            inline
            checked={isChecked}
            onChange={handleChange}
            label={option}
            type={'checkbox'}
            name={title}
            id={option}
            value={option}
            key={option}
          />
        );
      })}
    </Form.Group>
  );
}

MultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
};
