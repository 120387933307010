import React, { useState, useEffect, useMemo } from 'react';
import blueLogo from '../../assets/logo_blue_black.svg';
import styles from './CompanyRegistration.module.scss';
import { Form } from 'react-bootstrap';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useQueryParams } from '../../hooks/misc';
import { emailRegex } from '../../utilities/validation';
import { publicSite } from '../../constants/endpoints';
import { Spinner } from 'react-bootstrap';
import { useFlags } from 'launchdarkly-react-client-sdk';

const regex = new RegExp(emailRegex);

const createCompanyMutation = loader('./CompanyRegistration.create.graphql');
const createSessionMutation = loader('./CompanyRegistration.session.graphql');
const registerCompanyMutation = loader(
  './CompanyRegistration.register.graphql',
);

const logos = [
  'evergaurd',
  'nsa',
  'dcc',
  'fathom',
  'shield',
  'oceanair',
  'spindrift',
  'kdp',
];

const Logo = ({ name }) => (
  <img
    alt={`${name} logo`}
    src={`https://app.opasmobile.com/assets/graphics/logos/${name}_logo_white.png`}
    className={styles.footerLogo}
  />
);

export default function CompanyRegistration() {
  const { getParam } = useQueryParams();
  const payment = getParam('payment');
  const companyId = getParam('companyId');
  const [loading, setLoading] = useState(false);
  const token = getParam('token');
  const { enableCompanySignUpCaptcha } = useFlags();

  const [company, setCompany] = useState({
    name: '',
    contactEmail: '',
  });
  const [page, setPage] = useState();

  const [createCompany] = useMutation(createCompanyMutation);
  const [createSession] = useMutation(createSessionMutation);
  const [registerCompany] = useMutation(registerCompanyMutation);

  useEffect(() => {
    if (
      payment === 'success' &&
      enableCompanySignUpCaptcha !== undefined &&
      !enableCompanySignUpCaptcha
    ) {
      setPage('landing');
      registerCompany({ variables: { id: parseInt(companyId), token } }).then(
        () => {
          setTimeout(() => {
            window.location = 'https://opasmobile.com/thank-you-for-signing-up';
          }, 2_000);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment, enableCompanySignUpCaptcha]);

  const cancelUrl = `${window.location.origin}${window.location.pathname}`;
  const successUrl = `${window.location.origin}${window.location.pathname}?payment=success`;

  const disabled = useMemo(
    () =>
      page !== 'landing'
        ? !regex.test(company.contactEmail) || !company.name
        : false,
    [company, page],
  );

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.section}>
          <img className={styles.logo} src={blueLogo} alt="OPAS Logo" />
          <p className={styles.title}>
            {page !== 'landing'
              ? 'Try OPAS Mobile Free!'
              : "We're thrilled to have you onboard!"}
          </p>
          {page !== 'landing' && (
            <>
              <br />
              <span className={styles.content}>
                {
                  "Great job! You're just 60 seconds away from a safer, more efficient workplace—no credit card needed."
                }
              </span>
            </>
          )}
        </div>
        {page !== 'landing' ? (
          <div className={styles.section}>
            <div className={styles.nameBox}>
              <div>
                <p className={styles.label}>First name</p>
                <Form.Control
                  className={styles.formInput}
                  autoFocus={false}
                  type="text"
                  placeholder="John"
                />
              </div>
              <div>
                <p className={styles.label}>Last name</p>
                <Form.Control
                  className={styles.formInput}
                  autoFocus={false}
                  type="text"
                  placeholder="Doe"
                />
              </div>
            </div>
            <p className={styles.label}>Company</p>
            <Form.Control
              className={styles.formInput}
              autoFocus={false}
              type="text"
              placeholder="ACME Inc."
              value={company.name}
              onChange={(e) => setCompany({ ...company, name: e.target.value })}
            />
            <p className={styles.label}>Email</p>
            <Form.Control
              className={styles.formInput}
              autoFocus={false}
              type="email"
              placeholder="jdoe@acme.com"
              value={company.contactEmail}
              onChange={(e) =>
                setCompany({ ...company, contactEmail: e.target.value })
              }
            />
          </div>
        ) : (
          <div className={styles.section}>
            <img
              className={styles.rocketImage}
              src="https://app.opasmobile.com/assets/graphics/rocket_reg.png"
              alt="rocket_img"
            />
            <span className={styles.message}>
              Your account is being set up, you'll receive setup instructions by
              email shortly.
            </span>
          </div>
        )}

        <div className={styles.section}>
          <div
            className={disabled ? styles.buttonDisabled : styles.button}
            onClick={() => {
              if (disabled || loading) {
                return;
              }
              setLoading(true);
              page !== 'landing'
                ? createCompany({ variables: { ...company } }).then(
                    ({
                      data: {
                        createCompany: { id },
                      },
                    }) =>
                      createSession({
                        variables: { companyId: id, successUrl, cancelUrl },
                      }).then(
                        ({ data: { createSignupSession: sessionUrl } }) =>
                          (window.location = sessionUrl),
                      ),
                  )
                : (window.location = publicSite);
            }}
          >
            <p className={styles.buttonText}>
              {loading ? (
                <Spinner
                  size="inherit"
                  animation="border"
                  className={styles.spinner}
                />
              ) : page !== 'landing' ? (
                'Register'
              ) : (
                'Return to Website'
              )}
            </p>
          </div>

          {page !== 'landing' ? (
            <>
              <p style={{ color: '#000' }} className={styles.finePrint}>
                {
                  'We’ll do all the heavy lifting. You’ll never go back to paper again.  🎉'
                }
              </p>
              <span className={styles.finePrint}>
                By clicking "Register" you agree to the{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://opasmobile.com/privacy-policy"
                  className={styles.privacyLink}
                >
                  OPAS Mobile TOS
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://opasmobile.com/privacy-policy"
                  className={styles.privacyLink}
                >
                  Privacy Policy.
                </a>
              </span>

              <br />
              <span className={styles.loginText}>
                Already have an account?{' '}
                <a style={{ marginLeft: '0.25rem' }} href="/login">
                  Log in
                </a>
              </span>
            </>
          ) : (
            <>
              <span className={styles.finePrint}>
                Need help? Contact us anytime at{' '}
                <a href="mailto:support@opasmobile.com">
                  support@opasmobile.com
                </a>
              </span>
            </>
          )}

          <br />
          <p style={{ margin: 0 }} className={styles.finePrint}>
            © OPAS Mobile
          </p>
        </div>
      </div>
      <div className={styles.logos}>
        <p className={styles.trustedText}>Trusted by Industry Leaders</p>
        <div className={styles.logoContainer}>
          {logos.map((name, index) => (
            <Logo name={name} key={`${name}-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
}
