import classNames from 'classnames';
import { Icon } from '@mui/material';
import { Text } from '../typography';
import Button from '../Button';
import styles from './NetworkModal.module.scss';

function NetworkModal({ isOnline, show, onSubmit }) {
  return (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {isOnline ? (
            <div className={styles.onlineContainer}>
              <div className={styles.topContainer}>
                <Icon
                  baseClassName="material-icons-outlined"
                  sx={{ fontSize: '3rem' }}
                  className={styles.onlineIcon}
                >
                  wifi
                </Icon>
                <Text
                  textAlign="center"
                  noMargin
                  size="lg"
                  color="accentPrimary"
                  weight="bold"
                >
                  Welcome Back Online!
                </Text>
                <Text textAlign="center" size="sm" weight="semiBold">
                  Great news! You’re reconnected to the internet, and we’re
                  syncing your unsaved work.
                </Text>
              </div>
              <Text size="sm" weight="semiBold" color="secondary">
                To ensure all your data is securely uploaded, please stay online
                while we wrap this up. It won’t take long!
              </Text>
              <Text size="sm" weight="semiBold" color="secondary">
                Once completed, you’ll be all set to continue your work with
                everything up-to-date.
              </Text>
              <Button
                onClick={onSubmit}
                className={styles.continueButton}
                value="Continue"
              />
            </div>
          ) : (
            <>
              <div className={styles.topContainer}>
                <Icon
                  baseClassName="material-icons-outlined"
                  sx={{ fontSize: '3rem' }}
                  className={styles.offlineIcon}
                >
                  wifi_off
                </Icon>
                <Text noMargin size="lg" color="accentPrimary" weight="bold">
                  Offline Mode
                </Text>
                <Text textAlign="center" size="sm" weight="semiBold">
                  While you're offline, OPAS Mobile has got your back but there
                  are a few things you should know to ensure a smooth
                  experience.
                </Text>
              </div>
              <div className={styles.subHeader}>
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles.subIcon}
                >
                  sync
                </Icon>
                <Text
                  noMargin
                  size="md"
                  color="accentPrimary"
                  weight="semiBold"
                >
                  Syncing and Limitations
                </Text>
              </div>
              <Text size="sm" weight="semiBold" color="secondary">
                Please be aware that while OPAS Mobile will attempt to sync your
                data, complete data integrity without a connection cannot be
                guaranteed.
              </Text>
              <Text size="sm" weight="semiBold" color="secondary">
                Sync regularly by reconnecting to ensure your data is up to
                date.
              </Text>
              <div className={styles.subHeader}>
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles.subIcon}
                >
                  checklist
                </Icon>
                <Text
                  noMargin
                  size="md"
                  color="accentPrimary"
                  weight="semiBold"
                >
                  Available Features
                </Text>
              </div>
              <Text size="sm" weight="semiBold" color="secondary">
                Assessment completion is available offline. For seamless use,
                complete assessments online first to cache the necessary data.
              </Text>
              <div className={styles.subHeader}>
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles.subIcon}
                >
                  admin_panel_settings
                </Icon>
                <Text
                  noMargin
                  size="md"
                  color="accentPrimary"
                  weight="semiBold"
                >
                  Responsibility
                </Text>
              </div>
              <Text size="sm" weight="semiBold" color="secondary">
                OPAS Mobile is not liable for data loss in offline mode. By
                syncing often, you can help secure your work.
              </Text>
              <br />
              <div className={styles.bottomContainer}>
                <Text size="sm" weight="semiBold" noMargin>
                  Tap 'Acknowledge' to continue with an understanding of offline
                  mode.
                </Text>
                <Button
                  onClick={onSubmit}
                  className={styles.button}
                  value="Acknowledge"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default NetworkModal;
