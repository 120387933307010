import { alertVar, defaultAlertState } from '../graphql/cache/modal';

export const openAlertModal = ({
  title,
  description,
  onSubmit,
  buttonText = 'Submit',
  icon = 'warning',
  variant,
}) => {
  alertVar({
    ...alertVar(),
    title,
    description,
    onSubmit,
    icon,
    variant,
    buttonText,
    show: true,
  });
};

export const closeAlertModal = () => alertVar(defaultAlertState);

export const closeModalComponents = (ref, onClose) => {
  function handleClickOutside(event) {
    event.stopPropagation();
    if (ref.current && !ref.current.contains(event.target)) {
      onClose(false);
    }
  }
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
};
