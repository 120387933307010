import { useReactiveVar } from '@apollo/client';
import React from 'react';
import ImageCropModal from '../image_uploads/ImageCropModal';
import { imageUploadModalVar } from '../../graphql/cache/modal';
import { useInspection } from '../../hooks/offline-hooks/createInspectionhook';

export default function ImageUploadModal() {
  const {
    title,
    onSubmit,
    show,
    showDescription,
    onLoading = () => {},
  } = useReactiveVar(imageUploadModalVar);

  const { addImageResults } = useInspection();

  const setImageModalOpen = (val) =>
    imageUploadModalVar({ ...imageUploadModalVar(), show: val });

  return show ? (
    <ImageCropModal
      title={title}
      showDescription={showDescription}
      onClose={() => setImageModalOpen(false)}
      onSave={(file, description) => {
        onLoading();
        setImageModalOpen(false);
        addImageResults(description, onSubmit, file);
      }}
    />
  ) : null;
}
