import moment from 'moment';
import { userRoles } from '../constants/strings';
import { keys } from './translator/translation_keys';
import { pdfjs } from 'react-pdf';

export const isProd = !(
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
);

// WARNING:  This is order dependant on keys
// Ex. {a:1,b:2} != {b:2,a:1}
export const deepCompareObjects = (a, b) =>
  JSON.stringify(a) === JSON.stringify(b);

export const isLoggedIn = () => !!localStorage.getItem('token');

export const inputTypeToIconMap = {
  DISTANCE: 'straighten',
  IMAGE: 'photo_camera',
  NUMBER: '123',
  TEST: 'check_circle',
  TEXT: 'text_fields',
  VOLUME: 'oil_barrel',
  PERCENT: 'percent',
  PRESSURE: 'compress',
  SELECT_SINGLE: 'check_box',
  SELECT_MULTI: 'library_add_check',
  USER: 'account_box',
  TABLE: 'table_view',
  SIGNATURE_SINGLE: 'border_color',
  SIGNATURE_MULTI: 'rate_review',
  DATE: 'calendar_month',
  MATRIX: 'apps',
  TEXT_MULTI: 'rtt',
  FILE_SINGLE: 'upload_file',
  FILE_MULTI: 'drive_folder_upload',
  IMAGE_MULTI: 'add_a_photo',
  USER_MULTI: 'group',
  TIME: 'access_time',
  DATE_TIME: 'event',
  AUDIO: 'mic',
  ASSET: 'local_shipping',
};

export const observationSupportedTypes = [
  'DATE',
  'DATE_TIME',
  'TIME',
  'SELECT_SINGLE',
  'SELECT_MULTI',
  'TEXT',
  'TEXT_MULTI',
];
export function getObservationIcon(type) {
  return inputTypeToIconMap[
    observationSupportedTypes.includes(type) ? type : 'TEXT'
  ];
}

export const percentageToColor = (percentage) => {
  if (percentage >= 80) {
    return 'green';
  } else if (percentage >= 50) {
    return 'yellow';
  }
  return 'red';
};

export const hasWebpSupport = () => {
  return false;
  // var elem = document.createElement('canvas');

  // if (!!(elem.getContext && elem.getContext('2d'))) {
  //   // was able or not to get WebP representation
  //   return (
  //     elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
  //   );
  // } else {
  //   // very old browser like IE 8, canvas not supported
  //   return false;
  // }
};

export const cleanIndexedDB = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const isAdmin = (user) => user?.role === userRoles.admin;

export const getFileSizeString = (size) => {
  if (size < 1024) {
    return `${size} B`;
  } else if (size < 1024 * 1024) {
    return `${Math.round(size / 1024)} KB`;
  } else if (size < 1024 * 1024 * 1024) {
    return `${Math.round(size / 1024 / 1024)} MB`;
  }
};

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const backgroundScrollLock = (boolean) => {
  if (boolean) {
    document.body.style.position = 'fixed';
  } else {
    document.body.style.position = 'unset';
  }
};

export const workspacePhaseOptions = [
  'Lead',
  'Bid',
  'Scheduled',
  'In Progress',
  'Completed',
  'Cancelled',
];

export const workspaceStatusOptions = ['ACTIVE', 'INACTIVE'];

export const workspaceInputOptions = [
  { title: keys.workspace.JOB_ID, query: 'jobId' },
  { title: keys.workspace.INTERNAL_JOB_ID, query: 'internalJobId' },
  { title: keys.workspace.JOB_CODE, query: 'jobCode' },
  { title: keys.workspace.PROJECT_VALUE, query: 'value' },
];

export const workspaceDateSelectors = [
  { title: keys.workspace.START_DATE, query: 'scheduledStartDate' },
  { title: keys.workspace.END_DATE, query: 'scheduledEndDate' },
];

export const API_DELAY = 750;

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const setTimeStamp = () => {
  localStorage.setItem('modalTimestamp', Date.now().toString());
  return true;
};

export const networkModalLastOpened = () => {
  return localStorage.getItem('modalTimestamp');
};

export const timeStampDiff = (timeStamp) => {
  return moment().diff(moment(parseInt(timeStamp, 10)), 'hours');
};

export const isNetworkModalStale = () => {
  const timeStamp = networkModalLastOpened();
  if (timeStamp) {
    if (timeStampDiff(timeStamp) > 24) {
      setTimeStamp();
      return true;
    } else if (timeStampDiff(timeStamp) <= 24) {
      return false;
    }
  } else if (!timeStamp) {
    return setTimeStamp();
  }
};

export const convertPDFToImages = async (pdfUrl) => {
  try {
    const loadingTask = pdfjs.getDocument({ url: pdfUrl });
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 }); // Adjust scale as needed
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = {
      canvasContext,
      viewport,
    };
    await page.render(renderContext).promise;
    const imageUrl = canvas.toDataURL('image/png', 0.5); // Adjust quality as needed
    return imageUrl;
  } catch (error) {
    console.error('Error converting PDF to image:', error);
    return null;
  }
};

export const customColors = {
  BLUE: '#0d6efd',
  SECONDARY: '#6e6e73',
  SECONDARY_LIGHT: '#ced4da',
  YELLOW: '#ffc107',
  GREEN: '#28a745',
  RED: '#e9222c',
  RED_LIGHT: '#e35d6a',
  GREEN_LIGHT: '#479f76',
  PINK: '#d63384',
  PURPLE_LIGHT: '#8c68cd',
  ORANGE: '#fd7e14',
};

export const iconNames = [
  'directions_car',
  'local_shipping',
  'home',
  'construction',
  'plumbing',
  'two_wheeler',
  'home_repair',
  'favorite',
  'person',
  'check',
  'close',
  'hardware',
  'carpenter',
  'recommend',
  'star',
  'local_gas_station',
  'alarm',
  'account_circle',
  'add',
  'apps',
  'build',
  'call',
  'camera',
  'chat',
  'cloud',
  'code',
  'comment',
  'delete',
  'edit',
  'email',
  'face',
  'file_copy',
  'filter_list',
  'fitness_center',
  'folder',
  'help',
  'info',
  'language',
  'map',
  'notifications',
  'palette',
  'print',
  'save',
  'security',
  'shopping_cart',
  'thumb_up',
  'visibility',
  'work',
];

export function multiLineTruncate(text, maxLength) {
  if (text?.length <= maxLength) {
    return text;
  }
  return text?.slice(0, maxLength) + '...';
}

export function sanitizeArray(array) {
  if (!Array.isArray) {
    return array;
  }
  const sanitize = array.filter((v) => v.trim() !== '');
  return sanitize.join('|');
}

export function sortItemsByDateModified(array) {
  return array.sort((a, b) => {
    const dateA = moment(a.dateModified);
    const dateB = moment(b.dateModified);

    if (dateA.isBefore(dateB)) {
      return -1;
    }
    if (dateA.isAfter(dateB)) {
      return 1;
    }
    return 0;
  });
}
