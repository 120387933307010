import { makeVar } from '@apollo/client';

export const defaultNavbarState = {
  showMenu: false,
  title: '',
  onBack: () => {},
};

export const navigationExpandedVar = makeVar(false);

export const navbarVar = makeVar(defaultNavbarState);
