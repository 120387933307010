import moment from 'moment';
import i18n from '../i18n';

const images = [
  'https://app.opasmobile.com/assets/graphics/safety_share1.jpeg',
  'https://app.opasmobile.com/assets/graphics/safety_share2.jpeg',
  'https://app.opasmobile.com/assets/graphics/safety_share4.jpeg',
  'https://app.opasmobile.com/assets/graphics/safety_share5.jpeg',
  'https://app.opasmobile.com/assets/graphics/safety_share6.jpeg',
  'https://app.opasmobile.com/assets/graphics/safety_share8.jpeg',
  'https://app.opasmobile.com/assets/graphics/safety_share9.jpeg',
  'https://app.opasmobile.com/assets/graphics/safety_share10.jpeg',
];

export const dashboardImages = {
  firstAidKit:
    'https://app.opasmobile.com/assets/graphics/incident_stat_background_3.jpg',
  clipboard:
    'https://app.opasmobile.com/assets/graphics/action_item_stat_background_1.jpg',
  writeReport:
    'https://app.opasmobile.com/assets/graphics/near_miss_stat_background.jpg',
};

export const getImage = () => {
  return images[new moment().dayOfYear() % images.length];
};

export const toolboxTalkContentTypes = {
  AI: 'AI_GENERATED',
  FILE: 'FILE',
  RICH_TEXT: 'RICH_TEXT',
};

export const strings = {
  risks: 'Risks',
  mitigation: 'Mitigation',
  conclusion: 'Conclusion',
};

export const getRandomTopic = () => {
  const lang = i18n.resolvedLanguage;
  const topics = i18n.getResourceBundle(lang, 'toolbox').topics;
  const keys = Object.keys(topics);
  const randomIndex = Math.floor(Math.random() * keys.length);
  const randomKey = keys[randomIndex];
  return topics[randomKey];
};

export const toolboxTalkStatus = {
  PUBLISHED: { label: 'Published', value: 'FALSE' },
  DRAFT: { label: 'Draft', value: 'TRUE' },
};
