import styles from './ToolboxTalk.module.scss';
import { Text } from '../../components/typography';
import {
  getImage,
  toolboxTalkContentTypes,
} from '../../utilities/toolboxtalks';
import PdfViewer from '../../components/modals/file_viewer/PdfViewer';
import RichTextEditor from '../../components/rich_text/RichTextEditor';
import { Button } from '../../components';
import { paths } from '../../constants/strings';
import { useNavigate } from 'react-router-dom';
import SimpleUserCard from '../../components/SimpleUserCard';
import { getLocalTime } from '../../utilities/time';
import { useIsMobile } from '../../hooks/misc';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useModal } from '../../providers/ModalProvider';

export default function ToolboxTalk({ toolboxTalk, deleteToolboxTalk }) {
  const {
    id,
    title,
    description,
    content,
    contentType,
    mitigation,
    risks,
    fileUrl,
    conclusion,
    dateCreated,
    creator,
    imageUrl,
  } = toolboxTalk || {};
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { openConfirmationModal } = useModal();

  const display = () => {
    switch (contentType) {
      case toolboxTalkContentTypes.AI:
        return (
          <div>
            <Text weight="semiBold">{t(keys.dashboard.RISKS)}</Text>
            <Text>{risks}</Text>
            <Text weight="semiBold">{t(keys.dashboard.MITIGATION)}</Text>
            <Text>{mitigation}</Text>
            <Text weight="semiBold">{t(keys.dashboard.CONCLUSION)}</Text>
            <Text>{conclusion}</Text>
          </div>
        );
      case toolboxTalkContentTypes.FILE:
        return fileUrl.split('.').pop() === 'pdf' ? (
          <PdfViewer file={{ url: fileUrl }} width={1000} />
        ) : (
          <img src={fileUrl} alt="TBT" />
        );
      case toolboxTalkContentTypes.RICH_TEXT:
        return <RichTextEditor key={id} id={id} value={content} readOnly />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.page}>
      <div className={!isMobile ? styles.header : undefined}>
        <Text size="lg" weight="bold" color="accentPrimary">
          {t(keys.toolbox.TOOLBOX_TALK)}
        </Text>
        <div>
          <SimpleUserCard user={creator} />
          <Text size="sm">
            {getLocalTime(dateCreated).format('dddd, MMMM DD YYYY')}
          </Text>
        </div>
      </div>

      <div className={styles.content}>
        <Text weight="semiBold" size="lg" textAlign="center">
          {title}
        </Text>
        <div className={styles.top}>
          <img
            src={imageUrl || getImage()}
            alt="tbt"
            className={styles.image}
          />
          <Text weight="semiBold" color="secondary" textAlign="center">
            {description}
          </Text>
        </div>
        {display()}
      </div>
      <Button
        value={t(keys.action.DELETE)}
        variant="danger"
        align="right"
        onClick={() => {
          openConfirmationModal({
            title: t(keys.action.DELETE_VARIABLE, {
              variable: t(keys.toolbox.TOOLBOX_TALK),
            }),
            description: t(keys.action.DELETE_CONFIRMATION, {
              variable: t(keys.toolbox.TOOLBOX_TALK),
            }),
            variant: 'danger',
            onSubmit: () =>
              deleteToolboxTalk({ variables: { id } }).then(() =>
                navigate(`/${paths.toolbox}`),
              ),
          });
        }}
      />
    </div>
  );
}
