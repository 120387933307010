import React, { useState, useEffect, useRef } from 'react';
import { Text } from '../typography';
import styles from './Navbar.module.scss';
import pjson from '../../../package.json';
import logo from '../../assets/logo_grad.svg';
import { Icon } from '@mui/material';
import {
  useMutation,
  useQuery,
  useReactiveVar,
  useSubscription,
} from '@apollo/client';
import { navigationExpandedVar } from '../../graphql/cache/navbar';
import { animated } from 'react-spring';
import NotificationList from '../notifications/NotificationList';
import NotificationIcon from '../notifications/NotificationIcon';
import { loader } from 'graphql.macro';
import { pollInterval } from '../../constants/misc';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import WorkspaceSelector from '../WorkspaceSelector';
import workspaceImage from '../../assets/workspace_image.png';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { truncate } from '../../utilities/strings';
import { useOnlineStatus } from '../../hooks/offline-hooks/offline-misc';
import { hideToast, showToast, toastVar } from '../../graphql/cache/modal';
import Toast from '../modals/Toast';
import moment from 'moment';
import Modal, { MODAL_CLOSE_EVENT } from '../modals/Modal';
import RichTextEditor from '../rich_text/RichTextEditor';
import { useCurrentUser } from '../../providers/UserProvider';
import { closeModalComponents } from '../../utilities';
import { useIsMobile } from '../../hooks/misc';
import { getRoute, paths } from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import InfoMenu from './InfoMenu';

const notifierQuery = loader('./Navbar.notifierCount.graphql');
const announcementQuery = loader('./Navbar.announcements.graphql');
const snoozeAnnouncementMutation = loader(
  './Navbar.announcements.snooze.graphql',
);
const acceptAnnouncementMutation = loader(
  './Navbar.announcements.accept.graphql',
);
const notifierSubscription = loader('./Navbar.notifierSubscription.graphql');

export default function Navbar({ className, left }) {
  const { t } = useTranslation();
  const [showNotifications, setShowNotifications] = useState(false);
  const navigationExpanded = useReactiveVar(navigationExpandedVar);
  const toastProps = useReactiveVar(toastVar);
  const { user } = useCurrentUser();
  const today = new moment().format('yyyy-MM-DD');
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [infoMenuOpen, setInfoMenuOpen] = useState(false);

  const [notifierCount, setNotifierCount] = useState(0);

  const { refetch: refetchNotifierCount, data: { notifiersCount = 0 } = {} } =
    useQuery(notifierQuery);

  useEffect(() => {
    setNotifierCount(notifiersCount);
  }, [notifiersCount]);

  useSubscription(notifierSubscription, {
    onSubscriptionData: () => {
      setNotifierCount((prevCount) => prevCount + 1);
    },
  });

  const { data: { announcementInteractions = [] } = {} } = useQuery(
    announcementQuery,
    {
      variables: {
        userId: `${user.id}`,
        today,
      },
      skip: !user?.id,
      pollInterval: pollInterval.xxl,
    },
  );

  const [snoozeAnnouncement] = useMutation(snoozeAnnouncementMutation, {
    refetchQueries: ['GetAnnouncementsForUser'],
  });

  const [acceptAnnouncement] = useMutation(acceptAnnouncementMutation, {
    refetchQueries: ['GetAnnouncementsForUser'],
  });

  const { workspace } = useWorkspace();
  const [workspaceOpen, setWorkspaceOpen] = useState(false);
  const online = useOnlineStatus();

  const WorkspaceDropdown = () => (
    <div className={styles.workspaceDropdown} ref={workspaceRef}>
      <div
        className={styles.selector}
        onClick={() => setWorkspaceOpen(!workspaceOpen)}
      >
        <img
          src={workspace?.logoUrl ? workspace.logoUrl : workspaceImage}
          className={styles.avatar}
          alt={workspace?.title}
        />
        <Text noSelect size="sm" weight="bold" noMargin>
          {truncate(workspace?.title, 30)}
        </Text>
        <Icon>{workspaceOpen ? 'expand_less' : 'expand_more'}</Icon>
      </div>
      <div className={styles.workspaceSelector}>
        <WorkspaceSelector show={workspaceOpen} setShow={setWorkspaceOpen} />
      </div>
    </div>
  );

  const workspaceRef = useRef(null);
  const notificationRef = useRef(null);

  useEffect(
    () => closeModalComponents(workspaceRef, setWorkspaceOpen),
    [workspaceRef, setWorkspaceOpen],
  );
  useEffect(
    () => closeModalComponents(notificationRef, setShowNotifications),
    [notificationRef, setShowNotifications],
  );

  return (
    <div>
      <Modal
        cancelText={t(keys.announcements.SNOOZE)}
        submitText={t(keys.announcements.ACCEPT)}
        variant="success"
        title={
          announcementInteractions?.length
            ? announcementInteractions[0].announcement.title
            : ''
        }
        allowClose={false}
        onSubmit={() => {
          acceptAnnouncement({
            variables: {
              id: announcementInteractions[0].id,
            },
          });
        }}
        onClose={(_, event) => {
          if (event === MODAL_CLOSE_EVENT.submitted) {
            return;
          }
          snoozeAnnouncement({
            variables: {
              id: announcementInteractions[0].id,
              dateReleased: moment().add(1, 'days').format('yyyy-MM-DD'),
            },
          }).then(() => {
            showToast({
              title: t(keys.announcements.ANNOUNCEMENT_SNOOZED),
              message: t(keys.announcements.SNOOZED_MESSAGE),
            });
          });
        }}
        open={!!announcementInteractions?.length}
      >
        {!!announcementInteractions?.length && (
          <RichTextEditor
            readOnly
            onChange={() => {}}
            id={announcementInteractions[0].announcement.id}
            value={announcementInteractions[0].announcement.content}
          />
        )}
      </Modal>
      <animated.div
        style={{
          paddingLeft: isMobile ? null : left,
          width: '100%',
        }}
        className={classNames(styles.container, className)}
      >
        <div className={styles.leftContainer}>
          <img
            alt="Opas Mobile"
            src={logo}
            className={styles.mobileLogo}
            onClick={() => navigate(getRoute(workspace?.id, paths.dashboard))}
          />
          <WorkspaceDropdown />
        </div>
        <div className={styles.rightContainer}>
          {online ? null : (
            <Icon
              className={styles.offlineIcon}
              baseClassName="material-icons-outlined"
            >
              wifi_off
            </Icon>
          )}
          {process.env.REACT_APP_RELEASE_STAGE !== 'production' && (
            <Text
              className={styles.ghost}
              size="sm"
              weight="semiBold"
              color="secondary"
              noMargin
            >
              {`OPAS Mobile Beta v${pjson.version} (${process.env.REACT_APP_RELEASE_STAGE})`}
            </Text>
          )}
          <NotificationList
            containerRef={notificationRef}
            show={showNotifications}
            refetchCount={refetchNotifierCount}
            onHide={() => setShowNotifications(false)}
          />
          <NotificationIcon
            count={notifierCount}
            onClick={() => setShowNotifications(!showNotifications)}
          />

          <Icon
            className={styles.helpButton}
            baseClassName="material-icons-outlined"
            onClick={() => setInfoMenuOpen(!infoMenuOpen)}
          >
            info
          </Icon>
          <InfoMenu
            show={infoMenuOpen}
            className={styles.infoMenu}
            setShow={setInfoMenuOpen}
          />

          <Toast {...toastProps} onHide={() => hideToast()} />
          <div className={styles.menuButton}>
            <Icon onClick={() => navigationExpandedVar(!navigationExpanded)}>
              {navigationExpanded ? 'expand_less' : 'menu'}
            </Icon>
          </div>
        </div>
      </animated.div>
    </div>
  );
}
