import { StyleSheet } from '@react-pdf/renderer';

const fontSize = 8;
const red = '#e9222c';
const textPrimary = '#1d1d1f';
const blue = '#0d6efd';

export const commentStyles = StyleSheet.create({
  comment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: 250,
    flexWrap: 'wrap',
  },
  commentContainer: { marginTop: 3, marginBottom: 3, marginLeft: 'auto' },
  semiBold: {
    flex: 1,
    fontSize,
    minWidth: 75,
    maxWdith: 75,
    fontFamily: 'eina03semibold',
  },
  text: {
    flex: 5,
    fontSize,
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    color: textPrimary,
    fontFamily: 'eina03regular',
    marginLeft: 'auto',
    minWidth: 175,
    textAlign: 'right',
  },
  textRight: {
    fontSize,
    marginLeft: 'auto',
  },
  red: {
    fontSize: fontSize + 1,
    color: red,
    marginLeft: 'auto',
    marginBottom: 2,
  },
  commentLink: {
    color: blue,
    fontFamily: 'eina03semibold',
    fontSize,
    wordBreak: 'break-all',
    flexWrap: 'wrap',
    marginLeft: 'auto',
  },
});
