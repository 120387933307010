import { Icon } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { Text } from '../typography';
import styles from './TableSorting.module.scss';
import { closeModalComponents } from '../../utilities';
import { useTranslation } from 'react-i18next';

export default function TableSorting({
  setSortBy,
  setIsAscending,
  refetch,
  isAscending,
  sortingOptions,
  sortBy,
  sortStrings,
}) {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    closeModalComponents(ref, setOptionsOpen);
  }, [ref]);

  return (
    <div className={styles.container}>
      <div
        role="button"
        onClick={() => sortingOptions && setOptionsOpen(!optionsOpen)}
        className={styles.displayContainer}
      >
        {optionsOpen && (
          <div ref={ref} className={styles.dropContainer}>
            {sortingOptions.map((s) => (
              <div className={styles.dropItem} key={s}>
                <Text
                  key={s}
                  noMargin
                  weight="semiBold"
                  onClick={() => {
                    setSortBy(s);
                    refetch();
                  }}
                >
                  {t(sortStrings[s]) || sortStrings[s]}
                </Text>
              </div>
            ))}
          </div>
        )}
        <div>
          <Text noMargin weight="bold">
            {t(sortStrings[sortBy]) || sortStrings[sortBy]}
          </Text>
        </div>
      </div>
      <div
        role="button"
        onClick={() => {
          setIsAscending(!isAscending);
          refetch();
        }}
        className={styles.displayContainer}
      >
        {isAscending ? (
          <Icon className={styles.icon}>expand_less</Icon>
        ) : (
          <Icon className={styles.icon}>expand_more</Icon>
        )}
      </div>
    </div>
  );
}
