import { Text } from '../typography';
import styles from './VehicleCardList.module.scss';
import noImage from '../../assets/icons/image_blank.png';
import { Icon } from '@mui/material';
import { truncate } from '../../utilities';

export default function VehicleCardList({
  vehicle,
  readOnly = false,
  onAssetAdded = () => {},
  onAssetRemoved = () => {},
}) {
  return (
    <div
      tabIndex={0}
      className={styles.vehicleCardContainer}
      key={vehicle.id}
      onClick={() => onAssetAdded(vehicle)}
    >
      <img
        className={styles.vehicleImage}
        src={vehicle.imageUrl ? vehicle.imageUrl : noImage}
        alt={`${vehicle.make} ${vehicle.model}`}
      />
      <div className={styles.textContainer}>
        <Text size="sm" noSelect noMargin weight="semiBold">
          {truncate(`${vehicle.make} ${vehicle.model}`, 20)}
        </Text>
        <Text noMargin noSelect size="xs" weight="semiBold">
          {truncate(vehicle.unitNumber, 20)}
        </Text>
      </div>
      {!readOnly && (
        <Icon
          className={styles.closeIcon}
          sx={{ fontSize: '1rem' }}
          onClick={() => onAssetRemoved(vehicle.id)}
        >
          close
        </Icon>
      )}
    </div>
  );
}
