import React, { useState, useMemo } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import logo from '../assets/logo_white_horiz.svg';
import background from '../assets/logo_white_icon.svg';
import styles from './Register.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '../components';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Text } from '../components/typography';
import { privacyPolicy } from '../constants/endpoints';
import { keys } from '../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [passwordMessage, setPasswordMessage] = useState(
  //   'Please enter a strong password',
  // );
  const query = useQuery();
  const email = query.get('email');
  const token = query.get('token');
  const companyLogo = query.get('logo');
  const [terms, setTerms] = useState(false);
  const registerMutation = loader('./Register.graphql');
  const [user, setUser] = useState({
    email,
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [register] = useMutation(registerMutation);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.leftChild}>
            <img alt="Opas logo" className={styles.logo} src={logo} />
            <p className={styles.leftTitle}>{t(keys.utilities.LEFT_HEADER)}</p>
            <p className={styles.leftSubtitle}>
              {t(keys.utilities.LEFT_SUBTEXT)}
            </p>
            <img
              alt="background graphic"
              className={styles.backgroundGraphic}
              src={background}
            />
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.loginContainerParent}>
            <div className={styles.loginContainer}>
              <img
                className={styles.companyLogo}
                alt="company logo"
                src={companyLogo}
              />
              <p className={styles.rightTitle}>{t(keys.utilities.SIGN_UP)}</p>

              <Form
                noValidate
                validated={true}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>{t(keys.common.FIRST_NAME)}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder={t(keys.common.FIRST_NAME)}
                      value={user.firstName}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          firstName: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(keys.utilities.PLEASE_PROVIDE, {
                        variable: t(keys.common.FIRST_NAME),
                      })}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t(keys.common.LAST_NAME)}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder={t(keys.common.LAST_NAME)}
                      value={user.lastName}
                      onChange={(e) =>
                        setUser({ ...user, lastName: e.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(keys.utilities.PLEASE_PROVIDE, {
                        variable: t(keys.common.LAST_NAME),
                      })}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>{t(keys.settings.EMAIL)}</Form.Label>
                    <Form.Control
                      required
                      disabled
                      type="text"
                      placeholder={t(keys.settings.EMAIL)}
                      value={user.email}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>{t(keys.utilities.PASSWORD)}</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder={t(keys.utilities.PASSWORD)}
                      value={user.password}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          password: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(keys.utilities.ENTER_PASSWORD)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>
                      {t(keys.utilities.CONFIRM_PASSWORD)}
                    </Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder={t(keys.utilities.CONFIRM_PASSWORD)}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(keys.utilities.ENTER_PASSWORD)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                {user?.password !== confirmPassword ? (
                  <Text size="md" weight="semiBold" color="red">
                    {t(keys.utilities.PASSWORDS_DONT_MATCH)}
                  </Text>
                ) : null}
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>{t(keys.settings.PHONE)}</Form.Label>
                    <Form.Control
                      required
                      formNoValidate
                      isValid={true}
                      type="text"
                      placeholder={t(keys.settings.PHONE)}
                      value={user.phone}
                      onChange={(e) =>
                        setUser({ ...user, phone: e.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(keys.utilities.ENTER_CONTACT_NUMBER)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <br />
                <Form.Group className="mb-3">
                  <Form.Check
                    required
                    checked={terms}
                    onChange={(e) => setTerms(e.target.checked)}
                    label={
                      <Text noMargin color="red">
                        {t(keys.companySignUp.FINAL_TERMS_TEXT)}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={privacyPolicy}
                          className={styles.labelLink}
                        >
                          {t(keys.companySignUp.FINAL_TERMS_LINK)}
                        </a>
                      </Text>
                    }
                    feedback={t(keys.companySignUp.FINAL_TERMS_WARNING)}
                    feedbackType="invalid"
                  />
                </Form.Group>
                <Button
                  size="md"
                  disabled={
                    !user.firstName ||
                    !user.lastName ||
                    !user.password ||
                    user.password !== confirmPassword ||
                    !confirmPassword ||
                    !user.phone ||
                    !terms
                  }
                  className={styles.registerButton}
                  value={t(keys.utilities.REGISTER)}
                  onClick={() => {
                    if (!user.password || user.password !== confirmPassword) {
                      console.error("Passwords don't match.");
                      return;
                    }
                    register({ variables: { ...user, token } }).then(
                      ({ data, errors }) => {
                        if (!errors?.length) {
                          navigate('/login');
                        } else {
                          setErrorMessage(errors[0].message);
                        }
                      },
                    );
                  }}
                />
              </Form>
              {errorMessage?.length ? (
                <>
                  <br />
                  <Text size="md" weight="semiBold" color="red">
                    {errorMessage.includes('PasswordStrengthError')
                      ? t(keys.utilities.PASSWORD_TOO_WEAK)
                      : errorMessage}
                  </Text>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
