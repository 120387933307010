import React, { useState } from 'react';
import styles from './ImageCropModal.module.scss';
import ImageCropper from './ImageCropper';
import icons from '../../assets/icons';
import { Form } from 'react-bootstrap';
import { Text } from '../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function ImageCropModal({
  onSave,
  onClose,
  ratio,
  title,
  showDescription,
  transparent = false,
}) {
  const [description, setDescription] = useState('');
  const { t } = useTranslation();

  return (
    <>
      <div
        aria-hidden
        className={styles.background}
        onClick={() => onClose()}
      />
      <div className={styles.container}>
        <div className={styles.header}>
          <h6 className={styles.title}>{title}</h6>

          <div className={styles.close} role="button" onClick={onClose}>
            <img
              alt="close"
              className={styles.closeIcon}
              src={icons.black.close}
            />
          </div>
        </div>
        <hr className={styles.headerRule} />
        <div>
          {showDescription && (
            <>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Text size="md" weight="semiBold" noSelect>
                  {t(keys.action.ENTER, {
                    variable: t(keys.common.DESCRIPTION),
                  })}
                </Text>
                <Form.Control
                  type="text"
                  placeholder={t(keys.common.IMAGE_DESCRIPTION_PLACEHOLDER)}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form>
              <br />
            </>
          )}

          <Text size="md" weight="semiBold" noSelect>
            {t(keys.action.UPLOAD_VARIABLE, { variable: t(keys.common.IMAGE) })}
          </Text>
        </div>
        <ImageCropper
          onSave={(url) => onSave(url, description)}
          transparent={transparent}
          ratio={ratio}
        />
      </div>
    </>
  );
}
