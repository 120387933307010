import styles from './DropdownCombo.module.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useCombobox } from 'downshift';
import VehicleImage from '../VehicleImage';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import { dropdownConstants } from './dropdown';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import defaultLogo from '../../assets/workspace_image.png';
import { deepCompareObjects, truncate } from '../../utilities';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { dropdownTypes } from './dropdown';

const generateSearchString = (item) => {
  let value = '';
  Object.keys(item).forEach((key) => (value = value.concat(`${item[key]} `)));
  return value;
};

function VehicleCard({
  id,
  year,
  make,
  model,
  unitNumber,
  imageUrl,
  type,
  subtype,
  color,
}) {
  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.vehicleCardContainer}
      key={id}
    >
      <VehicleImage
        className={styles.vehicleImage}
        vehicle={{ imageUrl, type, subtype, color }}
      />
      <div className={styles.textContainer}>
        <Text size="md" noSelect noMargin weight="semiBold">
          {truncate(`${year || ''} ${make} ${model}`, 30)}
        </Text>
        <Text noMargin noSelect size="sm" weight="semiBold" textAlign="right">
          {truncate(unitNumber, 15)}
        </Text>
      </div>
    </div>
  );
}
export default function DropdownCombo({
  items,
  type,
  onChange,
  selected,
  optional,
  show,
  title,
  onRemove = () => {},
  required = false,
  preventStateChange = false,
  highlight = false,
}) {
  const [inputItems, setInputItems] = useState(items);
  const [inputValue, setInputValue] = useState('');
  const ref = useRef(inputValue);
  const { t } = useTranslation();
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getLabelProps,
    getItemProps,
    getToggleButtonProps,
    selectItem,
  } = useCombobox({
    inputValue,
    items: inputItems,
    itemToString: (item) => (item ? item.title : ''),
    itemKey: (item) => item.id,
    selectedItem: selected,
    onSelectedItemChange: ({ selectedItem, type }) => {
      if (
        preventStateChange &&
        type !== useCombobox.stateChangeTypes.ItemClick
      ) {
        return;
      }
      onChange(selectedItem);
    },
    onInputValueChange: ({ inputValue, type }) => {
      if (
        type === useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem
      ) {
        return;
      }
      setInputValue(inputValue);
      setInputItems(
        items.filter((item) =>
          generateSearchString(item)
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        ),
      );
    },
  });

  useEffect(() => {
    if (!inputValue && !deepCompareObjects(items, inputItems)) {
      setInputItems(items);
    }
    if (selected && !isOpen && inputValue !== '') {
      setInputValue('');
    }
  }, [inputItems, inputValue, isOpen, items, selected]);

  useEffect(() => {
    selectItem(null);
  }, [show, selectItem]);

  return (
    <div className={styles.container}>
      <div {...getInputProps({ ref })} />
      <Text noSelect {...getLabelProps()} noMargin weight="semiBold" size="md">
        {title} <span>{optional && ` (${t(keys.common.OPTIONAL)})`}</span>
      </Text>

      <div
        className={highlight && !selected ? styles.highlight : styles.selector}
        {...getToggleButtonProps()}
      >
        <div className={styles.selectorDisplay}>
          {selected ? (
            <div className={styles.selectedContainer}>
              <Text noMargin weight="semiBold">
                {type === dropdownTypes.ASSET
                  ? `${selected.year || ''} ${selected.make} ${selected.model}${
                      selected.unitNumber?.length
                        ? `(${selected.unitNumber})`
                        : ''
                    } `
                  : type === dropdownTypes.ACTIVITY
                  ? `${selected.description || selected.externalActivityId}`
                  : `${selected.title}`}
              </Text>
              {!required && (
                <Icon
                  onClick={(e) => {
                    selectItem(null);
                    e.stopPropagation();
                    e.preventDefault();
                    onRemove();
                  }}
                  sx={{ fontSize: '1rem', color: '#6e6e73' }}
                >
                  close
                </Icon>
              )}
            </div>
          ) : (
            <div className={styles.buttonContainer}>
              <Icon fontSize="inherit" baseClassName="material-icons-outlined">
                {dropdownConstants[type].icon}
              </Icon>
              <Text size="md" noSelect noMargin>
                {t(keys.action.SELECT_VARIABLE, {
                  variable: t(keys.common[type.toUpperCase()] || type),
                })}
              </Text>
            </div>
          )}
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      <div {...getMenuProps()}>
        {isOpen && (
          <div className={styles.dropdownContainer}>
            <div className={styles.searchBar}>
              <Form.Control
                {...getInputProps({
                  onClick: (event) => {
                    event.stopPropagation();
                  },
                  ref,
                })}
                className={styles.input}
                placeholder={
                  type !== dropdownTypes.ACTIVITY
                    ? t(keys.action.SEARCH, {
                        variable: `${
                          t(keys.common[type.toUpperCase()]) || type
                        }s`,
                      })
                    : `Search ${dropdownTypes.ACTIVITIES}`
                }
              />
            </div>
            <hr />

            {inputItems.length > 0 ? (
              inputItems?.map((item, index) => (
                <div
                  className={styles.option}
                  key={item.id}
                  {...getItemProps({
                    item,
                    index,
                  })}
                >
                  {type === dropdownTypes.ACTIVITY && (
                    <Text>{item.description || item.externalActivityId}</Text>
                  )}
                  {type === dropdownTypes.ASSET && (
                    <VehicleCard key={item.id} {...item} />
                  )}
                  {type === dropdownTypes.TEMPLATE && (
                    <Text size="md" noSelect noMargin weight="semiBold">
                      {item.title}
                    </Text>
                  )}
                  {type === dropdownTypes.WORKSPACE && (
                    <div className={styles.workspaceCard}>
                      <div className={styles.workspaceLogoTitle}>
                        <img
                          className={styles.logo}
                          src={item.logoUrl ? item.logoUrl : defaultLogo}
                          alt="default"
                        ></img>
                        <Text noMargin weight="semiBold" size="sm">
                          {truncate(item?.title, 13)}
                        </Text>
                      </div>
                      <Icon
                        sx={{
                          fontSize: '0.75rem',
                          color:
                            item?.status === 'Active' ? '#28a745' : '#e9222c',
                        }}
                        className={styles.statusButton}
                      >
                        circle
                      </Icon>
                    </div>
                  )}
                  {type === dropdownTypes.TASKS && (
                    <Text noSelect noMargin weight="semiBold">
                      {item.title}
                    </Text>
                  )}
                </div>
              ))
            ) : (
              <div className={styles.noMatch}>
                <Text className={styles.noMatch}>
                  {t(keys.action.NO_MATCHING_RESULTS)}
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
