import { Icon } from '@mui/material';
import React from 'react';
import { Text } from '../typography';
import styles from './InspectionTypeBadge.module.scss';
import { useTranslation } from 'react-i18next';
import { getTranslationKey } from '../../utilities/translator/translation_keys';
import { assessmentTypes } from '../../constants/strings';

export default function InspectionTypeBadge({ type, border = false }) {
  const invalid = { icon: 'close', color: 'black', name: 'Invalid' };
  const getTypeInfo = () => {
    if (!type) {
      return invalid;
    }
    const t = type === 'VEHICLE' ? 'fleet' : type?.toLowerCase();
    return assessmentTypes[t] ? assessmentTypes[t] : invalid;
  };

  const { icon, color, name } = getTypeInfo();
  const { t } = useTranslation();

  return (
    <div className={border ? styles.containerBorder : styles.container}>
      <Icon
        baseClassName="material-icons-outlined"
        className={styles[`${color}Icon`]}
      >
        {icon}
      </Icon>
      <Text weight="semibold" noMargin>
        {t(getTranslationKey(name, 'common')) || name}
      </Text>
    </div>
  );
}
