import { Text } from '../typography';
import styles from './AddVehicleFeaturesModal.module.scss';
import { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import Button from '../Button';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const vehicleFeatureMutation = loader(
  '../../pages/fleet/CreateVehicleFeatures.create.graphql',
);

export default function AddVehicleFeaturesModal({
  features,
  show = false,
  onHide,
  refetch,
}) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const { t } = useTranslation();

  const [addVehicleFeature] = useMutation(vehicleFeatureMutation);

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalHeader}>
            <Text color="accentPrimary" size="lg" noMargin weight="bold">
              {t(keys.action.ADD, { variable: t(keys.assets.FEATURE) })}
            </Text>
            <div className={styles.closeIcon}>
              <Icon
                baseClassName="material-icons-outlined"
                className={classNames(styles.iconButton, styles.red)}
                onClick={() => {
                  onHide();
                }}
              >
                close
              </Icon>
            </div>
          </div>
          <div className={styles.editFeaturesContainer}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addVehicleFeature({
                  variables: {
                    vehicleId: features.id,
                    title,
                    description,
                  },
                }).then(() => {
                  setTitle('');
                  setDescription('');
                  refetch();
                  onHide();
                });

                return false;
              }}
            >
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Text weight="bold" size="md">
                    {t(keys.assets.FEATURE_NAME)}
                  </Text>
                  <Form.Control
                    type="text"
                    placeholder={t(keys.assets.FEATURE_PLACEHOLDER)}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Text weight="bold" size="md">
                    {t(keys.common.DESCRIPTION)}
                  </Text>
                  <Form.Control
                    as="textarea"
                    type="text"
                    placeholder={t(keys.assets.FEATURE_DESCRIPTION_PLACEHOLDER)}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Button
                className={styles.saveButton}
                type="submit"
                disabled={!title.length}
                value={t(keys.action.ADD, { variable: null })}
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
