import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { assetFields, vehicleKeys } from '../../utilities/vehicles';
import { Text } from '../typography';
import styles from './VehicleMain.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import noImage from '../../assets/icons/image_blank.png';

export default function VehicleMain({ vehicle }) {
  const { t } = useTranslation();
  const {
    make,
    model,
    vehicleIdentificationNumber,
    imageUrl,
    unitNumber,
    year,
    color,
    isMetric,
    subtype,
    licensePlate,
    odometer = 0,
    location,
    features = [],
  } = vehicle;

  const dataType = vehicleKeys.includes(vehicle.type) ? 'vehicle' : 'asset';

  const items = [
    {
      key: t(assetFields.make[dataType].title),
      value: make,
    },
    {
      key: t(assetFields.model[dataType].title),
      value: model,
    },
    {
      key: t(keys.assets.YEAR),
      value: year,
    },
    {
      key: t(keys.assets.UNIT_NUMBER),
      value: unitNumber,
    },
    {
      key: t(keys.common.TYPE),
      value: subtype,
    },
    {
      key: t(keys.common.COLOUR),
      value: color,
    },
    { key: t(keys.common.LOCATION), value: location },
    ...(dataType === 'vehicle'
      ? [
          {
            key: t(keys.assets.ODOMETER),
            value: `${odometer.toLocaleString()} ${isMetric ? 'KM' : 'Mi'}`,
          },
          {
            key: t(keys.assets.VIN),
            value: vehicleIdentificationNumber,
          },
          {
            key: t(keys.assets.LICENSE_PLATE),
            value: licensePlate,
          },
        ]
      : []),
  ];

  return vehicle?.id ? (
    <div className={styles.container}>
      <div className={styles.parent}>
        <div className={styles.leftContainer}>
          <div className={styles.header}>
            <Text
              size="md"
              weight="bold"
              className={styles.title}
            >{`${make} ${model}`}</Text>
            <Text size="md" weight="semiBold" color="secondary">
              {vehicleIdentificationNumber}
            </Text>
          </div>
          <img
            className={styles.image}
            src={imageUrl ?? noImage}
            alt={`${make} ${model}`}
          />
          <Text size="md" weight="bold" color="secondary">
            {unitNumber}
          </Text>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.rightBottom}>
            <Text
              className={classNames(styles.itemKey, styles.featuresHeader)}
              size="md"
              weight="semiBold"
              color="secondaryLight"
            >
              {t(keys.common.INFORMATION)}
            </Text>
            <div className={styles.statsContainer}>
              {items.map((item) => (
                <div key={item.key} className={styles.itemContainer}>
                  <Text
                    className={styles.itemKey}
                    size="sm"
                    weight="semiBold"
                    color="secondaryLight"
                    noMargin
                  >
                    {`${item.key}:`}
                  </Text>
                  <Text
                    size="sm"
                    noMargin
                    weight="semiBold"
                    color="primaryLight"
                    textAlign="right"
                  >
                    {item.value}
                  </Text>
                </div>
              ))}
            </div>
            <Text
              className={classNames(styles.itemKey, styles.featuresHeader)}
              size="md"
              weight="semiBold"
              color="secondaryLight"
            >
              {t(keys.assets.FEATURES)}
            </Text>
            <Text size="sm" noVerticalMargin weight="bold" color="primaryLight">
              {features.map((f) => f.title).join(', ')}
            </Text>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.spinnerContainer}>
      <Spinner
        className={styles.spinner}
        animation="border"
        variant="primary"
      />
    </div>
  );
}
