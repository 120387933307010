import React, { useState, useEffect, useMemo } from 'react';
import { isValidUUID } from '../../utilities/offline_hooks_setup_apollo/offlineUtils';
import { useIndexedDB } from '../../hooks/offline-hooks/indexedDBhook';
import styles from './audio-wrapper.module.scss';

const AudioWrapper = ({ audio, children }) => {
  const { isDBOpen, getFileURL } = useIndexedDB('offline-file-db');
  const [url, setUrl] = useState(null);

  const dbOpen = useMemo(() => isDBOpen(), [isDBOpen]);

  useEffect(() => {
    const fetchAudio = async () => {
      if (dbOpen && isValidUUID(audio)) {
        const fetchedUrl = await getFileURL(audio);
        setUrl(fetchedUrl);
      } else if (audio) {
        setUrl(audio);
      }
    };

    fetchAudio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbOpen, audio]);

  // Return a fallback if the URL is not available yet
  if (!url) {
    return <div>Loading audio...</div>;
  }

  // Return the <source> element for the audio
  return (
    <audio controls className={styles.audio} src={url}>
      {children}
    </audio>
  );
};

export default AudioWrapper;
