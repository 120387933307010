const prodUrl = process.env.REACT_APP_AUTH0_AUDIENCE;
const prodWsUrl = prodUrl.startsWith('https://')
  ? prodUrl.replace('https://', 'wss://')
  : `wss://${prodUrl}`;

export const apiEndpoints = {
  dev: 'http://localhost:4000/graphql',
  prod: prodUrl,
  devWs: 'ws://localhost:4000/graphql',
  prodWs: prodWsUrl,
};

export const appEndpoints = {
  dev: 'http://localhost:3000',
  prod: process.env.REACT_APP_AUTH0_CALLBACK.replace('/redirect', ''),
};

export const notFound = '/403';

export const contact = 'https://www.opasmobile.com/contact';

export const privacyPolicy = 'https://www.opasmobile.com/privacy-policy';

export const publicSite = 'https://www.opasmobile.com';
