import { keys } from '../../utilities/translator/translation_keys';

export const eventTypeLookup = {
  createActionItem: 'CREATE_ACTION_ITEM',
  updateActionItem: 'UPDATE_ACTION_ITEM',
  completeAssessment: 'COMPLETE_ASSESSMENT',
  reportIncident: 'REPORT_INCIDENT',
  completeAssessmentItem: 'COMPLETE_ASSESSMENT_ITEM',
  addObservation: 'ADD_OBSERVATION',
  participantAssessment: 'PARTICIPANT_ASSESSMENT',
};

export const eventTypes = [
  {
    text: keys.boards.ACTION_ITEM_CREATED,
    key: eventTypeLookup.createActionItem,
  },
  {
    text: keys.boards.ASSESSMENT_COMPLETED,
    key: eventTypeLookup.completeAssessment,
  },
  {
    text: keys.boards.ASSESSMENT_PARTICIPATION,
    key: eventTypeLookup.participantAssessment,
  },
  {
    text: keys.boards.INCIDENT_REPORTED,
    key: eventTypeLookup.reportIncident,
  },
  {
    text: keys.boards.QUESTION_ANSWERED,
    key: eventTypeLookup.completeAssessmentItem,
  },
  {
    text: keys.boards.OBSERVATION_REPORTED,
    key: eventTypeLookup.addObservation,
  },
];

export const primaryGroupings = [
  { text: keys.boards.PRIMARY_GROUP_NONE, key: 'None' },
  { text: keys.boards.PRIMARY_GROUP_USER, key: 'name' },
  { text: keys.boards.PRIMARY_GROUP_WORKSPACE, key: 'workspace' },
];

export const secondaryGroupings = {
  CREATE_ACTION_ITEM: [
    { text: keys.boards.SECONDARY_GROUP_ASSET, key: 'Asset' },
    { text: keys.boards.SECONDARY_GROUP_STATUS, key: 'Status' },
    { text: keys.boards.SECONDARY_GROUP_INCIDENT, key: 'Incident' },
    { text: keys.boards.SECONDARY_GROUP_PRIORITY, key: 'Priority' },
    { text: keys.boards.SECONDARY_GROUP_INSPECTION, key: 'Inspection' },
  ],
  COMPLETE_ASSESSMENT: [
    { text: keys.boards.SECONDARY_GROUP_TYPE, key: 'Type' },
    { text: keys.boards.SECONDARY_GROUP_OUTCOME, key: 'Status' },
    { text: keys.boards.SECONDARY_GROUP_TEMPLATE, key: 'Template' },
  ],
  COMPLETE_ASSESSMENT_ITEM: [
    { text: keys.boards.SECONDARY_GROUP_ANSWER, key: 'Value' },
    { text: keys.boards.SECONDARY_GROUP_STATUS, key: 'Status' },
    { text: keys.boards.SECONDARY_GROUP_QUESTION, key: 'Question' },
    { text: keys.boards.SECONDARY_GROUP_ASSESSMENT, key: 'Template' },
    { text: keys.boards.SECONDARY_GROUP_QUESTION_TYPE, key: 'QuestionType' },
    {
      text: keys.boards.SECONDARY_GROUP_ASSESSMENT_TYPE,
      key: 'InspectionType',
    },
  ],
  REPORT_INCIDENT: [
    { text: keys.boards.SECONDARY_GROUP_TYPE, key: 'Type' },
    { text: keys.boards.SECONDARY_GROUP_SUBTYPE, key: 'Subtype' },
    { text: keys.boards.SECONDARY_GROUP_STATUS, key: 'Status' },
  ],
  ADD_OBSERVATION: [
    { text: keys.boards.SECONDARY_GROUP_TYPE, key: 'Type' },
    { text: keys.boards.SECONDARY_GROUP_IMAGES, key: 'Images' },
    { text: keys.boards.SECONDARY_GROUP_LOCATION, key: 'Location' },
  ],
  PARTICIPANT_ASSESSMENT: [
    { text: keys.boards.SECONDARY_GROUP_TYPE, key: 'Type' },
    { text: keys.boards.SECONDARY_GROUP_OUTCOME, key: 'Status' },
    { text: keys.boards.SECONDARY_GROUP_TEMPLATE, key: 'Template' },
  ],
};

export const getGroupMap = (eventType) => [
  ...primaryGroupings,
  ...secondaryGroupings[eventType],
];

export const bootstrapColors = [
  '#fd7e14',
  '#0dcaf0',
  '#5227CC',
  '#ABB5BE',
  '#28A745',
  '#DC3545',
  '#FFC107',
  '#17A2B8',
  '#0d6efd',
  '#6610f2',
  '#d63384',
];
