import { useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
import styles from './TablePagination.module.scss';

// Note:  This must be an odd number
const PAGES_TO_SHOW = 5;

export default function TablePagination({ pageSize, count, setPage, page }) {
  const { pages, numPages } = useMemo(() => {
    const pages = [];
    const numPages = Math.ceil(count / pageSize);
    const edgeSize = Math.floor(PAGES_TO_SHOW / 2);

    const startIndex = Math.max(1, page - edgeSize);
    const endIndex = Math.min(numPages, page + edgeSize);

    if (startIndex > 1) {
      pages.push(
        <Pagination.Item
          key={1}
          active={1 === page}
          onClick={() => {
            setPage(1);
          }}
        >
          {1}
        </Pagination.Item>,
      );
      if (startIndex > 2) {
        pages.push(<Pagination.Ellipsis key="start" disabled />);
      }
    }

    for (let i = startIndex; i <= endIndex; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => {
            setPage(i);
          }}
        >
          {i}
        </Pagination.Item>,
      );
    }

    if (endIndex < numPages) {
      if (endIndex < numPages - 1) {
        pages.push(<Pagination.Ellipsis key="end" disabled />);
      }

      pages.push(
        <Pagination.Item
          key={numPages}
          active={numPages === page}
          onClick={() => {
            setPage(numPages);
          }}
        >
          {numPages}
        </Pagination.Item>,
      );
    }

    return { pages, numPages };
  }, [count, pageSize, page, setPage]);

  return (
    numPages > 0 && (
      <div className={styles.container}>
        <div className={styles.paginationDisplay}>
          <Pagination size="sm">
            <Pagination.First
              disabled={page === 1}
              onClick={() => setPage(1)}
            />
            <Pagination.Prev
              disabled={page === 1}
              onClick={() => {
                setPage(page - 1);
              }}
            />
            {pages}
            <Pagination.Next
              disabled={page === numPages}
              onClick={() => {
                setPage(page + 1);
              }}
            />
            <Pagination.Last
              disabled={page === numPages}
              onClick={() => {
                setPage(numPages);
              }}
            />
          </Pagination>
        </div>
      </div>
    )
  );
}
