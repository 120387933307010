import { loader } from 'graphql.macro';
import MTTable from '../../components/tables/MTable';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useMemo, useState, useEffect } from 'react';
import { useURLParams } from '../../providers/URLParamProvider';
import {
  useTableComponents,
  CustomDateRangeFilter,
  checkDueDateColor,
  LabelFilter,
} from '../../components/tables/MTableComponents';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components/typography';
import { keys } from '../../utilities/translator/translation_keys';
import styles from './ActionItemList.module.scss';
import { Button } from '../../components';
import { useActionItemModal } from '../../hooks/misc';
import { Form } from 'react-bootstrap';
import { useQueryParams } from '../../hooks/misc';
import { useCurrentUser } from '../../providers/UserProvider';
import {
  priorityStringLookup,
  statusStringLookup,
} from '../../components/action_items/action_item_utilities';
import { useLabels } from '../../providers/LabelProvider';
import SimpleTabSystem from '../../components/SimpleTabSystem';
import { useApolloClient, gql } from '@apollo/client';
import { fetchAllData } from '../../utilities/excel_export/table_excel_export';
import { exportToExcel } from '../../components/excel/ExcelExport';
import ProgressModal from '../../components/modals/ProgressModal';
import { showToast } from '../../graphql/cache/modal';
import { toastVariant } from '../../constants/misc';
import format from 'date-fns/format';
import {
  excelHeaderLookup,
  formatActionItem,
} from '../../utilities/excel_export/data_converter';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import defaultLogo from '../../assets/workspace_image.png';
import { getWorkspace } from '../../components/tables/table.js';

const actionItemQuery = loader('./ActionItemList.fetch.graphql');

export default function ActionItemList() {
  const { page, pageSize, filters, subfilters, sort } = useURLParams();
  const { t } = useTranslation();
  const { openActionItemModal } = useActionItemModal();
  const [selectedTab, setSelectedTab] = useState('open');
  const { upsertParam, deleteParam, getParam } = useQueryParams();
  const { isWorkspaceAdmin, availableWorkspaces } = useWorkspace();
  const [dateSelectors, setDateSelectors] = useState({
    dueDateMin: getParam('dueDate')?.split('__')[0] || '',
    dueDateMax: getParam('dueDate')?.split('__')[1] || '',
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
    dateModifiedMin: getParam('dateModified')?.split('__')[0] || '',
    dateModifiedMax: getParam('dateModified')?.split('__')[1] || '',
    dateCompletedMin: getParam('dateCompleted')?.split('__')[0] || '',
    dateCompletedMax: getParam('dateCompleted')?.split('__')[1] || '',
  });
  const { user } = useCurrentUser();
  const [isFetchingAllData, setIsFetchingAllData] = useState(false);
  const [progress, setProgress] = useState(0);

  const workspaces = availableWorkspaces?.map((w) => ({
    label: `${w.title}`,
    value: `${w.id}`,
  }));

  const {
    dateCell,
    textCell,
    userCell,
    userFilterOptions,
    labelCell,
    multiLabelCell,
  } = useTableComponents();
  const client = useApolloClient();
  const {
    refetch,
    networkStatus,
    previousData: { actionItemsCount: oldCount } = {},
    data: { actionItems = [], actionItemsCount = 0 } = {},
  } = useQuery(actionItemQuery, {
    skip: !page || !pageSize || !sort,
    variables: {
      options: {
        page,
        pageSize,
        filters,
        subfilters,
        sort,
      },
    },
  });
  const allWorkspaces = useMemo(() => {
    const allWorkspacesParam = getParam('allWorkspaces');
    if (allWorkspacesParam === 'TRUE') {
      return true;
    }
    return false;
  }, [getParam]);

  const { labels } = useLabels();

  const [selectedLabels, setSelectedLabels] = useState(
    getParam('labels')
      ?.split(',')
      .map((n) => labels.find(({ id }) => id === parseInt(n))) || [],
  );

  const tabOptions = [
    { title: t(keys.action_items.OPEN), key: 'open' },
    { title: t(keys.action_items.CLOSED), key: 'closed' },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'assignee',
        header: t(keys.common.ASSIGNEE),
        enableSorting: false,
        Cell: ({ cell }) => userCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('workspace'),
      },
      {
        accessorKey: 'priority',
        header: t(keys.action_items.PRIORITY),
        filterVariant: 'select',
        size: 120,
        filterSelectOptions: Object.entries(priorityStringLookup).map(
          ([key, v]) => ({ label: t(v.name), value: key }),
        ),
        Cell: ({ cell }) => {
          return (
            <Text
              noMargin
              weight="semiBold"
              size="sm"
              truncate
              color={priorityStringLookup[cell.getValue()].color}
            >
              {t(priorityStringLookup[cell.getValue()].name)}
            </Text>
          );
        },
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        size: 120,
        filterVariant: 'select',
        filterSelectOptions: Object.entries(statusStringLookup).map(
          ([key, v]) => ({ label: t(v.title), value: key }),
        ),
        Cell: ({ cell }) =>
          labelCell({
            name: t(statusStringLookup[cell.getValue()].title),
            color: statusStringLookup[cell.getValue()].color,
          }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        enableSorting: false,
        Cell: ({ cell }) => userCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('workspace'),
      },
      {
        accessorKey: 'dueDate',
        header: t(keys.action_items.DUE_DATE),
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          const color = checkDueDateColor({
            dueDate: cell.getValue(),
            status,
          });
          return dateCell({ cell }, { color, dateOnly: true });
        },
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dueDateMin}
            maxDate={dateSelectors.dueDateMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dueDateMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dueDateMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'workspaceId',
        header: t(keys.common.WORKSPACE),
        enableSorting: false,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: workspaces,
        Cell: ({ cell }) => {
          const workspace = getWorkspace(cell.getValue(), availableWorkspaces);
          return (
            <>
              <img
                src={workspace.logoUrl || defaultLogo}
                className={styles.workspaceLogo}
                alt={'workspace_img'}
              />
              <Text noMargin weight="semiBold" size="sm" truncate>
                {workspace?.title}
              </Text>
            </>
          );
        },
      },
      {
        accessorKey: 'dateModified',
        header: t(keys.common.LAST_UPDATED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }, { showTime: true }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateModifiedMin}
            maxDate={dateSelectors.dateModifiedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMax: value })
            }
          />
        ),
      },
      ...(selectedTab === 'closed'
        ? [
            {
              accessorKey: 'dateCompleted',
              header: t(keys.common.DATE_COMPLETED),
              Cell: ({ cell }) => dateCell({ cell }, { showTime: true }),
              size: 275,
              grow: true,
              Filter: ({ column }) => (
                <CustomDateRangeFilter
                  column={column}
                  minDate={dateSelectors.dateCompletedMin}
                  maxDate={dateSelectors.dateCompletedMax}
                  setMinDate={(value) =>
                    setDateSelectors({
                      ...dateSelectors,
                      dateCompletedMin: value,
                    })
                  }
                  setMaxDate={(value) =>
                    setDateSelectors({
                      ...dateSelectors,
                      dateCompletedMax: value,
                    })
                  }
                />
              ),
            },
          ]
        : []),
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        Cell: ({ cell }) => dateCell({ cell }, { showTime: true }),
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'labels',
        header: t(keys.common.LABELS),
        enableSorting: false,
        size: 300,
        Filter: ({ column }) => (
          <LabelFilter
            column={column}
            selectedLabels={selectedLabels}
            labels={labels}
            handleChange={(label) => {
              setSelectedLabels(label);
            }}
            handleRemove={() => {
              column.setFilterValue(null);
              setSelectedLabels([]);
            }}
          />
        ),
        Cell: ({ cell }) => {
          const labels = cell.getValue() || [];
          return multiLabelCell({ labels });
        },
      },
    ],

    [
      t,
      userFilterOptions,
      workspaces,
      selectedTab,
      textCell,
      userCell,
      labelCell,
      dateCell,
      dateSelectors,
      availableWorkspaces,
      selectedLabels,
      labels,
      multiLabelCell,
    ],
  );

  const handleExport = async () => {
    const query = gql`
      ${actionItemQuery}
    `;
    await fetchAllData({
      filters,
      query,
      client,
      setIsFetching: setIsFetchingAllData,
      table: 'actionItems',
      count: 'actionItemsCount',
      setProgress,
    }).then((response, errors) => {
      if (errors?.length) {
        showToast({
          title: 'Error Exporting Data',
          variant: toastVariant.danger,
          message: `An error occurred: ${errors[0].message}`,
        });
      }
      if (response?.length) {
        exportToExcel({
          data: response.map((d) => formatActionItem(d)),
          sheetName: 'Action Item Data',
          exportedBy: `${user.firstName} ${user.lastName}`,
          type: 'Action Item',
          fileName: `Action_Item_Export_${format(new Date(), 'yyyy-MM-dd')}`,
          reportType: ['Report Type', 'Action Item Table Export'],
          headerMap: excelHeaderLookup,
        });
      }
    });
  };

  useEffect(() => {
    if (selectedTab === 'open') {
      upsertParam('open', true);
    } else {
      deleteParam('open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <>
      <MTTable
        isLoading={networkStatus === NetworkStatus.loading}
        data={actionItems || []}
        columns={columns}
        onRowClick={(actionItem) =>
          openActionItemModal(actionItem.id, null, refetch)
        }
        onClearAllFilters={() => {
          setSelectedLabels([]);
          setDateSelectors({});
        }}
        header={
          <div className={styles.container}>
            <div className={styles.header}>
              <Text size="lg" weight="bold" color="accentPrimary">
                {t(keys.common.ACTION_ITEMS)}
              </Text>
              <div className={styles.buttons}>
                <Button
                  variant="primary"
                  icon="add"
                  size="md"
                  testId="action-item"
                  value={t(keys.common.NEW)}
                  onClick={() => {
                    openActionItemModal(null, 'VEHICLE', refetch);
                  }}
                />
                {isWorkspaceAdmin && (
                  <Button
                    variant="primary"
                    outlined
                    size="md"
                    testId="action-item"
                    value={t(keys.action.EXPORT)}
                    onClick={handleExport}
                  />
                )}
              </div>
            </div>
            <SimpleTabSystem
              options={tabOptions}
              selected={selectedTab}
              setSelected={setSelectedTab}
              navigation={false}
            />
          </div>
        }
        rowCount={actionItemsCount || oldCount}
        customToolbar={
          <div className={styles.check}>
            <Form.Check
              size={'small'}
              checked={allWorkspaces}
              onChange={(e) => {
                if (e.target.checked) {
                  upsertParam('allWorkspaces', 'TRUE');
                } else {
                  deleteParam('allWorkspaces');
                }
              }}
            />
            <Text noMargin size="sm">
              {t(keys.templates.ALL_WORKSPACES)}
            </Text>
          </div>
        }
      />
      <ProgressModal
        show={isFetchingAllData}
        progress={progress}
        title="Exporting Data"
        message="One moment while we fetch your data. Please don't close this window!"
        icon="import_export"
      />
    </>
  );
}
