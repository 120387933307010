import { Icon } from '@mui/material';
import React from 'react';
import { useModal } from '../../providers/ModalProvider';

import Button from '../Button';
import styles from './ImageDropzone.module.scss';
import { modals } from '../../providers/modals';

export default function FileDropzone({
  title,
  onSubmit,
  onLoading,
  allWorkspaces,
  simple,
}) {
  const { openModal } = useModal();
  return (
    <div className={styles.container}>
      <Icon sx={{ fontSize: '3rem' }} className={styles.icon}>
        photo_camera
      </Icon>
      <Button
        className={styles.button}
        variant="secondary"
        size="md"
        outlined
        value="Choose File"
        onClick={() =>
          openModal({
            modalName: modals.fileUpload,
            variables: { title, onSubmit, onLoading, allWorkspaces, simple },
          })
        }
      />
    </div>
  );
}
