import styles from './AssessmentAnalysisCard.module.scss';
import { Text } from '../typography';
import { Icon } from '@mui/material';
import { useState } from 'react';

export default function AssessmentAnalysisCard({
  message,
  analysis,
  disclaimer,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div className={open ? styles.topContainer : styles.topContainerButton}>
        <div className={styles.header}>
          <Icon sx={{ color: 'white' }}>analytics</Icon>
          <Text noMargin weight="bold" color="white">
            SecondSight
          </Text>
        </div>
        <Icon onClick={() => setOpen(!open)} sx={{ color: 'white' }}>
          {open ? 'expand_less' : 'expand_more'}
        </Icon>
      </div>
      <div className={open ? styles.showContainer : styles.hideContainer}>
        <div className={styles.message}>
          <Text weight="semiBold" color="secondary" textAlign="center">
            {message}
          </Text>
        </div>
        <div className={styles.analysis}>
          {analysis?.map((a, index) => (
            <div className={styles.analysisList} key={a + index}>
              <Icon sx={{ color: 'red' }}>flag_circle</Icon>
              <Text weight="bold" noMargin>
                {a}
              </Text>
            </div>
          ))}
        </div>
        <div className={styles.disclaimer}>
          <Text size="sm" textAlign="center">
            {disclaimer}
          </Text>
        </div>
      </div>
    </div>
  );
}
