import React, { useMemo } from 'react';
import { Icon } from '@mui/material';
import styles from './FileViewerModal.module.scss';
import { Text } from '../../typography';
import classNames from 'classnames';
import PdfViewer from './PdfViewer';
import { fileType } from '../../../constants/misc';
import ImageViewer from './ImageViewer';
import EmptyViewer from './EmptyViewer';
import { truncate } from '../../../utilities';

export default function FileViewerModal({
  file,
  show = false,
  onClose,
  onDetailsClick,
}) {
  const Viewer = useMemo(() => {
    switch (file?.fileType) {
      case fileType.png:
      case 'png':
      case fileType.jpeg:
      case 'jpeg':
      case fileType.webp:
      case 'webp':
      case fileType.gif:
      case 'gif':
        return <ImageViewer file={file} />;
      case fileType.pdf:
      case 'pdf':
        return <PdfViewer file={file} />;
      default:
        return (
          <EmptyViewer
            file={file}
            onClose={onClose}
            onDetailsClick={onDetailsClick}
          />
        );
    }
  }, [file, onClose, onDetailsClick]);

  return (
    !!show && (
      <div className={show ? styles.show : styles.hide}>
        <div className={styles.background}>
          <div
            className={classNames(
              styles.container,
              show ? styles.showContainer : styles.hideContainer,
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.header}>
              <Text
                className={styles.header}
                size="lg"
                weight="semiBold"
                noMargin
              >
                {truncate(file.name, 20)}
              </Text>
              <Icon className={styles.closeIcon} onClick={onClose}>
                close
              </Icon>
            </div>
            <br />
            {Viewer}
            <br />
          </div>
        </div>
      </div>
    )
  );
}
