import React from 'react';
import classNames from 'classnames';
import styles from './Text.module.scss';

export default function Text({
  children,
  className,
  size = 'md',
  weight = 'regular',
  color = 'primary',
  noVerticalMargin = false,
  noMargin = false,
  textAlign = 'left',
  noSelect = false,
  truncate = false,
  onClick = () => {},
  hover,
  highlight = false,
  wordsToHighlight = [],
}) {
  const splitText =
    typeof children === 'string' &&
    children?.split(new RegExp(`(${wordsToHighlight.join('|')})`, 'gi'));

  return (
    <p
      onClick={onClick}
      className={classNames(
        styles[size],
        hover && styles.hover,
        noVerticalMargin && styles.noVerticalMargin,
        noSelect && styles.noSelect,
        noMargin && styles.noMargin,
        styles[weight.toLowerCase()],
        styles[color],
        styles[textAlign],
        truncate && styles.truncate,
        className,
      )}
    >
      {highlight
        ? splitText.map((part, index) =>
            wordsToHighlight.includes(part.toLowerCase()) ? (
              <b key={index}>{part}</b>
            ) : (
              part
            ),
          )
        : children}
    </p>
  );
}
