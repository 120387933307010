import React from 'react';
import { getLocalTime } from '../../utilities/time';
import { Text } from '../typography';
import styles from './ActionItemComment.module.scss';
import {
  priorityStringLookup,
  statusStringLookup,
} from './action_item_utilities';
import { useTranslation } from 'react-i18next';
import Label from './Label';
import { eventTypeLookup } from '../../pages/analytics/utilities';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider';
import { useWorkspace } from '../../providers/WorkspaceProvider';

const generateAssetLink = ({ id, workspaceId, assets }) => {
  const asset = assets?.find((a) => a.id === id) ?? {};
  return (
    <a href={`/workspace/${workspaceId}/asset/${id}`}>
      {asset?.make || asset?.model || asset?.unitNumber
        ? `${asset.make} ${asset.model} ${asset.unitNumber}`
        : 'an Asset'}
    </a>
  );
};

const getChangeDescription = (event, t, allUsers, workspaceVehicles) => {
  const {
    creator,
    eventType,
    information: { Delta: delta },
  } = event;
  const assignee =
    allUsers?.find((u) => u.id === delta?.assigneeId?.new) ?? null;
  const assigneeFullName = !!assignee
    ? `${assignee?.firstName} ${assignee?.lastName}`
    : 'a new user';
  const creatorFullName = `${creator.firstName} ${creator.lastName}`;
  const creatorLink = <a href={`/user/${creator.id}`}>{creatorFullName}</a>;
  const assigneeLink = (
    <a href={`/user/${delta?.assigneeId?.new}`}>{assigneeFullName}</a>
  );

  const errorMessage = (
    <div className={styles.activityString}>
      No recognizable changes detected.
    </div>
  );

  const participantId =
    delta?.participants?.new?.userId || delta?.participants?.old?.userId;

  const participant = allUsers?.find((u) => u.id === participantId) ?? null;

  const participantFullName = !!participant
    ? `${participant?.firstName} ${participant?.lastName}`
    : 'a new user';
  const participantLink = (
    <a href={`/user/${participantId}`}>{participantFullName}</a>
  );

  if (!delta || Object.keys(delta).length !== 1) {
    if (eventType === eventTypeLookup.createActionItem) {
      return (
        <div className={styles.activityString}>
          {creatorLink} created this action item on{' '}
          {getLocalTime(event.dateCreated).format('dddd MMMM Do YYYY, h:mm a')}
        </div>
      );
    } else {
      return errorMessage;
    }
  }

  const [key] = Object.keys(delta);

  switch (key) {
    case 'labels':
      return delta.labels.new ? (
        <div className={styles.labelString}>
          <div>{creatorLink} added the label </div>
          <Label name={delta.labels.new.name} color={delta.labels.new.color} />
        </div>
      ) : (
        <div className={styles.labelString}>
          <div>{creatorLink} removed the label </div>
          <Label name={delta.labels.old.name} color={delta.labels.old.color} />
        </div>
      );
    case 'assigneeId':
      return delta.assigneeId.new ? (
        <div className={styles.activityString}>
          {creatorLink} assigned this to {assigneeLink}.
        </div>
      ) : (
        <div className={styles.activityString}>
          {creatorLink} unassigned this action item
        </div>
      );
    case 'status':
      return (
        <div className={styles.activityString}>
          {creatorLink} updated the status from{' '}
          <strong>{t(statusStringLookup[delta.status.old].title)}</strong> to{' '}
          <strong>{t(statusStringLookup[delta.status.new].title)}</strong>.
        </div>
      );
    case 'priority':
      return (
        <div className={styles.activityString}>
          {creatorLink} updated the priority from{' '}
          <strong>{t(priorityStringLookup[delta.priority.old].name)}</strong> to{' '}
          <strong>{t(priorityStringLookup[delta.priority.new].name)}</strong>.
        </div>
      );
    case 'title':
      return (
        <div className={styles.activityString}>
          {creatorLink} updated the title from{' '}
          <strong>"{delta.title.old}"</strong> to{' '}
          <strong>"{delta.title.new}"</strong>.
        </div>
      );
    case 'description':
      return (
        <div className={styles.activityString}>
          {creatorLink} updated the description.
        </div>
      );
    case 'vehicleId':
      return delta.vehicleId.new ? (
        <div className={styles.activityString}>
          {creatorLink} linked{' '}
          {generateAssetLink({
            id: delta.vehicleId.new,
            workspaceId: event.workspaceId,
            assets: workspaceVehicles,
          })}
          .
        </div>
      ) : (
        <div className={styles.activityString}>
          {creatorLink} unlinked{' '}
          {generateAssetLink({
            id: delta.vehicleId.old,
            workspaceId: event.workspaceId,
            assets: workspaceVehicles,
          })}
          .
        </div>
      );
    case 'dueDate':
      return delta.dueDate.old && !delta.dueDate.new ? (
        <div className={styles.activityString}>
          {creatorLink} cleared the due date.
        </div>
      ) : (
        <div className={styles.activityString}>
          {creatorLink} set the Due Date to{' '}
          <strong>
            {getLocalTime(delta.dueDate.new).format('dddd, MMMM Do YYYY')}
          </strong>
          .
        </div>
      );
    case 'participants':
      return delta?.participants?.new ? (
        <div className={styles.activityString}>
          {creatorLink} added{' '}
          {creator?.id === participantId ? 'themselves' : participantLink} as a
          participant.
        </div>
      ) : (
        <div className={styles.activityString}>
          {creatorLink} removed{' '}
          {creator?.id === participantId ? 'themselves' : participantLink} as a
          participant.
        </div>
      );
    case 'attachments':
      return delta?.attachments?.new ? (
        <div className={styles.activityString}>
          {creatorLink} added an attachment.
        </div>
      ) : (
        <div className={styles.activityString}>
          {creatorLink} removed an attachment.
        </div>
      );
    case 'private':
      return delta?.private?.new ? (
        <div className={styles.activityString}>
          {creatorLink} marked this as private
        </div>
      ) : (
        <div className={styles.activityString}>
          {creatorLink} marked this as public
        </div>
      );
    default:
      return errorMessage;
  }
};

export default function ActionItemHistory({ event }) {
  const { t } = useTranslation();
  const { allUsers } = useWorkspacePermissions();
  const { workspaceVehicles } = useWorkspace();

  return (
    <div className={styles.container}>
      <img
        className={styles.avatar}
        src={event.creator.avatarUrl}
        alt={`${event.creator.firstName} ${event.creator.lastName}s avatar`}
      />
      <div className={styles.rightContainer}>
        <div className={styles.header}>
          <Text
            size="md"
            weight="bold"
            noMargin
          >{`${event.creator.firstName} ${event.creator.lastName}`}</Text>
          <Text size="sm" noMargin color="secondary">
            {getLocalTime(event.dateCreated).fromNow()}
          </Text>
        </div>
        {getChangeDescription(event, t, allUsers, workspaceVehicles)}
      </div>
    </div>
  );
}
