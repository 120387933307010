import styles from './BatchUpdateButtons.module.scss';
import { Button } from '../../components';
import { useModal } from '../../providers/ModalProvider';
import { strings } from './Expenses';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function BatchUpdateButtons({
  toApprove,
  toPay,
  type,
  onSubmit,
}) {
  const { t } = useTranslation();
  const { openConfirmationModal } = useModal();
  return (
    <div className={styles.container}>
      {!!toApprove?.length && (
        <Button
          value="Approve"
          icon="check"
          variant="success"
          outlined
          onClick={() =>
            openConfirmationModal({
              title: t(keys.common.BATCH_UPDATE_TITLE, {
                variable: t(keys.common.EXPENSE),
                action: t(keys.common.BATCH_UPDATE_APPROVAL),
              }),
              description: t(keys.common.BATCH_UPDATE_APPROVE_MESSAGE, {
                count: toApprove.length,
                type,
              }),
              variant: 'success',
              buttonText: t(keys.action.CONFIRM),
              onSubmit: () =>
                onSubmit({
                  ids: toApprove.map((a) => a.id),
                  status: strings.Approved,
                }),
            })
          }
        />
      )}
      {!!toPay?.length && (
        <Button
          value="Complete"
          icon="attach_money"
          variant="purple"
          outlined
          onClick={() =>
            openConfirmationModal({
              title: t(keys.common.BATCH_UPDATE_TITLE, {
                variable: t(keys.common.BATCH_UPDATE_PAYMENT),
                action: t(keys.common.BATCH_UPDATE_PROCESSING),
              }),
              description: t(keys.common.BATCH_UPDATE_PAY_MESSAGE, {
                count: toPay.length,
                type,
              }),
              variant: 'purple',
              buttonText: t(keys.action.CONFIRM),
              onSubmit: () =>
                onSubmit({
                  ids: toPay.map((p) => p.id),
                  status: strings.Complete,
                }),
            })
          }
        />
      )}
    </div>
  );
}
export const handleSelectAll = ({
  items,
  setSelected,
  setSelectAll,
  selectAll,
}) => {
  setSelectAll(!selectAll);
  setSelected(selectAll ? [] : items.map((t) => t.id));
};

export const handleRowSelect = ({
  item,
  setSelectAll,
  selected,
  setSelected,
}) => {
  setSelectAll(false);
  const isSelected = selected.includes(item.id);
  setSelected(
    isSelected
      ? selected?.filter((selectedId) => selectedId !== item.id)
      : [...selected, item.id],
  );
};

export const process = (items, selected, status) => {
  return items?.filter((item) => {
    return selected.includes(item.id) && item.status === status;
  });
};
