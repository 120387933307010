import styles from './FileUploadInput.module.scss';
import { Text } from '../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useState } from 'react';

//this is used to override the default file input modal for translation

export default function FileUploadInput({ onChange, accept }) {
  const { t } = useTranslation();
  const [selectedFileName, setSelectedFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onChange(event);
      setSelectedFileName(file.name);
    } else {
      setSelectedFileName('');
    }
  };
  return (
    <div className={styles.container}>
      <label>
        <Text
          noMargin
          weight="semiBold"
          className={styles.button}
          textAlign="center"
        >
          <input
            className={styles.hide}
            type={'file'}
            accept={accept || '*/*'}
            onChange={handleFileChange}
          />
          {t(keys.action.SELECT_VARIABLE, { variable: t(keys.common.FILE) })}
        </Text>
      </label>
      <Text noMargin className={styles.text}>
        {selectedFileName
          ? selectedFileName
          : t(keys.action.SELECTED, { variable: t(keys.common.NONE) })}
      </Text>
    </div>
  );
}
