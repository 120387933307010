export const actionItemTypeIcons = {
  FLEET: {
    icon: 'local_shipping',
    color: 'yellow',
  },
  HR: {
    icon: 'groups',
    color: 'blue',
  },
  INSPECTION: {
    icon: 'search',
    color: 'purple',
  },
  SERVICE: {
    icon: 'build',
    color: 'red',
  },
  HSE: {
    icon: 'health_and_safety',
    color: 'red',
  },
};
