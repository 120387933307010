import Modal from './Modal';
import { useState, useMemo } from 'react';
import { useCurrentUser } from '../../providers/UserProvider';
import { useApolloClient } from '@apollo/client';
import { CSVHeaders, formatCSVData, strings } from '../../pages/hr/Expenses';
import { loader } from 'graphql.macro';
import { showToast } from '../../graphql/cache/modal';
import { toastVariant, toastLength } from '../../constants/misc';
import { DropdownText } from '../dropdowns/DropdownText';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { Text } from '../typography';
import styles from './ExpenseReportModal.module.scss';

import moment from 'moment';
import { exportToExcel } from '../excel/ExcelExport';

const expenseQuery = loader('./ExpenseReportExportModal.graphql');

export default function ExpenseReportExportModal({ show, onClose, title }) {
  const { user, isAdmin } = useCurrentUser();
  const client = useApolloClient();
  const defaultState = {
    personnel: 'Personal',
    startDate: null,
    endDate: null,
  };
  const personnelOptions = { PERSONAL: 'Personal', TEAM: 'Team' };
  const options = Object.values(personnelOptions);
  const [selectedOptions, setSelectedOptions] = useState(defaultState);
  const onHide = () => {
    setSelectedOptions(defaultState);
    onClose();
  };
  const dateRange = useMemo(() => {
    return (
      `${selectedOptions.startDate?.split(' ')[0]} - ${
        selectedOptions.endDate?.split(' ')[0]
      }` || null
    );
  }, [selectedOptions]);

  const exportErrorCallback = (error) => {
    onHide();
    showToast({
      title: 'Error Exporting Report',
      message: `There was an error during export: ${error}`,
      time: toastLength.lg,
      variant: toastVariant.danger,
    });
  };

  const filters = useMemo(() => {
    let baseFilters = [
      { field: 'status', operator: 'ne', value: strings.Draft },
    ];
    if (selectedOptions.personnel === 'Personal') {
      baseFilters.push({
        field: 'creatorId',
        operator: 'eq',
        value: `${user?.id}`,
      });
    }
    if (selectedOptions.startDate && selectedOptions.endDate) {
      baseFilters.push({
        field: 'dateOfExpense',
        operator: 'between',
        value: [selectedOptions.startDate, selectedOptions.endDate],
      });
    }
    return baseFilters;
  }, [
    selectedOptions?.endDate,
    selectedOptions?.personnel,
    selectedOptions?.startDate,
    user?.id,
  ]);
  const fileName = useMemo(() => {
    return selectedOptions.personnel === personnelOptions.PERSONAL
      ? `${user.lastName}_${user.firstName}`
      : personnelOptions.TEAM.toUpperCase();
  }, [
    personnelOptions.PERSONAL,
    personnelOptions.TEAM,
    selectedOptions.personnel,
    user.firstName,
    user.lastName,
  ]);

  async function generateExpenseReport() {
    let allExpenses = [];
    let hasMoreData = true;
    const pageSize = 500;
    let page = 1;
    try {
      while (hasMoreData) {
        const { data: { expenses = [] } = {} } = await client.query({
          query: expenseQuery,
          fetchPolicy: 'network-only',
          variables: {
            options: {
              filters,
              pageSize,
              page,
              sort: [{ field: 'dateOfExpense', order: 'asc' }],
            },
          },
        });
        allExpenses = [...allExpenses, ...expenses];
        ++page;
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (expenses.length < 500) break;
      }
    } catch (error) {
      showToast({
        title: 'Error Exporting Timecards',
        variant: toastVariant.danger,
        message: `An Error occured: ${error} `,
      });
      onHide();
    }
    if (!!!allExpenses.length) {
      showToast({
        title: 'Check Your Filters',
        variant: toastVariant.warning,
        message: 'No data available for export.',
        time: toastLength.md,
      });
      onHide();
      return;
    } else {
      exportToExcel({
        data: allExpenses.map((expense) => formatCSVData(expense)),
        sheetName: dateRange,
        headerMap: CSVHeaders,
        exportedBy: `${user.firstName} ${user.lastName}`,
        dateRange,
        type: 'Expense',
        fileName: `${fileName}_Expense-Report-${selectedOptions.startDate}-${selectedOptions.endDate}`,
        reportType: ['Report Type', `Expense Report - ${fileName}`],
        errorCallback: exportErrorCallback,
      });
    }
  }
  async function handleGenerateReport() {
    await generateExpenseReport();
  }
  return (
    <Modal
      open={show}
      onClose={onHide}
      title={title}
      submitText="Get Report"
      submitDisabled={
        !moment(selectedOptions.startDate).isValid() ||
        !moment(selectedOptions.endDate).isValid()
      }
      onSubmit={handleGenerateReport}
    >
      <div>
        <DropdownText
          title="Personnel Type"
          items={isAdmin ? options : [options[0]]}
          onChange={(selected) =>
            setSelectedOptions({
              personnel: selected,
              startDate: null,
              endDate: null,
            })
          }
          selected={selectedOptions.personnel}
        />
        <Text weight="semiBold">Date Range</Text>
        <div className={styles.dateSelectors}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={moment(selectedOptions?.startDate)}
              onChange={(newMoment) => {
                const updatedDate = moment(newMoment).format('YYYY-MM-DD');
                setSelectedOptions({
                  ...selectedOptions,
                  startDate: updatedDate,
                });
              }}
            />
            <DatePicker
              value={moment(selectedOptions?.endDate)}
              onChange={(newMoment) => {
                const updatedEndDate = moment(newMoment).format('YYYY-MM-DD');
                setSelectedOptions({
                  ...selectedOptions,
                  endDate: updatedEndDate,
                });
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    </Modal>
  );
}
