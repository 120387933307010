import React, { useState, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import styles from './AddQuestionMenu.module.scss';
import { Close } from '@mui/icons-material';
import AddQuestionButton from './AddQuestionButton';
import { Form, Row, Col } from 'react-bootstrap';
import Button from '../Button';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { Text } from '../typography';
import ColumnInput from '../ColumnInput';
import OptionsSelector from '../OptionsSelector';
import { DropdownText } from '../dropdowns/DropdownText';
import {
  dateOptions,
  mockQuestionTypes,
  testQuestionOptions,
} from '../../utilities/inspection';

import { useTranslation } from 'react-i18next';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';

const addQuestionMutation = loader(
  '../../graphql/mutations/inspection_template_item_create.graphql',
);

export default function AddQuestionMenu({
  category,
  refetchCategory,
  showModal,
  onHide,
}) {
  const [selected, setSelected] = useState('none');
  const [question, setQuestion] = useState('');
  const [description, setDescription] = useState('');
  const [selectType, setSelectType] = useState('single');
  const [isRequired, setIsRequired] = useState(false);
  const [addQuestion] = useMutation(addQuestionMutation);
  const [selectionOptions, setSelectionOptions] = useState('');
  const [columns, setColumns] = useState([]);
  const [testSelectionOptions, setTestSelectionOptions] = useState('');
  const [dateType, setDateType] = useState('DATE');
  const { t } = useTranslation();
  const [numberLimit, setNumberLimit] = useState({
    min: undefined,
    max: undefined,
  });

  const generateAdditionalData = () => {
    switch (selected) {
      case 'Select':
        return selectionOptions?.options.map((o) => o.text).join('|');
      case 'Table':
        return columns.join('|');
      case 'Test':
        return testSelectionOptions.split(', ').join('|');
      case 'Number':
        return numberLimit.min || numberLimit.max
          ? `${numberLimit.min ?? ''}|${numberLimit.max ?? ''}`
          : null;
      default:
        return null;
    }
  };

  const questionInputType = useMemo(() => {
    switch (selected) {
      case 'Select':
      case 'Signature':
      case 'File':
        return `${selected.toUpperCase()}_${selectType.toUpperCase()}`;
      case 'Text':
      case 'Image':
      case 'User':
        if (selectType === 'multi') {
          return `${selected.toUpperCase()}_${selectType.toUpperCase()}`;
        } else {
          return `${selected.toUpperCase()}`;
        }
      case 'Date':
        return `${dateType.toUpperCase()}`;
      default:
        return `${selected.toUpperCase()}`;
    }
  }, [dateType, selectType, selected]);

  const invalidLimits =
    !isNaN(numberLimit?.min) &&
    !isNaN(numberLimit?.max) &&
    parseFloat(numberLimit?.max) < parseFloat(numberLimit?.min);

  const submitDisabled =
    !question ||
    selected === 'none' ||
    (selected?.toUpperCase().includes('SELECT') &&
      !selectionOptions?.options?.length) ||
    (selected?.toUpperCase().includes('TABLE') && !columns?.length) ||
    invalidLimits;

  return (
    <>
      <div
        className={classNames(
          styles.background,
          showModal ? styles.show : styles.hide,
        )}
      >
        <div className={styles.parent}>
          <div className={styles.header}>
            <Text weight="bold" size="lg">
              {t(keys.templates.ADD_TO, { variable: category.title })}
            </Text>
            <Close
              className={styles.closeIcon}
              onClick={() => {
                onHide();
                setDescription('');
                setQuestion('');
                setSelected('none');
                setColumns([]);
                setIsRequired(false);
                setSelectionOptions('');
                setSelectType('single');
                setTestSelectionOptions('');
                setDateType('DATE');
              }}
            />
          </div>
          <br />
          <Form noValidate onSubmit={(e) => e.preventDefault()}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Text
                  noMargin
                  weight="semiBold"
                  size="md"
                  className={styles.formTitle}
                >
                  {t(keys.templates.QUESTION)}
                </Text>
                <Form.Control
                  autoFocus={false}
                  required
                  type="text"
                  placeholder={t(keys.templates.QUESTION_TITLE_PLACEHOLDER)}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Text
                  noMargin
                  weight="semiBold"
                  size="md"
                  className={styles.formTitle}
                >
                  {`${t(keys.common.DESCRIPTION)} (${t(keys.common.OPTIONAL)})`}
                </Text>
                <Form.Control
                  autoFocus={false}
                  required
                  as="textarea"
                  type="text"
                  placeholder={t(
                    keys.templates.QUESTION_DESCRIPTION_PLACEHOLDER,
                  )}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Check
                  required
                  checked={isRequired}
                  id="switch"
                  type="switch"
                  label={t(keys.common.REQUIRED)}
                  onChange={(e) => {
                    setIsRequired(e.target.checked);
                  }}
                />
              </Form.Group>
            </Row>
          </Form>
          {selected === 'Select' ||
          selected === 'Signature' ||
          selected === 'Text' ||
          selected === 'File' ||
          selected === 'Image' ||
          selected === 'User' ? (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Text
                  noMargin
                  weight="semiBold"
                  size="md"
                  className={styles.formTitle}
                >
                  {t(keys.action.SELECT)}
                </Text>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Check
                      inline
                      checked={selectType === 'single'}
                      type="radio"
                      name="selectOptions"
                      label={
                        selected === 'Text'
                          ? t(keys.templates.SINGLE_LINE)
                          : t(keys.templates.SINGLE)
                      }
                      value="single"
                      onChange={(e) => setSelectType(e.target.value)}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      name="selectOptions"
                      checked={selectType === 'multi'}
                      label={
                        selected === 'Text'
                          ? t(keys.templates.MULTI_LINE)
                          : t(keys.templates.MULTI)
                      }
                      value="multi"
                      onChange={(e) => setSelectType(e.target.value)}
                    />
                  </Form.Group>
                </Row>
              </Form>

              {selected === 'Select' && (
                <>
                  <Text
                    noMargin
                    weight="semiBold"
                    size="md"
                    className={styles.formTitle}
                  >
                    {t(keys.common.OPTIONS)}
                  </Text>
                  <OptionsSelector
                    selected={selectionOptions}
                    onSelectionChanged={(opt) => {
                      setSelectionOptions(opt);
                    }}
                  />
                </>
              )}
            </>
          ) : null}
          {selected === 'Number' && (
            <div>
              <Text weight="semiBold">
                {t(keys.assessments.VALUE_TOLERANCE)}
              </Text>
              <div className={styles.numberLimit}>
                <Form.Control
                  placeholder="Min"
                  type="number"
                  isInvalid={invalidLimits}
                  onChange={(e) =>
                    setNumberLimit({ ...numberLimit, min: e.target.value })
                  }
                />
                <Form.Control
                  placeholder="Max"
                  type="number"
                  isInvalid={invalidLimits}
                  onChange={(e) =>
                    setNumberLimit({ ...numberLimit, max: e.target.value })
                  }
                />
              </div>
              {invalidLimits ? (
                <Text color="red">{t(keys.assessments.MIN_MAX)}</Text>
              ) : null}
            </div>
          )}

          {selected === 'Table' && (
            <ColumnInput columns={columns} onChange={setColumns} />
          )}
          {selected === 'Date' && (
            <Form.Group onChange={(e) => setDateType(e.target.value)}>
              <Text
                noMargin
                weight="semiBold"
                size="md"
                className={styles.formTitle}
              >
                {t(keys.action.SELECT)}
              </Text>
              {Object.entries(dateOptions).map(([key, value]) => (
                <Form.Check
                  key={`${key}-${value.title}`}
                  inline
                  type="radio"
                  label={t(getTranslationKey(key, 'templates'))}
                  name="dateOptions"
                  value={key}
                  defaultChecked={key === dateType}
                />
              ))}
            </Form.Group>
          )}
          <br />
          {selected === 'Test' && (
            <DropdownText
              items={testQuestionOptions}
              title={t(keys.templates.TEST_TYPE)}
              selected={testSelectionOptions}
              onChange={setTestSelectionOptions}
              namespace="templates"
            />
          )}

          <Text
            noMargin
            weight="semiBold"
            size="md"
            className={styles.formTitle}
          >
            {t(keys.common.TYPE)}
          </Text>
          <div className={styles.container}>
            {mockQuestionTypes.map(({ icon, name, key }) => (
              <AddQuestionButton
                key={name}
                icon={icon}
                name={name}
                selected={selected === name}
                onClick={() => setSelected(selected === name ? 'none' : name)}
              />
            ))}
          </div>
          <br />
          <Button
            className={styles.addButton}
            value={t(keys.action.ADD, { variable: null })}
            size="md"
            icon="add"
            disabled={submitDisabled}
            onClick={() => {
              addQuestion({
                variables: {
                  title: question,
                  description,
                  inputType: questionInputType,
                  templateId: category.inspectionTemplateId,
                  categoryId: category.id,
                  required: isRequired,
                  additionalData: generateAdditionalData(),
                },
              }).then(() => {
                setQuestion('');
                setSelected('none');
                setSelectionOptions('');
                setNumberLimit({ min: undefined, max: undefined });
                setDescription('');
                setDateType('DATE');
                setSelectType('single');
                setColumns([]);
                setIsRequired(false);
                setTestSelectionOptions('');
                refetchCategory();
                onHide();
              });
            }}
          />
        </div>
      </div>
    </>
  );
}
